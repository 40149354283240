import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Logout from "./components/logout";
import Header from "./components/header";
import Profil from "./components/profil/profil";
import HomePage from "./components/homepage/Homepage";
import NotFound from "./components/common/NotFound";
import Obchod from "./components/obchod/Obchod";
import ObchodPrilezitost from "./components/obchod/ObchodPrilezitost";
import Faktura from "./components/fakturace/Faktura";
import Fakturace from "./components/fakturace/Fakturace";
import StatistikaObchodu from "./components/obchod/StatistikaObchodu";
import PrehledZapujcek from "./components/zapujcky/PredhledZapujcek";
import Zapujcka from "./components/zapujcky/Zapujcka";
import NovaZapujcka from "./components/zapujcky/NovaZapujcka";
import Dealy from "./components/dealy/Dealy";
import Deal from "./components/dealy/Deal";
import NovyDeal from "./components/dealy/NovyDeal";
import NabidkaVyprodej from "./components/vyprodej/VyprodejNabidka";
import VyprodejKosik from "./components/vyprodej/VyprodejKosik";
import LicencePrehled from "./components/licence/LicencePrehled";
import ProduktyPrehled from "./components/produkty/ProduktyPrehled";
import ProduktDetail from "./components/produkty/ProduktDetail";
import PrehledAkci from "./components/kalendar/PrehledAkci";
import DetailAkce from "./components/kalendar/DetailAkce";
import Novinka from "./components/homepage/Novinka";
import Footer from "./components/Footer";
import Dodavatel from "./components/dodavatel/Dodavatel";
import NovaFaktura from "./components/dodavatel/NovaFaktura";
import NovaPriloha from "./components/dodavatel/NovaPriloha";
import Prilohy from "./components/dodavatel/Prilohy";
import Bonus from "./components/bonus/Bonus";
import BonusProdukty from "./components/bonus/BonusProdukty";
import BonusDetail from "./components/bonus/BonusDetail";
import BonusProdukt from "./components/bonus/BonusProdukt";
import BonusMeVeracoiny from "./components/bonus/BonusMeVeracoiny";
import NovyPozadavek from "./components/dodavatel/NovyPozadavek";
import NovyNarok from "./components/dodavatel/NovyNarok";
import LoginForm from "./components/loginForm";

class MainApp extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="main-wrapper ">
          <Header />
          <div id="main">
            <Switch>
              <Route path="/login" component={LoginForm} />
              <Route path="/logout" component={Logout} />
              <Route path="/profil" component={Profil} />
              <Route path="/dodavatel/prilohy/:id" component={Prilohy} />
              <Route path="/dodavatel/novyPozadavek/:id" component={NovyPozadavek} />
              <Route path="/dodavatel/novyNarok/:id" component={NovyNarok} />
              <Route path="/dodavatel/novaPriloha/:id" component={NovaPriloha} />
              <Route path="/dodavatel/novaFaktura/:id" component={NovaFaktura} />
              <Route path="/dodavatel" component={Dodavatel} />
              <Route path="/obchod/firma/:klient_id" component={Obchod} />
              <Route path="/obchod/:id" component={ObchodPrilezitost} />
              <Route path="/obchod" component={Obchod} />
              <Route path="/bonus/produkt/:bp_produkt_id" component={BonusProdukt} />
              <Route path="/bonus/detail" component={BonusDetail} />
              <Route path="/bonus/meVeracoiny" component={BonusMeVeracoiny} />
              <Route path="/bonus/:bp_skupina_id" component={BonusProdukty} />
              <Route path="/bonus" component={Bonus} />
              <Route
                  path="/produkty/:id/:nazev/:tab/:dir_id/:nazev2/:item_id/:item_nazev"
                  component={ProduktDetail}
              />
              <Route
                  path="/produkty/:id/:nazev/:tab/:dir_id/:nazev2/:item_id"
                  component={ProduktDetail}
              />
              <Route
                  path="/produkty/:id/:nazev/:tab/:dir_id/:nazev2"
                  component={ProduktDetail}
              />
              <Route
                path="/produkty/:id/:nazev/:tab/:dir_id"
                component={ProduktDetail}
              />
              <Route
                path="/produkty/:id/:nazev/:tab"
                component={ProduktDetail}
              />
              <Route path="/produkty/:id/:nazev" component={ProduktDetail} />
              <Route path="/produkty" component={ProduktyPrehled} />
              <Route path="/fakturace/:id" component={Faktura} />
              <Route path="/fakturace" component={Fakturace} />
              <Route path="/statistika-obchodu" component={StatistikaObchodu} />
              <Route
                path="/zapujcky-zarizeni/nova-zapujcka"
                component={NovaZapujcka}
              />
              <Route path="/zapujcky-zarizeni/:id" component={Zapujcka} />
              <Route path="/zapujcky-zarizeni" component={PrehledZapujcek} />
              <Route path="/registrace-dealu/novy-deal" component={NovyDeal} />
              <Route path="/registrace-dealu/:id" component={Deal} />
              <Route path="/registrace-dealu" component={Dealy} />
              <Route path="/kosik" component={VyprodejKosik} />
              <Route path="/vyprodej-skladu" component={NabidkaVyprodej} />
              <Route path="/licence-zarizeni" component={LicencePrehled} />
              <Route path="/novinky/:id/:nazev" component={Novinka} />
              <Route path="/kalendar/:id/:nazev" component={DetailAkce} />
              <Route path="/kalendar" component={PrehledAkci} />
              <Route path="/" exact component={HomePage} />
              <Route path="/not-found" component={NotFound} />
              <Redirect to="/not-found" />
            </Switch>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default MainApp;
