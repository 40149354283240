import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { connect } from "react-redux";
import { vlozitDoKosiku, ukazKosik } from "./../../actions/vyprodejSkladu";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import VyprodejPolozka from "./VyprodejPolozka";
import Modal from "../Modal";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";

class NabidkaVyprodej extends Component {
  state = { nabidka: [] };
  constructor(props) {
    super(props);
    this.onVlozitDoKosiku = this.onVlozitDoKosiku.bind(this);
  }

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const nabidka = await httpService.post(
      config.apiEndpoint + "vyprodejSkladu/nabidka.php",
      { firma_id: aktualniFirma.firma_id, partner_id: this.props.partnerId }
    );
    const user = authService.getCurrentUser();
    this.setState({
      aktualniFirma,
      nabidkaLoaded: true,
      nabidka: nabidka.data,
      user,
    });
    this.kosikInit();
  }

  kosikInit = () => {
    const obsahKosiku = this.props.obsahKosiku;
    const nabidka = this.state.nabidka;
    if (obsahKosiku && obsahKosiku.length && nabidka && nabidka.length) {
      obsahKosiku.map((p) =>
        nabidka.map((n, index) =>
          p.katalog_id === n.katalog_id ? (nabidka[index] = p) : null
        )
      );
      this.setState({ nabidka });
    }
  };

  onVlozitDoKosiku = (val) => {
    this.props.onVlozitDoKosiku(val);
  };
  onNahrajKosik = () => {
    this.props.onNahrajKosik();
  };

  doKosiku = (polozka, sn) => {
    let obsahKosiku = [];
    if (this.props.obsahKosiku) {
      obsahKosiku = [...this.props.obsahKosiku];
    }

    let index1 = obsahKosiku.findIndex(
      (p) => p.katalog_id === polozka.katalog_id
    );
    index1 = index1 === -1 ? obsahKosiku.length : index1;
    obsahKosiku[index1] = polozka;
    let index2 = obsahKosiku[index1].polozky.findIndex((p) => p.sn === sn);
    obsahKosiku[index1].polozky[index2].rezervace = true;
    obsahKosiku[index1].polozky[index2].login = this.state.user.login;
    this.onVlozitDoKosiku(obsahKosiku);
    toast.warn("Zboží bylo vloženo do košíku");
  };

  ukazDetail = (polozka) => {
    this.setState({
      detailShow: true,
      detailNadpis: polozka.info.typ + " " + polozka.info.vyrobek,
      detailPolozka: polozka,
    });
  };
  skyjDetail = () => {
    this.setState({ detailShow: false });
  };

  render() {
    const {
      nabidka,
      nabidkaLoaded,
      detailNadpis,
      detailShow,
      detailPolozka,
    } = this.state;

    const lang = authService.getLang();

    return (
      <React.Fragment>
        <Modal
          title={detailNadpis}
          show={detailShow}
          onClose={this.skyjDetail}
          hideSubmit={true}
          width="700px"
          key={detailPolozka ? detailPolozka.info.prilezitost_ciselnik_id : 0}
        >
          {detailPolozka ? (
            <React.Fragment>
              <div className="row">
                <div className="col-4">
                  <img
                    src={
                      "//api.exclusive-networks.cz/images/logoprodukt.php?prilezitost_ciselnik_id=" +
                      detailPolozka.info.prilezitost_ciselnik_id
                    }
                    alt=""
                  />
                </div>
                <div className="col-8">
                  <h4>SKU: {detailPolozka.info.sku}</h4>
                  <p>{lang.skladem} {detailPolozka.polozky.length} ks</p>
                  {detailPolozka.soubory ? (
                    <React.Fragment>
                      <h5>{lang.datasheety_a_soubory}</h5>
                      <ul className="product-features">
                        {detailPolozka.soubory.map((s) => (
                          <li key={s.soubor_id}>
                            <a
                              href={
                                "https://partner.exclusive-networks.cz/datasheet/" +
                                s.soubor_id +
                                "/" +
                                s.nazev_souboru
                              }
                            >
                              {s.nazev}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="padding-top-10">
                <h5>{lang.dostupne_polozky}</h5>
                <MobileView>
                  {" "}
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>{lang.cena_vyprodeje}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {detailPolozka.polozky.map((p) => (
                        <tr key={p.sn}>
                          <td>{p.sn}</td>

                          <td style={{ textAlign: "center" }}>
                            {p.rezervace ? (
                              <button className="btn btn-secondary">
                                <span className="icon icon-shopping-bag" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  this.doKosiku(detailPolozka, p.sn)
                                }
                              >
                                <span className="icon icon-shopping-bag" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </MobileView>
                <BrowserView>
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>{lang.poznamka}</th>
                        <th>{lang.bezna_cena}</th>
                        <th>{lang.cena_vyprodeje}</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {detailPolozka.polozky.map((p) => (
                        <tr key={p.sn}>
                          <td>{p.sn}</td>
                          <td>{p.poznamka}</td>
                          <td style={{ textAlign: "right" }}>
                            <small>CZK</small>{" "}
                            <NumberFormat
                              value={detailPolozka.bezna_cena}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>
                          <td style={{ textAlign: "right" }}>
                            <small>CZK</small>{" "}
                            <NumberFormat
                              value={p.cena}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={0}
                              displayType="text"
                            />
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {p.rezervace ? (
                              <button className="btn btn-secondary">
                                <span className="icon icon-shopping-bag" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  this.doKosiku(detailPolozka, p.sn)
                                }
                              >
                                <span className="icon icon-shopping-bag" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </BrowserView>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </Modal>
        {!this.props.partnerId ? (
          <NadpisStranky
            nadpis={lang.vyprodej_skladu}
            obrazek="./images/piggybank.jpg"
          />
        ) : null}
        <div
          className={
            "padding-top-20" + (!this.props.partnerId ? " container" : "")
          }
        >
          {nabidkaLoaded ? (
            <React.Fragment>
              {nabidka && nabidka.length ? (
                <React.Fragment>
                  <div className="row">
                    {nabidka.map((n, index) => (
                      <div
                        key={n.katalog_id}
                        className={"col-md-" + (this.props.partnerId ? 4 : 3)}
                      >
                        <VyprodejPolozka
                          polozka={n}
                          doKosiku={this.doKosiku}
                          onDetailShow={this.ukazDetail}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                <div className="alert alert-danger">
                  {lang.aktualne_v_nabidce_nic_neni}
                </div>
              )}
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  obsahKosiku: state.vyprodejSkladu,
});
const mapActionsToProps = {
  onVlozitDoKosiku: vlozitDoKosiku,
  onNahrajKosik: ukazKosik,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NabidkaVyprodej);
