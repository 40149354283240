import React, { Component } from "react";

class Poznamka extends Component {
  state = {};
  render() {
    const { datum, login, poznamka, jmeno, prijmeni, image } = this.props;
    return (
      <React.Fragment>
        <div className="row cols-border">
          <div
            className="col-md-3 center padding-vertical-15"
            style={{ fontSize: "0.8em" }}
          >
            {image ? (
              <img
                src={image}
                alt={jmeno + " " + prijmeni}
                style={{ width: "80px", borderRadius: "50%" }}
              />
            ) : null}
            <div>
              {jmeno} {prijmeni}
            </div>
            <div>{datum}</div>
          </div>
          <div className="col-md-9 padding-vertical-15">{poznamka}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default Poznamka;
