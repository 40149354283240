import React, { Component } from "react";
import UserInfo from "./userInfo";
import KosikIcon from "./vyprodej/KosikIcon";
import authService from "../services/authService";
import auth from "../services/authService";

class header extends Component {
  state = {};

  render() {
    const lang = authService.getLang();
    const user = auth.getCurrentUser();

    const { hideMenu } = this.props;
    return (
      <header id="header" className="awe-menubar-header">
        <nav className="awemenu-nav headroom" data-responsive-width="1200">
          <div className="container">
            <div className="awemenu-container">
              <div className="navbar-header">
                {!hideMenu && (
                  <ul className="navbar-icons">
                    <UserInfo />
                    <KosikIcon />
                  </ul>
                )}
              </div>

              <div className="awe-logo">
                <a href="#/" title="">
                  <img
                    src="images/logo-exn.png"
                    alt=""
                    style={{ width: "150px" }}
                  />
                </a>
              </div>

              {!hideMenu ? (
                <ul className="awemenu awemenu-right">
                  <li className="awemenu-item">
                    <a href="#/profil" title="">
                      <span>{lang.profil}</span>
                    </a>
                  </li>
                  {user.dodavatel == 1 && (
                      <li className="awemenu-item">
                        <a href="#/dodavatel" title="">
                          <span>{lang.dodavatel}</span>
                        </a>
                      </li>
                  )}
                  <li className="awemenu-item">
                    <a href="#/obchod" title="">
                      <span>{lang.obchod}</span>
                    </a>

                    <ul
                      className="awemenu-submenu awemenu-megamenu"
                      data-width="350px"
                      data-animation="fadeup"
                    >
                      <li className="awemenu-megamenu-item">
                        <div className="container-fluid">
                          <ul className="list-unstyled">
                            <li className="awemenu-item">
                              <a href="#/obchod" title="">
                                <span>{lang.obchodni_pripady}</span>
                              </a>
                            </li>
                            <li className="awemenu-item">
                              <a href="#/fakturace" title="">
                                <span>{lang.fakturace}</span>
                              </a>
                            </li>
                            <li className="awemenu-item">
                              <a href="#/statistika-obchodu" title="">
                                <span>{lang.statistika_obchodu}</span>
                              </a>
                            </li>
                            <li className="awemenu-item">
                              <a href="#/vyprodej-skladu" title="">
                                <span>{lang.vyprodej_skladu}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="awemenu-item">
                    <a href="#/registrace-dealu">
                      <span>{lang.dealy}</span>
                    </a>
                  </li>
                  <li className="awemenu-item">
                    <a href="#/zapujcky-zarizeni">
                      <span>{lang.zapujcky}</span>
                    </a>
                  </li>
                  <li className="awemenu-item">
                    <a href="#/licence-zarizeni">
                      <span>{lang.obnovy_licenci}</span>
                    </a>
                  </li>
                  <li className="awemenu-item">
                    <a href="#/produkty">
                      <span>{lang.produkty}</span>
                    </a>
                  </li>
                  <li className="awemenu-item">
                    <a href="#/kalendar">
                      <span>{lang.kalendar}</span>
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="awemenu awemenu-right" />
              )}
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default header;
