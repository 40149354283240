import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { Link } from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import Poznamky from "./../common/Poznamky";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PotvrditDeal from "./PotvrditDeal";

class Deal extends Component {
  state = {};
  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    if (aktualniFirma.partnerweb_obchod) {
      const dealId = this.props.match.params.id;
      const deal = await httpService.post(
        config.apiEndpoint + "dealy/deal.php",
        { firma_id: aktualniFirma.firma_id, deal_id: dealId }
      );
      const potvrditDeal =
        deal.data.smim_schvalit && parseInt(deal.data.stav) === 0
          ? true
          : false;
      this.setState({
        deal: deal.data,
        dealLoaded: true,
        dealId,
        potvrditDeal,
      });
    }
  }

  potvrdit = (stav) => {
    const { deal } = this.state;
    let stavColor = "warning";
    let stav_popis = "Čeká na potvrzení";
    switch (stav) {
      case -1:
        stavColor = "danger";
        stav_popis = "Zamítnut";
        break;
      case 1:
        stavColor = "success";
        stav_popis = "Potvrzeno";
        break;
    }
    const newDeal = { ...deal, stav, stav_popis, stavColor };
    this.setState({ deal: newDeal, potvrditDeal: false });
  };

  render() {
    const {
      aktualniFirma,
      dealId,
      deal,
      dealLoaded,
      potvrditDeal,
    } = this.state;
    const lang = authService.getLang();
    console.log(deal);
    if (aktualniFirma && !aktualniFirma.partnerweb_obchod) {
      return <div className="alert alert-danger">Nemáte přístup k dealům</div>;
    }
    return (
      <React.Fragment>
        <PotvrditDeal
          show={potvrditDeal}
          title="Potvrdit deal"
          onClose={() => this.setState({ potvrditDeal: false })}
          dealId={dealId}
          onSubmit={this.potvrdit}
        />
        <NadpisStranky
          nadpis={dealId ? "Detail dealu #" + dealId : null}
          obrazek="./images/deal.jpg"
        />
        <div className="container">
          {dealLoaded ? (
            <React.Fragment>
              <div className="center padding-20 border-bottom margin-bottom-10">
                <Link to="/registrace-dealu" className="btn btn-primary">
                  {lang.zpet_na_dealy}
                </Link>
              </div>
              <h3 className="center light upper">{deal.nazev_projektu}</h3>
              <div className="row cols-border">
                <div className="col-md-6 obchodniPripad infotab">
                  <ul>
                    <li>
                      {deal.brandy &&
                        deal.brandy.map((b) => (
                          <img
                            key={b}
                            src={
                              "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                              b
                            }
                            style={{
                              width: "65px",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                        ))}
                      <span className="value">
                        <span className={"badge badge-" + deal.stavColor}>
                          {deal.stav_popis}
                        </span>
                        {deal.smim_schvalit && parseInt(deal.stav) === 0 ? (
                          <div>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                this.setState({ potvrditDeal: true })
                              }
                            >
                              Potvrdit
                            </button>
                          </div>
                        ) : null}
                      </span>
                    </li>
                    <li>
                      {lang.registroval}
                      <span className="value">{deal.registroval}</span>
                    </li>
                    <li>
                      {lang.datum_registrace}
                      <span className="value">{deal.registroval_datum}</span>
                    </li>
                    <li>
                      {lang.datum_uzavreni}
                      <span className="value">{deal.datum_uzavreni}</span>
                    </li>
                    <li>
                      {lang.datum_realizace}
                      <span className="value">{deal.datum_realizace}</span>
                    </li>
                  </ul>
                  <h5 className="center upper light padding-10">
                    {lang.kontaktni_osoba_partnera}
                  </h5>
                  <ul>
                    <li>
                      {deal.partner_jmeno} {deal.partner_prijmeni}
                      <span className="value">{deal.partner_email1}</span>
                    </li>
                    <li>
                      {deal.partner_pozice}
                      <span className="value">{deal.partner_mobil}</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 obchodniPripad infotab">
                  <h5 className="center upper light padding-bottom-10">
                    {lang.zakaznik}
                  </h5>
                  <ul>
                    <li>{deal.zakaznik_firma_nazev}</li>
                    <li>
                      {lang.ic}: {deal.ico}
                      <span className="value">
                        {lang.dic}: {deal.dic}
                      </span>
                    </li>
                    <li>{deal.ulice}</li>
                    <li>
                      {deal.psc} {deal.mesto}
                    </li>
                  </ul>
                  <h5 className="center upper light padding-10">
                    {lang.kontaktni_osoba_zakaznika}
                  </h5>
                  <ul>
                    <li>
                      {deal.zakaznik_jmeno} {deal.zakaznik_prijmeni}
                      <span className="value">{deal.zakaznik_email1}</span>
                    </li>
                    {deal.zakaznik_pozice || deal.zakaznik_mobil ? (
                      <li>
                        {deal.zakaznik_pozice}
                        <span className="value">{deal.zakaznik_mobil}</span>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <hr />
              <div className="row cols-border">
                <div className="col-md-6">
                  <Tabs className="awe-nav-responsive">
                    <TabList className="awe-nav">
                      <Tab>{lang.specifikace_zarizeni}</Tab>
                      <Tab>{lang.dalsi_informace}</Tab>
                    </TabList>
                    <TabPanel className="padding-vertical-20">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: deal.specifikace,
                        }}
                      />
                    </TabPanel>
                    <TabPanel className="padding-vertical-20">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: deal.dalsi_info,
                        }}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
                <div className="col-md-6">
                  {deal.registroval && deal.registroval !== "" && (
                    <Poznamky
                      crmClassName="deal"
                      crmClassId={dealId}
                      title="Aktualizace dealu"
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Deal;
