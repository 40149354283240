import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import NovinkyHP from "./NovinkyHP";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";

class Novinka extends Component {
  state = {};

  componentDidMount() {
    this.nactiData();
  }

  componentDidUpdate() {
    if (
      this.state.novinka &&
      this.state.novinka.news_id != this.props.match.params.id
    ) {
      this.nactiData();
    }
  }

  nactiData = async () => {
    const ak = authService.aktualniFirma();
    const novinka = await httpService.post(
      config.apiEndpoint + "novinky/novinkaDetail.php",
      { news_id: this.props.match.params.id, agenda_id: ak.agenda_id }
    );
    this.setState({ novinka: novinka.data, novinkaLoaded: true });
  };

  render() {
    const { novinka, novinkaLoaded } = this.state;
    if (!(novinka && novinka.news_id)) {
      return <LoadMusic />;
    }
    return (
      <React.Fragment>
        <NadpisStranky nadpis="Novinky" />
        <div className="container padding-top-20">
          <div
            style={{
              backgroundImage: "url('" + novinka.image + "')",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              paddingTop: "300px"
            }}
          >
            <div style={{ margin: "0px 70px", backgroundColor: "white" }}>
              <div className="padding-30">
                <div className="entry-datetime col-md-8">
                  <span className="entry-day">{novinka.den}</span>
                  <span className="entry-month">/{novinka.mesic}</span>
                </div>
                <h2>{novinka.news_title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: novinka.summary
                  }}
                />
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: novinka.news_data
                  }}
                />
              </div>
            </div>
          </div>
          <NovinkyHP />
        </div>
      </React.Fragment>
    );
  }
}

export default Novinka;
