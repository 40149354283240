import React, { Component } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { isMobile } from "react-device-detect";
import config from "../../config.json";
import LoadMusic from "../common/LoadMusic.jsx";
import httpService from "../../services/httpService.js";
import { Link } from "react-router-dom";
import authService from "../../services/authService.js";

class DalsiAkce extends Component {
  state = {};

  async componentDidMount() {
    const akce = await httpService.post(
      config.apiEndpoint + "akce/vypisAkciCarousel.php"
    );
    this.setState({ akceLoaded: true, akce: akce.data });
  }

  render() {
    const { akceLoaded, akce } = this.state;
    var getSlug = require("speakingurl");
    const lang = authService.getLang();
    return (
      <React.Fragment>
        {akceLoaded ? (
          <React.Fragment>
            {akce && akce.length ? (
              <React.Fragment>
                <h3>{lang.kalendar_akci}</h3>
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={150}
                  totalSlides={akce.length}
                  visibleSlides={isMobile ? 1 : 4}
                  step={isMobile ? 1 : 4}
                  isPlaying={true}
                >
                  <Slider>
                    {akce.map((a, index) => (
                      <Slide index={index} key={index}>
                        <div style={{ paddingRight: isMobile ? null : "15px" }}>
                          <Link
                            to={
                              "/kalendar/" +
                              a.akce_id +
                              "/" +
                              getSlug(a.nazev, { lang: "cs" })
                            }
                          >
                            <img
                              src={
                                "//api.exclusive-networks.cz/images/logoakce.php?akce_id=" +
                                a.akce_id +
                                "&typ_akce=internal"
                              }
                              alt={a.nazev}
                            />
                          </Link>
                          <div className="product-body obchodniPripad">
                            <h2 className="product-name padding-vertical-10">
                              <Link
                                to={
                                  "/kalendar/" +
                                  a.akce_id +
                                  "/" +
                                  getSlug(a.nazev, { lang: "cs" })
                                }
                              >
                                {a.nazev}
                              </Link>
                            </h2>
                            <ul style={{ fontSize: "0.8em" }}>
                              <li>
                                <i className="fa fa-calendar margin-right-10" />
                                {a.datum_od}
                              </li>
                              <li>
                                <i className="fa fa-map-marker margin-right-10" />
                                {a.misto_konani}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Slide>
                    ))}
                  </Slider>
                  <ButtonBack className="btn btn-sm btn-arrow btn-default margin-right-10">
                    <i className="fa fa-chevron-left" />
                  </ButtonBack>
                  <ButtonNext className="btn btn-sm btn-arrow btn-default margin-right-10">
                    <i className="fa fa-chevron-right" />
                  </ButtonNext>
                </CarouselProvider>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default DalsiAkce;
