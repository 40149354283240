import httpService from "../services/httpService";
import config from "../config.json";

export function vyprodejSkladu(state = [], action) {
  switch (action.type) {
    case "ADD":
      state = action.payload;
      sessionStorage.setItem("kosik", JSON.stringify(state));
      break;
    case "SHOW":
      break;
  }
  console.log("Reducer vyprodejSkladu " + action.type + " called", state);
  return state;
}
