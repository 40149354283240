import React, { Component } from "react";
import { connect } from "react-redux";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import FaqKategorie from "./FaqKategorie";
import LoadMusic from "../common/LoadMusic";
import FaqHledat from "./FaqHledat";
import Modal from "../Modal";

class Faq extends Component {
  state = {
    faqKategorie: [],
    faq: [],
    faqLoaded: true,
    faqKategorieLoaded: false,
    aktualniFaqKategorie: null,
    showPolozitOtazku: false,
    novaOtazka: "",
  };

  constructor(props) {
    super(props);

    this.submitNovaOtazka = this.submitNovaOtazka.bind(this);
  }

  slugify = (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const user = authService.getCurrentUser();
    const { kategorie_id, kategorie_nazev } = this.props;

    let vypis = await httpService.post(
      config.apiEndpoint + "faq/kategorie.php",
      { firma_id: aktualniFirma.firma_id, partner_id: this.props.partnerId }
    );

    this.setState({
      aktualniFirma,
      faqKategorieLoaded: true,
      faqKategorie: vypis.data.faqKategorie,
      user,
    });

    if (kategorie_id) {
      await this.nacistOtazky(kategorie_id, kategorie_nazev);
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { kategorie_id, kategorie_nazev } = this.props;

    if (
      prevProps.kategorie_id !== kategorie_id ||
      prevProps.kategorie_nazev !== kategorie_nazev
    ) {
      await this.nacistOtazky(kategorie_id, kategorie_nazev);
    }
  }

  async nacistOtazky(kategorie_id, kategorie_nazev) {
    const aktualniFirma = authService.aktualniFirma();

    let vypis = null;

    this.setState({
      faqLoaded: false,
      faq: [],
    });

    if (kategorie_id === "tag") {
      vypis = await httpService.post(
        config.apiEndpoint + "faq/hledatDleTagu.php",
        { firma_id: aktualniFirma.firma_id, tag: kategorie_nazev }
      );
    } else {
      vypis = await httpService.post(config.apiEndpoint + "faq/vypis.php", {
        firma_id: aktualniFirma.firma_id,
        kategorie_id,
      });
    }

    this.setState({
      faqLoaded: true,
      faq: vypis.data.faq,
      aktualniFaqKategorie: kategorie_id,
    });
  }

  async submitNovaOtazka(e) {
    const aktualniFirma = authService.aktualniFirma();
    const { partnerId, kategorie_id } = this.props;
    const { novaOtazka } = this.state;

    await httpService.post(config.apiEndpoint + "faq/novaOtazka.php", {
      firma_id: aktualniFirma.firma_id,
      kategorie_id,
      partner_id: partnerId,
      otazka: novaOtazka,
    });
    this.setState({ showPolozitOtazku: false, novaOtazka: "" });
  }

  render() {
    const {
      aktualniFaqKategorie,
      faqKategorie,
      faq,
      faqKategorieLoaded,
      faqLoaded,
      showPolozitOtazku,
    } = this.state;
    const {
      baseURL,
      partnerId,
      kategorie_nazev,
      kategorie_id,
      item_id,
    } = this.props;
    const lang = authService.getLang();

    if (!partnerId) {
      return <p>Načítání...</p>;
    }

    return (
      <React.Fragment>
        <div
          className="row cols-border"
          style={{ width: "100%", margin: isMobile ? "0px" : null }}
        >
          <div
            className="col-md-3 col-12 widget widget_categories"
            style={{
              padding: isMobile ? "0px" : null,
            }}
          >
            <MobileView>
              <select
                className="form-control"
                onChange={(select) =>
                  this.setState({ aktualniFaqKategorie: select.value })
                }
              >
                {faqKategorie.map((k, k_index) => (
                  <option value={k.partner_faq_kategorie_id} key={k_index}>
                    {k.nazev}
                  </option>
                ))}
              </select>
            </MobileView>
            <BrowserView>
              <div className={"text-left"}>
                <FaqKategorie
                  onSelect={(id) => this.setState({ aktualniFaqKategorie: id })}
                  parent_id={false}
                  baseURL={baseURL}
                  partnerId={partnerId}
                  faqKategorie={faqKategorie}
                  aktualniFaqKategorie={aktualniFaqKategorie}
                />
              </div>
            </BrowserView>

            <button
              className={"btn btn-primary"}
              onClick={() => this.setState({ showPolozitOtazku: true })}
            >
              {lang.polozitOtazku}
            </button>

            <Modal
              show={showPolozitOtazku}
              title="Tracking"
              onClose={() => this.setState({ showPolozitOtazku: false })}
              hideSubmit={false}
              width="850px"
              onSubmit={this.submitNovaOtazka}
              submitText={lang.odeslat}
            >
              <input
                type="text"
                placeholder="Otázka"
                className="form-control margin-bottom-20"
                required={true}
                onChange={(e) => this.setState({ novaOtazka: e.target.value })}
              />
            </Modal>
          </div>

          <div
            className="col-md-9 col-12 obchodniPripad text-left"
            style={{
              padding: isMobile ? "0px" : null,
            }}
          >
            <FaqHledat baseURL={baseURL} onSelect={this.setShowFaq} />

            {kategorie_id === "tag" && (
              <h2 className={"mt-4"}>Tag: {kategorie_nazev}</h2>
            )}

            {faqLoaded &&
              faq &&
              faq.length > 0 &&
              faq.map((f, f_key) => (
                <div className={"mt-4 mb-4"} key={f_key}>
                  <strong>
                    <a
                      href={
                        baseURL +
                        "/" +
                        kategorie_id +
                        "/" +
                        kategorie_nazev +
                        "/" +
                        f.partner_faq_id +
                        "/" +
                        this.slugify(f.otazka)
                      }
                      className={
                        item_id && item_id == f.partner_faq_id && "text-danger"
                      }
                    >
                      {f.otazka}
                    </a>
                  </strong>

                  {item_id && item_id == f.partner_faq_id && (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: f.odpoved,
                        }}
                      />

                      {f.tagy && f.tagy.length > 0 && (
                        <p>
                          Tagy: &nbsp;
                          {f.tagy.map((tag, tag_key) => (
                            <a href={baseURL + "/tag/" + tag} key={tag_key}>
                              {tag}
                              {tag_key !== f.tagy.length - 1 && ", "}
                            </a>
                          ))}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null)(Faq);
