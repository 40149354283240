import React, { Component } from "react";

class ProgressBar extends Component {
  render() {
    return (
      <div className="progress progress-small">
        <div
          className={
            "progress-bar progress-bar-" +
            (this.props.typ ? this.props.typ : "success")
          }
          style={{ width: this.props.progress + "%" }}
        >
          <span style={{ paddingTop: "20px" }}>{this.props.popis}</span>
          <span className="percent">{this.props.progress}%</span>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
