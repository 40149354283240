import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "../common/LoadMusic";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import AkceNahled from "./AkceNahled";
import authService from "../../services/authService";

class PrehledAkci extends Component {
  state = {};

  async componentDidMount() {
    const ak = authService.aktualniFirma();
    const akce = await httpService.post(
      config.apiEndpoint + "akce/vypisAkci.php",
      { agenda_id: ak.agenda_id }
    );
    this.setState({
      akceLoaded: true,
      akce: akce.data.akce,
      typy_akci: akce.data.typy_akci,
      partneri: akce.data.partneri
    });
  }

  render() {
    const {
      akceLoaded,
      akce,
      typy_akci,
      partneri,
      filterPartner,
      filterTypAkce
    } = this.state;
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky nadpis={lang.kalendar_akci} obrazek="./images/kalendar.jpg" />
        <div className="container padding-top-20">
          <div className="row cols-border">
            <div className="col-md-9 col-md-push-3">
              {akceLoaded ? (
                akce && akce.length ? (
                  <React.Fragment>
                    <Tabs className="awe-nav-responsive">
                      <TabList className="awe-nav margin-bottom-10">
                        {akce.map(a => (
                          <Tab key={a.obdobi}>{a.obdobi}</Tab>
                        ))}
                      </TabList>
                      {akce.map(a => (
                        <TabPanel key={a.obdobi}>
                          {a.akce.map(d =>
                            (!filterPartner ||
                              (filterPartner > 0 &&
                                filterPartner === d.partner_id)) &&
                            (!filterTypAkce ||
                              (d.typ_akce &&
                                d.typ_akce.findIndex(
                                  t => t === filterTypAkce
                                ) >= 0)) ? (
                              <AkceNahled
                                akceId={d.akce_id}
                                key={d.akce_id}
                                filterPartner={filterPartner}
                              />
                            ) : null
                          )}
                        </TabPanel>
                      ))}
                    </Tabs>
                  </React.Fragment>
                ) : (
                  <div className="alert alert-danger">
                    {lang.aktualne_v_kalendari_neni_zadna_akce}
                  </div>
                )
              ) : (
                <LoadMusic />
              )}
            </div>
            <div className="col-md-3 col-md-pull-9">
              <div className="widget widget_product_categories">
                <h3 className="widget-title">{lang.vyrobce}</h3>
                <ul>
                  {partneri &&
                    partneri.map((p, index) => (
                      <li
                        key={index}
                        className={
                          p.partner_id === filterPartner ? "active" : null
                        }
                      >
                        <a
                          href="javascript:;"
                          onClick={() =>
                            p.partner_id === filterPartner
                              ? this.setState({ filterPartner: null })
                              : this.setState({ filterPartner: p.partner_id })
                          }
                        >
                          {p.nazev}
                        </a>
                      </li>
                    ))}
                </ul>
                <h3 className="widget-title">{lang.typ_akce}</h3>
                <ul>
                  {typy_akci &&
                    typy_akci.map(t => (
                      <li
                        key={t.typ_akce}
                        className={
                          t.typ_akce === filterTypAkce ? "active" : null
                        }
                      >
                        <a
                          href="javascript:;"
                          onClick={() =>
                            t.typ_akce === filterTypAkce
                              ? this.setState({ filterTypAkce: null })
                              : this.setState({
                                  filterTypAkce: t.typ_akce
                                })
                          }
                        >
                          {t.nazev}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrehledAkci;
