import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import authService from "./../../services/authService";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "./../common/LoadMusic";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import ObchodPrilezitostVarianta from "./ObchodPrilezitostVarianta";
import FloatingLabelInput from "floating-label-react";
import FileUploader from "./../common/FileUploader";
import ProgressBar from "../common/ProgressBar";
import Poznamky from "../common/Poznamky";
import { BrowserView, MobileView } from "react-device-detect";
import Modal from "../Modal";
import { toast } from "react-toastify";

class ObchodPrilezitost extends Component {
  state = {
    prilezitostId: null,
    aktualniFirma: {},
    prilezitost: {},
    variantaPolozky: [],
    variantaPolozkyLoaded: [],
    dodaciAdresy: [],
    defaultTabIndex: 0,
    klienti: [],
    ukazObjednavku: false,
    zalozitNovouFirmu: false,
    zalozitNovouOsobu: false,
    novaFirma: {},
    novaOsoba: {},
    osobyLoaded: false,
    soubory: [],
    souboryLoaded: false,
    akt_pravdepodobnost: [],
    aktVarianta: 0,
    poznamka: "",
    pocet_ks: 1,
  };

  componentDidMount = () => {
    this.init();
  };

  init = async (varianta) => {
    let akt_pravdepodobnost = [];
    akt_pravdepodobnost[-100] = { popis: "Ztraceno", typ: "danger" };
    akt_pravdepodobnost[0] = { popis: "Ztraceno", typ: "danger" };
    akt_pravdepodobnost[10] = { popis: "Úvodní zájem", typ: "warning" };
    akt_pravdepodobnost[25] = { popis: "Best Case", typ: "warning" };
    akt_pravdepodobnost[50] = { popis: "Landing", typ: "warning" };
    akt_pravdepodobnost[75] = { popis: "Commit", typ: "warning" };
    akt_pravdepodobnost[95] = { popis: "Podpis/Objednávka", typ: "success" };
    akt_pravdepodobnost[99] = {
      popis: "Objednáno u dodavatele",
      typ: "success",
    };
    akt_pravdepodobnost[100] = { popis: "Dodáno", typ: "success" };
    const prilezitostId = this.props.match.params.id;
    const aktualniFirma = authService.aktualniFirma();
    const prilezitost = await httpService.post(
      config.apiEndpoint + "obchodniPripady/prilezitost.php",
      {
        firma_id: aktualniFirma.firma_id,
        prilezitost_id: prilezitostId,
      }
    );
    this.setState({
      akt_pravdepodobnost,
      prilezitostId,
      aktualniFirma,
      prilezitost: prilezitost.data,
      prilezitostLoaded: true,
      novaPolozka: false,
      novyProdukt: false,
    });
    let variantyLoaded = false;
    let varianty = null;
    if (prilezitost.data && prilezitost.data.zverejnit_partnerweb) {
      varianty = await httpService.post(
        config.apiEndpoint + "obchodniPripady/prilezitostVarianty.php",
        {
          firma_id: aktualniFirma.firma_id,
          prilezitost_id: prilezitostId,
        }
      );
      variantyLoaded = true;
      let defaultZalozka = varianty.data.findIndex(
        (varianta) => varianta.varianta === prilezitost.data.default_varianta
      );
      defaultZalozka = Math.max(0, defaultZalozka);
      this.setState({ defaultTabIndex: defaultZalozka });
      console.log(prilezitost.data.default_varianta);
      console.log(defaultZalozka);
      console.log(varianty.data);
      this.nahrajPolozkyVarianty(
        varianta ? varianta : varianty.data[defaultZalozka].varianta,
        true
      );
    }
    this.setState({
      variantyLoaded,
      varianty: variantyLoaded ? varianty.data : null,
    });
    if (!prilezitost.zakaznik_firma_id) {
      const klienti = await httpService.post(
        config.apiEndpoint + "firma/klienti.php",
        {
          firma_id: aktualniFirma.firma_id,
        }
      );
      this.setState({ klienti: klienti.data });
    }
    if (this.state.prilezitost && this.state.prilezitost.zakaznik_firma_id) {
      this.nahrajOsoby();
      this.nahrajSoubory();
      this.nahrajDodaciAdresy();
    }
  };

  objednat = async (souhlas) => {
    const { prilezitost, aktVarianta, poznamka } = this.state;
    if (!souhlas) {
      this.setState({ ukazSouhlas: true });
    } else {
      const aktualniFirma = authService.aktualniFirma();
      const status = await httpService.post(
        config.apiEndpoint + "obchodniPripady/objednavka.php",
        {
          firma_id: aktualniFirma.firma_id,
          prilezitost_id: prilezitost.prilezitost_id,
          dodaci_adresa_id: this.state.prilezitost.dodaci_adresa_id,
          cislo_objednavky_partnera: this.state.prilezitost.cislo_objednavky_partnera,
          varianta: aktVarianta,
          poznamka,
        }
      );
      if (status.data.status !== "error") {
        const { akt_pravdepodobnost, default_varianta } = status.data;
        const newPrilezitost = {
          ...prilezitost,
          akt_pravdepodobnost,
          default_varianta,
        };
        this.setState({ prilezitost: newPrilezitost, ukazSouhlas: false });
        toast.success("Objednávka odeslána!");
        window.location.reload();
      } else {
        toast.error(status.data.error);
      }
    }
  };

  nahrajOsoby = async () => {
    const osoby = await httpService.post(
      config.apiEndpoint + "obchodniPripady/zakaznikOsoba.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        zakaznik_firma_id: this.state.prilezitost.zakaznik_firma_id,
      }
    );
    this.setState({ osoby: osoby.data, osobyLoaded: true });
  };

  setDodaciAdresa = (dodaci_adresa_id) => {
    const prilezitost = { ...this.state.prilezitost, dodaci_adresa_id };
    this.setState({ prilezitost });
  };

  setCisloObjednavky = (cislo_objednavky_partnera) => {
    const prilezitost = {
      ...this.state.prilezitost,
      cislo_objednavky_partnera,
    };
    this.setState({ prilezitost });
  };

  nahrajDodaciAdresy = async () => {
    const dodaciAdresy = await httpService.post(
      config.apiEndpoint + "obchodniPripady/dodaciAdresy.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        prilezitost_id: this.state.prilezitost.prilezitost_id,
      }
    );
    this.setState({
      dodaciAdresy: dodaciAdresy.data,
      dodaciAdresyLoaded: true,
    });
  };

  nahrajSoubory = async () => {
    const soubory = await httpService.post(
      config.apiEndpoint + "obchodniPripady/prilezitostSoubory.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        prilezitost_id: this.state.prilezitost.prilezitost_id,
      }
    );
    this.setState({ soubory: soubory.data, souboryLoaded: true });
  };

  nahrajPolozkyVarianty = async (varianta, force) => {
    this.setState({ aktVarianta: varianta });
    if (
      force ||
      (!this.state.variantaPolozkyLoaded[varianta] &&
        this.state.prilezitostLoaded)
    ) {
      const polozky = await httpService.post(
        config.apiEndpoint + "obchodniPripady/prilezitostVariantaPolozky.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          prilezitost_id: this.state.prilezitostId,
          varianta,
        }
      );
      let variantaPolozky = [...this.state.variantaPolozky];
      variantaPolozky[varianta] = polozky.data;
      let variantaPolozkyLoaded = [...this.state.variantaPolozkyLoaded];
      variantaPolozkyLoaded[varianta] = true;
      this.setState({ variantaPolozkyLoaded, variantaPolozky });
      return null;
    }
  };

  zmenPocet = async (varianta, polozka_id, pocet, save) => {
    let { variantaPolozky } = this.state;
    const { prilezitost } = this.state;
    const index = variantaPolozky[varianta].findIndex(
      (p) => p.polozka_id === polozka_id
    );
    if (index >= 0) {
      variantaPolozky[varianta][index].pocet_ks = pocet;
      this.setState({ variantaPolozky });
      if (true || save) {
        await httpService.post(
          config.apiEndpoint + "obchodniPripady/upravaPoctu.php",
          {
            polozka_id,
            pocet,
            prilezitost_id: prilezitost.prilezitost_id,
            firma_id: prilezitost.firma_id,
          }
        );
        this.init(varianta);
      }
    }
  };

  vypisVarianty = () => {
    const { prilezitost } = this.state;
    const user = authService.getCurrentUser();
    const lang = authService.getLang();
    if (this.state.varianty) {
      if (this.state.varianty.length > 1) {
        return (
          <Tabs
            className="awe-nav-responsive"
            defaultIndex={this.state.defaultTabIndex}
            onSelect={(n) =>
              this.nahrajPolozkyVarianty(this.state.varianty[n].varianta)
            }
          >
            <TabList className="awe-nav">
              {this.state.varianty.map((varianta) => (
                <Tab key={varianta.varianta}>
                  {varianta.nazev +
                    (varianta.varianta ===
                    this.state.prilezitost.default_varianta
                      ? " - " + lang.hlavni_varianta
                      : "")}
                </Tab>
              ))}
            </TabList>
            {this.state.varianty.map((varianta) => (
              <TabPanel key={varianta.varianta}>
                <ObchodPrilezitostVarianta
                  varianta={varianta.varianta}
                  polozkyLoaded={
                    this.state.variantaPolozkyLoaded[varianta.varianta]
                  }
                  polozky={this.state.variantaPolozky[varianta.varianta]}
                  prilezitost={prilezitost}
                  onChange={(varianta) => this.init(varianta)}
                  zmenPocet={(varianta, polozka_id, pocet, save) =>
                    this.zmenPocet(varianta, polozka_id, pocet, save)
                  }
                  edit={
                    user.login === prilezitost.login_zadani_orig &&
                    parseInt(prilezitost.akt_pravdepodobnost) > 0 &&
                    parseInt(prilezitost.akt_pravdepodobnost) <= 90
                  }
                />
              </TabPanel>
            ))}
          </Tabs>
        );
      } else {
        return (
          <ObchodPrilezitostVarianta
            varianta={this.state.varianty[0].varianta}
            polozkyLoaded={
              this.state.variantaPolozkyLoaded[this.state.varianty[0].varianta]
            }
            polozky={
              this.state.variantaPolozky[this.state.varianty[0].varianta]
            }
            prilezitost={prilezitost}
            edit={
              user.login === prilezitost.login_zadani_orig &&
              parseInt(prilezitost.akt_pravdepodobnost) > 0 &&
              parseInt(prilezitost.akt_pravdepodobnost) <= 90
            }
            zmenPocet={(varianta, polozka_id, pocet, save) =>
              this.zmenPocet(varianta, polozka_id, pocet, save)
            }
          />
        );
      }
    } else {
      return <div className="alert alert-danger">Chyba...</div>;
    }
  };

  zrusObchod = async () => {
    httpService.post(config.apiEndpoint + "obchodniPripady/zmenaDetail.php", {
      prilezitost_id: this.state.prilezitost.prilezitost_id,
      attr: "akt_pravdepodobnost",
      val: 0,
      firma_id: this.state.aktualniFirma.firma_id,
    });
    let prilezitost = { ...this.state.prilezitost, akt_pravdepodobnost: 0 };
    this.setState({ prilezitost, zrusitObchod: false });
  };

  hledat = async () => {
    const { hledat } = this.state;
    const hledani = await httpService.post(
      config.apiEndpoint + "obchodniPripady/hledatSKU.php",
      { hledat }
    );
    this.setState({ hledaniLoaded: true, hledaniVysledky: hledani.data });
  };

  novaPolozka = async () => {
    const {
      novyProdukt,
      pocet_ks,
      varianta,
      prilezitost,
      varianty,
    } = this.state;
    await httpService.post(
      config.apiEndpoint + "obchodniPripady/novaPolozka.php",
      {
        prilezitost_id: prilezitost.prilezitost_id,
        varianta: varianta || prilezitost.default_varianta,
        novyProdukt,
        pocet_ks,
        firma_id: prilezitost.firma_id,
      }
    );
    //window.location.reload();
    //this.nahrajPolozkyVarianty(varianta || prilezitost.default_varianta, true);
    this.init(varianta || prilezitost.default_varianta);
  };

  ukazObjednavku = () => {
    if (this.state.variantyLoaded) {
      const user = authService.getCurrentUser();
      const {
        prilezitost,
        novaPolozka,
        hledat,
        hledaniLoaded,
        hledaniVysledky,
        novyProdukt,
        pocet_ks,
        varianta,
        varianty,
        irisXML,
      } = this.state;
      let produkt = {};
      if (novyProdukt) {
        produkt = hledaniVysledky.vypis.find(
          (v) => v.prilezitost_ciselnik_id === novyProdukt
        );
      }
      return (
        <React.Fragment>
          <Modal
            show={novaPolozka}
            title="Nová položka"
            onClose={() => this.setState({ novaPolozka: false })}
            hideSubmit={!novyProdukt}
            submitText="Vložit produkt do kalkulace"
            onSubmit={this.novaPolozka}
            extraButtonOnClick={() => this.setState({ novyProdukt: null })}
            extraButtonText={novyProdukt && "Zpět"}
            extraButtonClass="btn btn-secondary"
          >
            {novyProdukt ? (
              <div className="row">
                <div className="col-12">
                  <small>Produkt</small>
                  <div>
                    {produkt.vyrobce}: {produkt.typ}, {produkt.vyrobek} (
                    {produkt.sku})
                  </div>
                </div>
                <div className="col-6">
                  <small>Počet ks</small>
                  <input
                    type="number"
                    defaultValue={pocet_ks}
                    className="form-control"
                    onChange={(e) =>
                        this.setState({ pocet_ks: e.target.value })
                    }
                  />
                </div>
                <div className="col-6">
                  <small>Varianta kalkulace</small>
                  <select
                    value={varianta || prilezitost.default_varianta}
                    className="form-control"
                    onChange={(e) =>
                      this.setState({ varianta: e.target.value })
                    }
                  >
                    {varianty &&
                      varianty.map((v) => (
                        <option value={v.varianta} key={v.varianta}>
                          {v.nazev}
                        </option>
                      ))}
                    <option value="-1">Nová varianta</option>
                  </select>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div className="row">
                  <div className="col-9">
                    <input
                      type="text"
                      value={hledat}
                      className="form-control"
                      placeholder="Hledat zboží podle SKU nebo názvu"
                      onChange={(e) =>
                        this.setState({
                          hledat: e.target.value,
                          hledaniLoaded: false,
                        })
                      }
                    />
                  </div>
                  <div className="col-3">
                    <button className="btn btn-primary" onClick={this.hledat}>
                      Hledat
                    </button>
                  </div>
                </div>
                {hledaniLoaded && (
                  <div className="pt-2">
                    {hledaniVysledky &&
                    hledaniVysledky.vypis &&
                    hledaniVysledky.vypis.length > 0 ? (
                      <div>
                        <p>
                          Nalezené výsledky: {hledaniVysledky.pocet_nolimit}
                          {hledaniVysledky.vypis.length <
                            parseInt(hledaniVysledky.pocet_nolimit) &&
                            ". Zobrazeno prvních " +
                              hledaniVysledky.vypis.length}
                        </p>
                        <table>
                          {hledaniVysledky.vypis.map((v) => (
                            <tr key={v.prilezitost_ciselnik_id}>
                              <td>
                                {v.vyrobek !== v.typ && v.typ + ", "}
                                {v.vyrobek}
                                <div>
                                  <small>{v.vyrobce}</small>
                                </div>
                              </td>
                              <td>{v.sku}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    this.setState({
                                      novyProdukt: v.prilezitost_ciselnik_id,
                                    })
                                  }
                                >
                                  Další krok
                                </button>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <div className="alert alert-warning">
                        Nepodařilo se nic najít k '{hledat}'
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </Modal>
          <Modal
            show={irisXML}
            title="Upload XML ve formátu Iris"
            onClose={() => this.setState({ irisXML: false })}
          >
            <small>Vložte soubor XML</small>
            <FileUploader
              url={config.apiEndpoint + "obchodniPripady/irisUpload.php"}
              params={{
                prilezitost_id: prilezitost.prilezitost_id,
                firma_id: prilezitost.firma_id,
              }}
              onUpload={() => {
                this.setState({ irisXML: false });
                this.init();
              }}
            />
          </Modal>
          {user.login === prilezitost.login_zadani_orig &&
            parseInt(prilezitost.akt_pravdepodobnost) >= 10 &&
            parseInt(prilezitost.akt_pravdepodobnost) < 75 && (
              <div className="text-center mb-2">
                <button
                  className="btn btn-primary"
                  onClick={() => this.setState({ novaPolozka: true })}
                >
                  Vložit novou položku
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => this.setState({ irisXML: true })}
                >
                  Upload XML ve formátu Iris
                </button>
              </div>
            )}
          {this.vypisVarianty()}
        </React.Fragment>
      );
    } else {
      return <LoadMusic />;
    }
  };

  pozadatOZverejneniDo = async () => {
    const pozadano_o_zverejneni = "1";
    const prilezitost = { ...this.state.prilezitost, pozadano_o_zverejneni };
    const aktualniFirma = authService.aktualniFirma();
    const akce = httpService.post(
      config.apiEndpoint + "obchodniPripady/pozadatOZverejneni.php",
      {
        firma_id: aktualniFirma.firma_id,
        prilezitost_id: prilezitost.prilezitost_id,
      }
    );
    this.setState({ prilezitost });
  };

  pozadatOZverejneni = () => {
    const lang = authService.getLang();
    if (
      this.state.prilezitost.pozadano_o_zverejneni !== "" &&
      this.state.prilezitost.pozadano_o_zverejneni !== "0"
    ) {
      return (
        <div className="alert alert-warning">
          {lang.bylo_pozadano_o_zverejneni}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="alert alert-danger">{lang.neverejna_pr}</div>
          <div className="center padding-top-10">
            <button
              className="btn btn-warning"
              onClick={() => this.pozadatOZverejneniDo()}
            >
              {lang.pozadat_o_zverejneni}
            </button>
          </div>
        </React.Fragment>
      );
    }
  };

  zvolFirmu = (event) => {
    console.log(event.target.value);
    if (event.target.value > 0) {
      var index = event.nativeEvent.target.selectedIndex;
      const zakaznik_firma_nazev = event.nativeEvent.target[index].text;
      const zakaznik_firma_id = event.target.value;
      const prilezitost = {
        ...this.state.prilezitost,
        zakaznik_firma_id,
        zakaznik_firma_nazev,
      };
      this.zmenaDetail("zakaznik_firma_id", zakaznik_firma_id);
      this.setState({ prilezitost });
      this.nahrajOsoby();
    }
  };

  zvolOsobu = (event) => {
    console.log(event.target.value);
    if (event.target.value > 0) {
      var index = event.nativeEvent.target.selectedIndex;
      const zakaznik_jmeno = event.nativeEvent.target[index].text;
      const zakaznik_osoba_id = event.target.value;
      const prilezitost = {
        ...this.state.prilezitost,
        zakaznik_osoba_id,
        zakaznik_jmeno,
        zakaznik_prijmeni: "",
      };
      this.zmenaDetail("zakaznik_osoba_id", zakaznik_osoba_id);
      this.setState({ prilezitost });
      this.nahrajOsoby();
    }
  };

  hledejFirmu = async (event) => {
    this.setState({ hledamFirmu: true });
    console.log("Hledam IC", event.target.value);
    const firma = await httpService.post(
      config.apiEndpoint + "obchodniPripady/hledatFirmu.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        ico: event.target.value,
      }
    );
    if (firma.data.firma_id) {
      const prilezitost = {
        ...this.state.prilezitost,
        zakaznik_firma_id: firma.data.firma_id,
        zakaznik_firma_nazev: firma.data.nazev,
      };
      this.setState({ prilezitost });
      this.zmenaDetail("zakaznik_firma_id", firma.data.firma_id);
      this.nahrajOsoby();
    } else {
      this.setState({ novaFirma: firma.data });
    }
    this.setState({ hledamFirmu: false });
  };

  setNovaOsoba = (event) => {
    const novaOsoba = {
      ...this.state.novaOsoba,
      [event.target.name]: event.target.value,
    };
    this.setState({ novaOsoba });
  };

  setNovaAdresa = (event) => {
    const novaAdresa = {
      ...this.state.novaAdresa,
      [event.target.name]: event.target.value,
    };
    console.log(novaAdresa);
    this.setState({ novaAdresa });
  };

  setNovaFirma = (event) => {
    const novaFirma = {
      ...this.state.novaFirma,
      [event.target.name]: event.target.value,
    };
    this.setState({ novaFirma });
  };

  ulozNovouAdresu = async () => {
    const adresa = this.state.novaAdresa;
    const lang = authService.getLang();
    if (
      adresa.nazev &&
      adresa.ulice &&
      adresa.mesto &&
      adresa.psc &&
      adresa.zeme
    ) {
      const novaAdresa = await httpService.post(
        config.apiEndpoint + "obchodniPripady/novaDodaciAdresa.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          adresa,
          prilezitost_id: this.state.prilezitost.prilezitost_id,
        }
      );
      await this.nahrajDodaciAdresy();
      const prilezitost = {
        ...this.state.prilezitost,
        dodaci_adresa_id: novaAdresa.data,
      };

      this.setState({ novaAdresa: false, prilezitost });
    } else {
      alert("Vyplňte všechny údaje (nepovinný je jen název budovy)");
    }
  };

  ulozNovouFirmu = async () => {
    const firma = this.state.novaFirma;
    const lang = authService.getLang();
    if (
      firma.nazev &&
      firma.ulice &&
      firma.mesto &&
      firma.psc &&
      firma.dic &&
      firma.ico
    ) {
      const firma = await httpService.post(
        config.apiEndpoint + "obchodniPripady/zalozitNovouFirmu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          ico: this.state.novaFirma.ico,
          dic: this.state.novaFirma.dic,
          nazev: this.state.novaFirma.nazev,
          ulice: this.state.novaFirma.ulice,
          mesto: this.state.novaFirma.mesto,
          psc: this.state.novaFirma.psc,
        }
      );
      const prilezitost = {
        ...this.state.prilezitost,
        zakaznik_firma_id: firma.data.firma_id,
        zakaznik_firma_nazev: firma.data.nazev,
      };
      this.setState({ prilezitost, zalozitNovouFirmu: false });
      this.zmenaDetail("zakaznik_firma_id", firma.data.firma_id);
      this.nahrajOsoby();
    } else {
      alert(lang.vsechno_povinne);
    }
  };

  ulozNovouOsobu = async () => {
    const osoba = this.state.novaOsoba;
    const lang = authService.getLang();
    if (osoba.jmeno && osoba.prijmeni && osoba.mobil && osoba.email1) {
      const osoba = await httpService.post(
        config.apiEndpoint + "obchodniPripady/zalozitNovouOsobu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          zakaznik_firma_id: this.state.prilezitost.zakaznik_firma_id,
          jmeno: this.state.novaOsoba.jmeno,
          prijmeni: this.state.novaOsoba.prijmeni,
          mobil: this.state.novaOsoba.mobil,
          email1: this.state.novaOsoba.email1,
        }
      );
      const prilezitost = {
        ...this.state.prilezitost,
        zakaznik_jmeno: osoba.data.zakaznik_jmeno,
        zakaznik_prijmeni: osoba.data.zakaznik_prijmeni,
        zakaznik_osoba_id: osoba.data.zakaznik_osoba_id,
      };
      this.setState({ prilezitost, zalozitNovouOsobu: false });
      this.zmenaDetail("zakaznik_osoba_id", osoba.data.zakaznik_osoba_id);
      this.nahrajOsoby();
    } else {
      alert(lang.vsechno_povinne);
    }
  };

  zmenaDetail = async (attr, val) => {
    let { prilezitost } = this.state;
    prilezitost[attr] = val;
    this.setState({ prilezitost });
    await httpService.post(
      config.apiEndpoint + "obchodniPripady/zmenaDetail.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        prilezitost_id: this.state.prilezitost.prilezitost_id,
        attr,
        val,
      }
    );
  };

  onUploadSoubor = (soubor) => {
    if (soubor.data.prilezitost_soubor_id) {
      let soubory = [];
      if (this.state.soubory && this.state.soubory.length) {
        soubory = [...this.state.soubory, soubor.data];
      } else {
        soubory[0] = soubor.data;
      }
      this.setState({ soubory });
    }
  };

  soubory = () => {
    const lang = authService.getLang();
    return this.state.souboryLoaded ? (
      <React.Fragment>
        <FileUploader
          url={config.apiEndpoint + "obchodniPripady/nahratSoubor.php"}
          onUpload={this.onUploadSoubor}
          params={{
            firma_id: this.state.aktualniFirma.firma_id,
            prilezitost_id: this.state.prilezitost.prilezitost_id,
          }}
        />
        {this.state.soubory && this.state.soubory.length > 0 ? (
          <React.Fragment>
            <MobileView>
              <table className="margin-top-10" style={{ fontSize: "0.8em" }}>
                <thead>
                  <tr>
                    <th>{lang.nazev}</th>
                    <th>{lang.datum}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.soubory.map((soubor) => (
                    <tr key={soubor.prilezitost_soubor_id}>
                      <td>
                        {soubor.link ? (
                          <a href={soubor.link}>{soubor.nazev}</a>
                        ) : (
                          soubor.nazev
                        )}
                      </td>
                      <td>{soubor.vlozil_datum}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </MobileView>
            <BrowserView>
              <table className="margin-top-10" style={{ fontSize: "0.8em" }}>
                <thead>
                  <tr>
                    <th>{lang.nazev}</th>

                    <th>{lang.autor}</th>
                    <th>{lang.datum}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.soubory.map((soubor) => (
                    <tr key={soubor.prilezitost_soubor_id}>
                      <td>
                        {soubor.link ? (
                          <a href={soubor.link}>{soubor.nazev}</a>
                        ) : (
                          soubor.nazev
                        )}
                      </td>
                      <td>{soubor.vlozil_login}</td>
                      <td>{soubor.vlozil_datum}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </BrowserView>
          </React.Fragment>
        ) : (
          <div className="alert alert-danger alert-outline margin-top-10">
            {lang.zadna_priloha}
          </div>
        )}
      </React.Fragment>
    ) : (
      <LoadMusic size={20} />
    );
  };

  render() {
    const { ukazSouhlas, zrusitObchod } = this.state;
    const lang = authService.getLang();
    if (!this.state.prilezitostLoaded) {
      return (
        <React.Fragment>
          <NadpisStranky
            nadpis={
              "PŘ:" +
              (this.state.prilezitostId ? this.state.prilezitostId : "...")
            }
            obrazek="./images/obchod.jpg"
          />
          <LoadMusic />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Modal
          show={zrusitObchod}
          title="Zrušení obchodu"
          onClose={() => this.setState({ zrusitObchod: false })}
          onSubmit={() => this.zrusObchod(true)}
        >
          Opravdu zrušit tento obchod?
        </Modal>
        <Modal
          show={ukazSouhlas}
          title={lang.potvrdit_objednavku}
          onClose={() => this.setState({ ukazSouhlas: false })}
          onSubmit={() => this.objednat(true)}
        >
          {lang.skutecne_objednat}
        </Modal>
        <NadpisStranky
          nadpis={
            "PŘ:" +
            (this.state.prilezitostId.toString
              ? this.state.prilezitostId
              : "....")
          }
          obrazek="./images/obchod.jpg"
        />
        {this.state.prilezitost ? (
          <div className="container padding-top-20">
            <h2 className="light upper underline center spacing-5">
              {this.state.prilezitost.popis}
            </h2>
            <div
              className="row cols-border padding-top-20"
              style={{ fontSize: "0.85em" }}
            >
              <div className="col-md-3 obchodniPripad padding-bottom-20">
                <div className="center margin-bottom-30">
                  <a href="#/obchod" className="btn btn-primary">
                    {lang.prehled_obch_pripadu}
                  </a>
                  {this.state.prilezitost.zverejnit_partnerweb === "1" && (
                      <a
                          href={
                              "https://api.exclusive-networks.cz/pw/obchodniPripady/exportXLSX.php?prilezitost_id=" +
                              this.state.prilezitostId +
                              "&token=" +
                              authService.getJwt()
                          }
                          className="btn btn-warning"
                      >
                        XLSX
                      </a>
                  )}
                </div>
                <ProgressBar
                  popis={
                    this.state.akt_pravdepodobnost[
                      this.state.prilezitost.akt_pravdepodobnost
                    ].popis
                  }
                  progress={this.state.prilezitost.akt_pravdepodobnost}
                  typ={
                    this.state.akt_pravdepodobnost[
                      this.state.prilezitost.akt_pravdepodobnost
                    ].typ
                  }
                />
                <ul>
                  <li>
                    <strong>ID:</strong>
                    <span style={{ float: "right" }}>
                      {this.state.prilezitost.prilezitost_id}
                    </span>
                  </li>
                  <li>
                    <strong>{lang.zakaznik}:</strong>
                    <div>{this.state.prilezitost.zakaznik_firma_nazev}</div>
                  </li>
                  <li>
                    <strong>{lang.kontaktni_osoba}:</strong>
                    <div>
                      {(this.state.prilezitost &&
                        this.state.prilezitost.zakaznik_jmeno) +
                        " " +
                        (this.state.prilezitost &&
                          this.state.prilezitost.zakaznik_prijmeni)}
                    </div>
                  </li>
                  <li>
                    <strong>{lang.datum}:</strong>
                    <span style={{ float: "right" }}>
                      {this.state.prilezitost.datum_zadani}
                    </span>
                  </li>
                  {parseInt(this.state.prilezitost.akt_pravdepodobnost) <=
                    75 && (
                    <li>
                      <div className="row">
                        <div className="col-md-5">
                          <strong>
                            {lang.datum_fakturace}:<br />
                            <span style={{ fontSize: "0.8em" }}>
                              {lang.predpokladane}
                            </span>
                          </strong>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            value={this.state.prilezitost.datum_fakturace}
                            onChange={(e) =>
                              this.zmenaDetail(
                                "datum_fakturace",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <strong>{lang.registrovany_deal}:</strong>
                    <span style={{ float: "right" }}>
                      {this.state.prilezitost.deal_id === "0" ? (
                        <span>n/a</span>
                      ) : (
                        <a
                          href={
                            "#/registrace-dealu/" +
                            this.state.prilezitost.deal_id
                          }
                        >
                          {this.state.prilezitost.deal_id}
                        </a>
                      )}
                    </span>
                  </li>
                  {this.state.prilezitost.faktury &&
                  this.state.prilezitost.faktury.length ? (
                    <li>
                      <strong>Faktury:</strong>
                      <ul style={{ paddingLeft: "30px" }}>
                        {this.state.prilezitost.faktury.map((f) => (
                          <li>
                            <a href={"/#/fakturace/" + f}>{f}</a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : null}
                </ul>
                <hr />
                <div className="row">
                  <div className="col-4">
                    <img
                      src={this.state.prilezitost.foto}
                      alt={
                        this.state.prilezitost.jmeno +
                        " " +
                        this.state.prilezitost.prijmeni
                      }
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div className="col-8">
                    <ul>
                      <li>
                        <strong>
                          {this.state.prilezitost.jmeno}{" "}
                          {this.state.prilezitost.prijmeni}
                        </strong>
                      </li>
                      <li>
                        <a
                          href={"mailto:" + this.state.prilezitost.email}
                          style={{ fontSize: "0.85em" }}
                        >
                          {this.state.prilezitost.email}
                        </a>
                      </li>
                      <li>
                        <a
                          href={"callto:" + this.state.prilezitost.mobil}
                          style={{ fontSize: "0.85em" }}
                        >
                          {this.state.prilezitost.mobil}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                {this.state.prilezitost.zverejnit_partnerweb === "1" ? (
                  <React.Fragment>
                    {this.state.prilezitost.akt_pravdepodobnost >= 75 &&
                    this.state.prilezitost.akt_pravdepodobnost <= 99 ? (
                      <div className="alert alert-success alert-outline">
                        <h3>Objednávka se zpracovává</h3>
                        <p>Obvyklá doba dodání je:</p>
                        <ul>
                          <li>v případě licencí a obnov 2 až 3 pracovní dny</li>
                          <li>
                            v případě dodávek hardware dle typu výrobku se doby
                            dodání pohybují typicky mezi 2 a 5 měsíci od
                            objednání
                          </li>
                        </ul>
                      </div>
                    ) : null}
                    {this.ukazObjednavku()}
                    {this.state.variantaPolozky[this.state.aktVarianta] &&
                    this.state.variantaPolozky[this.state.aktVarianta].length &&
                    !this.state.ukazObjednavku &&
                    this.state.prilezitost.akt_pravdepodobnost > 0 &&
                    this.state.prilezitost.akt_pravdepodobnost < 75 ? (
                      <div className="center">
                        <button
                          className="btn btn-success margin-10"
                          onClick={() =>
                            this.setState({ ukazObjednavku: true })
                          }
                        >
                          {lang.objednat_podle_kalkulace}
                        </button>{" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => this.setState({ zrusitObchod: true })}
                        >
                          {lang.zrusit_obchod}
                        </button>
                      </div>
                    ) : null}
                    {this.state.ukazObjednavku ? (
                      <div className="padding-top-20">
                        {!this.state.prilezitost.zakaznik_firma_id ||
                        this.state.prilezitost.zakaznik_firma_id === "0" ? (
                          <div>
                            <h4>{lang.volba_koncaka}</h4>
                            <div className="row">
                              <div className="col-md-8">
                                <select
                                  onChange={this.zvolFirmu}
                                  className="form-control"
                                >
                                  <option value="0">
                                    {lang.zvolte_koncaka}
                                  </option>
                                  {this.state.klienti &&
                                    this.state.klienti.map((klient) => (
                                      <option
                                        key={klient.firma_id}
                                        value={klient.firma_id}
                                      >
                                        {klient.nazev}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="col-md-4">
                                ...{lang.nebo}...{" "}
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    this.setState({ zalozitNovouFirmu: true })
                                  }
                                >
                                  {lang.zalozit_firmu}
                                </button>
                              </div>
                            </div>
                            {this.state.zalozitNovouFirmu ? (
                              <React.Fragment>
                                {this.state.hledamFirmu ? (
                                  <div className="alert alert-danger alert-outline margin-top-20">
                                    <div
                                      style={{
                                        float: "left",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      <LoadMusic
                                        inline={true}
                                        size={20}
                                        color="red"
                                      />
                                    </div>
                                    {lang.hledam_ic}
                                  </div>
                                ) : null}
                                <div
                                  className="row border margin-vertical-15"
                                  style={{ margin: "0px" }}
                                >
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="ico"
                                      placeholder={lang.ico}
                                      onBlur={this.hledejFirmu}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="dic"
                                      placeholder={lang.dic}
                                      value={this.state.novaFirma.dic}
                                      onBlur={this.setNovaFirma}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="nazev"
                                      placeholder={lang.nazev_spolecnosti}
                                      value={this.state.novaFirma.nazev}
                                      onBlur={this.setNovaFirma}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="ulice"
                                      placeholder={lang.ulice}
                                      value={this.state.novaFirma.ulice}
                                      onBlur={this.setNovaFirma}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="mesto"
                                      placeholder={lang.mesto}
                                      value={this.state.novaFirma.mesto}
                                      onBlur={this.setNovaFirma}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <FloatingLabelInput
                                      name="psc"
                                      placeholder={lang.psc}
                                      value={this.state.novaFirma.psc}
                                      onBlur={this.setNovaFirma}
                                    />
                                  </div>
                                  <div className="center col-md-12 padding-10">
                                    <button
                                      className="btn btn-primary"
                                      onClick={this.ulozNovouFirmu}
                                    >
                                      {lang.ulozit_firmu}
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.prilezitost.zakaznik_firma_id &&
                        this.state.prilezitost.zakaznik_firma_id !== "0" ? (
                          <div>
                            {!this.state.prilezitost.zakaznik_osoba_id ||
                            this.state.prilezitost.zakaznik_osoba_id === "0" ? (
                              <h5>{lang.a_jeste_osobu}</h5>
                            ) : (
                              <h5>{lang.jeste_upravit_osobu}</h5>
                            )}
                            <div className="row">
                              <div className="col-md-8">
                                {this.state.osobyLoaded ? (
                                  <select
                                    onChange={this.zvolOsobu}
                                    className="form-control"
                                    defaultValue={
                                      this.state.prilezitost.zakaznik_osoba_id
                                    }
                                  >
                                    <option value="0">
                                      {lang.zvolit_osobu}
                                    </option>
                                    {this.state.osoby &&
                                      this.state.osoby.map((osoba) => (
                                        <option
                                          key={osoba.osoba_id}
                                          value={osoba.osoba_id}
                                        >
                                          {osoba.jmeno + " " + osoba.prijmeni}
                                        </option>
                                      ))}
                                  </select>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      style={{
                                        float: "left",
                                        marginRight: "5px",
                                      }}
                                    >
                                      <LoadMusic
                                        size={20}
                                        inline={true}
                                        color="black"
                                      />
                                    </div>
                                    Nahrávám osoby
                                  </React.Fragment>
                                )}
                              </div>
                              <div className="col-md-4">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    this.setState({ zalozitNovouOsobu: true })
                                  }
                                >
                                  {lang.zalozit_osobu}
                                </button>
                              </div>
                            </div>
                            {this.state.zalozitNovouOsobu ? (
                              <React.Fragment>
                                <div
                                  className="row border margin-vertical-15"
                                  style={{ margin: "0px" }}
                                >
                                  <div className="col-md-3">
                                    <FloatingLabelInput
                                      name="jmeno"
                                      placeholder={lang.jmeno}
                                      onBlur={this.setNovaOsoba}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <FloatingLabelInput
                                      name="prijmeni"
                                      placeholder={lang.prijmeni}
                                      onBlur={this.setNovaOsoba}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <FloatingLabelInput
                                      name="mobil"
                                      placeholder={lang.mobil}
                                      onBlur={this.setNovaOsoba}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <FloatingLabelInput
                                      name="email1"
                                      placeholder={lang.email}
                                      onBlur={this.setNovaOsoba}
                                    />
                                  </div>
                                  <div className="center col-md-12 padding-10">
                                    <button
                                      className="btn btn-primary"
                                      onClick={this.ulozNovouOsobu}
                                    >
                                      {lang.ulozit_osobu}
                                    </button>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : null}
                          </div>
                        ) : null}
                        <div className="padding-vertical-20">
                          <h5>{lang.dodaci_adresa}</h5>
                          {this.state.dodaciAdresyLoaded ? (
                            <React.Fragment>
                              <div className="row">
                                <div className="col-md-8">
                                  <select
                                    onChange={(e) =>
                                      this.setDodaciAdresa(e.target.value)
                                    }
                                    key={
                                      "dodaci_adresa_id" +
                                      this.state.prilezitost.dodaci_adresa_id
                                    }
                                    name="dodaci_adresa_id"
                                    className="form-control"
                                    defaultValue={
                                      this.state.prilezitost.dodaci_adresa_id
                                    }
                                  >
                                    {this.state.dodaciAdresy.map((a, index) => (
                                      <option
                                        value={a.adresa_id}
                                        key={a.adresa_id}
                                      >
                                        {a.nazev}, {a.ulice}, {a.mesto}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-4">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      this.setState({ novaAdresa: true })
                                    }
                                  >
                                    {lang.pridat_adresu}
                                  </button>
                                </div>
                              </div>
                              {this.state.novaAdresa ? (
                                <React.Fragment>
                                  <div
                                    className="row border margin-vertical-15"
                                    style={{ margin: "0px" }}
                                  >
                                    <div className="col-md-12 padding-top-10">
                                      <select
                                        name="firma_id"
                                        className="form-control"
                                        onChange={this.setNovaAdresa}
                                      >
                                        <option
                                          value={
                                            this.state.prilezitost.firma_id
                                          }
                                        >
                                          {this.state.prilezitost.firma_nazev}
                                        </option>
                                        <option
                                          value={
                                            this.state.prilezitost
                                              .zakaznik_firma_id
                                          }
                                        >
                                          {
                                            this.state.prilezitost
                                              .zakaznik_firma_nazev
                                          }
                                        </option>
                                      </select>
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="nazev"
                                        placeholder={lang.nazev}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="budova"
                                        placeholder={lang.budova}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="ulice"
                                        placeholder={lang.ulice}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="mesto"
                                        placeholder={lang.mesto}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="psc"
                                        placeholder={lang.psc}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <FloatingLabelInput
                                        name="zeme"
                                        placeholder={lang.zeme}
                                        onBlur={this.setNovaAdresa}
                                      />
                                    </div>
                                    <div className="col-md-12 text-center padding-bottom-20">
                                      <button
                                        className="btn btn-primary"
                                        onClick={this.ulozNovouAdresu}
                                      >
                                        {lang.ulozit}
                                      </button>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <LoadMusic />
                          )}
                        </div>

                        {this.state.prilezitost.zakaznik_firma_id &&
                        this.state.prilezitost.zakaznik_firma_id !== "0" &&
                        this.state.prilezitost.zakaznik_osoba_id &&
                        this.state.prilezitost.zakaznik_osoba_id !== "0" ? (
                          <React.Fragment>
                            <hr />
                            <ul className="padding-top-20">
                              <li>{lang.ceny_bez_dph}</li>
                              {this.state.varianty &&
                              this.state.varianty.length > 1 ? (
                                <li style={{ color: "red" }}>
                                  {lang.vice_variant}
                                </li>
                              ) : null}
                            </ul>
                            <div className="row">
                              <div className="col-md-6 padding-bottom-10">
                                <input
                                  type="text"
                                  name="cislo_objednavky_partnera"
                                  placeholder={lang.cislo_objednavky_partnera}
                                  value={
                                    this.state.prilezitost
                                      .cislo_objednavky_partnera
                                  }
                                  className="form-control margin-bottom-10"
                                  onChange={(e) =>
                                    this.setCisloObjednavky(e.target.value)
                                  }
                                />
                                <textarea
                                  style={{ height: "200px", fontSize: "0.8em" }}
                                  className="form-control"
                                  name="poznamka"
                                  placeholder={lang.poznamka_k_obj}
                                  onChange={(ta) =>
                                    this.setState({ poznamka: ta.target.value })
                                  }
                                />
                              </div>
                              <div className="col-md-6">{this.soubory()}</div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        <div>
                          <h3>{lang.mena_fakturace}</h3>
                          <p>
                            {lang.zvolte_si_menu_faktura_obj}
                          </p>
                          <strong>{lang.mena}</strong>
                          <select
                            className="form-control"
                            value={this.state.prilezitost.mena_fakturace}
                            onChange={(e) =>
                              this.zmenaDetail("mena_fakturace", e.target.value)
                            }
                          >
                            <option value="CZK">CZK</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                          </select>
                        </div>
                        <div className="padding-20 center">
                          {this.state.prilezitost.zakaznik_firma_id &&
                          this.state.prilezitost.zakaznik_firma_id !== "0" &&
                          this.state.prilezitost.zakaznik_osoba_id &&
                          this.state.prilezitost.zakaznik_osoba_id !== "0" ? (
                            <button
                              className="btn btn-success margin-right-5"
                              onClick={() => this.objednat(false)}
                            >
                              {lang.objednat}
                            </button>
                          ) : null}
                          <button
                            className="btn btn-primary btn-outline"
                            onClick={() =>
                              this.setState({ ukazObjednavku: false })
                            }
                          >
                            {lang.zrusit_objednavku}
                          </button>
                        </div>
                      </div>
                    ) : /// konec objednavkoveho formulare
                    null}
                    <hr />
                    <Poznamky
                      crmClassName="prilezitost"
                      crmClassId={this.state.prilezitost.prilezitost_id}
                    />
                    {this.state.prilezitost.akt_pravdepodobnost >= 75 ? (
                      <div>
                        <h4 className="center upper light">
                          {lang.soubory_a_prilohy}
                        </h4>
                        {this.soubory()}
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>{this.pozadatOZverejneni()}</React.Fragment>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="alert alert-danger margin-top-20">
              {lang.menate_pristup_k_prilezitorsti}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ObchodPrilezitost;
