import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LicencePrehledFirma from "./LicencePrehledFirma";
import { BrowserView, MobileView } from "react-device-detect";

class LicencePrehledFirmy extends Component {
  state = { firmyLoaded: false, firmy: [], firmaIndex: 0 };
  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const firmy = await httpService.post(
      config.apiEndpoint + "licence/prehledFirmy.php",
      { firma_id: aktualniFirma.firma_id }
    );
    this.setState({ firmyLoaded: true, firmy: firmy.data });
  }
  render() {
    const { firmy, firmyLoaded, firmaIndex } = this.state;
    return (
      <React.Fragment>
        {firmyLoaded ? (
          firmy ? (
            <React.Fragment>
              <MobileView>
                <select
                  className="form-control margin-bottom-20"
                  onChange={target =>
                    this.setState({ firmaIndex: target.target.selectedIndex })
                  }
                >
                  {firmy.map((f, index) => (
                    <option value={index} key={index}>
                      {f.zakaznik_firma_nazev}
                    </option>
                  ))}
                </select>

                {firmy[firmaIndex] && (
                <LicencePrehledFirma
                  firma_id={firmy[firmaIndex].zakaznik_firma_id}
                  firma_nazev={firmy[firmaIndex].zakaznik_firma_nazev}
                />
                )}
              </MobileView>
              <BrowserView>
                <div className="row" style={{ width: "100%" }}>
                  <div className="col-md-3 widget widget_product_categories">
                    <ul>
                      {firmy.map((f, index) => (
                        <li
                          key={f.zakaznik_firma_id}
                          className={firmaIndex === index ? "active" : null}
                        >
                          <a
                            href="javascript:;"
                            onClick={() => {
                              this.setState({ firmaIndex: index });
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth"
                              });
                            }}
                          >
                            {f.zakaznik_firma_nazev}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-9">
                    {firmy[firmaIndex] && (
                        <LicencePrehledFirma
                            firma_id={firmy[firmaIndex].zakaznik_firma_id}
                            firma_nazev={firmy[firmaIndex].zakaznik_firma_nazev}
                        />
                    )}
                  </div>
                </div>
              </BrowserView>
            </React.Fragment>
          ) : (
            <div className="alert alert-danger alert-outline">Nic tu není</div>
          )
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default LicencePrehledFirmy;
