import React, { Component } from "react";
import FloatingLabelInput from "floating-label-react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { toast } from "react-toastify";
import authService from "../../services/authService";

class ZmenaHesla extends Component {
  state = {
    stareHeslo: "",
    noveHeslo: "",
    noveHeslo2: "",
    chyby: [],
  };

  overHeslo = () => {
    const { noveHeslo, noveHeslo2, stareHeslo } = this.state;
    const lang = authService.getLang();
    var passwordValidator = require("password-validator");
    var schema = new passwordValidator();
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .symbols() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]);
    const errs = schema.validate(noveHeslo, { list: true });
    let chyby = [];
    for (let i = 0; i < errs.length; i++) {
      switch (errs[i]) {
        case "min":
          chyby.push(lang.pass_min);
          break;
        case "max":
          chyby.push(lang.pass_max);
          break;
        case "uppercase":
          chyby.push(lang.pass_uppercase);
          break;
        case "lowercase":
          chyby.push(lang.pass_lowercase);
          break;
        case "digits":
          chyby.push(lang.pass_digits);
          break;
        case "symbols":
          chyby.push(lang.pass_symbols);
          break;
        case "spaces":
          chyby.push(lang.pass_spaces);
          break;
      }
    }
    if (noveHeslo != noveHeslo2) {
      chyby.push(lang.pass_match);
    }
    if (!stareHeslo) {
      chyby.push(lang.pass_set_current);
    }
    if (chyby.length) {
      return (
        <div className="alert alert-danger alert-outline margin-top-20">
          <ul>
            {chyby.map((p, index) => (
              <li key={index}>{p}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="center padding-20">
          <button className="btn btn-primary" onClick={() => this.zmenHeslo()}>
            Změnit heslo
          </button>
        </div>
      );
    }
  };

  zmenHeslo = async () => {
    const { noveHeslo, stareHeslo } = this.state;
    const status = await httpService.post(
      config.apiEndpoint + "profil/zmenaHesla.php",
      { stareHeslo, noveHeslo }
    );
    if (status.data.status === "error") {
      toast.warn(status.data.error);
    } else {
      toast("ok");
      window.location = "/#/logout";
    }
  };

  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <div className="padding-top-20">
          <FloatingLabelInput
            type="password"
            placeholder={lang.soucasne_heslo}
            onChange={(inp) => this.setState({ stareHeslo: inp.target.value })}
          />
          <FloatingLabelInput
            type="password"
            placeholder={lang.nove_heslo}
            onChange={(inp) => this.setState({ noveHeslo: inp.target.value })}
          />
          <FloatingLabelInput
            type="password"
            placeholder={lang.nove_heslo2}
            onChange={(inp) => this.setState({ noveHeslo2: inp.target.value })}
          />
        </div>
        {this.overHeslo()}
      </React.Fragment>
    );
  }
}

export default ZmenaHesla;
