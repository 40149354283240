import React, { Component } from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import { ReCaptcha } from "react-recaptcha-v3";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "../common/LoadMusic";
import HledatFirmu from "../common/HledatFirmu";
import ZmenaHesla from "./ZmenaHesla";
import authService from "../../services/authService";
import NadpisStranky from "../common/nadpis";

class Registrace extends Component {
  state = {
    user: {},
    partner_ids: [],
    jsem_dodavatel: false
  };

  componentDidMount = () => {
    /*loadReCaptcha(
      "6Lfw9bwZAAAAAE_02wANKiCoSBNiVe_NLQYNBU6b",
      this.verifyCallback
    );*/
    const hash = this.props.match.params.id;
    if (hash) {
      this.nahrajRegistraci(hash);
    }
  };

  nahrajRegistraci = async (hash) => {
    let agenda_id = authService.getAgendaIdByUrl();

    const data = await httpService.post(
      config.apiEndpoint + "profil/registraceData.php",
      { hash, agenda_id }
    );
    this.setState({
      login: data.data && data.data.login,
      loaded: true,
      hash,
      brandy: data.data.brandy,
    });
  };

  verifyCallback = (recaptchaToken) => {
    console.log(recaptchaToken, "<= your recaptcha token");
  };

  updateToken = () => {
    this.recaptcha.execute();
  };

  step1go = async () => {
    const { email } = this.state;
    let agenda_id = authService.getAgendaIdByUrl();
    const step2 = await httpService.post(
      config.apiEndpoint + "profil/registraceStep1.php",
      { login: email, agenda_id }
    );
    this.setState({ step2: true });
  };

  step1 = () => {
    const { email, souhlas } = this.state;
    const lang = authService.getLangByUrl();
    return (
      <div style={{ paddingTop: "100px" }}>
        <div className="row">
          <div className="col-md-6">
            <h3>{lang.registrace_partnera}</h3>
            <p>
              {lang.registrace_partnera_radek_1}
            </p>
            <p>
              {lang.registrace_partnera_radek_2}
            </p>
          </div>
          <div className="col-md-6">
            <h3>Krok 1</h3>
            <input
              type="email"
              className="form-control"
              placeholder={lang.zadejte_vas_email_jako_login}
              onChange={(e) => this.setState({ email: e.target.value })}
              value={email}
            />
            <ul style={{ paddingTop: "20px", listStyle: "none" }}>
              <li>
                <input
                  type="checkbox"
                  id="souhlas1"
                  style={{ marginLeft: "-25px", marginRight: "10px" }}
                  checked={souhlas && souhlas.souhlas1}
                  onClick={(e) =>
                    this.setState({
                      souhlas: { ...souhlas, souhlas1: e.target.checked },
                    })
                  }
                />
                {lang.souhlasim_s + " "}
                <a
                  href={lang.url_podminek}
                  style={{ color: "#c22b2e" }}
                >
                  {lang.podminkami_ucasti}
                </a>{" "}
                i{" "}
                <a
                  href={lang.url_obchodnich_podminek}
                  style={{ color: "#c22b2e" }}
                >
                  {lang.obchodnimi_podminkami_udalosti}
                </a>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="souhlas2"
                  style={{ marginLeft: "-25px", marginRight: "10px" }}
                  checked={souhlas && souhlas.souhlas2}
                  onClick={(e) =>
                    this.setState({
                      souhlas: { ...souhlas, souhlas2: e.target.checked },
                    })
                  }
                />
                {lang.beru_na_vedomi + " "}
                <a
                  href={lang.url_soukromi}
                  style={{ color: "#c22b2e" }}
                >
                  {lang.zasady_ochrany}
                </a>
              </li>
            </ul>
            <p className="center" style={{ paddingTop: "20px" }}>
              <button
                className="btn btn-primary"
                onClick={
                  () => {
                    if (
                        !email ||
                        email === "" ||
                        !(souhlas && souhlas.souhlas1 && souhlas.souhlas2)) {
                      if (!email ||
                          email === "") {
                        alert("Prosím vyplňte email");
                        return;
                      }
                      if (!(souhlas && souhlas.souhlas1 && souhlas.souhlas2)) {
                        alert("Je potřeba souhlasit s podmínkami a zasádami naší společnosti.");
                        return;
                      }
                    } else {
                      this.step1go();
                    }
                  }
              }
              >
                {lang.pokracovat_na_druhy_krok}
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  step2 = () => {
    const lang = authService.getLangByUrl();
    return (
      <div style={{ paddingTop: "50px" }}>
        <h3>{lang.registrace_partnera}</h3>
        <p>{lang.do_mailu_by_zaslan_odkaz_pro_pokracvani_v_registraci}</p>
      </div>
    );
  };

  vyberFirmy = (data) => {
    this.upravitUsera("firma_id", data);
  };

  upravitUsera = (attr, val) => {
    let { user } = this.state;
    user[attr] = val;
    this.setState({ user });
  };

  overHeslo = () => {
    const { pass1, pass2 } = this.state.user;
    const lang = authService.getLangByUrl();
    var passwordValidator = require("password-validator");
    var schema = new passwordValidator();
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .symbols() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]);
    const errs = schema.validate(pass1, { list: true });
    let chyby = [];
    for (let i = 0; i < errs.length; i++) {
      switch (errs[i]) {
        case "min":
          chyby.push(lang.pass_min);
          break;
        case "max":
          chyby.push(lang.pass_max);
          break;
        case "uppercase":
          chyby.push(lang.pass_uppercase);
          break;
        case "lowercase":
          chyby.push(lang.pass_lowercase);
          break;
        case "digits":
          chyby.push(lang.pass_digits);
          break;
        case "symbols":
          chyby.push(lang.pass_symbols);
          break;
        case "spaces":
          chyby.push(lang.pass_spaces);
          break;
      }
    }
    if (pass1 !== pass2) {
      chyby.push(lang.pass_match);
    }
    return chyby;
  };

  overFormular = () => {
    const { user, partner_ids, jsem_dodavatel } = this.state;
    const lang = authService.getLangByUrl();
    let err = [];
    if (!user.pass1 || user.pass1 === "") {
      err.push(lang.zadajte_vase_heslo);
    } else {
      const passerr = this.overHeslo();
      for (let i = 0; i < passerr.length; i++) {
        err.push(passerr[i]);
      }
    }
    if (
      !user.jmeno ||
      user.jmeno === "" ||
      !user.prijmeni ||
      user.prijmeni === "" ||
      !user.mobil ||
      user.mobil === ""
    ) {
      err.push(lang.jmeno_prijmeni_mobil_jsou_povinne);
    }
    if (!(partner_ids && partner_ids.length > 0) && !jsem_dodavatel) {
      err.push(lang.zvolte_alespon_jednoho_vyrobce);
    }
    return err.length > 0 ? (
      <div className="alert alert-danger">
        <ul>
          {err.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
    ) : (
      <button className="btn btn-primary" onClick={this.vytvoritLogin}>
        {lang.vytvorit_ucet}
      </button>
    );
  };

  vytvoritLogin = async () => {
    const { login, hash, user, partner_ids, jsem_dodavatel } = this.state;
    const agenda_id = authService.getAgendaIdByUrl();
    await httpService.post(config.apiEndpoint + "profil/novyLogin.php", {
      login,
      hash,
      user,
      partner_ids,
      agenda_id,
      jsem_dodavatel: jsem_dodavatel ? 1 : 0
    });
    this.setState({ step4: true });
  };

  partners = (partner_id, checked) => {
    let { partner_ids } = this.state;
    if (checked) {
      partner_ids.push(partner_id);
    } else {
      partner_ids = partner_ids.filter((p) => p !== partner_id);
    }
    this.setState({ partner_ids });
  };

  step3 = () => {
    const { login, loaded, brandy, partner_ids } = this.state;
    const lang = authService.getLangByUrl();
    return (
      <div style={{ paddingTop: "50px" }}>
        {loaded ? (
          login && login !== "" ? (
            <div className="row">
              <div className="col-md-12">
                <h3>{lang.registrace} / Krok 2</h3>
              </div>
              <div className="col-md-6" style={{ paddingTop: "20px" }}>
                <h4>{lang.heslo}</h4>
                <div className="row">
                  <div className="col-6">
                    <small>{lang.zadejte_heslo}</small>
                    <input
                      type="password"
                      className="form-control"
                      name="pass1"
                      onChange={(e) =>
                        this.upravitUsera(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="col-6">
                    <small>{lang.kontola_hesla}</small>
                    <input
                      type="password"
                      className="form-control"
                      name="pass2"
                      onChange={(e) =>
                        this.upravitUsera(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <h4 style={{ paddingTop: "20px" }}>{lang.osobni_udaje}</h4>
                <div className="row">
                  <div className="col-md-6">
                    <small>{lang.jmeno}</small>
                    <input
                      type="text"
                      className="form-control"
                      name="jmeno"
                      onChange={(e) =>
                        this.upravitUsera(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <small>{lang.prijmeni}</small>
                    <input
                      type="text"
                      className="form-control"
                      name="prijmeni"
                      onChange={(e) =>
                        this.upravitUsera(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <small>{lang.mobil}</small>
                    <input
                      type="text"
                      className="form-control"
                      name="mobil"
                      onChange={(e) =>
                        this.upravitUsera(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12">
                    <h4 style={{ paddingTop: "20px" }}>{lang.spolecnost}</h4>
                    <HledatFirmu onSelect={(e) => this.vyberFirmy(e)} />
                  </div>
                </div>
              </div>
              <div className="col-md-6" style={{ paddingTop: "20px" }}>
                <h4>{lang.o_nabidku_kterych_vyrobcu_mate_zajem}</h4>

                <input
                    type="checkbox"
                    id={"partner_0"}
                    style={{
                      float: "left",
                      marginRight: "10px",
                      marginTop: "5px",
                    }}
                    onChange={(e) =>
                        this.setState({jsem_dodavatel: e.target.checked})
                    }
                />
                <label
                    htmlFor={"partner_0"}
                    style={{ fontSize: "1em" }}
                >
                  {lang.zadne_jsem_dodavatel}
                </label>

                {brandy && brandy.length > 0 && (
                  <div className="row">
                    {brandy.map((b) => (
                      <div className="col-md-4" key={b.partner_id}>
                        <input
                          type="checkbox"
                          value={b.partner_id}
                          id={"partner_" + b.partner_id}
                          style={{
                            float: "left",
                            marginRight: "10px",
                            marginTop: "5px",
                          }}
                          checked={
                            partner_ids &&
                            partner_ids.findIndex((p) => p === b.partner_id) >=
                              0
                          }
                          onChange={(e) =>
                            this.partners(b.partner_id, e.target.checked)
                          }
                        />
                        <label
                          htmlFor={"partner_" + b.partner_id}
                          style={{ fontSize: "1em" }}
                        >
                          {b.nazev}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12" style={{ marginTop: "20px" }}>
                {this.overFormular()}
              </div>
            </div>
          ) : (
            <div className="alert alert-danger">Error</div>
          )
        ) : (
          <LoadMusic />
        )}
      </div>
    );
  };

  step4 = () => {
    const lang = authService.getLangByUrl();
    return (
      <div style={{ paddingTop: "50px" }}>
        <h3>{lang.dokonceni_registrace}</h3>
        <p>
          {lang.vase_registrace_byka_dokoncena}
          {lang.dekujeme}
        </p>
      </div>
    );
  };

  render() {
    const { step2, step4 } = this.state;
    const lang = authService.getLangByUrl();
    const hash = this.props.match.params.id;
    return (
      <React.Fragment>
        <div id="wrapper" className="main-wrapper ">
          <Header hideMenu={true} />
          <NadpisStranky
            nadpis={lang.registrace}
            obrazek="./images/bg_1920x1080.jpg"
          />
          <div id="main">
            <div className="container">
              {hash
                ? step4
                  ? this.step4()
                  : this.step3()
                : step2
                ? this.step2()
                : this.step1()}
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Registrace;
