import React, { Component } from "react";
import FloatingLabelInput from "floating-label-react";
import authService from "../../services/authService";

class DodaciAdresa extends Component {
  state = {
    novaAdresa: {}
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.props.adresa) {
      this.props.onChange(input, this.props.adresa.adresa_id);
    } else {
      const novaAdresa = {
        ...this.state.novaAdresa,
        [input.name]: input.value
      };
      this.setState({ novaAdresa });
    }
    return true;
  };

  render() {
    const lang = authService.getLang();
    return (
      <div className="col-md-3 padding-bottom-20">
        <div
          style={{
            border: "solid 1px #ddd",
            borderRadius: "5px",
            padding: "10px"
          }}
        >
          <FloatingLabelInput
            id="nazev"
            placeholder={lang.nazev_mista}
            name="nazev"
            type="text"
            onBlur={this.handleChange}
            value={this.props.adresa ? this.props.adresa.nazev : ""}
            ref={this.props.adresa ? this.props.adresa.nazev : ""}
          />
          <FloatingLabelInput
            id="ulice"
            placeholder={lang.ulice}
            name="ulice"
            type="text"
            onBlur={this.handleChange}
            value={this.props.adresa ? this.props.adresa.ulice : ""}
          />
          <FloatingLabelInput
            id="mesto"
            placeholder={lang.mesto}
            name="mesto"
            type="text"
            onBlur={this.handleChange}
            value={this.props.adresa ? this.props.adresa.mesto : ""}
          />
          <FloatingLabelInput
            id="psc"
            placeholder={lang.psc}
            name="psc"
            type="text"
            onBlur={this.handleChange}
            value={this.props.adresa ? this.props.adresa.psc : ""}
          />
          <FloatingLabelInput
            id="zeme"
            placeholder={lang.zeme}
            name="zeme"
            type="text"
            onBlur={this.handleChange}
            value={this.props.adresa ? this.props.adresa.zeme : ""}
          />
          <div className="center">
            {this.props.adresa ? (
              <button
                className="btn btn-primary"
                onClick={() => this.props.onDelete(this.props.adresa.adresa_id)}
              >
                {lang.smazat_adresu}
              </button>
            ) : (
              <button
                className="btn btn-success"
                onClick={() => this.props.onCreate(this.state.novaAdresa)}
              >
                {lang.nova_adresa}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DodaciAdresa;
