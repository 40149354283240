export function kosikSamoobsluha(state = [], action) {
  switch (action.type) {
    case "ADDSAMOOBSLUHA":
      state = action.payload;
      console.log("kosik", state);
      sessionStorage.setItem("kosikSamoobsluha", JSON.stringify(state));
      break;
    case "SHOW":
      break;
  }
  console.log("Reducer kosikSamoobsluha " + action.type + " called", state);
  return state;
}
