import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import LicencePrehledKvartal from "./LicencePrehledKvartal";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import LicencePrehledFirmy from "./LicencePrehledFirmy";
import authService from "../../services/authService";

class LicencePrehled extends Component {
  state = {};
  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky nadpis={lang.obnovy_licenci} obrazek="./images/obchod.jpg" />
        <div className="container">
          <Tabs className="awe-nav-responsive">
            <TabList className="awe-nav margin-bottom-10">
              <Tab>{lang.razeni_podle_obdobi}</Tab>
              <Tab>{lang.razeni_podle_zakaznika}</Tab>
            </TabList>
            <TabPanel>
              <LicencePrehledKvartal />
            </TabPanel>
            <TabPanel>
              <LicencePrehledFirmy />
            </TabPanel>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default LicencePrehled;
