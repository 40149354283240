import React, { Component } from "react";
import _ from "lodash";

class Pagination extends Component {
  state = {};
  render() {
    const { aktStranka, pocetStranek } = this.props;
    //const stranky = _.range(1, pocetStranek + 1);
    let stranky = [];
    let startPage = Math.max(1, aktStranka - 5);
    const endPage = Math.min(startPage + 9, pocetStranek);
    if (endPage - startPage < 9) {
      startPage = Math.max(1, endPage - 9);
    }
    for (let i = startPage; i <= endPage; i++) {
      stranky.push(i);
    }
    if (pocetStranek === 1) {
      return null;
    }
    return (
      <nav>
        <ul className="pagination margin-top-20">
          <li className="pagination-prev">
            <a
              className="page-link"
              onClick={() => this.props.onSelect(Math.max(1, aktStranka - 1))}
            >
              &laquo;
            </a>
          </li>
          {stranky.map(stranka => (
            <li
              key={stranka}
              className={
                "page-item" + (stranka === aktStranka ? " active" : "")
              }
            >
              <a
                className="page-link"
                onClick={() => this.props.onSelect(stranka)}
              >
                {stranka}
              </a>
            </li>
          ))}
          <li className="pagination-next">
            <a
              className="page-link"
              onClick={() =>
                this.props.onSelect(Math.min(pocetStranek, aktStranka + 1))
              }
            >
              &raquo;
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
