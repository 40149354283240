import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { Link } from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import Pagination from "../common/Pagination";
import { BrowserView } from "react-device-detect";
import { MobileView } from "react-device-detect";

class PrehledZapujcek extends Component {
  state = {
    zapujcky: [],
    aktStranka: 1,
    pocetStranek: 1,
    limitStranka: 10,
    zapujckyLoaded: false
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const zapujcky = await httpService.post(
      config.apiEndpoint + "zapujcky/zapujcky.php",
      { firma_id: aktualniFirma.firma_id }
    );
    const pocetStranek = Math.ceil(
      zapujcky.data ? zapujcky.data.length / this.state.limitStranka : 0
    );
    this.setState({
      zapujckyLoaded: true,
      zapujcky: zapujcky.data,
      pocetStranek
    });
  }

  zmenStranku = aktStranka => {
    this.setState({ aktStranka });
  };

  zapujckaTermin = zapujcka => {
    return (
      <React.Fragment>
        {zapujcka.po_terminu ? (
          <span className="badge badge-danger">Po termínu!!</span>
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={lang.zapujcky_zarizeni}
          obrazek="./images/zapujcky.jpg"
        />
        <div className="container">
          <div className="center padding-top-20">
            <Link
              to="/zapujcky-zarizeni/nova-zapujcka"
              className="btn btn-primary"
            >
              {lang.nova_zapujcka}
            </Link>
          </div>
          <hr />
          {this.state.zapujckyLoaded ? (
            <React.Fragment>
              {this.state.zapujcky ? (
                <React.Fragment>
                  <MobileView>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{lang.zakaznik}</th>
                          <th>{lang.datum_pozadavku}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.zapujcky.map((zapujcka, index) =>
                          index >=
                            (this.state.aktStranka - 1) *
                              this.state.limitStranka &&
                          index <
                            this.state.aktStranka * this.state.limitStranka ? (
                            <tr key={zapujcka.zapujcka_id}>
                              <td className="right">
                                <Link
                                  to={
                                    "/zapujcky-zarizeni/" + zapujcka.zapujcka_id
                                  }
                                >
                                  <strong>{zapujcka.zapujcka_id}</strong>
                                </Link>
                              </td>
                              <td>
                                {zapujcka.zakaznik_firma_nazev}
                                <MobileView>
                                  <span
                                    className={
                                      "badge badge-" + zapujcka.stavColor
                                    }
                                  >
                                    {zapujcka.stav}
                                  </span>
                                  {this.zapujckaTermin(zapujcka)}
                                </MobileView>
                              </td>

                              <td className="center">
                                {zapujcka.datum_vzniku}
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </MobileView>
                  <BrowserView>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{lang.zakaznik}</th>
                          <th>{lang.datum_pozadavku}</th>
                          <th>{lang.zapujcka_od}</th>
                          <th>{lang.zapujcka_do}</th>
                          <th>{lang.stav}</th>
                          <th>{lang.odeslano_ks}</th>
                          <th>{lang.vraceno_ks}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.zapujcky.map((zapujcka, index) =>
                          index >=
                            (this.state.aktStranka - 1) *
                              this.state.limitStranka &&
                          index <
                            this.state.aktStranka * this.state.limitStranka ? (
                            <tr key={zapujcka.zapujcka_id}>
                              <td className="right">
                                <Link
                                  to={
                                    "/zapujcky-zarizeni/" + zapujcka.zapujcka_id
                                  }
                                >
                                  <strong>{zapujcka.zapujcka_id}</strong>
                                </Link>
                              </td>
                              <td>
                                {zapujcka.zakaznik_firma_nazev}
                                <MobileView>
                                  <span
                                    className={
                                      "badge badge-" + zapujcka.stavColor
                                    }
                                  >
                                    {zapujcka.stav}
                                  </span>
                                  {this.zapujckaTermin(zapujcka)}
                                </MobileView>
                              </td>

                              <td className="center">
                                {zapujcka.datum_vzniku}
                              </td>
                              <td className="center">{zapujcka.datum_od}</td>
                              <td className="center">{zapujcka.datum_do}</td>
                              <td className="center">
                                <span
                                  className={
                                    "badge badge-" + zapujcka.stavColor
                                  }
                                >
                                  {zapujcka.stav}
                                </span>
                                {this.zapujckaTermin(zapujcka)}
                              </td>
                              <td className="center">
                                {zapujcka.pocet_odeslano}
                              </td>
                              <td className="center">
                                {zapujcka.pocet_vraceno}
                              </td>

                              <td className="center">
                                <Link
                                  to={
                                    "/zapujcky-zarizeni/" + zapujcka.zapujcka_id
                                  }
                                  className="btn btn-primary btn-sm"
                                >
                                  {lang.detail}
                                </Link>
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </BrowserView>
                  <Pagination
                    aktStranka={this.state.aktStranka}
                    pocetStranek={this.state.pocetStranek}
                    onSelect={this.zmenStranku}
                  />
                </React.Fragment>
              ) : (
                <div className="alert alert-danger">
                  Neevidujeme žádné zápůjčky
                </div>
              )}
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PrehledZapujcek;
