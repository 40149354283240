import React, {Component} from "react";
import NadpisStranky from "./../common/nadpis";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "../common/LoadMusic";
import {NavLink} from "react-router-dom";
import DF from "./../common/DF";
import Modal from "./../common/Modal";

class Prilohy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            objednavkaLoaded: false,
            objednavka: [],
            plan_id: props.match.params.id,
            pdfNahled: null,
            imageToShow: -1
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id && this.state.plan_id !== this.props.match.params.id) {
            this.setState({plan_id: this.props.match.params.id});
            this.nactiObjednavku();
        }
    }

    componentDidMount() {
        this.nactiObjednavku();
    }

    async nactiObjednavku() {
        const {plan_id} = this.state;

        if (!plan_id) return;

        const aktualniFirma = authService.aktualniFirma();
        const objednavka = await httpService.post(
            config.apiEndpoint + "dodavatel/objednavka.php",
            {
                firma_id: aktualniFirma.firma_id,
                plan_id: plan_id
            }
        );
        this.setState({objednavkaLoaded: true, objednavka: objednavka.data});
    }


    modalImageSection() {
        const {objednavka, imageToShow} = this.state;
        const aktualniFirma = authService.aktualniFirma();

        return (
            <React.Fragment>
                {imageToShow >= 0 &&
                objednavka.prilohy &&
                objednavka.prilohy[imageToShow] &&
                (objednavka.prilohy[imageToShow].isVideo ? (
                    <iframe
                        width="100%"
                        height="315"
                        src={"https://www.youtube.com/embed/" + objednavka.prilohy[imageToShow].youtube}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                ) : (
                    <img
                        src={
                            config.apiEndpoint +
                            "dodavatel/download.php?soubor_id=" +
                            objednavka.prilohy[imageToShow].soubor_id +
                            "&plan_id=" +
                            this.state.plan_id +
                            "&firma_id=" +
                            aktualniFirma.firma_id +
                            "&token=" +
                            authService.getJwt() +
                            "&inline=true"
                        }
                        style={{width: "100%"}}
                        onClick={() => this.setState({imageToShow: imageToShow === objednavka.prilohy.length - 1 ? 0 : imageToShow + 1})}
                    />
                ))}
            </React.Fragment>
        );
    }


    modalImageFooter() {
        const {objednavka, imageToShow} = this.state;
        return (
            <React.Fragment>
                {objednavka.showGallery && imageToShow >= 0 && (
                    <React.Fragment>
                        <span className="m-r-10">
                          <DF date={objednavka.prilohy[imageToShow].vlozil_datum}/> | {objednavka.prilohy[imageToShow].vlozil_login}
                        </span>
                        <button
                            className="btn btn-secondary m-r-10"
                            onClick={() => this.setState({imageToShow: imageToShow - 1})}
                            disabled={imageToShow === 0}
                        >
                            <span className="fa fa-arrow-left"/>
                        </button>
                        <button
                            className="btn btn-secondary m-r-10"
                            onClick={() => this.setState({imageToShow: imageToShow + 1})}
                            disabled={imageToShow === objednavka.prilohy.length - 1}
                        >
                            <span className="fa fa-arrow-right"/>
                        </button>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    render() {
        const {objednavkaLoaded, objednavka, pdfNahled, imageToShow} = this.state;
        const aktualniFirma = authService.aktualniFirma();
        const lang = authService.getLang();

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.dodavatel}
                    obrazek="./images/reference1.jpg"
                />

                <div className="container padding-top-20">

                    <h3 className="light upper underline center spacing-5 padding-bottom-20 padding-top-20">{lang.prilohy}</h3>

                    {objednavkaLoaded ? (
                        <div>
                            {objednavka.prilohy && objednavka.prilohy.length > 0 ? (
                                <div className={"padding-bottom-20"}>
                                    {objednavka.showGallery ? (
                                        <div className="row">
                                            {objednavka.prilohy.map((p, index) => (
                                                <div key={index} className="col-4 col-md-3"
                                                     style={{paddingBottom: "20px"}}>
                                                    <a
                                                        href="javascript:;"
                                                        onClick={() => this.setState({imageToShow: index})}
                                                        data-target=".fullImage"
                                                        data-toggle="modal"
                                                        style={{
                                                            width: "100%",
                                                            paddingTop: "66%",
                                                            border: "solid 1px #ddd",
                                                            position: "relative",
                                                            display: "block",
                                                            backgroundSize: "cover",
                                                            backgroundImage: p.thumb
                                                                ? "url(" + p.thumb + ")"
                                                                : "url(" +
                                                                config.apiEndpoint +
                                                                "dodavatel/download.php?soubor_id=" +
                                                                p.soubor_id +
                                                                "&plan_id=" +
                                                                this.state.plan_id +
                                                                "&firma_id=" +
                                                                aktualniFirma.firma_id +
                                                                "&token=" +
                                                                authService.getJwt() +
                                                                "&inline=true" +
                                                                ")",
                                                        }}
                                                    ></a>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                            <div>
                                                {objednavka.prilohy.map((p, index) => (
                                                    <div
                                                        className={"d-flex flex-row comment-row" + (index > 0 ? " b-t" : "")}>
                                                        <div className="p-2">
                                                            {p.isImage || (p.isVideo && p.thumb) ? (
                                                                <a
                                                                    href="javascript:;"
                                                                    onClick={() => this.setState({imageToShow: index})}
                                                                    data-target=".fullImage"
                                                                    data-toggle="modal"
                                                                    style={{
                                                                        display: "block",
                                                                        width: "64px",
                                                                        height: "64px",
                                                                        borderRadius: "50%",
                                                                        backgroundSize: "cover",
                                                                        backgroundImage: p.thumb
                                                                            ? "url(" + p.thumb + ")"
                                                                            : "url(" +
                                                                            config.apiEndpoint +
                                                                            "dodavatel/download.php?soubor_id=" +
                                                                            p.soubor_id +
                                                                            "&plan_id=" +
                                                                            this.state.plan_id +
                                                                            "&firma_id=" +
                                                                            aktualniFirma.firma_id +
                                                                            "&token=" +
                                                                            authService.getJwt() +
                                                                            "&inline=true" +
                                                                            ")",
                                                                    }}
                                                                ></a>
                                                            ) : (
                                                                <a
                                                                    href={
                                                                        p.link === ""
                                                                            ? config.apiEndpoint +
                                                                            "dodavatel/download.php?soubor_id=" +
                                                                            p.soubor_id +
                                                                            "&plan_id=" +
                                                                            this.state.plan_id +
                                                                            "&firma_id=" +
                                                                            aktualniFirma.firma_id +
                                                                            "&token=" +
                                                                            authService.getJwt() +
                                                                            "&inline=true"
                                                                            : p.link
                                                                    }
                                                                    className="round"
                                                                    target={p.link !== "" && "_blank"}
                                                                    style={{fontSize: "1.4em", color: "white"}}
                                                                >
                                                                    <span
                                                                        className={p.isVideo ? "ti-youtube" : "icon-cloud-download icon"}/>
                                                                </a>
                                                            )}
                                                        </div>
                                                        <div className="comment-text w-100">
                                                            <h5>
                                                                {p.content_type === "application/pdf" ? (
                                                                    <button
                                                                        className="btn btn-link"
                                                                        data-toggle="modal"
                                                                        data-target=".nahled-pdf"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                pdfNahled:
                                                                                    p.link === ""
                                                                                        ? config.apiEndpoint +
                                                                                        "dodavatel/download.php?soubor_id=" +
                                                                                        p.soubor_id +
                                                                                        "&plan_id=" +
                                                                                        this.state.plan_id +
                                                                                        "&firma_id=" +
                                                                                        aktualniFirma.firma_id +
                                                                                        "&token=" +
                                                                                        authService.getJwt() +
                                                                                        "&inline=1"
                                                                                        : p.link,
                                                                            })
                                                                        }
                                                                    >
                                                                        {p.nazev}
                                                                    </button>
                                                                ) : (
                                                                    <a
                                                                        href={
                                                                            p.link === ""
                                                                                ? config.apiEndpoint +
                                                                                "dodavatel/download.php?soubor_id=" +
                                                                                p.soubor_id +
                                                                                "&plan_id=" +
                                                                                this.state.plan_id +
                                                                                "&firma_id=" +
                                                                                aktualniFirma.firma_id +
                                                                                "&token=" +
                                                                                authService.getJwt()
                                                                                : p.link
                                                                        }
                                                                        target={p.link !== "" && "_blank"}
                                                                    >
                                                                        {p.nazev}
                                                                    </a>
                                                                )}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                    )}
                                </div>
                            ) : (
                                <div className="alert alert-danger alert-outline">
                                    {lang.nicTuNeni}
                                </div>
                            )}
                        </div>
                    ) : (
                        <LoadMusic/>
                    )}

                </div>

                <Modal
                    title={imageToShow >= 0 && objednavka.prilohy && objednavka.prilohy[imageToShow] && objednavka.prilohy[imageToShow].nazev}
                    id="fullImage"
                >
                    <section>{this.modalImageSection()}</section>
                    <footer>{this.modalImageFooter()}</footer>
                </Modal>

                <Modal id="nahled-pdf" title="Náhled PDF">
                    <iframe src={pdfNahled} style={{width: "100%", height: "70vh", border: "0"}}/>
                </Modal>
            </React.Fragment>
        )
            ;
    }
}

export default Prilohy;
