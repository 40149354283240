import React, { Component } from "react";
import NadpisStranky from "./../common/nadpis";

class NotFound extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <NadpisStranky nadpis="404 - Page Not Found" />
        <div className="container margin-top-30">
          <div className="alert alert-danger">
            Požadovaná stránka neexistuje
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
