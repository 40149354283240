import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Card from "./../common/Card";
import Modal from "./../Modal";
import DatePicker from "react-datepicker";
/*https://reactdatepicker.com/#example-16*/
import "../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
import { toast } from "react-toastify";
import NovaZapujckaZarizeni from "./NovaZapujckaZarizeni";
import FloatingLabelInput from "floating-label-react";
import { BrowserView } from "react-device-detect";

class NovaZapujcka extends Component {
  state = {
    zarizeniLoaded: false,
    zarizeni: [],
    kalendar: [],
    startDate: null,
    maxDate: null,
    limitDelka: 14,
    dateFormat: "dd.MM.yyyy",
    zapujckaObsah: [],
    zvolRadu: false,
    ukazBrandy: false,
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const zarizeni = await httpService.post(
      config.apiEndpoint + "zapujcky/seznamZarizeni.php",
      { firma_id: aktualniFirma.firma_id }
    );
    this.setState({
      aktualniFirma,
      zakaznik_id: aktualniFirma.firma_id,
      zarizeni: zarizeni.data,
      zarizeniLoaded: true,
    });
    this.nahrajFirmy();
  }

  nahrajFirmy = async () => {
    const firmy = await httpService.post(
      config.apiEndpoint + "zapujcky/firmy.php",
      { firma_id: this.state.aktualniFirma.firma_id }
    );

    this.setState({ firmy: firmy.data, firmyLoaded: true });
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.aktualniFirma.firma_id
    );
    const osoba_id =
      this.state.firmy &&
      this.state.firmy.length &&
      this.state.firmy[index].osoby &&
      this.state.firmy[index].osoby.length &&
      this.state.firmy[index].osoby[0].osoba_id;
    this.nastavOsobu(osoba_id);
  };

  partnerZvolen = (partnerId) => {
    this.setState({ partnerId, zvolRadu: true });
  };

  ukazProdukty = (rada) => {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <div className="row padding-top-20" style={{ width: "100%" }}>
          {this.state.zarizeni[this.state.partnerId].rady[rada].produkty.map(
            (p, index) =>
              p.sns.map((sn, index) => (
                <div className="col-md-4" key={index}>
                  <Card
                    nadpis={sn.typ}
                    style={{ fontSize: "0.8em" }}
                    obsah={
                      <div className="obchodniPripad infotab">
                        <ul>
                          <li>{sn.vyrobek}</li>
                          <li>
                            <strong>SN</strong>
                            <span className="value">{sn.sn}</span>
                          </li>
                          <li>
                            <strong>{lang.sklad}</strong>
                            <span className="value">{sn.sklad}</span>
                          </li>
                        </ul>
                        <div className="center">
                          {this.state.zapujckaObsah &&
                          this.state.zapujckaObsah[sn.zarizeni_id] ? (
                            <button
                              disabled="disabled"
                              className="btn btn-primary"
                            >
                              {lang.jiz_je_na_seznamu}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => this.overeniModal(sn)}
                            >
                              {lang.over_dostupnost}
                            </button>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>
              ))
          )}
        </div>
      </React.Fragment>
    );
  };

  overeniModal = async (sn) => {
    const terminy = await httpService.post(
      config.apiEndpoint + "zapujcky/terminy.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        zapujcka_zarizeni_id: sn.zarizeni_id,
      }
    );
    let kalendar = this.state.kalendar;
    kalendar[sn.zarizeni_id] = terminy.data.terminy;
    console.log(kalendar);
    this.setState({
      overujiDostupnost: sn,
      modalShow: true,
      kalendar,
      limitDelka: parseInt(terminy.data.limit),
    });
  };

  ukazRady = () => {
    return (
      <React.Fragment>
        <Tabs className="awe-nav-responsive">
          <TabList className="awe-nav">
            {this.state.zarizeni[this.state.partnerId].rady.map((r) => (
              <Tab key={r.rada}>{r.rada}</Tab>
            ))}
          </TabList>
          {this.state.zarizeni[this.state.partnerId].rady.map((r, index) => (
            <TabPanel key={r.rada}>{this.ukazProdukty(index)}</TabPanel>
          ))}
        </Tabs>
      </React.Fragment>
    );
  };

  zvolRadu = () => {
    const partner = this.state.zarizeni[this.state.partnerId];
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <h3 className="center upper underline light margin-bottom-30">
          {lang.zvolte_zarizeni}
        </h3>
        <div className="row cols-border">
          <div className="col-md-2 center">
            <BrowserView>
              <img
                src={
                  "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                  partner.partner_id
                }
              />
            </BrowserView>
            <button
              className="btn btn-primary margin-top-20"
              onClick={() => this.setState({ zvolRadu: false })}
            >
              {lang.zpet}
            </button>
          </div>
          <div className="col-md-10">{this.ukazRady()}</div>
        </div>
      </React.Fragment>
    );
  };

  zvoltePartnera = () => {
    if (this.state.zvolRadu) {
      return this.zvolRadu();
    }
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <h3 className="center upper underline light margin-bottom-30">
          {lang.zvolte_zarizeni}
        </h3>
        {this.state.zapujckaObsah && this.state.zapujckaObsah.length ? (
          <React.Fragment>
            <hr />
            <div className="center">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ ukazBrandy: false })}
              >
                {lang.skryt_nabidku}
              </button>
            </div>
            <hr />
          </React.Fragment>
        ) : null}
        {this.state.zarizeniLoaded ? (
          <React.Fragment>
            {this.state.zarizeni && this.state.zarizeni.length ? (
              <React.Fragment>
                <div className="row">
                  {this.state.zarizeni.map((z, index) => (
                    <div
                      className="col-md-2 col-6 center padding-bottom-15"
                      key={z.partner_id}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.partnerZvolen(index)}
                      >
                        <img
                          src={
                            "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                            z.partner_id
                          }
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ) : (
              <div className="alert alert-danger">{lang.nic_k_zapujceni}</div>
            )}
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  };

  modalClose = () => {
    this.setState({
      modalShow: false,
      startDate: null,
      maxDate: null,
      endDate: null,
    });
  };

  handleChangeDate = (date) => {
    let maxDate = new Date(date);
    maxDate.setDate(maxDate.getDate() + this.state.limitDelka - 1);
    this.setState({ startDate: date, endDate: maxDate, maxDate: maxDate });
  };

  overInterval = (datum, curDate) => {
    const d = new Date(datum);
    if (
      d.getFullYear() === curDate.getFullYear() &&
      d.getMonth() === curDate.getMonth() &&
      d.getDate() === curDate.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleChangeEndDate = (date) => {
    const { zarizeni_id } = this.state.overujiDostupnost;
    var err = false;

    if (date <= this.state.startDate) {
      err = "Den konce výpůjčky musí být vyšší než datum začátku...";
    }

    const { startDate } = this.state;
    const endDate = date;
    let curDate = new Date(startDate.getTime());

    while (curDate.setDate(curDate.getDate() + 1) <= endDate) {
      let index = this.state.kalendar[zarizeni_id].findIndex(
        (datum) => true === this.overInterval(datum, curDate)
      );
      if (index >= 0) {
        err = "Tento interval datumů nejde použít";
        break;
      }
    }

    if (err) {
      toast.error(err);
      this.setState({ endDate: null });
      return false;
    } else {
      this.setState({ endDate: date });
      return true;
    }
  };

  deleteZarizeni = (zarizeni_id) => {
    const arr = this.state.zapujckaObsah.filter(
      (z, index) => z.zarizeni.zarizeni_id !== zarizeni_id
    );
    let zapujckaObsah = [];
    for (let i = 0; i < arr.length; i++) {
      zapujckaObsah[arr[i].zarizeni.zarizeni_id] = arr[i];
    }
    this.setState({ zapujckaObsah });
  };

  addZarizeni = () => {
    let { zapujckaObsah } = this.state;
    const { startDate, endDate } = this.state;
    const { zarizeni_id } = this.state.overujiDostupnost;
    if (startDate && endDate) {
      zapujckaObsah[zarizeni_id] = {
        zarizeni: this.state.overujiDostupnost,
        startDate,
        endDate,
      };
      this.setState({ zapujckaObsah, ukazBrandy: false, zvolRadu: false });
      this.modalClose();
    } else {
      toast.error("Není zadán datum začátku a konce!");
    }
  };

  nastavFirmu = (currentTarget) => {
    if (this.state.zakaznik_id !== currentTarget.target.value) {
      this.setState({
        zakaznik_id: currentTarget.target.value,
        novaOsoba: false,
      });
    }
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    const osoba_id =
      this.state.firmy[index].osoby &&
      this.state.firmy[index].osoby.length &&
      this.state.firmy[index].osoby[0].osoba_id;
    this.nastavOsobu(osoba_id);
  };

  selectFirmy = () => {
    if (
      this.state.firmyLoaded &&
      !(this.state.firmy && this.state.firmy.length)
    ) {
      return this.firmaForm();
    }
    return (
      <React.Fragment>
        <select
          className="form-control"
          onChange={this.nastavFirmu}
          defaultValue={this.state.zakaznik_id}
        >
          {this.state.firmy &&
            this.state.firmy.map((f) => (
              <option value={f.firma_id} key={f.firma_id}>
                {f.nazev}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 border-bottom margin-bottom-10">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaFirma: true })}
          >
            Nová firma
          </button>
        </div>
      </React.Fragment>
    );
  };

  handleChange = (currentTarget) => {
    const attr = currentTarget.target.name;
    const val =
      attr === "souhlas"
        ? currentTarget.target.checked
        : currentTarget.target.value;
    this.setState({ [attr]: val });
  };

  ulozOsobu = async () => {
    const { jmeno, prijmeni, mobil, email1, zakaznik_id, firmy } = this.state;
    if (jmeno && prijmeni) {
      const osoba = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitOsobu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          jmeno,
          prijmeni,
          mobil,
          email1,
          zakaznik_id,
        }
      );
      if (osoba.data.osoba_id) {
        const index = firmy.findIndex((f) => f.firma_id === zakaznik_id);
        firmy[index].osoby.push(osoba.data);
        this.setState({
          firmy,
          novaOsoba: false,
          osoba_id: osoba.data.osoba_id,
        });
      }
    } else {
      toast.error("Jméno a příjmení jsou povinné údaje");
    }
  };

  ulozFirmu = async () => {
    const { ico, dic, ulice, mesto, psc, nazev } = this.state;
    if (ico && dic && ulice && mesto && psc && nazev) {
      this.setState({ hledamIco: true });
      const firma = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitFirmu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          ico,
          dic,
          ulice,
          mesto,
          psc,
          nazev,
        }
      );
      if (firma.data.firma_id) {
        const firmy = [...this.state.firmy];
        firmy.push(firma.data);
        this.setState({
          firmy,
          novaFirma: false,
          zakaznik_id: firma.data.firma_id,
        });
      }
      this.setState({ hledamIco: false });
    } else {
      toast.error("Pro uložení firmy musíte vyplnit všechny údaje");
    }
  };

  ulozDodaciAdresu = async () => {
    const {
      dodaci_adresa_nazev,
      dodaci_adresa_ulice,
      dodaci_adresa_mesto,
      dodaci_adresa_psc,
      zakaznik_id,
      firmy,
    } = this.state;
    if (
      dodaci_adresa_nazev &&
      dodaci_adresa_ulice &&
      dodaci_adresa_mesto &&
      dodaci_adresa_psc
    ) {
      const dodaciAdresa = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitDodaciAdresu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          zakaznik_id,
          dodaci_adresa_nazev,
          dodaci_adresa_ulice,
          dodaci_adresa_mesto,
          dodaci_adresa_psc,
        }
      );
      if (dodaciAdresa.data.dodaci_adresa_id) {
        const index = firmy.findIndex((f) => f.firma_id === zakaznik_id);
        firmy[index].dodaci_adresy.push(dodaciAdresa.data);
        this.setState({
          firmy,
          novaDodaciAdresa: false,
          dodaci_adresa_id: dodaciAdresa.data.dodaci_adresa_id,
        });
      }
    } else {
      toast.error("Pro uložení dodací adresy musíte vyplnit všechny údaje");
    }
  };

  hledejFirmu = async (currentTarget) => {
    this.setState({ hledamIco: true });
    const ico = currentTarget.target.value;
    const firma = await httpService.post(
      config.apiEndpoint + "zapujcky/hledatFirmu.php",
      { firma_id: this.state.aktualniFirma.firma_id, ico }
    );
    if (firma.data.firma_id) {
      const firmy = [...this.state.firmy];
      firmy.push(firma.data);
      this.setState({
        firmy,
        novaFirma: false,
        zakaznik_id: firma.data.firma_id,
      });
    } else {
      const { ico, dic, nazev, ulice, mesto, psc } = firma.data;
      this.setState({ ico, dic, nazev, ulice, mesto, psc });
    }
    this.setState({ hledamIco: false });
  };

  firmaForm = () => {
    const lang = authService.getLang();
    return (
      <div>
        <h5>Nová firma</h5>
        <FloatingLabelInput
          id="ico"
          placeholder={lang.ic}
          name="ico"
          type="text"
          onChange={this.handleChange}
          onBlur={this.hledejFirmu}
        />
        {this.state.hledamIco ? (
          <LoadMusic />
        ) : (
          <React.Fragment>
            <FloatingLabelInput
              id="dic"
              placeholder={lang.dic}
              name="dic"
              type="text"
              value={this.state.dic}
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="nazev"
              placeholder={lang.nazev_spolecnosti}
              name="nazev"
              value={this.state.nazev}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="ulice"
              placeholder={lang.ulice}
              name="ulice"
              value={this.state.ulice}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="mesto"
              placeholder={lang.mesto}
              name="mesto"
              value={this.state.mesto}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="psc"
              placeholder={lang.psc}
              name="psc"
              value={this.state.psc}
              type="text"
              onChange={this.handleChange}
            />
            <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
              <button
                className="btn btn-primary"
                onClick={() => this.ulozFirmu()}
              >
                Uložit novou firmu
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  dodaciAdresaForm = () => {
    return (
      <div>
        <h5>Nová dodací adresa</h5>
        <FloatingLabelInput
          id="dodaci_adresa_nazev"
          placeholder="Název místa"
          name="dodaci_adresa_nazev"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="dodaci_adresa_ulice"
          placeholder="Ulice"
          name="dodaci_adresa_ulice"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="dodaci_adresa_mesto"
          placeholder="Město"
          name="dodaci_adresa_mesto"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="dodaci_adresa_psc"
          placeholder="PSČ"
          name="dodaci_adresa_psc"
          type="text"
          onChange={this.handleChange}
        />
        <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
          <button
            className="btn btn-primary"
            onClick={() => this.ulozDodaciAdresu()}
          >
            Uložit novou adresu
          </button>
        </div>
      </div>
    );
  };

  osobaForm = () => {
    return (
      <div>
        <h5>Nová kontaktní osoba</h5>
        <FloatingLabelInput
          id="jmeno"
          placeholder="Jméno"
          name="jmeno"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="prijmeni"
          placeholder="Příjmení"
          name="prijmeni"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="mobil"
          placeholder="Mobil"
          name="mobil"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="email1"
          placeholder="E-mail"
          name="email1"
          type="text"
          onChange={this.handleChange}
        />
        <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
          <button className="btn btn-primary" onClick={() => this.ulozOsobu()}>
            Uložit novou osobu
          </button>
        </div>
      </div>
    );
  };

  nastavOsobu = (osoba_id) => {
    if (osoba_id && osoba_id !== this.state.osoba_id) {
      this.setState({ osoba_id });
    }
  };

  selectOsoby = () => {
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (
      this.state.firmy &&
      this.state.firmy.length &&
      !(this.state.firmy[index].osoby && this.state.firmy[index].osoby.length)
    ) {
      return this.osobaForm();
    }
    return (
      <React.Fragment>
        <select
          className="form-control"
          onChange={(e) => this.nastavOsobu(e.target.value)}
          defaultValue={this.state.osoba_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].osoby.map((o) => (
              <option value={o.osoba_id} key={o.osoba_id}>
                {o.jmeno} {o.prijmeni}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 border-bottom margin-bottom-10">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaOsoba: true })}
          >
            Nová osoba
          </button>
        </div>
      </React.Fragment>
    );
  };

  selectDodaciAdresy = () => {
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (
      this.state.firmy &&
      this.state.firmy.length &&
      !(
        this.state.firmy[index].dodaci_adresy &&
        this.state.firmy[index].dodaci_adresy.length
      )
    ) {
      return this.dodaciAdresaForm();
    }
    return (
      <React.Fragment>
        <select
          className="form-control"
          onChange={this.nastavDodaciAdresu}
          defaultValue={this.state.dodaci_adresa_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].dodaci_adresy.map((a) => (
              <option value={a.dodaci_adresa_id} key={a.dodaci_adresa_id}>
                {a.ulice}, {a.mesto}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 border-bottom margin-bottom-10">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaDodaciAdresa: true })}
          >
            Nová dodací adresa
          </button>
        </div>
      </React.Fragment>
    );
  };

  lzeUlozit = () => {
    const { zakaznik_id, osoba_id, souhlas, zapujckaObsah } = this.state;
    this.setState({ ukladaniZapujcky: true });
    if (!zakaznik_id) {
      this.setState({ err: "Není zvolen zákazník" });
      return false;
    }
    if (!osoba_id) {
      this.setState({ err: "Není zvolena osoba u zákazníka" });
      return false;
    }
    if (!souhlas) {
      this.setState({ err: "Musíte souhlasit s pomínkami zápůjčky" });
      return false;
    }
    if (!(zapujckaObsah && zapujckaObsah.length)) {
      this.setState({ err: "Požadavek je prázdný" });
      return false;
    }
    this.setState({ err: false });
    return true;
  };

  ulozZapujcku = async () => {
    if (this.lzeUlozit()) {
      const {
        aktualniFirma,
        zakaznik_id,
        osoba_id,
        dodaci_adresa_id,
        zapujckaObsah,
        poznamka,
      } = this.state;
      const zapujcka = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitZapujcku.php",
        {
          firma_id: aktualniFirma.firma_id,
          zakaznik_id,
          osoba_id,
          dodaci_adresa_id,
          zapujckaObsah,
          poznamka,
        }
      );
      if (zapujcka.data.zapujcka_id) {
        window.location = "#/zapujcky-zarizeni/" + zapujcka.data.zapujcka_id;
      } else {
        toast.error("Něco je špatně ... :(");
      }
    }
  };

  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <Modal
          show={this.state.ukladaniZapujcky}
          title="Odeslat požadavek na zápůjčků"
          onSubmit={this.ulozZapujcku}
          onClose={() => this.setState({ ukladaniZapujcky: false })}
          submitText="Uložit požadavek"
          hideSubmit={this.state.err}
        >
          {this.state.err
            ? this.state.err
            : "Skutečně odeslat tento požadavek?"}
        </Modal>
        <Modal
          show={this.state.modalShow}
          title={
            "Dostupnost SN: " +
            (this.state.overujiDostupnost
              ? this.state.overujiDostupnost.sn
              : null)
          }
          onClose={this.modalClose}
          onSubmit={this.addZarizeni}
          submitText="Přidat do zápůjčky"
        >
          {this.state.kalendar &&
          this.state.overujiDostupnost &&
          this.state.kalendar[this.state.overujiDostupnost.zarizeni_id] ? (
            <React.Fragment>
              <div className="center">
                <DatePicker
                  placeholderText="Začátek zápůjčky"
                  selected={this.state.startDate}
                  onChange={this.handleChangeDate}
                  dateFormat={this.state.dateFormat}
                  monthsShown={2}
                  minDate={new Date()}
                  excludeDates={
                    this.state.kalendar[
                      this.state.overujiDostupnost.zarizeni_id
                    ]
                  }
                />
                <br />
                <DatePicker
                  placeholderText="Konec zápůjčky"
                  selected={this.state.endDate}
                  onChange={this.handleChangeEndDate}
                  dateFormat={this.state.dateFormat}
                  monthsShown={2}
                  minDate={this.state.startDate ? this.state.startDate : null}
                  maxDate={this.state.maxDate ? this.state.maxDate : null}
                  excludeDates={
                    this.state.kalendar[
                      this.state.overujiDostupnost.zarizeni_id
                    ]
                  }
                />
              </div>
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </Modal>
        <NadpisStranky
          nadpis={lang.nova_zapujcka}
          obrazek="./images/zapujcky.jpg"
        />
        <div className="container padding-top-20">
          {this.state.ukazBrandy ? (
            this.zvoltePartnera()
          ) : (
            <div className="center">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ ukazBrandy: true })}
              >
                {lang.pridat_zarizeni}
              </button>
            </div>
          )}
          <hr />
          <div className="row cols-border">
            <div className="col-md-3">
              <h4>{lang.zakaznik}</h4>
              {this.state.firmyLoaded ? (
                <React.Fragment>
                  {!this.state.novaFirma
                    ? this.selectFirmy()
                    : this.firmaForm()}
                  {!this.state.novaOsoba
                    ? this.selectOsoby()
                    : this.osobaForm()}
                  {!this.state.novaDodaciAdresa
                    ? this.selectDodaciAdresy()
                    : this.dodaciAdresaForm()}
                </React.Fragment>
              ) : (
                <LoadMusic />
              )}
            </div>
            <div className="col-md-9">
              <h4>{lang.obsah_zapujcky}</h4>
              {this.state.zapujckaObsah && this.state.zapujckaObsah.length ? (
                <div className="row padding-top-20">
                  {this.state.zapujckaObsah.map((z, index) => (
                    <div className="col-md-4" key={index}>
                      <NovaZapujckaZarizeni
                        zarizeni={z}
                        onDelete={this.deleteZarizeni}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="alert alert-danger">{lang.zatim_nic}</div>
              )}
            </div>
          </div>
          <hr />
          <h3>{lang.podminky_zapujcky}</h3>
          <div className="row padding-top-20">
            <div
              className="col-md-6 text-justify"
              style={{ fontSize: "0.9em" }}
            >
              <ol>
                <li>
                  Dodavatel poskytuje na některé druhy produktů a na určitou
                  dobu možnost Odběrateli tyto produkty vyzkoušet.
                </li>
                <li>
                  Produkty jsou specifikovány v Zápůjčním protokolu, který je
                  Odběratel povinen potvrdit a odeslat na info@veracomp.cz nebo
                  faxem na +420 724 647 785. Odběratel je povinen odmítnout
                  převzetí zásilky dopravcem v případě poškození obalu produktu.
                  Okamžikem převzetí produktu od dopravce odpovídá Odběratel za
                  škodu vzniklou na produktu. Odběratel je povinen reklamovat
                  zjevné vady produktu spočívající v rozdílu oproti Zápůjčnímu
                  protokolu nejpozději následující pracovní den po převzetí
                  produktu. Po marném uplynutí této lhůty je produkt považován
                  za dodaný v bezvadném stavu.
                </li>
                <li>
                  Odběratel je oprávněn produkt užívat pro osobní potřebu za
                  účelem zjištění jeho funkcionalit, vhodnosti produktu pro
                  potřeby klienta. Odběratel není oprávněn produkt užívat za
                  účelem úplatného poskytování služeb třetím osobám. Odběratel
                  není oprávněn produkt užívat v rozporu s jeho účelem a je
                  povinen o něj a jeho všechny součásti a příslušenství pečovat
                  s péčí řádného hospodáře. Odběratel není oprávněn umožnit
                  užívání Produktu třetí osobě. Odběratel je povinen informovat
                  Dodavatele o nefunkčnosti, poškození, vadě produktu
                  bezprostředně po vzniku takovéto události.
                </li>
                <li>
                  Odběratel je povinen vrátit produkt nejpozději poslední den
                  trvání zápůjčky. Za vrácení se pro účely této smlouvy považuje
                  okamžik osobního předání produktu, odevzdání poštovní
                  přepravě. Odběratel je povinen produkt vrátit ve stavu, v
                  jakém jej převzal, včetně všech součástí a příslušenství
                  popsaného v Zápůjčním protokolu v originálním nepoškozeném
                  obalu na své náklady do provozovny Dodavatele, není-li
                  smluvními stranami výslovně dohodnuto jinak (dále jen Řádné
                  vrácení).
                </li>
              </ol>
            </div>
            <div
              className="col-md-6 text-justify"
              style={{ fontSize: "0.9em" }}
            >
              <ol start="5">
                <li>
                  Smluvní strany se dohodly, že v případě, že Odběratel poruší
                  povinnost produkt včas a Řádně vrátit, je povinen zaplatit
                  Dodavateli smluvní pokutu ve výši 0,2 % z prodejní koncové
                  ceny produktu dle aktuálně platného ceníku za každý den
                  prodlení. Smluvní pokuta je splatná doručením výzvy k její
                  úhradě.
                </li>
                <li>
                  V případě vady produktu vzniklé nikoli zaviněním Odběratele je
                  Dodavatel povinen produkt převzít, provést odstranění závady
                  produktu. O dobu od odevzdání produktu do jeho vrácení se
                  prodlužuje lhůta trvání zápůjčky.
                </li>
                <li>
                  Po dobu trvání zápůjčky zajišťuje Dodavatel Odběrateli
                  telefonickou nebo e-mailovou uživatelskou podporu vztahující
                  se k produktu.
                </li>
                <li>
                  Dodavatel neodpovídá Odběrateli za škodu způsobenou jemu či
                  třetí osobě užitím produktu, zejména:
                  <ol style={{ listStyleType: "lower-latin" }}>
                    <li>
                      za škodu vzniklou poškozením či ztrátou dat způsobených
                      užíváním produktu,
                    </li>
                    <li>
                      majetnou či nemajetkovou újmu způsobenou užíváním
                      produtku,
                    </li>
                    <li>
                      náklady na vrácení, užívání, ostrahu, pojištění produktu,
                    </li>
                    <li>
                      škodu vzniklou nekompabilitou produktu s HW a SW
                      Odběratele,
                    </li>
                    <li>
                      za škodu vzniklou v důsledku poškození, ztráty,
                      nefunkčnosti produktu.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <textarea
                className="form-control"
                placeholder={lang.poznamka_k_zapujcce}
                style={{ height: "140px" }}
              />
            </div>
            <div className="col-md-6">
              <div className="banner">
                <input
                  type="checkbox"
                  id="souhlas"
                  name="souhlas"
                  onChange={this.handleChange}
                />
                <label htmlFor="souhlas">{lang.zapujcka_souhlas}</label>
              </div>
              <div className="center">
                <button
                  className="btn btn-primary"
                  onClick={() => this.lzeUlozit()}
                >
                  {lang.odeslat_pozadavek_zapujcka}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NovaZapujcka;
