import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "./LoadMusic";
import authService from "./../../services/authService";
import Modal from "../Modal";
import Poznamka from "./Poznamka";

class Poznamky extends Component {
  state = {};
  async componentDidMount() {
    const { crmClassName, crmClassId } = this.props;
    const aktualniFirma = authService.aktualniFirma();
    const poznamky = await httpService.post(
      config.apiEndpoint + "poznamky/poznamky.php",
      { firma_id: aktualniFirma.firma_id, crmClassName, crmClassId }
    );
    this.setState({
      poznamky: poznamky.data,
      aktualniFirma,
      poznamkyLoaded: true,
    });
  }

  pripravPoznamku = (currentTarget) => {
    this.setState({ poznamka: currentTarget.target.value });
  };

  ulozitNovouPoznamku = async () => {
    const { firma_id } = this.state.aktualniFirma;
    const { crmClassId, crmClassName } = this.props;
    const { poznamka } = this.state;
    const result = await httpService.post(
      config.apiEndpoint + "poznamky/ulozitNovouPoznamku.php",
      { firma_id, crmClassId, crmClassName, poznamka }
    );
    this.setState({ novaPoznamka: false });
    this.componentDidMount();
    console.log("Ukladani", this.state.poznamka);
  };

  render() {
    const lang = authService.getLang();
    const { title } = this.props;
    return (
      <React.Fragment>
        <Modal
          show={this.state.novaPoznamka}
          title={lang.nova_poznamka}
          onClose={() => this.setState({ novaPoznamka: false })}
          onSubmit={this.ulozitNovouPoznamku}
          submitText={lang.ulozit_poznamku}
        >
          <textarea
            placeholder="Text poznámky"
            className="form-control"
            onBlur={this.pripravPoznamku}
          />
        </Modal>
        <h4 className="center upper light">{title ? title : lang.poznamky}</h4>
        {this.state.poznamkyLoaded ? (
          <React.Fragment>
            {this.state.poznamky && this.state.poznamky.length ? (
              <React.Fragment>
                {this.state.poznamky.map((p, index) => (
                  <Poznamka
                    key={index}
                    datum={p.datum}
                    login={p.login}
                    jmeno={p.jmeno}
                    prijmeni={p.prijmeni}
                    image={p.image}
                    poznamka={p.poznamka}
                  />
                ))}
              </React.Fragment>
            ) : (
              <div className="alert alert-danger alert-outline">
                {lang.bez_poznamek}
              </div>
            )}
            <div className="center border-top border-bottom padding-vertical-15 margin-vertical-15">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ novaPoznamka: true })}
              >
                {lang.vlozit_poznamku}
              </button>
            </div>
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default Poznamky;
