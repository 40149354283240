import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import authService from "./../../services/authService";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "./../common/LoadMusic";
import { BrowserView, MobileView } from "react-device-detect";
import Card from "../common/Card";

class Faktura extends Component {
  state = {};

  async componentDidMount() {
    const doklad = this.props.match.params.id;
    const aktualniFirma = authService.aktualniFirma();
    const faktura = await httpService.post(
      config.apiEndpoint + "fakturace/faktura.php",
      {
        firma_id: aktualniFirma.firma_id,
        doklad
      }
    );
    this.setState({
      dokladLoaded: true,
      doklad,
      aktualniFirma,
      faktura: faktura.data,
      fakturaLoaded: true
    });
  }

  ukazFakturu = () => {
    if (this.state.fakturaLoaded && !this.state.faktura.doklad) {
      return (
        <div className="alert alert-danger margin-top-20">
          K této faktuře nemáte přístup
        </div>
      );
    }
    const lang = authService.getLang();
    return (
      <div className="row padding-top-20 cols-border">
        <div className="col-md-3 obchodniPripad padding-bottom-20">
          <div className="center margin-bottom-30">
            <a href="#/fakturace" className="btn btn-primary">
              {lang.prehled_faktur}
            </a>
          </div>
          <ul>
            <li>
              <strong>{lang.cislo_faktury}:</strong>
              <span style={{ float: "right" }}>{this.state.doklad}</span>
            </li>
            <li>
              <strong>{lang.vystaveno}:</strong>
              <span style={{ float: "right" }}>
                {this.state.faktura.vystaveno}
              </span>
            </li>
            <li
              style={{
                whiteSpace: "nowrap",
                color: this.state.faktura.po_splatnosti ? "red" : "inherit"
              }}
            >
              <strong>{lang.splatno}:</strong>
              <span style={{ float: "right" }}>
                {this.state.faktura.splatno}
              </span>
            </li>
            <li>
              <strong>{lang.uhrazeno}:</strong>
              <span style={{ float: "right" }}>
                {this.state.faktura.uhrazeno}
              </span>
            </li>
            <li>
              <strong>{lang.zbyva_uhradit}:</strong>
              <br />
              <small style={{ padding: "0px" }}>
                {this.state.faktura.mena}
              </small>
              <span style={{ float: "right" }}>
                {this.state.faktura.proplatit}
              </span>
            </li>
            <li>
              <strong>{lang.celkem}:</strong>
              <br />
              <small style={{ padding: "0px" }}>
                {this.state.faktura.mena}
              </small>
              <span style={{ float: "right" }}>
                {this.state.faktura.celkem}
              </span>
            </li>
            <li>
              <strong>{lang.vystavil}:</strong>
              <span style={{ float: "right" }}>
                {this.state.faktura.vystavil}
              </span>
            </li>
            {this.state.faktura.upominka1_datum ? (
              <li>
                <strong>1. {lang.upominka}</strong>
                <span style={{ float: "right" }}>
                  {this.state.faktura.upominka1_datum}
                </span>
              </li>
            ) : null}
            {this.state.faktura.upominka2_datum ? (
              <li>
                <strong>2. {lang.upominka}</strong>
                <span style={{ float: "right" }}>
                  {this.state.faktura.upominka2_datum}
                </span>
              </li>
            ) : null}
            {this.state.faktura.upominka3_datum ? (
              <li>
                <strong>3. {lang.upominka}</strong>
                <span style={{ float: "right" }}>
                  {this.state.faktura.upominka3_datum}
                </span>
              </li>
            ) : null}
            {this.state.faktura.link_pdf ? (
              <li className="center">
                <a
                  href={this.state.faktura.link_pdf}
                  className="btn btn-primary"
                >
                  {lang.pdf_download}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
        <div className="col-md-9">
          <h4>{this.state.faktura.popis}</h4>
          <MobileView>
            {this.state.faktura.polozky.map(p => (
              <Card nadpis={p.popis}>
                <div className="obchodniPripad">
                  <ul>
                    <li>
                      <strong>{lang.pocet_ks}</strong>
                      <span style={{ float: "right" }}>{p.pocet_mj}</span>
                    </li>
                    <li>
                      <strong>{lang.cena_ks}</strong>
                      <span style={{ float: "right" }}>{p.cena}</span>
                    </li>
                    <li>
                      <strong>{lang.sazba_dph}</strong>
                      <span style={{ float: "right" }}>{p.dph_sazba}</span>
                    </li>
                    <li>
                      <strong>{lang.cizi_mena}</strong>
                      <span style={{ float: "right" }}>
                        <small>{p.mena}</small>
                        {p.cena_cm}
                      </span>
                    </li>
                    <li>
                      <strong>{lang.celkem}</strong>
                      <span style={{ float: "right" }}>{p.celkem}</span>
                    </li>
                    <li>
                      <strong>{lang.celkem_cm}</strong>
                      <span style={{ float: "right" }}>
                        <small>{p.mena}</small>
                        {p.celkem_cm}
                      </span>
                    </li>
                    <li>
                      <strong>{lang.celkem_s_dph}</strong>
                      <span style={{ float: "right" }}>{p.celkem_s_dph}</span>
                    </li>
                  </ul>
                </div>
              </Card>
            ))}
            <Card nadpis={lang.celkem_za_fakturu}>
              <div className="obchodniPripad">
                <ul>
                  <li>
                    <strong>{lang.celkem}</strong>
                    <span style={{ float: "right" }}>
                      {this.state.faktura.celkem_polozky}
                    </span>
                  </li>
                  <li>
                    <strong>{lang.celkem_cm}</strong>
                    <span style={{ float: "right" }}>
                      {this.state.faktura.celkem_polozky_cm}
                    </span>
                  </li>
                  <li>
                    <strong>{lang.celkem_s_dph}</strong>
                    <span style={{ float: "right" }}>
                      {this.state.faktura.celkem_polozky_s_dph}
                    </span>
                  </li>
                </ul>
              </div>
            </Card>
          </MobileView>
          <BrowserView>
            <table style={{ fontSize: "0.8em" }} className="margin-top-30">
              <thead>
                <tr>
                  <th>{lang.popis}</th>
                  <th>{lang.pocet_ks}</th>
                  <th>{lang.cena_ks}</th>
                  <th>{lang.sazba_dph}</th>
                  <th>{lang.cizi_mena}</th>
                  <th>{lang.celkem}</th>
                  <th>{lang.celkem_cm}</th>
                  <th>{lang.celkem_s_dph}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.faktura.polozky.map(p => (
                  <tr key={p.poradi}>
                    <td>{p.popis}</td>
                    <td style={{ textAlign: "right" }}>{p.pocet_mj}</td>
                    <td style={{ textAlign: "right" }}>{p.cena}</td>
                    <td style={{ textAlign: "right" }}>{p.dph_sazba}</td>
                    <td style={{ textAlign: "right" }}>
                      <small>{p.mena}</small>
                      {p.cena_cm}
                    </td>
                    <td style={{ textAlign: "right" }}>{p.celkem}</td>
                    <td style={{ textAlign: "right" }}>
                      <small>{p.mena}</small>
                      {p.celkem_cm}
                    </td>
                    <td style={{ textAlign: "right" }}>{p.celkem_s_dph}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Celkem</td>
                  <td colSpan="4" />
                  <td style={{ textAlign: "right" }}>
                    {this.state.faktura.celkem_polozky}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {this.state.faktura.celkem_polozky_cm}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {this.state.faktura.celkem_polozky_s_dph}
                  </td>
                </tr>
              </tfoot>
            </table>
          </BrowserView>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={
            "Faktura " + (this.state.doklad ? this.state.doklad : ".........")
          }
          obrazek="./images/fakturace.jpg"
        />
        <div className="container">
          {this.state.dokladLoaded ? (
            <React.Fragment>{this.ukazFakturu()}</React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Faktura;
