import React, { Component } from "react";
import Card from "./../common/Card";

class NovaZapujckaZarizeni extends Component {
  state = {
    startDate: new Date("2019-01-05"),
    endDate: new Date("2019-01-20"),
    dateFormat: "yyyy-MM-dd",
  };

  handleChangeDate = (date) => {
    this.setState({ startDate: date });
  };

  handleChangeEndDate = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    const { zarizeni, startDate, endDate } = this.props.zarizeni;
    return (
      <Card
        nadpis={zarizeni.typ}
        style={{ fontSize: "0.8em" }}
        image={
          "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
          zarizeni.partner_id
        }
      >
        <div className="obchodniPripad infotab">
          <ul>
            <li>{zarizeni.vyrobek}</li>
            <li>
              <strong>SN:</strong>
              <span className="value">{zarizeni.sn}</span>
            </li>
            <li>
              <strong>Datum od:</strong>
              <span className="value">
                {startDate.getDate()}. {startDate.getMonth() + 1}.{" "}
                {startDate.getFullYear()}
              </span>
            </li>
            <li>
              <strong>Datum do:</strong>
              <span className="value">
                {endDate.getDate()}. {endDate.getMonth() + 1}.{" "}
                {endDate.getFullYear()}
              </span>
            </li>
          </ul>
        </div>
        <div className="center">
          <button
            className="btn btn-primary"
            onClick={() => this.props.onDelete(zarizeni.zarizeni_id)}
          >
            Odebrat
          </button>
        </div>
      </Card>
    );
  }
}

export default NovaZapujckaZarizeni;
