import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import config from "../../config.json";
import httpService from "../../services/httpService";
import { Link } from "react-router-dom";
import Card from "../common/Card";
import Poznamky from "../common/Poznamky";
import { lang } from "moment";

class Zapujcka extends Component {
  state = { zapujckaId: false, zapujckaLoaded: false };
  async componentDidMount() {
    const zapujckaId = this.props.match.params.id;
    const aktualniFirma = authService.aktualniFirma();
    this.setState({ zapujckaId, aktualniFirma });
    const zapujcka = await httpService.post(
      config.apiEndpoint + "zapujcky/zapujcka.php",
      { zapujcka_id: zapujckaId, firma_id: aktualniFirma.firma_id }
    );
    this.setState({ zapujcka: zapujcka.data, zapujckaLoaded: true });
  }
  render() {
    const zapujcka = this.state.zapujcka;
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky nadpis={lang.zapujcka + " #" + this.state.zapujckaId} />
        {this.state.zapujckaLoaded &&
        this.state.zapujcka &&
        this.state.zapujcka.zapujcka_id ? (
          <React.Fragment>
            <div className="container">
              <div className="row cols-border margin-top-20">
                <div className="col-md-3 obchodniPripad">
                  <div className="center">
                    <Link to="/zapujcky-zarizeni" className="btn btn-primary">
                      {lang.zpet_na_prehled}
                    </Link>
                  </div>
                  <ul className="margin-top-20">
                    <li className="center">
                      <span className={"badge badge-" + zapujcka.stavColor}>
                        {zapujcka.stav}
                      </span>
                    </li>
                    <li>
                      <strong>{lang.zakaznik}</strong>
                      <div>{zapujcka.zakaznik_firma_nazev}</div>
                    </li>
                    <li>
                      <strong>{lang.osoba}</strong>
                      <div>{zapujcka.zakaznik_osoba_cele_jmeno}</div>
                    </li>
                    <li>
                      <strong>{lang.dodaci_adresa}</strong>
                      <div>{zapujcka.dodaci_adresa}</div>
                    </li>
                    <li>
                      <strong>{lang.datum_pozadavku}</strong>
                      <div>{zapujcka.datum_vzniku}</div>
                    </li>
                    <li>
                      <strong>{lang.datum_zapujcky}</strong>
                      <div>
                        {zapujcka.datum_od !== "" ? (
                          <React.Fragment>
                            {zapujcka.datum_od} až {zapujcka.datum_do}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {lang.zatim_nepotvrzeno}
                          </React.Fragment>
                        )}
                      </div>
                    </li>
                    <li>
                      <strong>
                        {lang.odeslano_ks}/{lang.vraceno_ks}
                      </strong>
                      <div>
                        {zapujcka.pocet_odeslano} / {zapujcka.pocet_vraceno}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9">
                  {zapujcka.polozky && zapujcka.polozky.length ? (
                    <React.Fragment>
                      <div className="row">
                        {zapujcka.polozky.map((polozka, index) => (
                          <div className="col-md-4" key={index}>
                            <Card
                              image={
                                "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                                polozka.partner_id
                              }
                              nadpis={polozka.typ}
                              style={{ fontSize: "0.8em" }}
                              obsah={
                                <div className="obchodniPripad infotab">
                                  <ul>
                                    <li>{polozka.vyrobek}</li>
                                    <li>
                                      <strong>SN:</strong>
                                      <span className="value">
                                        {polozka.sn}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>
                                        {lang.datum_od_pozadavek}:
                                      </strong>
                                      <span className="value">
                                        {polozka.datum_od_pozadavek}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>
                                        {lang.datum_do_pozadavek}:
                                      </strong>
                                      <span className="value">
                                        {polozka.datum_do_pozadavek}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>
                                        {lang.schvalene_datum_od}:
                                      </strong>
                                      <span className="value">
                                        {polozka.datum_od}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>
                                        {lang.schvalene_datum_do}:
                                      </strong>
                                      <span className="value">
                                        {polozka.datum_do}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>{lang.datum_odeslani}:</strong>
                                      <span className="value">
                                        {polozka.datum_odeslano}
                                      </span>
                                    </li>
                                    <li>
                                      <strong>{lang.datum_vraceni}:</strong>
                                      <span className="value">
                                        {polozka.datum_vraceno}
                                        {polozka.po_terminu ? (
                                          <span className="badge badge-danger">
                                            {lang.po_terminu}
                                          </span>
                                        ) : null}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="alert alert-danger">
                      {lang.prazdna_zapujcka}
                    </div>
                  )}
                  <Poznamky
                    crmClassName="zapujcka"
                    crmClassId={this.state.zapujckaId}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : this.state.zapujckaLoaded ? (
          <div className="container padding-top-20">
            <div className="alert alert-danger">
              K této zápůjce nemáte přístup
            </div>
          </div>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default Zapujcka;
