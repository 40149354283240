import React, { Component } from "react";
import NadpisStranky from "./../common/nadpis";
import DalsiAkce from "../kalendar/DalsiAkce";
import NovinkyHP from "./NovinkyHP";
import authService from "../../services/authService";

class HomePage extends Component {
  state = {};

  componentDidMount = () => {
    window.location = "#/obchod";
  };

  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={lang.vitejte}
          obrazek="./images/reference1.jpg"
        />
        <div className="container padding-top-20">
          <NovinkyHP />
          <hr />
          <DalsiAkce />
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
