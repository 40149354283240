import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

class DF extends Component {
  state = {};
  render() {
    const { date, showTime, compareDate } = this.props;
    const nullText = this.props.nullText ? this.props.nullText : "n/a";
    return !(date === "0000-00-00" || date === "0000-00-00 00:00:00") && date ? (
      showTime ? (
        compareDate ? (
          moment(date).format("M/D/Y") === moment(compareDate).format("M/D/Y") ? (
            moment(date).format("H:mm")
          ) : (
            moment(date).format(this.props.jazyk === "en" ? "M/D/Y H:mm" : "D. M. Y H:mm")
          )
        ) : (
          moment(date).format(this.props.jazyk === "en" ? "M/D/Y H:mm" : "D. M. Y H:mm")
        )
      ) : (
        moment(date).format(this.props.jazyk === "en" ? "M/D/Y" : "D. M. Y")
      )
    ) : (
      nullText
    );
  }
}

const mapStateToProps = state => ({
  jazyk: state.jazyk
});

export default connect(mapStateToProps)(DF);
