import React, {Component} from "react";
import NadpisStranky from "./../common/nadpis";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import ProgressBar from "../common/ProgressBar";

class NovyNarok extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            soubory: null,
            uploading: 0,
            progress: 0,
            error: "",
            poznamka: ""
        };

        this.state = this.defaultState;


        this.nastavitSoubory = this.nastavitSoubory.bind(this);
        this.validate = this.validate.bind(this);
        this.odeslat = this.odeslat.bind(this);
    }

    validate() {
        let lang = authService.getLang();

        const {soubory, poznamka} = this.state;

        if (!soubory) {
            this.setState({error: lang.musiBytSoubor});
            return false;
        }

        /*if (poznamka.trim() == "") {
            this.setState({error: "Vyplněte prosím požadavek."});
            return false;
        }*/

        var reg = /(\.jpg|\.jpeg|\.pdf|\.doc|\.docx|\.png)$/i;
        for (var i in soubory) {
            if (!reg.exec(soubory[i].name.toLowerCase())) {
                this.setState({error: lang.povoleneSoubory + " *.JPG,*.JPEG, *.PDF,*.DOC,*.DOCX,*.PNG"});
                return false;
            }
        }

        this.setState({error: ""});
        return true;
    }


    odeslat(event) {
        const {soubory, poznamka} = this.state;
        const aktualniFirma = authService.aktualniFirma();

        if (this.validate()) {
            this.setState({uploading: 1, progress: 0});

            const fd = new FormData();
            fd.append("plan_id", this.props.match.params.id);
            fd.append("firma_id", aktualniFirma.firma_id);
            fd.append("poznamka", poznamka);

            for (var i in soubory) {
                fd.append("soubory[]", soubory[i], soubory[i].name);
            }

            httpService
                .post(config.apiEndpoint + "dodavatel/novyNarok.php", fd, {
                    onUploadProgress: (progressEvent) => {
                        let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.setState({progress});
                    },
                }).then(() => {
                    window.location.href = "#/dodavatel";
            });
        }

        event.preventDefault();
        return false;
    }

    nastavitSoubory(event) {
        if (event.target && event.target.files) {
            this.setState({soubory: Array.from(event.target.files)});
        }
    }

    render() {
        const {progress, uploading, soubory, error} = this.state;
        const lang = authService.getLang();

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.dodavatel + ": " + lang.novaPriloha+ " ("+this.props.match.params.id+")"}
                    obrazek="./images/reference1.jpg"
                />

                <div className="container padding-top-20">

                    <h3 className="light upper underline center spacing-5 padding-bottom-20 padding-top-20">Nový claim</h3>

                    {error != "" && (
                        <p className={"alert alert-danger"}>{error}</p>
                    )}

                    {uploading > 0 ? (
                        <React.Fragment>
                            <ProgressBar popis={"Nahrávání..."} progress={progress}/>
                        </React.Fragment>
                    ) : (
                        <form onSubmit={(event) => this.odeslat(event)}>
                            <table>
                                <tbody>
                                {/*<tr>
                                    <th>Nárok</th>
                                    <td>
                                        <textarea
                                            name="poznamka"
                                            placeholder="Popište nárok"
                                            className="form-control"
                                            onChange={tarea => this.setState({ poznamka: tarea.target.value })}
                                        />
                                    </td>
                                </tr>*/}
                                <tr>
                                    <th>{lang.priloha}</th>
                                    <td>
                                        <button
                                            type={"button"}
                                            onClick={() => this.fileInput2.click()}
                                            className={"btn btn-secondary"}
                                        >
                                          <span className="btn-label">
                                            <i className="ti-cloud-up"/>
                                          </span>
                                            {lang.nahratSoubor}
                                            {soubory && soubory.length && soubory.map((soubor,soubor_1)=>(
                                                <React.Fragment key={soubor_1}>
                                                    <br/>({soubor.name})
                                                </React.Fragment>
                                            ))}
                                        </button>
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                            ref={(fileInput2) => (this.fileInput2 = fileInput2)}
                                            style={{display: "none"}}
                                            multiple={true}
                                            onChange={this.nastavitSoubory}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p className={"text-center padding-top-20"}>
                                <button className={"btn btn-primary"} type={"submit"}>{lang.odeslat}</button>
                            </p>
                        </form>
                    )}

                </div>
            </React.Fragment>
        );
    }
}

export default NovyNarok;
