import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import NumberFormat from "react-number-format";
import { BrowserView, MobileView } from "react-device-detect";
import Card from "../common/Card";
import authService from "../../services/authService";
import ProgressBar from "../common/ProgressBar";
import Modal from "../Modal";
import Tracking from "./Tracking";
import httpService from "../../services/httpService";
import config from "../../config.json";

class ObchodPrilezitostVarianta extends Component {
  state = {};

  delPolozka = async (polozka_id, confirmed) => {
    if (confirmed) {
      const { firma_id, prilezitost_id } = this.props.prilezitost;
      await httpService.post(
        config.apiEndpoint + "obchodniPripady/delPolozky.php",
        { polozka_id, prilezitost_id, firma_id }
      );
      if (this.props.onChange) {
        this.props.onChange(this.props.varianta);
      } else {
        window.location.reload();
      }
    } else {
      if (window.confirm("Skutečně smazat?")) {
        this.delPolozka(polozka_id, true);
      }
    }
  };

  zmenPocet = (polozka_id, pocet, save) => {
    this.props.zmenPocet(this.props.varianta, polozka_id, pocet, save);
  };

  render() {
    const lang = authService.getLang();
    const { showTrackingId } = this.state;
    let celkem = {};
    let p = {};
    if (this.props.polozkyLoaded) {
      for (let i = 0; i < this.props.polozky.length; i++) {
        p = this.props.polozky[i];
        if (!celkem[p.prodej_mena]) {
          celkem[p.prodej_mena] = 0;
        }
        celkem[p.prodej_mena] +=
          p.jednotkova_cena * ((100 - p.sleva_prodej) / 100) * p.pocet_ks;
      }
    }
    return this.props.polozkyLoaded ? (
      this.props.polozky && this.props.polozky.length > 0 ? (
        <React.Fragment>
          <Modal
            show={showTrackingId}
            title="Tracking"
            onClose={() => this.setState({ showTrackingId: null })}
            hideSubmit={true}
            width="850px"
          >
            {showTrackingId && <Tracking doprava_id={showTrackingId} />}
          </Modal>
          <MobileView>
            {this.props.polozky.map((p) => (
              <div className="margin-vertical-20" key={p.polozka_id}>
                <Card nadpis={p.typ + ", " + p.vyrobek}>
                  <div className="badge badge-primary">{p.sku}</div>
                  <div className="row obchodniPripad padding-top-10">
                    <div className="col-3">
                      <img
                        src={
                          "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                          p.partner_id
                        }
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                    <div className="col-9">
                      <ul>
                        <li>
                          <strong>{lang.cena_ks}</strong>
                          <span style={{ float: "right" }}>
                            <small>{p.prodej_mena}</small>
                            <NumberFormat
                              value={p.jednotkova_cena * 1}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType="text"
                            />
                          </span>
                        </li>
                        <li>
                          <strong>{lang.sleva}</strong>
                          <span style={{ float: "right" }}>
                            {p.sleva_prodej}%
                          </span>
                        </li>
                        <li>
                          <strong>{lang.cena_ks_sleva}</strong>
                          <span style={{ float: "right" }}>
                            <small>{p.prodej_mena}</small>
                            <NumberFormat
                              value={
                                p.jednotkova_cena *
                                ((100 - p.sleva_prodej) / 100)
                              }
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType="text"
                            />
                          </span>
                        </li>
                        <li>
                          <strong>{lang.pocet_ks}</strong>
                          <span style={{ float: "right" }}>{p.pocet_ks}</span>
                        </li>
                        <li>
                          <strong>{lang.celkem}</strong>
                          <span style={{ float: "right" }}>
                            <small>{p.prodej_mena}</small>{" "}
                            <NumberFormat
                              value={
                                p.jednotkova_cena *
                                ((100 - p.sleva_prodej) / 100) *
                                p.pocet_ks
                              }
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType="text"
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </MobileView>
          <BrowserView>
            <table className="margin-top-20">
              <thead>
                <tr>
                  <th>{lang.popis}</th>
                  <th>{lang.cena_ks}</th>
                  <th>{lang.sleva}</th>
                  <th>{lang.cena_ks_sleva}</th>
                  <th>{lang.pocet_ks}</th>
                  <th>{lang.celkem}</th>
                  {this.props.edit && <th />}
                </tr>
              </thead>
              <tbody>
                {this.props.polozky.map((p) => (
                  <tr key={p.polozka_id}>
                    <td>
                      <img
                        src={
                          "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                          p.partner_id
                        }
                        style={{
                          height: "52px",
                          float: "left",
                          marginRight: "10px",
                          borderRadius: "50%",
                        }}
                      />
                      {p.typ + ", " + p.vyrobek}
                      <div>
                        <span className="badge badge-success margin-right-5">
                          {p.sku}
                        </span>
                        {p.objednavka_datum_dodani &&
                          p.objednavka_datum_dodani !== "0000-00-00" &&
                          p.objednavka_dodano_datum !== "0000-00-00" && (
                            <div>
                              Předpokl. datum doručení:{" "}
                              {p.doprava_id && parseInt(p.doprava_id) > 0 ? (
                                <button
                                  className="btn btn-link"
                                  style={{ padding: "0px", margin: "0px" }}
                                  onClick={() =>
                                    this.setState({
                                      showTrackingId: p.doprava_id,
                                    })
                                  }
                                >
                                  {p.objednavka_datum_dodani}
                                </button>
                              ) : (
                                p.objednavka_datum_dodani
                              )}{" "}
                              <div style={{ paddingTop: "20px" }}>
                                <ProgressBar
                                  popis={p.statusDescription}
                                  progress={p.doprava_progress}
                                  typ={null}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={p.jednotkova_cena * 1}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small style={{ padding: "0px" }}>
                          {p.prodej_mena}
                        </small>
                      </div>
                    </td>
                    <td style={{ textAlign: "right" }}>{p.sleva_prodej}%</td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={
                          p.jednotkova_cena * ((100 - p.sleva_prodej) / 100)
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small style={{ padding: "0px" }}>
                          {p.prodej_mena}
                        </small>
                      </div>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {this.props.edit ? (
                        <input
                          type="number"
                          defaultValue={p.pocet_ks}
                          style={{ width: "60px", textAlign: "right" }}
                          onChange={(e) =>
                            this.zmenPocet(p.polozka_id, e.target.value)
                          }
                          onBlur={(e) =>
                            this.zmenPocet(p.polozka_id, e.target.value, true)
                          }
                        />
                      ) : (
                        <NumberFormat
                          value={p.pocet_ks * 1}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={0}
                          fixedDecimalScale={true}
                          displayType="text"
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <NumberFormat
                        value={
                          p.jednotkova_cena *
                          ((100 - p.sleva_prodej) / 100) *
                          p.pocet_ks
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small style={{ padding: "0px" }}>
                          {p.prodej_mena}
                        </small>
                      </div>
                    </td>
                    {this.props.edit && (
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => this.delPolozka(p.polozka_id)}
                        >
                          <span className="icon icon-trash" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              <tr>
                <td colSpan="5" className="text-right">
                  Celkem:
                </td>
                <td className="text-right">
                  {celkem.EUR && (
                    <div>
                      <NumberFormat
                        value={celkem.EUR}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small>EUR</small>
                      </div>
                    </div>
                  )}
                  {celkem.USD && (
                    <div>
                      <NumberFormat
                        value={celkem.USD}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small>USD</small>
                      </div>
                    </div>
                  )}
                  {celkem.CZK && (
                    <div>
                      <NumberFormat
                        value={celkem.CZK}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                      <div>
                        <small>CZK</small>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </table>
          </BrowserView>
        </React.Fragment>
      ) : (
        <div className="alert alert-danger mt-3">Nejsou zde žádné položky</div>
      )
    ) : (
      <LoadMusic />
    );
  }
}

export default ObchodPrilezitostVarianta;
