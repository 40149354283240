import React, {Component} from "react";
import NadpisStranky from "./../common/nadpis";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "../common/LoadMusic";
import {NavLink} from "react-router-dom";
import moment from "moment";
import auth from "../../services/authService";

class Dodavatel extends Component {
    constructor(props) {
        super(props);

        this.fileInput2 = []; // Příloha request
        this.fileInput3 = []; // Příloha claimu
        this.fileInput4 = []; // Příloha rozpočtu

        this.state = {
            soubory: null,
            uploading: 0,
            progress: 0,
            error: "",
            success: "",

            objednavkyLoaded: false,
            objednavky: []
        };

        this.smazatPrilohuNarok = this.smazatPrilohuNarok.bind(this);
    }

    async componentDidMount() {
        await this.nacistPrehled();
    }

    async nacistPrehled() {
        const aktualniFirma = authService.aktualniFirma();
        const objednavky = await httpService.post(
            config.apiEndpoint + "dodavatel/prehled.php",
            {
                firma_id: aktualniFirma.firma_id
            }
        );
        this.setState({objednavkyLoaded: true, objednavky: objednavky.data});
    }

    validateSoubory(soubory) {
        const lang = authService.getLang();

        if (!soubory) {
            this.setState({error: lang.musiBytSoubor});
            return false;
        }

        var reg = /(\.jpg|\.jpeg|\.pdf|\.doc|\.docx|\.png)$/i;
        for (let i in soubory) {
            if (!reg.exec(soubory[i].name.toLowerCase())) {
                this.setState({error: lang.povoleneSoubory + " *.JPG,*.JPEG, *.PDF,*.DOC,*.DOCX,*.PNG"});
                return false;
            }
        }
        return true;
    }

    odeslatSouboryKPozadavku(event, plan_id, rozpocet) {
        const aktualniFirma = authService.aktualniFirma();

        if (event.target && event.target.files) {
            let soubory = Array.from(event.target.files);

            if (this.validateSoubory(soubory)) {
                this.setState({error: "", success: "", uploading: 1, progress: 0});

                const fd = new FormData();
                fd.append("plan_id", plan_id);
                fd.append("rozpocet", rozpocet);
                fd.append("firma_id", aktualniFirma.firma_id);

                for (let i in soubory) {
                    fd.append("soubory[]", soubory[i], soubory[i].name);
                }

                httpService
                    .post(config.apiEndpoint + "dodavatel/novyPozadavek.php", fd, {
                        onUploadProgress: (progressEvent) => {
                            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            this.setState({progress});
                        },
                    }).then(() => {
                    this.nacistPrehled();
                });
            }
        }
    }

    odeslatSouboryKNaroku(event, plan_id) {
        const aktualniFirma = authService.aktualniFirma();

        if (event.target && event.target.files) {
            let soubory = Array.from(event.target.files);

            if (this.validateSoubory(soubory)) {
                this.setState({error: "", success: "", uploading: 1, progress: 0});

                const fd = new FormData();
                fd.append("plan_id", plan_id);
                fd.append("firma_id", aktualniFirma.firma_id);

                for (let i in soubory) {
                    fd.append("soubory[]", soubory[i], soubory[i].name);
                }

                httpService
                    .post(config.apiEndpoint + "dodavatel/novyNarok.php", fd, {
                        onUploadProgress: (progressEvent) => {
                            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            this.setState({progress});
                        },
                    }).then(() => {
                    this.nacistPrehled();
                });
            }
        }
    }

    async odeslatPozadavek(pozadavek_id) {
        const aktualniFirma = authService.aktualniFirma();
        const lang = authService.getLang();

        await httpService
            .post(config.apiEndpoint + "dodavatel/odeslatPozadavek.php", {
                pozadavek_id,
                firma_id: aktualniFirma.firma_id
            }).then(async () => {
                this.setState({error: "", success: lang.request_odeslan});
                this.nacistPrehled();
            });

        return false;
    }

    async odeslatNarok(narok_id) {
        const aktualniFirma = authService.aktualniFirma();

        await httpService
            .post(config.apiEndpoint + "dodavatel/odeslatNarok.php", {
                narok_id,
                firma_id: aktualniFirma.firma_id
            }).then(async () => {
                this.nacistPrehled();
            });

        return false;
    }

    async smazatPrilohuPozadavek(pozadavek_id, priloha_id) {
        const aktualniFirma = authService.aktualniFirma();

        await httpService
            .post(config.apiEndpoint + "dodavatel/smazatPrilohuPozadavek.php", {
                pozadavek_id, priloha_id,
                firma_id: aktualniFirma.firma_id
            }).then(async () => {
                this.nacistPrehled();
            });

        return false;
    }

    async smazatPrilohuNarok(narok_id, priloha_id) {
        const aktualniFirma = authService.aktualniFirma();

        await httpService
            .post(config.apiEndpoint + "dodavatel/smazatPrilohuNarok.php", {
                narok_id, priloha_id,
                firma_id: aktualniFirma.firma_id
            }).then(async () => {
                this.nacistPrehled();
            });

        return false;
    }

    render() {
        const {objednavkyLoaded, objednavky, success} = this.state;
        const lang = authService.getLang();
        const aktualniFirma = authService.aktualniFirma();
        const user = auth.getCurrentUser();

        if (user.dodavatel == 0) {
            return (
                <div className="alert alert-danger">
                    Nemáte přístup k dodavatelské sekci.
                </div>
            );
        }

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.dodavatel}
                    obrazek="./images/reference1.jpg"
                />

                <div className="container padding-top-20">
                    {success != "" && (
                        <div className="alert alert-success">
                            {success}
                        </div>
                    )}

                    {objednavkyLoaded ? (
                        objednavky && objednavky.length > 0 && (
                            <React.Fragment>
                                <h3 className="light upper underline center spacing-5 padding-bottom-20 padding-top-20">{lang.objednavky}</h3>

                                <p className={"text-center"}>
                                    <big>
                                        <a href={lang.url_dodavatel_guidline} target="_blank">GUIDELINE</a>
                                    </big><br/>
                                    <a href="/images/logo/logo.zip" target="_blank">LOGO</a>
                                </p>

                                <table style={{width: "100%"}}>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th colSpan={4}>{lang.nazev}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {objednavky.map((objednavka, index) => (
                                        [
                                            <tr key={index}>
                                                <td>
                                                    {objednavka.plan_id}
                                                </td>
                                                <td colSpan={4}>
                                                    {objednavka.nazev}
                                                </td>
                                            </tr>,
                                            <tr>
                                                <td></td>
                                                <th colSpan={2}>
                                                    Request&nbsp;
                                                    {(objednavka.pozadavky && objednavka.pozadavky.length > 0 && objednavka.pozadavky && objednavka.pozadavky.length > 0 && objednavka.pozadavky[0].soubory && objednavka.pozadavky[0].soubory.length > 0) && (
                                                        objednavka.pozadavky[0].vytvoreno === "0000-00-00 00:00:00" ? (
                                                            <button
                                                                onClick={() => this.odeslatPozadavek(objednavka.pozadavky[0].marketing_plan_dodavatel_pozadavek_id)}
                                                                className={"btn btn-primary pull-right"}>{lang.odeslat_ke_schvaleni}</button>
                                                        ) : (
                                                            <span
                                                                className={"pull-right"}>{lang.odeslano_ke_schvaleni}</span>
                                                        )
                                                    )}
                                                </th>
                                                <th colSpan={2}>
                                                    Claim&nbsp;
                                                    {(objednavka.naroky && objednavka.naroky.length > 0 && objednavka.naroky && objednavka.naroky.length > 0 && objednavka.naroky[0].soubory && objednavka.naroky[0].soubory.length > 0) && (
                                                        objednavka.naroky[0].vytvoreno === "0000-00-00 00:00:00" ? (
                                                            <button
                                                                onClick={() => this.odeslatNarok(objednavka.naroky[0].marketing_plan_dodavatel_narok_id)}
                                                                className={"btn btn-primary pull-right"}>{lang.odeslat_ke_schvaleni}</button>
                                                        ) : (
                                                            <span
                                                                className={"pull-right"}>{lang.odeslano_ke_schvaleni}</span>
                                                        )
                                                    )}
                                                </th>
                                            </tr>,
                                            <tr>
                                                <td></td>
                                                <td colSpan={2} valign="top">
                                                    {(objednavka.pozadavky && objednavka.pozadavky.length) ? (
                                                        <table>
                                                            <thead>
                                                            <th>{lang.prilohy}</th>
                                                            <th>{lang.schvaleno}</th>
                                                            </thead>
                                                            {objednavka.pozadavky.map((pozadavek, pozadavek_i) => (
                                                                <tbody key={"pozadavek-" + pozadavek_i}>
                                                                <tr>
                                                                    <td>{pozadavek.soubory.map((soubor_id, soubor_key) => (
                                                                        <React.Fragment key={"soubor_key" + soubor_key}>
                                                                            {objednavka.prilohy.map((priloha, index_priloha) => (
                                                                                priloha.soubor_id == soubor_id && (
                                                                                    <div key={index_priloha}>
                                                                                        <a href={
                                                                                            config.apiEndpoint +
                                                                                            "dodavatel/download.php?soubor_id=" +
                                                                                            priloha.soubor_id +
                                                                                            "&plan_id=" +
                                                                                            objednavka.plan_id +
                                                                                            "&firma_id=" +
                                                                                            aktualniFirma.firma_id +
                                                                                            "&token=" +
                                                                                            authService.getJwt() +
                                                                                            "&inline=true"
                                                                                        } target="_blank">
                                                                                            {priloha.nazev}
                                                                                        </a>

                                                                                        {(!pozadavek.schvaleno || pozadavek.schvaleno == "0000-00-00 00:00:00") && (
                                                                                            <React.Fragment>
                                                                                                &nbsp; (<a
                                                                                                href="javascript:;"
                                                                                                onClick={() => this.smazatPrilohuPozadavek(pozadavek.marketing_plan_dodavatel_pozadavek_id, priloha.soubor_id)}>{lang.smazatPriloha}</a>)
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            ))}
                                                                        </React.Fragment>
                                                                    ))}


                                                                        {(!pozadavek.schvaleno || pozadavek.schvaleno == "0000-00-00 00:00:00") && (
                                                                            <React.Fragment>
                                                                                <br/>
                                                                                <button
                                                                                    type={"button"}
                                                                                    onClick={() => this.fileInput2[objednavka.plan_id].click()}
                                                                                    className={"btn btn-secondary"}
                                                                                >
                                                                                  <span className="btn-label">
                                                                                    <i className="ti-cloud-up"/>
                                                                                  </span>
                                                                                    {lang.nahratSoubor}
                                                                                </button>
                                                                                <input
                                                                                    type="file"
                                                                                    name="file"
                                                                                    accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                                    ref={(fileInput2) => (this.fileInput2[objednavka.plan_id] = fileInput2)}
                                                                                    style={{display: "none"}}
                                                                                    multiple={true}
                                                                                    onChange={(event) => this.odeslatSouboryKPozadavku(event, objednavka.plan_id, 0)}
                                                                                />
                                                                            </React.Fragment>
                                                                        )}
                                                                    </td>
                                                                    <td rowSpan={3}>
                                                                        {(pozadavek.schvaleno && pozadavek.schvaleno != "0000-00-00 00:00:00") ? (
                                                                            <React.Fragment>
                                                                                {moment(pozadavek.schvaleno).format("D. M. Y H:mm")}
                                                                            </React.Fragment>
                                                                        ) : <em>ne</em>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{lang.rozpocet}</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {pozadavek.rozpocet.map((soubor_id, soubor_key) => (
                                                                            <React.Fragment
                                                                                key={"soubor_key" + soubor_key}>
                                                                                {objednavka.prilohy.map((priloha, index_priloha) => (
                                                                                    priloha.soubor_id == soubor_id && (
                                                                                        <div key={index_priloha}>
                                                                                            <a href={
                                                                                                config.apiEndpoint +
                                                                                                "dodavatel/download.php?soubor_id=" +
                                                                                                priloha.soubor_id +
                                                                                                "&plan_id=" +
                                                                                                objednavka.plan_id +
                                                                                                "&firma_id=" +
                                                                                                aktualniFirma.firma_id +
                                                                                                "&token=" +
                                                                                                authService.getJwt() +
                                                                                                "&inline=true"
                                                                                            } target="_blank">
                                                                                                {priloha.nazev}
                                                                                            </a>

                                                                                            {(!pozadavek.schvaleno || pozadavek.schvaleno == "0000-00-00 00:00:00") && (
                                                                                                <React.Fragment>
                                                                                                    &nbsp; (<a
                                                                                                    href="javascript:;"
                                                                                                    onClick={() => this.smazatPrilohuPozadavek(pozadavek.marketing_plan_dodavatel_pozadavek_id, priloha.soubor_id)}>{lang.smazat_z_rozpoctu}</a>)
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                ))}
                                                                            </React.Fragment>
                                                                        ))}


                                                                        {(!pozadavek.schvaleno || pozadavek.schvaleno == "0000-00-00 00:00:00") && (
                                                                            <React.Fragment>
                                                                                <br/>
                                                                                <button
                                                                                    type={"button"}
                                                                                    onClick={() => this.fileInput4[objednavka.plan_id].click()}
                                                                                    className={"btn btn-secondary"}
                                                                                >
                                                                                    <span className="btn-label">
                                                                                    <i className="ti-cloud-up"/>
                                                                                    </span>
                                                                                    {lang.nahratSoubor}
                                                                                </button>
                                                                                <input
                                                                                    type="file"
                                                                                    name="file"
                                                                                    accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                                    ref={(fileInput4) => (this.fileInput4[objednavka.plan_id] = fileInput4)}
                                                                                    style={{display: "none"}}
                                                                                    multiple={true}
                                                                                    onChange={(event) => this.odeslatSouboryKPozadavku(event, objednavka.plan_id, 1)}
                                                                                />
                                                                            </React.Fragment>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            ))}
                                                        </table>
                                                    ) : (
                                                        <div>
                                                            <p>{lang.zatim_zadne_prilohy}</p>


                                                            <button
                                                                type={"button"}
                                                                onClick={() => this.fileInput2[objednavka.plan_id].click()}
                                                                className={"btn btn-secondary"}
                                                            >
                                                                  <span className="btn-label">
                                                                    <i className="ti-cloud-up"/>
                                                                  </span>
                                                                {lang.nahratSoubor}
                                                            </button>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                ref={(fileInput2) => (this.fileInput2[objednavka.plan_id] = fileInput2)}
                                                                style={{display: "none"}}
                                                                multiple={true}
                                                                onChange={(event) => this.odeslatSouboryKPozadavku(event, objednavka.plan_id, 0)}
                                                            />


                                                            <p className="mt-4">{lang.zatim_zadny_rozpocet}</p>


                                                            <button
                                                                type={"button"}
                                                                onClick={() => this.fileInput4[objednavka.plan_id].click()}
                                                                className={"btn btn-secondary"}
                                                            >
                                                                  <span className="btn-label">
                                                                    <i className="ti-cloud-up"/>
                                                                  </span>
                                                                {lang.nahrat_rozpocet}
                                                            </button>
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                ref={(fileInput4) => (this.fileInput4[objednavka.plan_id] = fileInput4)}
                                                                style={{display: "none"}}
                                                                multiple={true}
                                                                onChange={(event) => this.odeslatSouboryKPozadavku(event, objednavka.plan_id,1)}
                                                            />
                                                        </div>
                                                    )}
                                                </td>
                                                <td colSpan={2} valign="top">

                                                    {(objednavka.pozadavky && objednavka.pozadavky.length) ? (
                                                        objednavka.pozadavky.map((pozadavek, pozadavek_i) => (
                                                            (pozadavek.schvaleno && pozadavek.schvaleno != "0000-00-00 00:00:00") ? (
                                                                <React.Fragment>
                                                                    {(objednavka.naroky && objednavka.naroky && objednavka.naroky.length) ? (
                                                                        <table>
                                                                            <thead>
                                                                            {/*<th>Požadavek</th>*/}
                                                                            <th>{lang.prilohy}</th>
                                                                            <th>{lang.schvaleno}</th>
                                                                            <th>{lang.faktura}</th>
                                                                            </thead>
                                                                            <tbody>
                                                                            {objednavka.naroky.map((narok, narok_i) => (
                                                                                <tr key={"narok-" + narok_i}>
                                                                                    {/*<td>{narok.poznamka}</td>*/}
                                                                                    <td>{narok.soubory.map((soubor_id, soubor_key) => (
                                                                                        <React.Fragment
                                                                                            key={"soubor_key" + soubor_key}>
                                                                                            {objednavka.prilohy.map((priloha, index_priloha) => (
                                                                                                priloha.soubor_id == soubor_id && (
                                                                                                    <div
                                                                                                        key={index_priloha}>
                                                                                                        <a href={
                                                                                                            config.apiEndpoint +
                                                                                                            "dodavatel/download.php?soubor_id=" +
                                                                                                            priloha.soubor_id +
                                                                                                            "&plan_id=" +
                                                                                                            objednavka.plan_id +
                                                                                                            "&firma_id=" +
                                                                                                            aktualniFirma.firma_id +
                                                                                                            "&token=" +
                                                                                                            authService.getJwt() +
                                                                                                            "&inline=true"
                                                                                                        }
                                                                                                           target="_blank">
                                                                                                            {priloha.nazev}
                                                                                                        </a>

                                                                                                        {(!narok.schvaleno || narok.schvaleno == "0000-00-00 00:00:00") && (
                                                                                                            <React.Fragment>
                                                                                                                &nbsp; (<a
                                                                                                                href="javascript:;"
                                                                                                                onClick={() => this.smazatPrilohuNarok(narok.marketing_plan_dodavatel_narok_id, priloha.soubor_id)}>{lang.smazatPriloha}</a>)
                                                                                                            </React.Fragment>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )
                                                                                            ))}
                                                                                        </React.Fragment>
                                                                                    ))}


                                                                                        {(!narok.schvaleno || narok.schvaleno == "0000-00-00 00:00:00") && (
                                                                                            <React.Fragment>
                                                                                                <br/>

                                                                                                <button
                                                                                                    type={"button"}
                                                                                                    onClick={() => this.fileInput3[objednavka.plan_id].click()}
                                                                                                    className={"btn btn-secondary"}
                                                                                                >
                                                                                    <span className="btn-label">
                                                                                    <i className="ti-cloud-up"/>
                                                                                    </span>
                                                                                                    {lang.nahratSoubor}
                                                                                                </button>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    name="file"
                                                                                                    accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                                                    ref={(fileInput3) => (this.fileInput3[objednavka.plan_id] = fileInput3)}
                                                                                                    style={{display: "none"}}
                                                                                                    multiple={true}
                                                                                                    onChange={(event) => this.odeslatSouboryKNaroku(event, objednavka.plan_id)}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(narok.schvaleno && narok.schvaleno != "0000-00-00 00:00:00" && narok.schvaleno != "") ? (
                                                                                            <React.Fragment>
                                                                                                {moment(narok.schvaleno).format("D. M. Y H:mm")}
                                                                                            </React.Fragment>
                                                                                        ) : <em>ne</em>}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(narok.schvaleno && narok.schvaleno != "0000-00-00 00:00:00" && narok.schvaleno != "") ? (
                                                                                            objednavka.interni_objednavky.slice(0, 1).map((io, io_index) => (
                                                                                                <div key={io_index}>
                                                                                                    {io.faktura_soubor_nazev ? (
                                                                                                        <React.Fragment>
                                                                                                            {io.faktura_soubor_nazev}
                                                                                                        </React.Fragment>
                                                                                                    ) : (
                                                                                                        <NavLink
                                                                                                            to={"/dodavatel/novaFaktura/" + io.interni_objednavka_id}
                                                                                                            className="btn btn-primary"
                                                                                                        >
                                                                                                            {lang.vlozit_fakturu}
                                                                                                        </NavLink>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))
                                                                                        ) : (
                                                                                            <div>{lang.pockejte_na_schlaveli_a_potom_fakturu}</div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                        </table>
                                                                    ) : (
                                                                        <div>
                                                                            <p>{lang.zatim_zadne_prilohy}</p>


                                                                            <button
                                                                                type={"button"}
                                                                                onClick={() => this.fileInput3[objednavka.plan_id].click()}
                                                                                className={"btn btn-secondary"}
                                                                            >
                                                                <span className="btn-label">
                                                                <i className="ti-cloud-up"/>
                                                                </span>
                                                                                {lang.nahratSoubor}
                                                                            </button>
                                                                            <input
                                                                                type="file"
                                                                                name="file"
                                                                                accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                                                ref={(fileInput3) => (this.fileInput3[objednavka.plan_id] = fileInput3)}
                                                                                style={{display: "none"}}
                                                                                multiple={true}
                                                                                onChange={(event) => this.odeslatSouboryKNaroku(event, objednavka.plan_id)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <div>{lang.vyckejte_na_schvaleni_requestu}</div>
                                                            )
                                                        ))
                                                    ) : (
                                                        <div>{lang.vyplnite_request}</div>
                                                    )}

                                                </td>
                                            </tr>
                                        ]
                                    ))}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )
                    ) : (
                        <LoadMusic/>
                    )}

                </div>
            </React.Fragment>
        );
    }
}

export default Dodavatel;
