import React, { Component } from "react";
import Modal from "../Modal";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "../../services/authService";

class PotvrditDeal extends Component {
  state = {};

  potvrdit = async stav => {
    const { poznamka } = this.state;
    const aktualniFirma = authService.aktualniFirma();
    if (this.props.onSubmit) {
      this.props.onSubmit(stav);
    }
    httpService.post(config.apiEndpoint + "dealy/potvrdit.php", {
      deal_id: this.props.dealId,
      poznamka,
      stav,
      firma_id: aktualniFirma.firma_id
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        title={this.props.title}
        onClose={this.props.onClose}
        hideSubmit={true}
      >
        <textarea
          name="poznamka"
          placeholder="Poznámka k potvrzení"
          className="form-control"
          onChange={tarea => this.setState({ poznamka: tarea.target.value })}
        />
        <div className="row padding-top-20">
          <div className="col-md-4 center padding-10">
            <button
              className="btn btn-primary"
              onClick={() => this.potvrdit(-1)}
            >
              Zamítnout deal
            </button>
          </div>
          <div className="col-md-4 center padding-10">
            <button
              className="btn btn-warning"
              onClick={() => this.potvrdit(0.5)}
            >
              Požádat o dodatečné info
            </button>
          </div>
          <div className="col-md-4 center padding-10">
            <button
              className="btn btn-success"
              onClick={() => this.potvrdit(1)}
            >
              Potvrdit deal
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PotvrditDeal;
