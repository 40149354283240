import React, {Component} from "react";
import NadpisStranky from "../common/nadpis";
import {Link} from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import {loadVeracoin} from "../../services/veracoinService";
import BonusProduktyItem from "./BonusProduktyItem";
import BonusDetailObjednavka from "./BonusDetailObjednavka";

class BonusDetail extends Component {
    state = {
        objednavky: [],
        bonusLoaded: false,
    };

    componentDidMount() {
        this.nahratBonusy();
    }

    nahratBonusy = async () => {
        const aktualniFirma = authService.aktualniFirma();

        this.setState({bonusLoaded: false});

        const bonusData = await httpService.post(
            config.apiEndpoint + "bonus/vypisObjednavek.php",
            {firma_id: aktualniFirma.firma_id}
        );

        this.setState({
            objednavky: bonusData.data.objednavky,
            bonusLoaded: true,
        });
    };

    render() {
        const lang = authService.getLang();
        const {bonusLoaded, objednavky} = this.state;

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.bonus_program}
                    obrazek="./images/deal.jpg"
                />
                <div className="container padding-top-50">

                    {bonusLoaded ? (
                        <React.Fragment>
                            {(objednavky && objednavky.length > 0) ? (
                                <React.Fragment>

                                    <div role="tabpanel">
                                        <div className="awe-nav-responsive">
                                            <ul className="awe-nav" role="tablist">
                                                {objednavky.map((objednavka, o_key) => (
                                                    <li className={o_key==0?"active":""} key={o_key}>
                                                        <a href={"#objednavka-"+objednavka.bp_objednavka_id}
                                                           title="" aria-controls="docs-tabs-2" role="tab"
                                                            data-toggle="tab">{objednavka.ext_id}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="tab-content padding-top-25">
                                        {objednavky.map((objednavka, o_key) => (
                                            <div role="tabpanel" className={"tab-pane "+((o_key==0)?" in active":"")} id={"objednavka-"+objednavka.bp_objednavka_id}>
                                                <BonusDetailObjednavka objednavka={objednavka} key={o_key}/>
                                            </div>
                                        ))}
                                    </div>

                                </React.Fragment>
                            ) : (
                                <div className="alert alert-danger">Žádné objednávky</div>
                            )}
                        </React.Fragment>
                    ) : (
                        <LoadMusic/>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default BonusDetail;
