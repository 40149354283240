import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import config from "../../config.json";
import httpService from "../../services/httpService";

class PrihlaskaAkceFirma extends Component {
  state = { odpovedi: {} };

  async componentDidUpdate() {
    if (this.props.show && !this.state.prihlaskaLoaded) {
      const aktualniFirma = authService.aktualniFirma();
      const prihlaska = await httpService.post(
        config.apiEndpoint + "akce/prihlasky.v2.php",
        { firma_id: aktualniFirma.firma_id, akce_id: this.props.akceId }
      );
      this.setState({
        prihlaska: prihlaska.data.osoby,
        otazky: prihlaska.data.otazky,
        prihlaskaLoaded: true,
      });
    }
  }

  prihlasitNaAkci = async (osoba_id) => {
    let prihlasky = [...this.state.prihlaska];
    const index = prihlasky.findIndex((p) => p.osoba_id === osoba_id);
    const { odpovedi } = this.state;
    prihlasky[index].potvrzeni_ucasti = -999;
    const { ubytovani, parkovani } = prihlasky[index];
    this.setState({ prihlaska: prihlasky });
    const aktualniFirma = authService.aktualniFirma();
    const prihlaska = await httpService.post(
      config.apiEndpoint + "akce/prihlasky.v2.php",
      {
        firma_id: aktualniFirma.firma_id,
        akce_id: this.props.akceId,
        prihlasit_osoba_id: osoba_id,
        ubytovani,
        parkovani,
        odpovedi,
      }
    );
    this.setState({ prihlaska: prihlaska.data.osoby, prihlasitOsobu: null });
  };

  odhlasitZAkce = async (osoba_id) => {
    let prihlasky = [...this.state.prihlaska];
    const index = prihlasky.findIndex((p) => p.osoba_id === osoba_id);
    prihlasky[index].potvrzeni_ucasti = -999;
    this.setState({ prihlaska: prihlasky });
    const aktualniFirma = authService.aktualniFirma();
    const prihlaska = await httpService.post(
      config.apiEndpoint + "akce/prihlasky.v2.php",
      {
        firma_id: aktualniFirma.firma_id,
        akce_id: this.props.akceId,
        odhlasit_osoba_id: osoba_id,
      }
    );
    this.setState({ prihlaska: prihlaska.data.osoby });
  };

  bookParkingAcc = (checkbox) => {
    let prihlasky = [...this.state.prihlaska];
    const index = prihlasky.findIndex(
      (p) => p.osoba_id === checkbox.target.value
    );
    eval(
      "prihlasky[index]." + checkbox.target.name + "=checkbox.target.checked"
    );
    this.setState({ prihlaska: prihlasky });
  };

  zmenaOdpovedi = (odpoved_id, odpoved) => {
    let { odpovedi } = this.state;
    const { otazky } = this.state;

    odpovedi[odpoved_id] = odpoved;
    let formCheck = true;
    for (let i = 0; i < otazky.length; i++) {
      if (
        !(
          (odpovedi &&
            odpovedi[otazky[i].otazka_id] &&
            odpovedi[otazky[i].otazka_id] !== "") ||
          otazky[i].typ === "checkbox"
        )
      ) {
        formCheck = false;
      }
      console.log(
        "otazka",
        odpovedi[otazky[i].otazka_id],
        formCheck,
        otazky[i].otazka_id
      );
    }

    this.setState({ odpovedi, formCheck });
  };

  render() {
    const {
      prihlaska,
      prihlaskaLoaded,
      otazky,
      prihlasitOsobu,
      odpovedi,
      formCheck,
    } = this.state;
    const lang = authService.getLang();
    let index = -1;
    if (prihlasitOsobu && parseInt(prihlasitOsobu) > 0) {
      index = prihlaska.findIndex((o) => o.osoba_id === prihlasitOsobu);
    }
    return (
      <React.Fragment>
        {prihlaskaLoaded ? (
          prihlasitOsobu && parseInt(prihlasitOsobu) > 0 ? (
            <div>
              <h5>
                {prihlaska &&
                  prihlaska[index] &&
                  prihlaska[index].jmeno + " " + prihlaska[index].prijmeni}
              </h5>
              <div className="">
                {otazky &&
                  otazky.map((o) => (
                    <div
                      key={o.otazka_id}
                      style={{
                        marginTop: "10px",
                        paddingTop: "10px",
                        borderTop: "solid 1px #ddd",
                      }}
                      className="row"
                    >
                      <div className="col-md-4">
                        <strong>{o.otazka}</strong>
                      </div>
                      <div className="col-md-8">
                        {o.typ === "text" && (
                          <input
                            type="text"
                            className="form-control"
                            name={"otazka_" + o.otazka_id}
                            value={odpovedi && odpovedi[o.otazka_id]}
                            onChange={(e) =>
                              this.zmenaOdpovedi(o.otazka_id, e.target.value)
                            }
                          />
                        )}
                        {o.typ === "checkbox" && (
                          <div>
                            <input
                              type="checkbox"
                              name={"otazka_" + o.otazka_id}
                              value="Ano"
                              data-role="none"
                              onChange={(e) =>
                                this.zmenaOdpovedi(
                                  o.otazka_id,
                                  e.target.checked ? 1 : 0
                                )
                              }
                            />{" "}
                            Ano
                          </div>
                        )}
                        {o.typ === "select" && (
                          <select
                            name={"otazka_" + o.otazka_id}
                            className="form-control"
                            value={odpovedi && odpovedi[o.otazka_id]}
                            onChange={(e) =>
                              this.zmenaOdpovedi(o.otazka_id, e.target.value)
                            }
                          >
                            {!(odpovedi && odpovedi[o.otazka_id]) && (
                              <option value="">Zvolte možnost</option>
                            )}
                            {o.odpovedi &&
                              o.odpovedi.map((oo) => (
                                <option
                                  value={oo.odpoved_id}
                                  key={oo.odpoved_id}
                                >
                                  {oo.odpoved}
                                </option>
                              ))}
                          </select>
                        )}
                        {o.typ === "radio" && (
                          <ul
                            style={{
                              listStyle: "none",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            {o.odpovedi &&
                              o.odpovedi.map((oo) => (
                                <li
                                  key={oo.odpoved_id}
                                  style={{
                                    listStyle: "none",
                                    padding: "0px",
                                    margin: "0px",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name={"otazka_" + o.otazka_id}
                                    value={oo.odpoved_id}
                                    onChange={(e) =>
                                      this.zmenaOdpovedi(
                                        o.otazka_id,
                                        e.target.value
                                      )
                                    }
                                  />{" "}
                                  {oo.odpoved}
                                </li>
                              ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="center" style={{ paddingTop: "10px" }}>
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                  disabled={!formCheck}
                  onClick={() => this.prihlasitNaAkci(prihlasitOsobu)}
                >
                  Odeslat přihlášku
                </button>
                <button
                  className="btn btn-dark"
                  onClick={() => this.setState({ prihlasitOsobu: null })}
                >
                  Zpět
                </button>
              </div>
              {!formCheck && (
                <p
                  className="center"
                  style={{ paddingTop: "10px", color: "red" }}
                >
                  Odpovězte na všechny dotazy
                </p>
              )}
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>{lang.osoba}</th>
                  {prihlaska && prihlaska[0].nabizet_ubytovani ? (
                    <th>{lang.chci_ubytovani}</th>
                  ) : null}
                  {prihlaska && prihlaska[0].nabizet_parkovani ? (
                    <th>{lang.chci_parkovani}</th>
                  ) : null}
                  <th>{lang.prihlaska}</th>
                </tr>
              </thead>
              <tbody>
                {prihlaska &&
                  prihlaska.length &&
                  prihlaska.map((p) => (
                    <tr key={p.osoba_id}>
                      <td>
                        {p.jmeno} {p.prijmeni}
                      </td>
                      {prihlaska && prihlaska[0].nabizet_ubytovani ? (
                        <td className="center">
                          <input
                            name="ubytovani"
                            value={p.osoba_id}
                            type="checkbox"
                            disabled={p.potvrzeni_ucasti || p.uzavreno}
                            defaultChecked={p.ubytovani}
                            onChange={this.bookParkingAcc}
                          />
                        </td>
                      ) : null}
                      {prihlaska && prihlaska[0].nabizet_parkovani ? (
                        <td className="center">
                          <input
                            name="parkovani"
                            value={p.osoba_id}
                            type="checkbox"
                            disabled={p.potvrzeni_ucasti || p.uzavreno}
                            defaultChecked={p.parkovani}
                            onChange={this.bookParkingAcc}
                          />
                        </td>
                      ) : null}
                      <td className="center">
                        {p.potvrzeni_ucasti ? (
                          p.potvrzeni_ucasti === -999 ? (
                            <button className="btn btn-primary" disabled={true}>
                              {lang.zpracovani_pozadavku}
                            </button>
                          ) : (
                            <React.Fragment>
                              <button
                                className="btn btn-dark"
                                onClick={() => this.odhlasitZAkce(p.osoba_id)}
                              >
                                {lang.odhlasit_z_akce}
                              </button>
                              <div>
                                <small>
                                  {p.potvrzeni_ucasti == 0 ? (
                                    lang.ceka_na_potvrzeni
                                  ) : p.potvrzeni_ucasti > 0 ? (
                                    <span style={{ color: "green" }}>
                                      {lang.ucast_potvrzena}
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      {lang.ucast_zamitnuta}
                                    </span>
                                  )}
                                </small>
                              </div>
                            </React.Fragment>
                          )
                        ) : p.uzavreno ? (
                          lang.nelze_se_prihlasit
                        ) : otazky && otazky.length > 0 ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              this.setState({ prihlasitOsobu: p.osoba_id })
                            }
                          >
                            {lang.prihlasit_na_akci}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.prihlasitNaAkci(p.osoba_id)}
                          >
                            {lang.prihlasit_na_akci}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default PrihlaskaAkceFirma;
