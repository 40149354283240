import React, { Component } from "react";
import { connect } from "react-redux";
import authService from "../../services/authService";
import { vlozitDoKosiku } from "./../../actions/vyprodejSkladu";
import { vlozitDoKosikuSamoobsluha } from "./../../actions/kosikSamoobsluha";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

class KosikIcon extends Component {
  constructor(props) {
    super(props);
    this.onVlozitDoKosiku = this.onVlozitDoKosiku.bind(this);
  }

  pocetPolozek = () => {
    const user = authService.getCurrentUser();
    let pocet = 0;
    let celkemCena = 0;
    if (this.props.obsahKosiku) {
      for (let i = 0; i < this.props.obsahKosiku.length; i++) {
        for (let j = 0; j < this.props.obsahKosiku[i].polozky.length; j++) {
          if (
            this.props.obsahKosiku[i].polozky[j].rezervace &&
            this.props.obsahKosiku[i].polozky[j].login === user.login
          ) {
            pocet++;
            celkemCena += this.props.obsahKosiku[i].polozky[j].cena;
          }
        }
      }
    }
    if (this.props.obsahKosiku2) {
      pocet += this.props.obsahKosiku2.length;
    }
    return { pocet, celkemCena };
  };

  onVlozitDoKosiku = (val) => {
    this.props.onVlozitDoKosiku(val);
  };

  onVlozitDoKosiku2 = (val) => {
    this.props.onVlozitDoKosiku2(val);
  };

  vyhoditZKosiku = (katalog_id, sn) => {
    let obsahKosiku = [...this.props.obsahKosiku];
    const index1 = obsahKosiku.findIndex((p) => p.katalog_id === katalog_id);
    const index2 = obsahKosiku[index1].polozky.findIndex((p) => p.sn === sn);
    obsahKosiku[index1].polozky[index2].rezervace = null;
    obsahKosiku[index1].polozky[index2].login = null;
    this.onVlozitDoKosiku(obsahKosiku);
  };

  vyhoditZKosiku2 = (index) => {
    let obsahKosiku2 = this.props.obsahKosiku2;
    obsahKosiku2 = obsahKosiku2.filter((i, index2) => index !== index2);
    this.onVlozitDoKosiku2(obsahKosiku2);
  };

  render() {
    const { obsahKosiku } = this.props;
    let { obsahKosiku2 } = this.props;
    let i = 0;
    let j = 0;
    if (obsahKosiku2) {
      for (i = 0; i < obsahKosiku2.length; i++) {
        obsahKosiku2[i].cenaCelkem =
          obsahKosiku2[i].produkt.cena.prodej_cena_poSleve;
        for (j = 0; j < obsahKosiku2[i].doplnky.length; j++) {
          obsahKosiku2[i].cenaCelkem +=
            obsahKosiku2[i].doplnky[j].doplnek.cena.prodej_cena_poSleve;
        }
      }
    }

    console.log("cena", obsahKosiku2);
    const stav = this.pocetPolozek();
    const user = authService.getCurrentUser();
    return (
      <React.Fragment>
        {stav.pocet ? (
          <li className="menubar-cart">
            <a href="#/kosik">
              <span className="icon icon-shopping-bag" />
              <span className="cart-number">{stav.pocet}</span>
            </a>
            <ul className="submenu megamenu" style={{ width: "300px" }}>
              <li>
                <div className="container-fluid">
                  <ul className="whishlist">
                    {obsahKosiku2 &&
                      obsahKosiku2.length > 0 &&
                      obsahKosiku2.map((i, index) => (
                        <li key={index}>
                          <div className="whishlist-item">
                            <div className="product-image">
                              {" "}
                              <img
                                src={
                                  "https://api.exclusive-networks.cz/images/eprodfoto.php?prilezitost_ciselnik_id=" +
                                  i.produkt.prilezitost_ciselnik_id
                                }
                                alt=""
                              />
                            </div>
                            <div className="product-body">
                              <div className="whishlist-name">
                                <h3>{i.produkt.nazev}</h3>
                              </div>
                              <div className="whishlist-price">
                                {i.doplnky &&
                                  i.doplnky.length > 0 &&
                                  i.doplnky.map((d) => (
                                    <div
                                      key={d.doplnek.prilezitost_ciselnik_id}
                                    >
                                      + {d.doplnek.vyrobek}
                                    </div>
                                  ))}
                              </div>
                              <div className="whishlist-price border-top">
                                <span>Cena:</span>
                                <strong>
                                  <small>{i.produkt.cena.prodej_mena}</small>{" "}
                                  <NumberFormat
                                    value={i.cenaCelkem}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType="text"
                                  />
                                </strong>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => this.vyhoditZKosiku2(index)}
                            className="btn btn-danger btn-sm remove"
                          >
                            <i className="icon icon-remove" />
                          </button>
                        </li>
                      ))}
                    {obsahKosiku &&
                      obsahKosiku.length > 0 &&
                      obsahKosiku.map((i) =>
                        i.polozky.map((p) =>
                          p.rezervace && p.login === user.login ? (
                            <li key={p.sn}>
                              <div className="whishlist-item">
                                <div className="product-image">
                                  <img
                                    src={
                                      "https://api.exclusive-networks.cz/images/logoprodukt.php?prilezitost_ciselnik_id=" +
                                      i.info.prilezitost_ciselnik_id
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="product-body">
                                  <div className="whishlist-name">
                                    <h3>
                                      {i.info.typ} {i.info.vyrobek}
                                    </h3>
                                  </div>
                                  <div className="whishlist-price">
                                    <span>ID:</span>
                                    <strong>{p.sn}</strong>
                                  </div>
                                  <div className="whishlist-price border-top">
                                    <span>Cena:</span>
                                    <strong>
                                      <small>CZK</small>{" "}
                                      <NumberFormat
                                        value={p.cena}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                      />
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={() =>
                                  this.vyhoditZKosiku(i.katalog_id, p.sn)
                                }
                                className="btn btn-danger btn-sm remove"
                              >
                                <i className="icon icon-remove" />
                              </button>
                            </li>
                          ) : null
                        )
                      )}
                  </ul>
                  <div className="cart-action">
                    <Link to="/kosik" className="btn btn-primary">
                      Dokončit objednávku
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  obsahKosiku: state.vyprodejSkladu,
  obsahKosiku2: state.kosikSamoobsluha,
});
const mapActionsToProps = {
  onVlozitDoKosiku: vlozitDoKosiku,
  onVlozitDoKosiku2: vlozitDoKosikuSamoobsluha,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(KosikIcon);
