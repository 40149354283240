import React, { Component } from "react";
import { BrowserView, MobileView } from "react-device-detect";

class NadpisStranky extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <BrowserView>
          <div
            className="main-header background background-image-heading-products"
            style={{
              backgroundImage:
                "url('" +
                (false && this.props.obrazek
                  ? this.props.obrazek
                  : "./images/pw_bg.jpg") +
                "')",
            }}
          >
            <div className="container">
              <h1>{this.props.nadpis}</h1>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="background background-dark border-bottom mobile-bg">
            <div className="container center">
              <h1>{this.props.nadpis}</h1>
            </div>
          </div>
        </MobileView>
      </React.Fragment>
    );
  }
}

export default NadpisStranky;
