import React, { Component } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { isMobile } from "react-device-detect";
import config from "../../config.json";
import httpService from "../../services/httpService.js";
import { Link } from "react-router-dom";
import authService from "../../services/authService.js";

class NovinkyHP extends Component {
  state = {};

  async componentDidMount() {
    const ak = authService.aktualniFirma();
    const novinky = await httpService.post(
      config.apiEndpoint + "novinky/prehledNovinek.php",
      { agenda_id: ak.agenda_id }
    );
    this.setState({ novinkyLoaded: true, novinky: novinky.data });
  }

  render() {
    const { novinky, novinkyLoaded } = this.state;
    const lang = authService.getLang();
    var getSlug = require("speakingurl");
    return (
      <React.Fragment>
        {novinkyLoaded ? (
          <React.Fragment>
            <h3>{lang.novinky}</h3>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={isMobile ? 125 : 40}
              totalSlides={novinky.length}
              visibleSlides={isMobile ? 1 : 2}
              step={isMobile ? 1 : 2}
            >
              <Slider>
                {novinky.map((n, index) => (
                  <Slide index={index} key={n.news_id}>
                    <div className="section-post">
                      <div className="section-post-media">
                        <Link
                          to={
                            "/novinky/" +
                            n.news_id +
                            "/" +
                            getSlug(n.news_title)
                          }
                        >
                          <img src={n.image} alt={n.news_title} />
                        </Link>
                      </div>
                      <div className="section-post-body">
                        <div className="section-post-time">
                          <span>{n.den}</span>
                          <small>{n.mesic}</small>
                        </div>
                        <h4 className="section-post-title">
                          <Link
                            to={
                              "/novinky/" +
                              n.news_id +
                              "/" +
                              getSlug(n.news_title)
                            }
                          >
                            {n.news_title}
                          </Link>
                        </h4>
                        <Link
                          to={
                            "/novinky/" +
                            n.news_id +
                            "/" +
                            getSlug(n.news_title)
                          }
                          className="read-more"
                        >
                          {lang.dalsi_informace}
                        </Link>
                      </div>
                    </div>
                  </Slide>
                ))}
              </Slider>
              <ButtonBack className="btn btn-sm btn-arrow btn-default margin-right-10">
                <i className="fa fa-chevron-left" />
              </ButtonBack>
              <ButtonNext className="btn btn-sm btn-arrow btn-default margin-right-10">
                <i className="fa fa-chevron-right" />
              </ButtonNext>
            </CarouselProvider>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default NovinkyHP;
