import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { Link } from "react-router-dom";
import Modal from "./../Modal";
import PrihlaskaAkceFirma from "./PrihlaskaAkceFirma";
import { BrowserView, MobileView } from "react-device-detect";
import Card from "../common/Card";
import authService from "../../services/authService";

class AkceNahled extends Component {
  state = { akceLoaded: false };
  async componentDidMount() {
    console.log("AKCE", this.props);
    const akce = await httpService.post(
      config.apiEndpoint + "akce/detail.php",
      {
        akce_id: this.props.akceId,
      }
    );
    this.setState({ akce: akce.data, akceLoaded: true });
  }
  render() {
    const { akceLoaded, akce } = this.state;
    const lang = authService.getLang();
    let prihlasovani = false;
    if (akce) {
      const d1 = new Date();
      const d2 = new Date(akce.uzaverka_prihlasek.replace(" ", "T"));
      prihlasovani = d1.getTime() <= d2.getTime() ? true : false;
    }
    var getSlug = require("speakingurl");
    return (
      <div>
        {akceLoaded ? (
          <React.Fragment>
            <Modal
              key={akce.akce_id}
              title={"Přihlásit na akci " + akce.nazev}
              show={this.state.ukazPrihlasku}
              hideSubmit={true}
              onClose={() => this.setState({ ukazPrihlasku: false })}
            >
              <PrihlaskaAkceFirma
                akceId={akce.akce_id}
                show={this.state.ukazPrihlasku}
              />
            </Modal>
            <MobileView>
              <Card
                nadpis={akce.nazev}
                image={
                  "https://api.exclusive-networks.cz/images/logoakce.php?akce_id=" +
                  akce.akce_id +
                  "&typ_akce=internal"
                }
                imageCyrcle={true}
              >
                <div className="obchodniPripad">
                  <ul>
                    <li>{akce.misto_konani}</li>
                    <li>{akce.termin}</li>
                  </ul>
                </div>
                <div className="padding-vertical-20">
                  {akce.url_registrace ? (
                    <a
                      href={akce.url_registrace}
                      className="btn btn-primary margin-right-10"
                    >
                      {lang.prihlasit}
                    </a>
                  ) : prihlasovani && akce.bez_nasi_registrace !== "1" ? (
                    <button
                      className="btn btn-primary margin-right-10"
                      onClick={() => this.setState({ ukazPrihlasku: true })}
                    >
                      {lang.prihlasit}
                    </button>
                  ) : null}
                  <Link
                    to={
                      "/kalendar/" +
                      akce.akce_id +
                      "/" +
                      getSlug(akce.nazev, { lang: "cs" })
                    }
                    className="btn btn-dark btn-outline"
                  >
                    {lang.vice_informaci}
                  </Link>
                </div>
              </Card>
            </MobileView>
            <BrowserView>
              <div className="row padding-bottom-40" style={{ width: "100%" }}>
                <div className="col-md-4">
                  <img
                    src={
                      "https://api.exclusive-networks.cz/images/logoakce.php?akce_id=" +
                      akce.akce_id +
                      "&typ_akce=internal"
                    }
                  />
                </div>
                <div className="col-md-8 obchodniPripad">
                  <h2 style={{ fontSize: "1.4em" }}>
                    <Link
                      to={
                        "/kalendar/" +
                        akce.akce_id +
                        "/" +
                        getSlug(akce.nazev, { lang: "cs" })
                      }
                    >
                      {akce.nazev}
                    </Link>
                  </h2>
                  <ul>
                    <li>{akce.misto_konani}</li>
                    <li>{akce.termin}</li>
                  </ul>
                  <div className="padding-vertical-20">
                    {akce.url_registrace ? (
                      <a
                        href={akce.url_registrace}
                        className="btn btn-primary margin-right-10"
                      >
                        {lang.prihlasit}
                      </a>
                    ) : prihlasovani && akce.bez_nasi_registrace !== "1" ? (
                      <button
                        className="btn btn-primary margin-right-10"
                        onClick={() => this.setState({ ukazPrihlasku: true })}
                      >
                        {lang.prihlasit}
                      </button>
                    ) : null}
                    <Link
                      to={
                        "/kalendar/" +
                        akce.akce_id +
                        "/" +
                        getSlug(akce.nazev, { lang: "cs" })
                      }
                      className="btn btn-dark btn-outline"
                    >
                      {lang.vice_informaci}
                    </Link>
                  </div>
                </div>
              </div>
            </BrowserView>
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </div>
    );
  }
}

export default AkceNahled;
