import React, {Component} from "react";
import NadpisStranky from "../common/nadpis";
import {Link} from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";

class Bonus extends Component {
    state = {
        skupiny: [],
        bonusLoaded: false,
    };

    componentDidMount() {
        this.nahratBonusy();
    }

    nahratBonusy = async () => {
        const aktualniFirma = authService.aktualniFirma();
        if (aktualniFirma.partnerweb_obchod) {
            this.setState({bonusLoaded: false});
            const bonusData = await httpService.post(
                config.apiEndpoint + "bonus/produkty.php",
                {firma_id: aktualniFirma.firma_id}
            );

            this.setState({
                aktualniFirma,
                skupiny: bonusData.data.skupiny,
                bonusLoaded: true,
            });
        }
    };

    render() {
        const lang = authService.getLang();
        const {bonusLoaded, skupiny} = this.state;
        if (
            this.state.aktualniFirma &&
            !this.state.aktualniFirma.partnerweb_obchod
        ) {
            return (
                <div className="alert alert-danger">
                    Nemáte přístup k bonus programu
                </div>
            );
        }
        return (
            <React.Fragment>
                {!this.props.hideHeader && (
                    <NadpisStranky
                        nadpis={lang.bonus_program}
                        obrazek="./images/deal.jpg"
                    />
                )}
                <div className="container">
                    <div className="center padding-top-20">
                        <p>
                            <strong>{lang.bonus_line_1}</strong>
                        </p>
                        <p>{lang.bonus_line_2}</p>
                        <p>{lang.bonus_line_3}</p>
                    </div>

                    {bonusLoaded ? (
                        <React.Fragment>
                            {skupiny && skupiny.length > 0 && skupiny[0].length > 0 ? (
                                <React.Fragment>
                                    &nbsp;
                                    <div className={"row"}>
                                        {skupiny[0].map((skupina, skupina_key) => (
                                            <div
                                                className="product product-grid col-md-3"
                                                key={skupina_key}
                                            >
                                                <div className="product-media">
                                                    <div className="product-thumbnail">
                                                        <Link
                                                            to={"/bonus/" + skupina.bp_skupina_id}
                                                            title={skupina.nazev}
                                                        >
                                                            <img
                                                                src={
                                                                    "images/bonus_okruhy/" +
                                                                    skupina.bp_skupina_id +
                                                                    ".jpg"
                                                                }
                                                                alt=""
                                                                className="current"
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="product-body">
                                                    <h2 className="center upper padding-top-20">
                                                        <Link
                                                            to={"/bonus/" + skupina.bp_skupina_id}
                                                            title={skupina.nazev}
                                                        >
                                                            {skupina.nazev}
                                                        </Link>
                                                    </h2>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="alert alert-danger">
                                    {lang.bonus_bez_kategorie}
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <LoadMusic/>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default Bonus;
