import React, { Component } from "react";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "../common/LoadMusic";

class Tracking extends Component {
  state = {};

  componentDidMount = () => {
    this.nahrajTracking();
  };

  componentDidUpdate = () => {
    const { doprava_id } = this.state;
    if (doprava_id && doprava_id !== this.props.doprava_id) {
      this.nahrajTracking();
    }
  };

  nahrajTracking = async () => {
    const { doprava_id } = this.props;
    const tracking = await httpService.post(
      config.apiEndpoint + "obchodniPripady/tracking.php",
      { doprava_id }
    );
    this.setState({
      loaded: true,
      doprava_id,
      tracking: tracking.data.tracking,
    });
  };

  render() {
    const { loaded, tracking } = this.state;
    return (
      <React.Fragment>
        {loaded ? (
          <React.Fragment>
            {tracking && tracking.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Status</th>
                    <th>Místo</th>
                  </tr>
                </thead>
                <tbody>
                  {tracking.map((t, index) => (
                    <tr key={index}>
                      <td>{t.timestamp}</td>
                      <td>{t.description}</td>
                      <td>{t.locationAddressLocality}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="alert alert-danger">Nic tu není...</div>
            )}
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default Tracking;
