import httpService from "./httpService";
import config from "../config.json";
import authService from "./authService";


export async function loadVeracoin() {
    const aktualniFirma = authService.aktualniFirma();

    //localStorage.removeItem("veracoin");

    const {data: result, headers} = await httpService.post(
        config.apiEndpoint + "bonus/dostupnyKredit.php",

        {
            firma_id: aktualniFirma.firma_id
        });

    if (headers["x-error"]) {
        throw headers["x-error"];
    }

    if ("vc" in result) {
        localStorage.setItem("veracoin", result.vc);
        console.log(result.vc);
    } else {
        localStorage.setItem("veracoin", -1);
    }
}

export async function loadVeracoinHistory() {
    const aktualniFirma = authService.aktualniFirma();

    const {data: result, headers} = await httpService.post(
        config.apiEndpoint + "bonus/prehledVercoinu.php",

        {
            firma_id: aktualniFirma.firma_id
        });

    if (headers["x-error"]) {
        throw headers["x-error"];
    }

    return result;
}

export function getVeracoin() {
    return localStorage.getItem("veracoin");
}

export default {
    loadVeracoin,
    getVeracoin
};
