import React, { Component } from "react";
import httpService from "../../services/httpService";
import ProgressBar from "./ProgressBar";

class FileUploader extends Component {
  state = {
    uploading: false,
    progress: 0
  };

  uploadFile = event => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ uploading: true, progress: 0, fileName: file.name });
      const fd = new FormData();
      fd.append("soubor", file, file.name);
      Object.keys(this.props.params).forEach(key =>
        fd.append(key, this.props.params[key])
      );
      httpService
        .post(this.props.url, fd, {
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            this.setState({ progress });
          }
        })
        .then(res => {
          this.props.onUpload(res);
          setTimeout(() => {
            this.setState({ uploading: false });
          }, 1000);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.uploading ? (
          <ProgressBar
            popis={this.state.fileName}
            progress={this.state.progress}
          />
        ) : (
          <React.Fragment>
            <div className="center">
              <input
                type="file"
                name="file"
                ref={fileInput => (this.fileInput = fileInput)}
                style={{ display: "none" }}
                onChange={this.uploadFile}
              />
              <button
                onClick={() => this.fileInput.click()}
                className="btn btn-primary"
              >
                Nahrát soubor
              </button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default FileUploader;
