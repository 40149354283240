import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { ListGroup, ListGroupItem } from "reactstrap";
import authService from "./../../services/authService";
import config from "../../config.json";
import httpService from "../../services/httpService";
import LoadMusic from "./../common/LoadMusic";
import { BrowserView, MobileView } from "react-device-detect";
import Card from "../common/Card";

class Fakturace extends Component {
  state = {
    listItems: [],
    selectedListItem: "all",
    fakturyRok: [],
    fakturyRokLoaded: []
  };

  componentDidMount() {
    const rok = new Date().getFullYear();
    const lang = authService.getLang();
    let listItems = [];
    listItems[listItems.length] = {
      id: "all",
      nazev: lang.nezaplacene
    };
    for (let i = rok; i >= 2015; i--) {
      listItems[listItems.length] = { id: i, nazev: "Rok " + i };
    }
    const aktualniFirma = authService.aktualniFirma();
    this.setState({ listItems, aktualniFirma });
  }

  zobrazRok = () => {
    const rok = this.state.selectedListItem;
    const lang = authService.getLang();
    if (this.state.fakturyRokLoaded[rok]) {
      if (this.state.fakturyRok[rok] === null) {
        return (
          <div className="alert alert-danger">Nemáte přístup k fakturaci</div>
        );
      }
      if (this.state.fakturyRok[rok].faktury) {
        return (
          <React.Fragment>
            <MobileView>
              {this.state.fakturyRok[rok].faktury.map((f, index) => (
                <Card nadpis={f.doklad} key={index}>
                  <h2 style={{ fontSize: "1em" }}>{f.popis}</h2>
                  <div className="obchodniPripad">
                    <ul>
                      <li>
                        <strong>{lang.vystaveno}:</strong>
                        <span style={{ float: "right" }}>{f.vystaveno}</span>
                      </li>
                      <li
                        style={{
                          color: f.po_splatnosti ? "red" : "inherit"
                        }}
                      >
                        <strong>{lang.splatno}:</strong>
                        <span style={{ float: "right" }}>{f.splatno}</span>
                      </li>
                      <li>
                        <strong>{lang.uhrazeno}:</strong>
                        <span style={{ float: "right" }}>{f.uhrazeno}</span>
                      </li>
                      <li>
                        <strong>{lang.zbyva_uhradit}:</strong>
                        <span style={{ float: "right" }}>
                          <small>{f.mena}</small>
                          {f.proplatit}
                        </span>
                        {f.upominka1_datum ? (
                          <span className="badge badge-success margin-left-5">
                            1. {lang.upominka}
                          </span>
                        ) : null}
                        {f.upominka2_datum ? (
                          <span className="badge badge-warning margin-left-5">
                            2. {lang.upominka}
                          </span>
                        ) : null}
                        {f.upominka3_datum ? (
                          <span className="badge badge-danger margin-left-5">
                            3. {lang.upominka}
                          </span>
                        ) : null}
                      </li>
                      <li>
                        <strong>Celkem:</strong>
                        <span style={{ float: "right" }}>
                          <small>{f.mena}</small>
                          {f.celkem}
                        </span>
                      </li>
                    </ul>
                    <div className="center">
                      <a
                        href={"#/fakturace/" + f.doklad}
                        className="btn btn-primary"
                      >
                        Detail
                      </a>
                    </div>
                  </div>
                </Card>
              ))}
            </MobileView>
            <BrowserView>
              <table style={{ fontSize: "0.8em" }}>
                <thead>
                  <tr>
                    <th>{lang.doklad}</th>
                    <th>{lang.datumy}</th>
                    <th>{lang.popis}</th>
                    <th>{lang.zbyva_uhradit}</th>
                    <th>{lang.celkem}</th>
                    <th>{lang.vystavil}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fakturyRok[rok].faktury.map(f => (
                    <tr
                      key={f.doklad}
                      style={
                        f.po_splatnosti
                          ? { color: "red", backgroundColor: "#f5d0d6" }
                          : null
                      }
                    >
                      <td>
                        <a href={"#/fakturace/" + f.doklad}>{f.doklad}</a>
                      </td>
                      <td>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <strong>{lang.vystaveno}:</strong> {f.vystaveno}
                        </div>
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            color: f.po_splatnosti ? "red" : "inherit"
                          }}
                        >
                          <strong>{lang.splatno}:</strong> {f.splatno}
                        </div>{" "}
                        <div style={{ whiteSpace: "nowrap" }}>
                          <strong>{lang.uhrazeno}:</strong> {f.uhrazeno}
                        </div>
                      </td>
                      <td>{f.popis}</td>
                      <td style={{ textAlign: "right" }}>
                        {f.proplatit}
                        <div>
                          <small style={{ padding: "0px" }}>{f.mena}</small>
                        </div>
                        {f.upominka1_datum ? (
                          <span className="badge badge-success margin-left-5">
                            1. {lang.upominka}
                          </span>
                        ) : null}
                        {f.upominka2_datum ? (
                          <span className="badge badge-warning margin-left-5">
                            2. {lang.upominka}
                          </span>
                        ) : null}
                        {f.upominka3_datum ? (
                          <span className="badge badge-danger margin-left-5">
                            3. {lang.upominka}
                          </span>
                        ) : null}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {f.celkem}
                        <div>
                          <small style={{ padding: "0px" }}>{f.mena}</small>
                        </div>
                      </td>
                      <td>{f.vystavil}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">Celkem:</td>
                    <td style={{ textAlign: "right" }}>
                      {this.state.fakturyRok[rok].celkem_proplatit.map(f => (
                        <React.Fragment key={f.mena}>
                          {f.castka}
                          <div>
                            <small style={{ padding: "0px" }}>{f.mena}</small>
                          </div>
                        </React.Fragment>
                      ))}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {this.state.fakturyRok[rok].celkem.map(f => (
                        <React.Fragment key={f.mena}>
                          {f.castka}
                          <div>
                            <small style={{ padding: "0px" }}>{f.mena}</small>
                          </div>
                        </React.Fragment>
                      ))}
                    </td>
                    <td />
                  </tr>
                </tfoot>
              </table>
            </BrowserView>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="alert alert-danger alert-outline">
              Pro tento filtr zde nejsou žádné faktury
            </div>
          </React.Fragment>
        );
      }
    } else {
      this.nahrajRok(rok);
      return (
        <React.Fragment>
          <LoadMusic />
        </React.Fragment>
      );
    }
  };

  nahrajRok = async rok => {
    if (this.state.aktualniFirma) {
      const fakturyRokData = await httpService.post(
        config.apiEndpoint + "fakturace/faktury.php",
        { firma_id: this.state.aktualniFirma.firma_id, rok }
      );
      let fakturyRokLoaded = [...this.state.fakturyRokLoaded];
      fakturyRokLoaded[rok] = true;
      let fakturyRok = [...this.state.fakturyRok];
      fakturyRok[rok] = fakturyRokData.data;
      this.setState({ fakturyRokLoaded, fakturyRok });
    }
  };

  zvolRok = rok => {
    this.setState({ selectedListItem: rok });
  };

  render() {
    return (
      <React.Fragment>
        <NadpisStranky nadpis="Fakturace" obrazek="./images/fakturace.jpg" />
        <div className="container">
          <div className="row padding-top-20">
            <div className="col-md-2 padding-bottom-20">
              <div className="widget widget_product_categories">
                <ul>
                  {this.state.listItems.map(itm => (
                    <li
                      key={itm.id}
                      className={
                        itm.id === this.state.selectedListItem ? "active" : null
                      }
                    >
                      <a
                        href="javascript:;"
                        onClick={() => this.zvolRok(itm.id)}
                      >
                        {itm.nazev}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-10">{this.zobrazRok()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Fakturace;
