import jwtDecode from "jwt-decode";
import http from "./httpService";
import { toast } from "react-toastify";
import config from "../config.json";
import {loadVeracoin} from "./veracoinService";

const apiEndpoint = config.apiEndpoint + "auth.php";
const tokenKey = "token";
const firma_id = "firma_id";

http.setJwt(getJwt());

export async function login(email, password, showerror = false) {
  try {
    localStorage.removeItem(firma_id);
    const { data: jwt, headers } = await http.post(apiEndpoint, {
      email,
      password,
    });
    if (headers["x-error"]) {
      localStorage.removeItem(tokenKey);
      throw headers["x-error"];
    }
    localStorage.setItem(tokenKey, jwt);

    const user = jwtDecode(jwt);
    if (user.firmy[0].firma_id) {
      localStorage.setItem(firma_id, user.firmy[0].firma_id);
    }


    // Při každém příhlášení načíst Veracoiny
    http.setJwt(getJwt());
    await loadVeracoin();


    if (showerror) {
      window.location.reload();
    }
  } catch (error) {
    if (showerror) {
      toast.error(error);
    } else {
      console.log(error);
    }
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function aktualniFirma() {
  const user = getCurrentUser();
  const firma_id = localStorage.getItem("firma_id");
  let firma = {};
  if (firma_id && user && user.firmy) {
    firma = user.firmy.filter((f) => f.firma_id === firma_id)[0];
  }
  return firma;
}

export function getLang() {
  const user = getCurrentUser();
  const firma_id = localStorage.getItem("firma_id");
  let firma = {};
  if (firma_id && user && user.firmy) {
    firma = user.firmy.filter((f) => f.firma_id === firma_id)[0];
  }
  //return firma.agenda_id;
  const agenda_id = firma.agenda_id ? firma.agenda_id : "cz";
  const lang = config.lang[agenda_id] || config.lang["cz"];
  return lang;
}

export function getAgendaIdByUrl() {
  let agenda_id = "cz";
  const url = window.location.toString();

  if (url.includes(".sk/") || url.includes(":3001")) {
    agenda_id = "sk";
  }

  return agenda_id;
}

export function getLangByUrl() {
  let agenda_id = getAgendaIdByUrl();

  const lang = config.lang[agenda_id] || config.lang["cz"];
  return lang;
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  aktualniFirma,
  getLang,
  getLangByUrl,
  getAgendaIdByUrl
};
