import React, { Component } from "react";
import NadpisStranky from "./../common/nadpis";
import config from "../../config.json";
import http from "../../services/httpService";
import authService from "./../../services/authService";
import SkupinaObchodnichPripadu from "./SkupinaObchodnichPripadu";
import LoadMusic from "./../common/LoadMusic";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Modal from "../Modal";

class Obchod extends Component {
  state = {
    obchodLoaded: false,
    obchodniPripady: [],
    aktualniFirma: {},
    hledat: ""
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    this.setState({ aktualniFirma });
    const obchodniPripady = await http.post(
        config.apiEndpoint + "obchodniPripady/obchodniPripady.php",
        {
          firma_id: aktualniFirma.firma_id,
          klient_id: ("klient_id" in this.props.match.params) ? this.props.match.params.klient_id : null
        }
    );
    this.setState({
      obchodLoaded: true,
      obchodniPripady: obchodniPripady.data,
    });
  }

  componentDidUpdate(prevProps) {
    if (("klient_id" in this.props.match.params) && !("klient_id" in prevProps.match.params)
      || !("klient_id" in this.props.match.params) && ("klient_id" in prevProps.match.params)
      || prevProps.match.params.klient_id !== this.props.match.params.klient_id) {

      this.setState({
        obchodLoaded: false,
        obchodniPripady: [],
      });

      this.componentDidMount();
    }
  }

  hledatObchodniPripady = async (hledat) => {
    const aktualniFirma = authService.aktualniFirma();
    
    this.setState({
      obchodLoaded: false,
      obchodniPripady: [],
      hledat: hledat,
    });

    const obchodniPripady = await http.post(
        config.apiEndpoint + "obchodniPripady/obchodniPripady.php",
        {
          hledat: hledat,
          firma_id: aktualniFirma.firma_id,
          klient_id: ("klient_id" in this.props.match.params) ? this.props.match.params.klient_id : null
        }
    );
    this.setState({
      obchodLoaded: true,
      obchodniPripady: obchodniPripady.data,
    });
  }

  novaPR = async () => {
    const aktualniFirma = authService.aktualniFirma();
    const { nazev } = this.state;
    const prilezitost_id = await http.post(
      config.apiEndpoint + "obchodniPripady/novaPR.php",
      { firma_id: aktualniFirma.firma_id, nazev }
    );
    this.setState({ nazev: "", novaPR: false });
    window.location.href = "#/obchod/" + prilezitost_id.data.insert_id;
  };

  render() {
    const lang = authService.getLang();
    const { novaPR, nazev, hledat } = this.state;
    if (!this.state.obchodLoaded) {
      return (
        <React.Fragment>
          <NadpisStranky
            nadpis={lang.aktualni_obchodni_pripady}
            obrazek="./images/obchod.jpg"
          />
          <div className="container center padding-top-30">
            <LoadMusic />
          </div>
        </React.Fragment>
      );
    }
    let nadpis = lang.aktualni_obchodni_pripady;
    if (("klient_id" in this.props.match.params) && this.props.match.params.klient_id > 0) {
      if (this.state.obchodniPripady && this.state.obchodniPripady.length > 0
          && this.state.obchodniPripady[0]
          && this.state.obchodniPripady[0].obchodniPripady
          && this.state.obchodniPripady[0].obchodniPripady.length > 0) {
        nadpis += ": " + this.state.obchodniPripady[0].obchodniPripady[0].zakaznik_firma_nazev
      }
    }
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={nadpis}
          obrazek="./images/obchod.jpg"
        />
        <div className="container padding-top-20">
          <Modal
            title={lang.novy_obchodni_pripad}
            show={novaPR}
            onClose={() => this.setState({ novaPR: false })}
            disableSubmit={!(nazev && nazev !== "")}
            onSubmit={this.novaPR}
          >
            <small>Název obchodního případu</small>
            <input
              type="text"
              className="form-control"
              placeholder={lang.zadejte_nazev}
              value={nazev}
              onChange={(e) => this.setState({ nazev: e.target.value })}
            />
          </Modal>
          {this.state.obchodniPripady && (
            <p className="text-center">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ novaPR: true })}
              >
                {lang.novy_obchodni_pripad}
              </button>
            </p>
          )}
          <Tabs className="awe-nav-responsive">
            <TabList className="awe-nav">
              {this.state.obchodniPripady
                ? this.state.obchodniPripady.map((e) => (
                    <Tab key={e.popis}>{e.popis}</Tab>
                  ))
                : null}
            </TabList>


            <input
                type="text"
                placeholder="Hledat obchodní případy dle podle zákazníka, popisu, obchodního kontaktu..."
                className="form-control margin-bottom-20 margin-top-20"
                defaultValue={hledat}
                onBlur={(input) => this.hledatObchodniPripady(input.target.value)}
                onKeyUp={(input) => (input.key === 'Enter' || input.keyCode === 13) && this.hledatObchodniPripady(input.target.value)}
            />

            {this.state.obchodniPripady ? (
              this.state.obchodniPripady.map((e) => (
                <TabPanel key={e.popis}>
                  <SkupinaObchodnichPripadu
                    key={e.popis}
                    popis={e.popis}
                    obchodniPripady={e.obchodniPripady}
                  />
                </TabPanel>
              ))
            ) : (
              <div className="alert alert-danger">
                {lang.nemate_pristup_k_obchodmin_pripadum}
              </div>
            )}
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default Obchod;
