import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import authService from "./../../services/authService";
import {getVeracoin, loadVeracoin} from "../../services/veracoinService";
import BonusProduktyItemModal from "./BonusProduktyItemModal";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "./../common/LoadMusic";

class BonusProdukt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            bonusProduktLoaded: false,
            produkt: null,
            vyrobce: null,
            nexturl: false,
            prevurl: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen() {
        this.setState({showModal: true});
    };

    handleClose() {
        this.setState({showModal: false});
    };

    componentDidMount() {
        this.setState({bp_produkt_id: this.props.match.params.bp_produkt_id});
        this.nahratProdukt(this.props.match.params.bp_produkt_id);
        loadVeracoin();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.bp_produkt_id !== this.props.match.params.bp_produkt_id) {
            this.setState({bp_produkt_id: this.props.match.params.bp_produkt_id});
            this.nahratProdukt(this.props.match.params.bp_produkt_id);
        }
    }

    nahratProdukt = async (bp_produkt_id) => {
        const aktualniFirma = authService.aktualniFirma();

        this.setState({bonusProduktLoaded: true});

        if (aktualniFirma.partnerweb_obchod) {
            this.setState({bonusProduktLoaded: false});
            const bonusData = await httpService.post(
                config.apiEndpoint + "bonus/produkt.php",
                {firma_id: aktualniFirma.firma_id, bp_produkt_id}
            );

            this.setState({
                aktualniFirma,
                skupiny: bonusData.data.skupiny,
                produkt: bonusData.data.produkt,
                vyrobce: bonusData.data.vyrobce,
                staty: bonusData.data.staty,
                statyano: bonusData.data.statyano,
                bonusProduktLoaded: true,
            });
        }
    };

    render() {
        const lang = authService.getLang();
        const {produkt, prevurl, nexturl, vyrobce, staty, statyano, showModal} = this.state;
        const dostupnykredit = getVeracoin();

        if (!produkt) {
            return (<LoadMusic/>);
        }

        return (
            <React.Fragment>
                <div className="container padding-top-50">
                    {showModal === true && (
                        <BonusProduktyItemModal produkt={produkt} open={this.state.showModal} close={this.handleClose}/>
                    )}

                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-slider-wrapper thumbs-bottom">

                                <div className="swiper-container product-slider-main">
                                    <div className="swiper-wrapper">
                                        {(produkt.obrazek_url && JSON.parse(produkt.obrazek_url) && JSON.parse(produkt.obrazek_url).length > 0) && JSON.parse(produkt.obrazek_url).map((obrazek, obrazek_key) => (
                                            <div className="swiper-slide" key={obrazek_key}>
                                                <div className="easyzoom easyzoom--overlay">
                                                    <a href={obrazek.replace("bonus.veracomp.cz/uploads/images/dodavatele","partner.veracomp.cz/images/bonus-dodavatele")} title="">
                                                        <img src={obrazek.replace("bonus.veracomp.cz/uploads/images/dodavatele","partner.veracomp.cz/images/bonus-dodavatele")} alt=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="swiper-button-prev"><i className="fa fa-chevron-left"> </i></div>
                                    <div className="swiper-button-next"><i className="fa fa-chevron-right"> </i></div>
                                </div>

                                <div className="swiper-container product-slider-thumbs">
                                    <div className="swiper-wrapper">

                                        {(produkt.obrazek_url && JSON.parse(produkt.obrazek_url) && JSON.parse(produkt.obrazek_url).length > 0) && JSON.parse(produkt.obrazek_url).map((obrazek, obrazek_key) => (
                                            <div className="swiper-slide" key={obrazek_key}>
                                                <img src={obrazek.replace("bonus.veracomp.cz/uploads/images/dodavatele","partner.veracomp.cz/images/bonus-dodavatele")} alt=""/>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*$query="select bp_produkt_id,nazev from bp_produkt where aktivni='1' and bp_produkt_id<$produkt->bp_produkt_id order by bp_produkt_id desc";
                    $q=MySQL_Query($query) or die(MySQL_Error());
                    $prev=(object)MySQL_Fetch_Assoc($q);
                    if ($prev->bp_produkt_id){
                        $prevurl="$this->produkty_url/produkt/$prev->bp_produkt_id/".strtolower(munge_string_to_url($prev->nazev));
                    }

                    $query="select bp_produkt_id,nazev from bp_produkt where aktivni='1' and bp_produkt_id>$produkt->bp_produkt_id order by bp_produkt_id";
                    $q=MySQL_Query($query) or die(MySQL_Error());
                    $nxt=(object)MySQL_Fetch_Assoc($q);
                    if ($nxt->bp_produkt_id){
                        $nexturl="$this->produkty_url/produkt/$nxt->bp_produkt_id/".strtolower(munge_string_to_url($nxt->nazev));
                    }*/}

                        <div className="col-md-6">
                            <nav className="pnav">
                                <div className="pull-right">
                                    {prevurl && (
                                        <a href={prevurl} className="btn btn-sm btn-arrow btn-default">
                                            <i className="fa fa-chevron-left"> </i>
                                        </a>
                                    )}
                                    {nexturl && (
                                        <a href={nexturl} className="btn btn-sm btn-arrow btn-default">
                                            <i className="fa fa-chevron-right"> </i>
                                        </a>
                                    )}
                                </div>

                                <Link to={"/bonus/" + produkt.bp_skupina_id} className="back-to-pcate">
                                    <i className="fa fa-chevron-left"> </i>
                                    <span>Zpět na přehled</span>
                                </Link>
                            </nav>

                            <div className="product-details-wrapper">
                                <h2 className="product-name">
                                    <a href="$url" title={produkt.nazev}>{produkt.nazev}</a>
                                </h2>

                                <div className="product-status">
                                    <span>Dostupné</span>
                                    <span> - </span>
                                    <small>SKU: BP{("000000" + produkt.bp_produkt_id).substr(-2)}</small>
                                </div>

                                <div className="product-description">
                                    <p>{produkt.uvod}</p>
                                </div>

                                {produkt.klicove_vlastnosti && (
                                    <div className="product-features">
                                        <h3>Klíčové vlastnosti:</h3>{produkt.klicove_vlastnosti}
                                    </div>
                                )}

                                <div className="product-actions-wrapper">

                                    <div className="product-list-actions">
                                    <span className="product-price">
                                        <span className="amount">v&cent; {produkt.cena_vc}</span>
                                    </span>

                                        <a href="javascript:;"
                                           onClick={this.handleOpen}
                                           className="btn btn-lg btn-primary"
                                           data-toggle="tooltip"
                                           title="Vložit do košíku">
                                            <i className="icon icon-shopping-bag"> </i>
                                        </a>
                                    </div>
                                </div>

                                <div className="product-meta">
                                    <div className="product-category">
                                        <span>Kategorie: </span>
                                        <Link to={"/bonus/" + produkt.bp_skupina_id}>
                                        {produkt.skupina}
                                        </Link>
                                    </div>


                                    <div>-</div>

                                    <div className="product-tags">
                                        <span>Brand: </span>
                                           {produkt.dodavatel}
                                    </div>
                                    {staty.length > statyano.length && (
                                        <React.Fragment>
                                            <div>-</div>
                                            <div className="product-category">
                                                <span>Omezení: </span>
                                                <strong>nárok na zboží lze uplatnit pouze na území
                                                    {statyano.map((value, key) => (
                                                        <span key={key}>key</span>
                                                    ))}
                                                </strong>
                                            </div>
                                        </React.Fragment>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="product-details-left">
                        <div role="tabpanel" className="product-details">
                            <nav>
                                <ul className="nav" role="tablist">
                                    <li role="presentation" className="active">
                                        <a href="#product-description" data-toggle="tab">Popis</a>
                                    </li>
                                    <li role="presentation">
                                        <a href="#product-infomation" data-toggle="tab">Výrobce/dodavatel</a>
                                    </li>
                                </ul>
                            </nav>

                            <div className="tab-content">
                                <div role="tabpanel" className="tab-pane active" id="product-description">
                                    <div dangerouslySetInnerHTML={{__html: produkt.popis}}/>
                                </div>

                                <div role="tabpanel" className="tab-pane" id="product-infomation">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <h3>{vyrobce.nazev}</h3>
                                            <div dangerouslySetInnerHTML={{__html: vyrobce.popis}}/>
                                        </div>
                                        <div className="col-md-4">
                                            {/*
        ".((file_exists("img/dodavatel/bp_dodavatel_".$vyrobce->bp_dodavatel_id.".png"))?"<div className="center">
        <img src="img/dodavatel/bp_dodavatel_".$vyrobce->bp_dodavatel_id.".png" alt="$vyrobce->nazev" title="$vyrobce->nazev" />
        </div>":"")."*/}
                                            <h4 className="upper center">Kde lze voucher uplatnit</h4>
                                            <ul>
                                                {staty.map((stat, key) => (
                                                    <li key={key}>
                                                        <span>
                                                            <img src={"http://static.veracomp.pl/images/lang/"+stat.toLowerCase()+".png"} alt={stat} title={stat}
                                                                 style={{boxShadow: "0px 0px 3px rgba(0,0,0,0.3)"}}/>
                                                        </span>
                                                        <span className="value">
                                                            {stat in statyano ? "Ano" : "Ne"}
                                                        </span>
                                                    </li>
                                                ))}
                                             </ul>
                                            <p>
                                                Voucher lze zaslat kamkoliv, nicméně dodavatel zboží dodává pouze na
                                                území
                                                specifikovaných zemí.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* <div className="relared-products">
        <div className="relared-products-header margin-bottom-50">
        <h3 className="upper">Související produkty</h3>
        </div>

        <div className="products owl-carousel" data-items="4">\n";
        $vypis=MySQL_Query("select bp_produkt_id from bp_produkt where aktivni='1' and bp_dodavatel_id='$produkt->bp_dodavatel_id' and bp_produkt_id!='$produkt->bp_produkt_id'");
        while ($zaznam=MySQL_Fetch_Assoc($vypis)){
            $return.=BonusProgram::produkt_nahled($zaznam[bp_produkt_id]);
        }

        $return.="        </div>
            </div>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default BonusProdukt;
