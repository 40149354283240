import React, {Component} from "react";
import NadpisStranky from "../common/nadpis";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import {loadVeracoinHistory} from "../../services/veracoinService";
import DF from "../common/DF";

class BonusMeVeracoiny extends Component {
    state = {
        veracoinHistory: [],
        bonusLoaded: false,
    };

    componentDidMount() {
        this.nahratBonusy();
    }

    nahratBonusy = async () => {
        const aktualniFirma = authService.aktualniFirma();

        this.setState({bonusLoaded: false});

        const veracoinHistory = await loadVeracoinHistory();
        const vyhled = await httpService.post(
            config.apiEndpoint + "bonus/vyhled.php",
            {firma_id: aktualniFirma.firma_id}
        );

        this.setState({
            veracoinHistory: veracoinHistory,
            vyhled: vyhled.data.data,
            bonusLoaded: true,
        });
    };

    render() {
        const lang = authService.getLang();
        const {bonusLoaded, veracoinHistory, vyhled} = this.state;
        let proc = 0;


        if (bonusLoaded && veracoinHistory) {
            proc = Math.round((veracoinHistory.konec_do_mesice / (veracoinHistory.celkem_vc - veracoinHistory.utracene_vc)) * 100);
        }

        function drawChart() {
            const head = document.head;
            let script = document.getElementById('googleChartsScript');
            if (!script) {
                script = document.createElement('script');
                script.src = 'https://www.gstatic.com/charts/loader.js';
                script.id = 'googleChartsScript';
                script.onload = () => {
                    if (window.google && window.google.charts) {
                        window.google.charts.load('current', {'packages': ['corechart']});

                        window.google.charts.setOnLoadCallback(() => setGoogle(window.google))
                    }
                };
                head.appendChild(script);
            } else if (window.google && window.google.charts && window.google.visualization) {
                setGoogle(window.google);
            }
        }

        function setGoogle(google) {
            google.charts.load('current', {'packages': ['corechart']});
            //google.charts.setOnLoadCallback(drawChart);

            var chart = null;
            var data = null;
            var options = {
                title: 'Vývoj množství dostupných veracoinů',
                curveType: 'function',
                legend: {position: 'bottom'}
            };

            let tmp = [
                ['Měsíc', 'Platné', 'Zůstatek', 'Končící', 'Nové']
            ];

            for (let i in vyhled) {
                let zaznam = vyhled[i];
                tmp.push([
                    zaznam.obdobi,
                    parseInt(zaznam.vc_hodnota),
                    parseInt(zaznam.vc_hodnota - zaznam.vc_utracene),
                    parseInt(zaznam.vc_hodnota_koncici),
                    parseInt(zaznam.vc_hodnota_vydane)
                ]);
            }

            data = google.visualization.arrayToDataTable(tmp);

            chart = new google.visualization.LineChart(document.getElementById('curve_chart'));
            chart.draw(data, options);
        }

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.bonus_program}
                    obrazek="./images/deal.jpg"
                />
                <div className="container padding-top-50">

                    {bonusLoaded && veracoinHistory ? (
                        (veracoinHistory.celkem_vc - veracoinHistory.utracene_vc > 0 ? (
                            <React.Fragment>
                                <div role="tabpanel">
                                    <div className="awe-nav-responsive">
                                        <ul className="awe-nav" role="tablist">
                                            <li role="presentation" className="active">
                                                <a href="#rychly-prehled" title=""
                                                   aria-controls="rychly-prehled" role="tab"
                                                   data-toggle="tab">Rychlý
                                                    přehled</a>
                                            </li>

                                            <li role="presentation" className="">
                                                <a href="#vyhled"
                                                   onClick={() => {
                                                       setTimeout(function () {
                                                           drawChart();
                                                       }, 500);
                                                   }}
                                                   title=""
                                                   aria-controls="docs-tabs-2" role="tab" data-toggle="tab">Výhled</a>
                                            </li>

                                            <li role="presentation" className="">
                                                <a href="#platne-veracoiny" title=""
                                                   aria-controls="platne-veracoiny" role="tab"
                                                   data-toggle="tab">Výpis
                                                    účtu</a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>


                                <div className="tab-content padding-top-25">
                                    <div role="tabpanel" className="tab-pane in show active" id="rychly-prehled">
                                        <div className="text-muted">
                                            <div className="row">
                                                <div className="col-md-10 col-md-offset-1">

                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-info"
                                                             style={{"width": "100%"}}>
                                                            <span><strong>Platné veraciony:</strong>
                                                                {veracoinHistory.celkem_vc - veracoinHistory.utracene_vc}
                                                            </span>
                                                            <span className="percent">100%</span>
                                                        </div>
                                                    </div>

                                                    <div className="progress">
                                                        <div className="progress-bar progress-bar-warning"
                                                             style={{"width": proc + "%"}}>
                                                            <span><strong>Veracoiny končící v nejbližších 30 dnech:</strong>
                                                                {veracoinHistory.konec_do_mesice}</span>
                                                            <span className="percent">{proc}%</span>
                                                        </div>
                                                    </div>

                                                    <div className="center">
                                                        <a href="#platne-veracoiny"
                                                           className="btn  btn-primary btn-lg" data-toggle="tab">Přehled
                                                            expirace platných veracoinů</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div role="tabpanel" className="tab-pane" id="vyhled">
                                        <div className="text-muted">
                                            <div className="row">
                                                <div className="col-md-10 col-md-offset-1">
                                                    {/*".BonusProgram::vyhled($osoba_id)."*/}
                                                    <div id="curve_chart"
                                                         style={{"width": "100%", height: "500px"}}></div>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th rowSpan="2">Období</th>
                                                            <th colSpan="4">Počet veracoinů</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Platné celkem</th>
                                                            <th>Z toho utracené</th>
                                                            <th>Končící</th>
                                                            <th>Nové</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {vyhled.map((zaznam) => (
                                                            <tr>
                                                                <td>{zaznam.obdobi}</td>
                                                                <td className="text-right">{zaznam.vc_hodnota}</td>
                                                                <td className="text-right">{zaznam.vc_utracene}</td>
                                                                <td className="text-right">{zaznam.vc_hodnota_koncici}</td>
                                                                <td className="text-right">{zaznam.vc_hodnota_vydane}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div role="tabpanel" className="tab-pane" id="platne-veracoiny">
                                        <div className="row">
                                            <div className="col-md-10 col-md-offset-1">
                                                <div className="text-muted">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th rowSpan="2">Obchodní případ</th>
                                                            <th rowSpan="2">Zákazník</th>
                                                            <th colSpan="3">Veracoiny</th>
                                                            <th rowSpan="2">Platné od</th>
                                                            <th rowSpan="2">Platné do</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Připsané</th>
                                                            <th>Použité</th>
                                                            <th>Zbývá</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="2" className="text-right">Celkem</th>
                                                            <th className="text-right">{veracoinHistory.celkem_vc}</th>
                                                            <th className="text-right">{veracoinHistory.utracene_vc}</th>
                                                            <th className="text-right">{veracoinHistory.celkem_vc - veracoinHistory.utracene_vc}</th>
                                                            <th colSpan="2"></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {veracoinHistory.seznam.map(polozka => (
                                                            <tr>
                                                                <td>{polozka.popis}</td>
                                                                <td>{polozka.zakaznik_firma_nazev}</td>
                                                                <td className="text-right">{polozka.vc_hodnota}</td>
                                                                <td className="text-right">{polozka.vc_utracene}</td>
                                                                <td className="text-right">{polozka.vc_hodnota - polozka.vc_utracene}</td>
                                                                <td style={{
                                                                    "text-align": "center",
                                                                    "white-space": "nowrap"
                                                                }}>
                                                                    <DF date={polozka.vc_datum_od} showTime={false}/>
                                                                </td>
                                                                <td style={{
                                                                    "text-align": "center",
                                                                    "white-space": "nowrap"
                                                                }}>
                                                                    <DF date={polozka.vc_datum_do} showTime={false}/>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="alert alert-danger alert-outline">
                                <p>V současnosti nemáte žádné platné veracoiny...</p>
                            </div>
                        ))
                    ) : (
                        <LoadMusic/>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default BonusMeVeracoiny;
