import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import authService from "./../../services/authService";
import {getVeracoin} from "../../services/veracoinService";
import BonusProduktyItemModal from "./BonusProduktyItemModal";

class BonusProduktyItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen() {
        this.setState({showModal: true});
    }

    handleClose() {
        this.setState({showModal: false});
    }

    render() {
        const lang = authService.getLang();
        const {produkt} = this.props;
        const dostupnykredit = getVeracoin();
        const {showModal} = this.state;

        return (
            <React.Fragment>
                {showModal === true && (
                    <BonusProduktyItemModal
                        produkt={produkt}
                        open={this.state.showModal}
                        close={this.handleClose}
                    />
                )}


                <div className="product product-grid">
                    <div className="product-media">
                        <div className="product-thumbnail">
                            <NavLink
                                to={"/bonus/produkt/" + produkt.bp_produkt_id}>
                                {(produkt.obrazek_url && JSON.parse(produkt.obrazek_url) && JSON.parse(produkt.obrazek_url).length > 0) ? JSON.parse(produkt.obrazek_url).map((obrazek, obrazek_key) => (
                                    <img src={obrazek.replace("bonus.veracomp.cz/uploads/images/dodavatele","partner.veracomp.cz/images/bonus-dodavatele")} alt="" key={obrazek_key}
                                         className={obrazek_key === 0 ? "current" : ""}/>
                                )) : (
                                    <img
                                        src="./img/samples/products/grid/1.jpg"
                                        alt="" className="current"/>
                                )}
                            </NavLink>
                        </div>

                        {!produkt.is_va && (
                            <div className="product-hover">
                                <div className="product-actions">
                                    <a href="javascript:;"
                                       onClick={this.handleOpen}
                                       className="awe-button product-add-cart"
                                       data-toggle="tooltip"
                                       title="Vložit do košíku">
                                        <i className="icon icon-shopping-bag"> </i>
                                    </a>

                                    {/*<Link to={"/bonus/produkt/"+produkt.bp_produkt_id}>
                                       className="awe-button product-quick-view"
                                       data-toggle="tooltip" title="Náhled">
                                        <i className="icon icon-eye"> </i>
                                    </Link>*/}
                                </div>
                            </div>
                        )}

                        {produkt.label && produkt.label !== "0" && (
                            <span className={"product-label " + produkt.label}>
                            <span>{produkt.label}</span>
                          </span>
                        )}
                    </div>

                    <div className="product-body">
                        <h2 className="product-name">
                            <NavLink
                                to={"/bonus/produkt/" + produkt.bp_produkt_id}
                                title={produkt.nazev}
                            >
                                {produkt.nazev}
                            </NavLink>
                        </h2>

                        <div className="product-category">
                            <span>{produkt.dodavatel}</span>
                        </div>

                        <div className="product-price">
                              <span className="amount">
                                {produkt.is_va && <React.Fragment>až</React.Fragment>}
                                  v&cent;
                                  {produkt.cena_vc}
                                  {/*".number_format($produkt->cena_vc,0,",",".")*/}
                              </span>

                            {/*
                            {dostupnykredit >= produkt.cena_vc ? (
                                <button
                                    className="btn btn-lg btn-primary">Vložit do
                                    košíku</button>
                            ) : (
                                <div
                                    className="alert alert-danger alert-outline">Nelze
                                    vložit do košíku – příliš málo
                                    Veracoinů.</div>
                            )}
                            */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BonusProduktyItem;
