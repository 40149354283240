import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import LoadMusic from "./../common/LoadMusic";
import httpService from "../../services/httpService";
import { Link } from "react-router-dom";
import config from "../../config.json";
import authService from "../../services/authService";

class ProduktyPrehled extends Component {
  state = { prehledLoaded: false, prehled: [] };
  async componentDidMount() {
    const ak = authService.aktualniFirma();
    const prehled = await httpService.post(
      config.apiEndpoint + "produkty/prehled.php",
      { agenda_id: ak.agenda_id }
    );
    this.setState({ prehled: prehled.data, prehledLoaded: true });
  }
  render() {
    const { prehledLoaded, prehled } = this.state;
    var getSlug = require("speakingurl");
    return (
      <React.Fragment>
        <NadpisStranky nadpis="Produkty" obrazek="./images/produkty.jpg" />
        <div className="container padding-top-20">
          {prehledLoaded ? (
            <React.Fragment>
              <div className="row">
                {prehled.map((p) => (
                  <div
                    className="col-md-3 col-6 padding-bottom-20"
                    key={p.partner_id}
                  >
                    <Link
                      to={
                        "/produkty/" +
                        p.partner_id +
                        "/" +
                        getSlug(p.nazev, { lang: "cs" })
                      }
                    >
                      <img
                        src={
                          "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                          p.partner_id
                        }
                        alt={p.nazev}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ProduktyPrehled;
