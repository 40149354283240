import React, { Component } from "react";
import FloatingLabelInput from "floating-label-react";
import auth from "../../services/authService";

class Osoba extends Component {
  state = {
    edituji: false,
    osoba: this.props.osoba,
    novaOsoba: []
  };

  handleChange = ({ currentTarget: input }) => {
    let val = input.value;
    if (input.type === "checkbox") {
      val = input.checked ? "1" : "0";
    }
    if (this.props.osoba) {
      const osoba = {
        ...this.state.osoba,
        [input.name]: val
      };
      this.setState({ osoba, edituji: false });
      this.props.onChange(this.props.osoba.osoba_id, input.name, val);
    } else {
      let novaOsoba = this.state.novaOsoba;
      novaOsoba[input.name] = val;
      this.setState({ novaOsoba });
    }
    return true;
  };

  render() {
    const aktualniFirma = auth.aktualniFirma();
    const lang = auth.getLang();
    if (!this.state.edituji && !this.props.osoba) {
      return (
        <div className="col-md-3 center padding-top-50">
          <button
            className="btn btn-success"
            onClick={() => this.setState({ edituji: true })}
          >
            {lang.pridat_osobu}
          </button>
        </div>
      );
    }
    return (
      <div className="col-md-3 padding-bottom-20">
        <div
          style={{
            border: "solid 1px #ddd",
            borderRadius: "5px",
            padding: "10px"
          }}
        >
          {this.state.edituji || !this.props.osoba ? (
            <React.Fragment>
              <FloatingLabelInput
                id="titul_pred"
                placeholder={lang.titul_pred_jmenem}
                name="titul_pred"
                type="text"
                onBlur={this.handleChange}
                value={this.props.osoba ? this.props.osoba.titul_pred : ""}
                ref={this.props.osoba ? this.props.osoba.titul_pred : ""}
              />
              <FloatingLabelInput
                id="jmeno"
                placeholder={lang.jmeno}
                name="jmeno"
                type="text"
                onBlur={this.handleChange}
                value={this.props.osoba ? this.props.osoba.jmeno : ""}
                ref={this.props.osoba ? this.props.osoba.jmeno : ""}
              />
              <FloatingLabelInput
                id="prijmeni"
                placeholder={lang.prijmeni}
                name="prijmeni"
                type="text"
                onBlur={this.handleChange}
                value={this.props.osoba ? this.props.osoba.prijmeni : ""}
                ref={this.props.osoba ? this.props.osoba.prijmeni : ""}
              />
              <FloatingLabelInput
                id="titul_za"
                placeholder={lang.titul_za_jmenem}
                name="titul_za"
                type="text"
                onBlur={this.handleChange}
                value={this.props.osoba ? this.props.osoba.titul_za : ""}
                ref={this.props.osoba ? this.props.osoba.titul_za : ""}
              />
            </React.Fragment>
          ) : (
            <h5
              onClick={() => this.setState({ edituji: true })}
              style={{ cursor: "pointer" }}
            >
              {this.props.osoba.cele_jmeno}
            </h5>
          )}
          <FloatingLabelInput
            id="pozice"
            placeholder={lang.pozice_ve_firme}
            name="pozice"
            type="text"
            onBlur={this.handleChange}
            value={this.props.osoba ? this.props.osoba.pozice : ""}
            ref={this.props.osoba ? this.props.osoba.pozice : ""}
          />
          <FloatingLabelInput
            id="mobil"
            placeholder={lang.mobil}
            name="mobil"
            type="text"
            onBlur={this.handleChange}
            value={this.props.osoba ? this.props.osoba.mobil : ""}
            ref={this.props.osoba ? this.props.osoba.mobil : ""}
          />
          <FloatingLabelInput
            id="email1"
            placeholder={lang.email}
            name="email1"
            type="email"
            onBlur={this.handleChange}
            value={this.props.osoba ? this.props.osoba.email1 : ""}
            ref={this.props.osoba ? this.props.osoba.email1 : ""}
          />
          <div className="form-check">
            <input
              id={
                "partnerweb_obchod_" +
                (this.props.osoba ? this.props.osoba.osoba_id : "nova")
              }
              className="form-check-input"
              name="partnerweb_obchod"
              type="checkbox"
              value={1}
              checked={
                (this.state.osoba &&
                  this.state.osoba.partnerweb_obchod === "1") ||
                this.state.novaOsoba["partnerweb_obchod"] === "1"
                  ? true
                  : false
              }
              disabled={aktualniFirma.partnerweb_obchod ? false : true}
              onChange={this.handleChange}
            />
            <label
              className="form-check-label"
              htmlFor={
                "partnerweb_obchod_" +
                (this.props.osoba ? this.props.osoba.osoba_id : "nova")
              }
            >
              {lang.pristup_obchod}
            </label>
          </div>
          <div className="form-check">
            <input
              id={
                "partnerweb_fakturace_" +
                (this.props.osoba ? this.props.osoba.osoba_id : "nova")
              }
              className="form-check-input"
              name="partnerweb_fakturace"
              type="checkbox"
              value={1}
              checked={
                (this.state.osoba &&
                  this.state.osoba.partnerweb_fakturace === "1") ||
                this.state.novaOsoba["partnerweb_fakturace"] === "1"
                  ? true
                  : false
              }
              disabled={aktualniFirma.partnerweb_fakturace ? false : true}
              onChange={this.handleChange}
            />
            <label
              className="form-check-label"
              htmlFor={
                "partnerweb_fakturace_" +
                (this.props.osoba ? this.props.osoba.osoba_id : "nova")
              }
            >
              {lang.pristup_fakturace}
            </label>
          </div>
          <div className="center padding-top-10">
            {this.props.osoba ? (
              <button
                className="btn btn-primary"
                onClick={() => this.props.onDelete(this.props.osoba.osoba_id)}
              >
                {lang.smazat_osobu}
              </button>
            ) : (
              <React.Fragment>
                <button
                  className="btn btn-success"
                  onClick={async () => {const result = await this.props.onCreate(this.state.novaOsoba); if (result) {this.setState({ edituji: false });}}}              >
                  {lang.ulozit_osobu}
                </button>
                <button
                  className="btn btn-success btn-outline margin-top-5"
                  onClick={() => this.setState({ edituji: false })}
                >
                  Cancel
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Osoba;
