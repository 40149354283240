import React, { Component } from "react";
import authService from "../services/authService";

class Modal extends Component {
  state = {};
  render() {
    const lang = authService.getLang();
    const showHideClassName = this.props.show
      ? "modal display-block"
      : "modal display-none";
    return (
      <div className={showHideClassName}>
        <div
          className="modal-dialog"
          style={{ maxWidth: this.props.width ? this.props.width : null }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.props.onClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                style={{
                  maxHeight: Math.round(window.innerHeight - 230) + "px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {this.props.children}
              </div>
            </div>
            <div className="modal-footer">
              {this.props.extraButtonText && this.props.extraButtonOnClick ? (
                <button
                  className={this.props.extraButtonClass}
                  onClick={this.props.extraButtonOnClick}
                >
                  {this.props.extraButtonText}
                </button>
              ) : null}
              {!this.props.hideSubmit ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.props.onSubmit()}
                  disabled={this.props.disableSubmit}
                >
                  {this.props.submitText ? this.props.submitText : "Provést"}
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => this.props.onClose()}
              >
                {lang.zavrit}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
