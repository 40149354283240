import React, { Component } from "react";

class Modal extends Component {
  ///////////  data-toggle="modal" data-target=".filter-modal"
  state = {};

  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  render() {
    if (this.props.noModal) {
      {
        return this.props.children;
      }
    }
    return (
      <div
        className={"modal fade show " + this.props.id}
        key={this.props.id}
        id={"modal-" + this.props.id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden={false}
        style={this.props.zIndex ? { display: "none", zIndex: this.props.zIndex } : { display: "none" }}
      >
        <div className={"modal-dialog modal-" + (this.props.xl ? "xl" : "lg")}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {this.props.title} 
                {this.props.title2 && <React.Fragment> {this.props.title2}</React.Fragment>}
              </h4>
              <button type="button" data-target={"." + this.props.id} className="close" data-dismiss="modal" aria-hidden="false">
                ×
              </button>
            </div>
            <div
              className="modal-body scroll1"
              style={
                this.props.xl
                  ? {
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      overflow: this.props.allowOverflow ? "visible" : "scroll-x",
                    }
                  : this.props.allowOverflow
                  ? { overflow: "visible" }
                  : null
              }
            >
              {this.props.children && this.props.children[0] && this.props.children[0].type === "section"
                ? this.props.children[0]
                : this.props.children}
            </div>
            {this.props.children && this.props.children[1] && this.props.children[1].type === "footer" ? (
              <div className="modal-footer">
                {this.props.children[1]}
                <button
                  type="button"
                  className="btn btn-danger waves-effect text-left"
                  data-dismiss="modal"
                  onClick={this.onCloseClick}
                >
                  Zavřít
                </button>
              </div>
            ) : (
              <div className="modal-footer">
                {this.props.buttons &&
                  this.props.buttons.map((b, index) =>
                    b.link ? (
                      <a
                        href="javascript:;"
                        onClick={function () {
                          setTimeout(function () {
                            window.location = b.link;
                          }, 500);
                        }}
                        className="btn btn-secondary btn-outline"
                        key={index}
                        data-dismiss="modal"
                      >
                        {b.popis}
                      </a>
                    ) : (
                      <button>
                        {b.popis}
                      </button>
                    )
                  )}
                <button
                  type="button"
                  className="btn btn-danger waves-effect text-left"
                  data-dismiss="modal"
                  onClick={this.onCloseClick}
                >
                  Zavřít
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
