import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { Link } from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import { loadVeracoin } from "../../services/veracoinService";
import BonusProduktyItem from "./BonusProduktyItem";

class BonusProdukty extends Component {
  state = {
    skupiny: [],
    produkty: [],
    pocetProduktu: 0,
    bonusLoaded: false,
    bp_skupina_id: 0,
    dostupnykredit: 0,
  };

  componentDidMount() {
    this.setState({ bp_skupina_id: this.props.match.params.bp_skupina_id });
    this.nahratBonusy(this.props.match.params.bp_skupina_id);
    loadVeracoin();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.bp_skupina_id !==
      this.props.match.params.bp_skupina_id
    ) {
      this.setState({ bp_skupina_id: this.props.match.params.bp_skupina_id });
      this.nahratBonusy(this.props.match.params.bp_skupina_id);
    }
  }

  nahratBonusy = async (bp_skupina_id) => {
    const aktualniFirma = authService.aktualniFirma();

    this.setState({ bonusLoaded: true });

    if (aktualniFirma.partnerweb_obchod) {
      this.setState({ bonusLoaded: false });
      const bonusData = await httpService.post(
        config.apiEndpoint + "bonus/produkty.php",
        { firma_id: aktualniFirma.firma_id, bp_skupina_id }
      );

      this.setState({
        aktualniFirma,
        skupiny: bonusData.data.skupiny,
        produkty: bonusData.data.produkty,
        pocetProduktu: bonusData.data.pocet_produktu,
        bonusLoaded: true,
      });
    }
  };

  hledat = (bp_skupina_id) => {
    this.nahratBonusy(bp_skupina_id);
  };

  render() {
    const lang = authService.getLang();
    const { bonusLoaded, skupiny, produkty, dostupnykredit } = this.state;
    if (
      this.state.aktualniFirma &&
      !this.state.aktualniFirma.partnerweb_obchod
    ) {
      return (
        <div className="alert alert-danger">
          Nemáte přístup k bonus programu
        </div>
      );
    }

    let skupina = {};
    bonusLoaded &&
      skupiny &&
      skupiny.length > 0 &&
      skupiny[0].length > 0 &&
      skupiny[0].map((s) => {
        if (s.bp_skupina_id == this.state.bp_skupina_id) {
          skupina = s;
        }
      });
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={lang.bonus_program}
          obrazek="./images/deal.jpg"
        />
        <div className="container padding-top-50">
          {bonusLoaded ? (
            <React.Fragment>
              {skupiny && skupiny.length > 0 && skupiny[0].length > 0 ? (
                <div className="row">
                  <div className="col-md-3 col-md-pull-9">
                    <h3 className={"widget-title"}>Kategorie</h3>
                    <div className={"widget widget_categories"}>
                      <ul>
                        <li
                          className={!this.state.bp_skupina_id ? "active" : ""}
                        >
                          <Link to="/bonus" title="">
                            Všechny kategorie
                          </Link>
                        </li>

                        {skupiny[0].map((s, s_key) => (
                          <li
                            key={s_key}
                            className={
                              this.state.bp_skupina_id == s.bp_skupina_id
                                ? "active"
                                : ""
                            }
                          >
                            <Link to={"/bonus/" + s.bp_skupina_id}>
                              {s.nazev}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-9 col-md-push-3">
                    <div className="product-header-actions">
                      <h2 className="light upper center underline spacing-5">
                        {skupina.nazev}
                      </h2>
                      <div
                        dangerouslySetInnerHTML={{ __html: skupina.popis }}
                      />
                    </div>

                    <div className="products products-grid-wrapper">
                      <div className="row">
                        {produkty &&
                          produkty.length > 0 &&
                          produkty.map((produkt, produkt_key) => (
                            <div
                              className="col-md-4 col-sm-4 col-xs-12"
                              key={produkt_key}
                            >
                              <BonusProduktyItem produkt={produkt} />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger">
                  {lang.bonus_bez_kategorie}
                </div>
              )}
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default BonusProdukty;
