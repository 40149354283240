import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import LoadMusic from "./LoadMusic";

class HledatFirmu extends Component {
  state = {
    results: false,
    newResults: false,
    hide: false,
    firmaNazev: "",
    firmaNazevProps: "",
  };

  timer = false;

  componentDidMount = () => {
    if (this.props.value) {
      this.setState({
        firmaNazev: this.props.value,
        firmaNazevProps: this.props.value,
      });
    }
  };

  componentDidUpdate = () => {
    if (
      /*this.props.value &&*/ this.state.firmaNazevProps != this.props.value
    ) {
      console.log("zmen");
      this.setState({
        firmaNazev: this.props.value,
        firmaNazevProps: this.props.value,
      });
    }
  };

  hledat = async (srch, callApi) => {
    this.setState({ firmaNazev: srch });
    const { agenda_id } = this.props;
    if (callApi) {
      const results = await httpService.post(
        config.apiEndpoint + "firmy/hledat.php",
        { srch, agenda_id }
      );
      this.setState({
        results: results.data.results,
        newResults: results.data.new_results,
        hide: false,
      });
    } else {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => this.hledat(srch, true), 250);
    }
  };

  novaFirma = async (ico, zdroj) => {
    this.setState({ novaFirmaLoading: true });
    const novaFirma = await httpService.post(
      config.apiEndpoint + "firmy/novaFirma.php",
      { ico, zdroj }
    );
    if (this.props.onSelect) {
      this.props.onSelect(novaFirma.data.firma_id);
    }
    this.setState({ novaFirmaLoading: false, hide: true });
  };

  render() {
    const { results, newResults, hide, novaFirmaLoading } = this.state;
    const { inline } = this.props;
    return (
      <div className="form-material btn-group" style={{ width: "100%" }}>
        <input
          type="text"
          className="form-control"
          placeholder="Hledat firmu..."
          onChange={(e) => this.hledat(e.target.value)}
          value={this.state.firmaNazev ? this.state.firmaNazev : ""}
          onBlur={() => setTimeout(() => this.setState({ hide: true }), 500)}
          style={this.props.style}
        />
        {(results || newResults) && (!hide || novaFirmaLoading) && (
          <div
            className={!inline && "dropdown-menu"}
            style={{ display: "block" }}
          >
            {novaFirmaLoading && (
              <div className="dropdown-item">
                <LoadMusic size={32} /> Páruji data...
              </div>
            )}
            {!novaFirmaLoading &&
              results &&
              results.length > 0 &&
              results.map((r, index) => (
                <a
                  href="javascript:;"
                  data-dismiss={this.props.closeModal ? "modal" : null}
                  onClick={() =>
                    this.props.onSelect &&
                    (this.setState({ firmaNazev: r.nazev }) ||
                      this.props.onSelect(r.firma_id, r.nazev, r.mesto))
                  }
                  className="dropdown-item"
                  key={index + "_" + r.firma_id}
                >
                  {r.nazev}, {r.mesto}
                </a>
              ))}
            {!novaFirmaLoading &&
              newResults &&
              newResults.length > 0 &&
              newResults.map((r, index) => (
                <a
                  href="javascript:void(false);"
                  key={index + "_" + r.ico}
                  className="dropdown-item"
                  onClick={() =>
                    this.setState({ firmaNazev: r.nazev }) ||
                    this.novaFirma(r.ico, r.zdroj)
                  }
                >
                  {r.nazev}, {r.adresa}
                </a>
              ))}
            {!(
              (results && results.length > 0) ||
              (newResults && newResults.length > 0)
            ) && (
              <div style={{ padding: "10px" }}>
                Žádná firma nebyla nalezena. Zadejte IČ firmy, budeme hledat
                jinde.
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default HledatFirmu;
