import React, {Component} from "react";
import {Link} from "react-router-dom";
import authService from "./../../services/authService";
import {getVeracoin, loadVeracoin} from "../../services/veracoinService";
import Modal from "../Modal";
import httpService from "../../services/httpService";
import config from "../../config.json";

class BonusProduktyItemModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultValueData: {
                pocet: 1,
                jmeno: '',
                prijmeni: '',
                firma: '',
                ulice: '',
                budova: '',
                mesto: '',
                psc: '',
                zeme_trh: 'cz',
                email1: '',
                telefon: '',
                poznamka: ''
            },

            pocet: 1,
            jmeno: '',
            prijmeni: '',
            firma: '',
            ulice: '',
            budova: '',
            mesto: '',
            psc: '',
            zeme_trh: 'cz',
            email1: '',
            telefon: '',
            poznamka: '',

            odeslano: false,
            odesilani: false,
            error: "",
        };

        this.handleClose = this.handleClose.bind(this);
        this.odeslatObjednavku = this.odeslatObjednavku.bind(this);
    }

    componentDidMount = async () => {
        const aktualniFirma = authService.aktualniFirma();

        const data = await httpService.post(
            config.apiEndpoint + "bonus/posledniPouziteUdaje.php",
            {
                firma_id: aktualniFirma.firma_id
            }
        );

        if (data.data.status == "SUCCESS") {
            const user = data.data.data;
            this.setState({
                jmeno: user.jmeno,
                prijmeni: user.prijmeni,
                firma: user.firma,
                ulice: user.ulice,
                budova: user.budova,
                mesto: user.mesto,
                psc: user.psc,
                email1: user.email1,
                telefon: user.telefon,
                poznamka: user.poznamka
            });

            const defaultValueData = this.state.defaultValueData;
            for (let i in user) {
                defaultValueData[i] = user[i];
            }
            this.setState(defaultValueData);
        }

        loadVeracoin();
    }

    odeslatObjednavku = async () => {
        const {produkt} = this.props;
        const aktualniFirma = authService.aktualniFirma();

        if (this.state.pocet < 1) {
            alert("Minimální počet kusů je: 1");
            return;
        }
        this.setState({
            error: "",
            odesilani: true
        });

        const objednavka = await httpService.post(
            config.apiEndpoint + "bonus/novaObjednavka.php",
            {
                pocet: this.state.pocet,
                jmeno: this.state.jmeno,
                prijmeni: this.state.prijmeni,
                firma: this.state.firma,
                ulice: this.state.ulice,
                budova: this.state.budova,
                mesto: this.state.mesto,
                psc: this.state.psc,
                zeme_trh: this.state.zeme_trh,
                email1: this.state.email1,
                telefon: this.state.telefon,
                poznamka: this.state.poznamka,

                cena: produkt.cena_vc,
                bp_produkt_id: produkt.bp_produkt_id,

                firma_id: aktualniFirma.firma_id
            }
        );

        const data = objednavka.data;

        if (data.status == "ERROR") {
            this.setState({
                error: "message" in data ? data.message: "Došlo k chybě"
            });
        } else {
            this.setState({
                error: "",
                odeslano: true,
                odesilani: false
            });
        }
    };

    handleClose(){
        const {close} = this.props;

        this.setState({odeslano: false, error: ""});
        loadVeracoin();

        if (close) {
            close();
        }

        window.location.reload(true);
    }

    render() {
        const lang = authService.getLang();
        const {produkt, open, close} = this.props;
        const {defaultValueData, error, odeslano, odesilani} = this.state;

        return (
            <React.Fragment>
                <Modal
                    show={open}
                    title={"Objednávka: " + produkt.nazev}
                    onClose={this.handleClose}
                    hideSubmit={true}
                >
                    {error != "" && (
                        <p className={"alert alert-danger"}>{error}</p>
                    )}

                    {odesilani ? (
                        <p className={"alert alert-success"}>.... probíhá odesílání ....</p>
                    ) : (odeslano ? (
                        <p className={"alert alert-success"}>Objednávka byla odeslána.</p>
                    ) : (
                        <form action="javascript:void(0)" onSubmit={this.odeslatObjednavku}>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Počet *</th>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            required="required"
                                            min="1"
                                            defaultValue={defaultValueData.pocet}
                                            onChange={(e) => this.setState({pocet: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Jméno *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.jmeno}
                                            onChange={(e) => this.setState({jmeno: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Příjmení *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.prijmeni}
                                            onChange={(e) => this.setState({prijmeni: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Firma</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={defaultValueData.firma}
                                            onChange={(e) => this.setState({firma: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Ulice *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.ulice}
                                            onChange={(e) => this.setState({ulice: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Budova</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={defaultValueData.budova}
                                            onChange={(e) => this.setState({budova: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Město *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.mesto}
                                            onChange={(e) => this.setState({mesto: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>PSČ *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.psc}
                                            onChange={(e) => this.setState({psc: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Země *</th>
                                    <td>
                                        <select
                                            className="form-control"
                                            required="required"
                                            onChange={(e) => this.setState({zeme_trh: e.target.value})}>
                                            defaultValue={defaultValueData.zeme_trh}
                                            <option value="cz">Česká republika</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email *</th>
                                    <td>
                                        <input
                                            type="email"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.email1}
                                            onChange={(e) => this.setState({email1: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Telefon *</th>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required="required"
                                            defaultValue={defaultValueData.telefon}
                                            onChange={(e) => this.setState({telefon: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Poznámka</th>
                                    <td>
                                    <textarea
                                        className="form-control"
                                        defaultValue={defaultValueData.poznamka}
                                        onChange={(e) => this.setState({poznamka: e.target.value})}
                                    />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p className={"text-center padding-top-20"}>
                                <button className={"btn btn-primary"} type={"submit"}>Odeslat objednávku</button>
                            </p>
                        </form>
                    ))}

                </Modal>
            </React.Fragment>
        );
    }
}

export default BonusProduktyItemModal;
