import React, { Component } from "react";
import LoadMusic from "./../common/LoadMusic";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { lang } from "moment";
import authService from "../../services/authService";

class ProduktyProduktu extends Component {
  state = { aktProdukt: 0 };

  async componentDidMount() {
    console.log("P_ID", this.props.partnerId);
    const ak = authService.aktualniFirma();
    let { produkt_id, baseURL } = this.props;
    if (this.props.partnerId) {
      const produkty = await httpService.post(
        config.apiEndpoint + "produkty/produktyProduktu.php",
        { partner_id: this.props.partnerId, firma_id: ak.firma_id }
      );

      if (!produkt_id) {
        produkt_id = produkty.data[0].produkt_id;
        if (produkt_id) {
          window.location =
            baseURL +
            "/" +
            produkt_id +
            "/" +
            this.slugify(produkty.data[0].nazev);
        }
      }

      this.setState({
        produktyLoaded: true,
        produkty: produkty.data,
        aktualniFirma: ak,
        produkt_id,
      });
    }
    this.setState({ produkt_id });
  }

  slugify = (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  generateLink = (obj) => {
    if (obj.lik) {
      return obj.link;
    } else {
      return (
        "https://www.veracomp.cz/api/downloads/" +
        this.state.aktualniFirma.firma_id +
        "/" +
        localStorage.getItem("token") +
        "/" +
        obj.soubor_id +
        "/" +
        obj.nazev
      );
    }
  };

  render() {
    const { produktyLoaded, produkty, produkt_id } = this.state;
    const { baseURL } = this.props;
    const lang = authService.getLang();
    return (
      <div>
        {produktyLoaded ? (
          produkty && produkty.length ? (
            <div
              className="row cols-border"
              style={{ width: "100%", margin: isMobile ? "0px" : null }}
            >
              <div
                className="col-md-3 col-12 widget widget_categories"
                style={{
                  padding: isMobile ? "0px" : null,
                }}
              >
                <MobileView>
                  <select
                    className="form-control"
                    onChange={(select) =>
                      this.setState({ aktProdukt: select.target.selectedIndex })
                    }
                  >
                    {produkty.map((p, index) => (
                      <option key={index}>{p.nazev}</option>
                    ))}
                  </select>
                </MobileView>
                <BrowserView>
                  <ul>
                    {produkty.map((p, index) => (
                      <li
                        className={
                          produkt_id === p.produkt_id ? "active" : null
                        }
                        key={index}
                      >
                        <a
                          href={
                            baseURL +
                            "/" +
                            p.produkt_id +
                            "/" +
                            this.slugify(p.nazev)
                          }
                          onClick={() =>
                            this.setState({ produkt_id: p.produkt_id })
                          }
                        >
                          {p.nazev}
                        </a>
                      </li>
                    ))}
                  </ul>
                </BrowserView>
              </div>
              {produkty &&
                produkty.findIndex((p) => p.produkt_id === produkt_id) >= 0 && (
                  <div
                    className="col-md-9 col-12 obchodniPripad"
                    style={{
                      padding: isMobile ? "0px" : null,
                    }}
                  >
                    <h3 className="light upper center spacing-5">
                      {produkty.find((p) => p.produkt_id === produkt_id).nazev}
                    </h3>
                    {produkty.find((p) => p.produkt_id === produkt_id)
                      .soubory ? (
                      <ul
                        className="margin-vertical-20 banner padding-horizontal-20"
                        style={{ fontSize: "0.9em" }}
                      >
                        {produkty
                          .find((p) => p.produkt_id === produkt_id)
                          .soubory.map((s, index) => (
                            <li className="row" key={s.soubor_id}>
                              <div className="col-9">{s.nazev}</div>
                              <div className="col-3 right">
                                <a
                                  href={this.generateLink(s)}
                                  className="btn btn-primary btn-sm"
                                  style={{ float: "right" }}
                                >
                                  Download
                                </a>
                              </div>
                            </li>
                          ))}
                      </ul>
                    ) : null}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: produkty.find(
                          (p) => p.produkt_id === produkt_id
                        ).popis,
                      }}
                    />
                  </div>
                )}
            </div>
          ) : (
            <div className="alert alert-danger alert-outline">
              {lang.kontakt_pro_produkt_info}
            </div>
          )
        ) : (
          <LoadMusic />
        )}
      </div>
    );
  }
}

export default ProduktyProduktu;
