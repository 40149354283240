import { combineReducers } from "redux";
import { vyprodejSkladu } from "./vyprodejSkladu";
import { kosikSamoobsluha } from "./kosikSamoobsluha";
/*import { kosik2 } from "./kosik2";*/

export const reducers = combineReducers({
  vyprodejSkladu,
  kosikSamoobsluha,
  /*kosik2*/
});
