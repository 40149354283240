import React, { Component } from "react";
import authService from "../services/authService";

class Footer extends Component {
  state = {};
  render() {
    const lang = authService.getLangByUrl();
    const agenda_id = authService.getAgendaIdByUrl();
    return (
      <footer className="footer">
        <div className="footer-wrapper">
          <div className="footer-widgets padding-bottom-30">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-6">
                      <div className="widget">
                        <h3 className="widget-title">
                          {lang.exlusive_network_czechia} s.r.o.
                        </h3>
                        <div className="widget-content">
                          {lang.exlusive_network_czechia_adresa_1}
                          <br />
                          {lang.exlusive_network_czechia_adresa_2}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="widget">
                        <h3 className="widget-title">{lang.kontaktuje_nas}</h3>
                        <div className="widget-content">
                          E-mail:{" "}
                          <a href="mailto:info@exclusive-networks.cz">
                            {lang.info_exlusive_network_cz}
                          </a>
                          <br />
                          Telefon:{" "}
                          <a href={"callto:"+ lang.exlusice_networks_telefon}>{lang.exlusice_networks_telefon}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <iframe
                    src={lang.url_mapy}
                    width="100%"
                    height="300"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12 col-sm-6">
                      <div className="widget">
                        <h3 className="widget-title">{lang.dalsi_odkazy}</h3>
                        <div className="widget-content">
                          <ul style={{ fontWeight: "0.8em" }}>
                            {agenda_id === "cz" && (
                                <li>
                                  <a href="https://bonus.veracomp.cz">
                                    BONUS PORTAL - místo, kde uplatníte získané
                                    veracoiny
                                  </a>
                                </li>
                            )}
                            <li>
                              <a href={lang.url_soukromi}>
                                {lang.zasady_ochrany_soukromi}
                              </a>
                            </li>
                            <li>
                              <a href={lang.url_obchodnich_podminek}>
                                {lang.obchodni_podminky_projektu_a_promo_akci}
                              </a>
                            </li>
                            <li>
                              <a href={lang.url_podminek}>
                                {lang.podminky_akci}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="widget">
                        <h3 className="widget-title">{lang.kontaktuje_nas}</h3>
                        <div className="widget-content">
                          <ul className="list-socials">
                            <li>
                              <a href={lang.url_linkedin}>
                                <i className="fa fa-linkedin" />
                              </a>
                            </li>
                            <li>
                              <a href={lang.url_youtube}>
                                <i className="fa fa-youtube" />
                              </a>
                            </li>
                            <li>
                              <a href={lang.url_instagram}>
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            Copyright &copy; 2022 {lang.exlusive_network_czechia} s.r.o.
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
