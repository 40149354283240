import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import authService from "./../../services/authService";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import LoadMusic from "../common/LoadMusic";
import httpService from "../../services/httpService";
import config from "../../config.json";
import NumberFormat from "react-number-format";
import Chart from "react-google-charts";
import { BrowserView, MobileView } from "react-device-detect";

class StatistikaObchodu extends Component {
  state = {
    rokLoaded: [],
    rokData: [],
    celkemData: [],
    celkemLoaded: false,
    mesice: [
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srpen",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec"
    ]
  };
  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const rok = new Date().getFullYear();
    let roky = [];
    for (let i = rok; i >= 2009; i--) {
      roky[roky.length] = i;
    }
    const datarok = await httpService.post(
      config.apiEndpoint + "obchodniPripady/statistikaRok.php",
      { firma_id: aktualniFirma.firma_id, rok }
    );

    const celkem = await httpService.post(
      config.apiEndpoint + "obchodniPripady/statistikaCelkem.php",
      { firma_id: aktualniFirma.firma_id, rok }
    );

    let rokData = [];
    rokData[rok] = datarok.data;
    let rokLoaded = [];
    rokLoaded[rok] = true;
    this.setState({
      roky,
      aktualniFirma,
      rokData,
      rokLoaded,
      celkemData: celkem.data,
      celkemLoaded: true
    });
  }

  nahrajRok = async rok => {
    const novyrok = await httpService.post(
      config.apiEndpoint + "obchodniPripady/statistikaRok.php",
      { firma_id: this.state.aktualniFirma.firma_id, rok }
    );
    let rokData = [...this.state.rokData];
    rokData[rok] = novyrok.data;
    let rokLoaded = [...this.state.rokLoaded];
    rokLoaded[rok] = true;
    this.setState({ rokData, rokLoaded });
  };

  ukazRok = (rok, onSelect = false) => {
    const { mesice } = this.state;
    const lang = authService.getLang();
    if (!this.state.rokLoaded[rok] && onSelect) {
      this.nahrajRok(rok);
    }
    return (
      <React.Fragment>
        <h3 className="upper center underline margin-top-30">
          {lang.sumarizace_roku} {rok}
        </h3>
        {!this.state.rokLoaded[rok] ? (
          <LoadMusic />
        ) : (
          <React.Fragment>
            {this.state.rokData[rok].tabledata ? (
              <React.Fragment>
                <div
                  className="row padding-top-20"
                  style={{ width: "100%", margin: "0px" }}
                >
                  <div className="col-md-8" style={{ padding: "0px" }}>
                    <Chart
                      width="100%"
                      height={300}
                      chartType="ColumnChart"
                      loader={<LoadMusic />}
                      data={this.state.rokData[rok].googlechartdata}
                      options={{
                        hAxis: {
                          title: lang.mesic,
                          minValue: 0
                        },
                        vAxis: {
                          title: lang.prodej
                        },
                        backgroundColor: "#f5f5f5",
                        colors: [
                          "#d43863",
                          "#f7ca13",
                          "#e98a18",
                          "#2cb8c2",
                          "#8b6ca9",
                          "#90c542"
                        ]
                      }}
                    />
                  </div>
                  <div className="col-md-4" style={{ padding: "0px" }}>
                    <Chart
                      width="100%"
                      height={300}
                      chartType="PieChart"
                      loader={<LoadMusic />}
                      data={this.state.rokData[rok].googlepiechartdata}
                      options={{
                        legend: { position: "none" },
                        is3D: true,
                        backgroundColor: "#f1f1f1",
                        colors: [
                          "#d43863",
                          "#f7ca13",
                          "#e98a18",
                          "#2cb8c2",
                          "#8b6ca9",
                          "#90c542"
                        ]
                      }}
                      legendToggle
                    />
                  </div>
                </div>
                <MobileView>
                  {this.state.rokData[rok].tabledata.map((p, index) => (
                    <React.Fragment key={index}>
                      <h5 className="padding-vertical-10">{mesice[index]}</h5>
                      <table>
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>{lang.prodej}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {p.map(b =>
                            b.prodej > 0 ? (
                              <tr key={b.vendor}>
                                <td>{b.vendor}</td>
                                <td style={{ textAlign: "right" }}>
                                  <NumberFormat
                                    value={b.prodej}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={0}
                                    displayType="text"
                                  />
                                </td>
                              </tr>
                            ) : null
                          )}
                        </tbody>
                      </table>
                    </React.Fragment>
                  ))}
                </MobileView>
                <BrowserView>
                  <table style={{ tableLayout: "fixed", fontSize: "0.8em" }}>
                    <thead>
                      <tr>
                        <th>{lang.vyrobce}</th>
                        {this.state.rokData[rok].tabledata.map(p => (
                          <th key={p[0].obdobi} style={{ textAlign: "center" }}>
                            {p[0].obdobi}
                          </th>
                        ))}
                      </tr>
                      {this.state.rokData[rok].tabledata[0].map((p, index) => (
                        <tr key={index}>
                          <td>{p.vendor}</td>
                          {this.state.rokData[rok].tabledata.map(r => (
                            <td
                              style={{ textAlign: "right" }}
                              key={r[index].obdobi}
                            >
                              <NumberFormat
                                value={r[index].prodej}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={0}
                                displayType="text"
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </thead>
                  </table>
                </BrowserView>
              </React.Fragment>
            ) : (
              <div className="alert alert-danger">{lang.rok_bez_dat}</div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  ukazSumu = () => {
    const lang = authService.getLang();
    if (this.state.celkemLoaded) {
      //const data = this.generujDataGraf(this.state.celkemData);
      console.log("GData", this.state.celkemData.googlechartdata);
    }
    return (
      <React.Fragment>
        {!this.state.celkemLoaded ? (
          <LoadMusic />
        ) : (
          <React.Fragment>
            <div
              className="row padding-top-20"
              style={{ width: "100%", margin: "0px" }}
            >
              <div className="col-md-8 nopadding" style={{ padding: "0px" }}>
                <Chart
                  width="100%"
                  height={300}
                  chartType="ColumnChart"
                  loader={<LoadMusic />}
                  data={this.state.celkemData.googlechartdata}
                  options={{
                    hAxis: {
                      title: "Rok",
                      minValue: 0
                    },
                    vAxis: {
                      title: lang.prodej
                    },
                    backgroundColor: "#f5f5f5",
                    colors: [
                      "#d43863",
                      "#f7ca13",
                      "#e98a18",
                      "#2cb8c2",
                      "#8b6ca9",
                      "#90c542"
                    ]
                  }}
                />
              </div>
              <div className="col-md-4 nopadding" style={{ padding: "0px" }}>
                <Chart
                  width="100%"
                  height={300}
                  chartType="PieChart"
                  loader={<LoadMusic />}
                  data={this.state.celkemData.googlepiechartdata}
                  options={{
                    legend: { position: "none" },
                    is3D: true,
                    backgroundColor: "#f1f1f1",
                    colors: [
                      "#d43863",
                      "#f7ca13",
                      "#e98a18",
                      "#2cb8c2",
                      "#8b6ca9",
                      "#90c542"
                    ]
                  }}
                  legendToggle
                />
              </div>
            </div>
            <MobileView>
              {this.state.celkemData.tabledata.map(p => (
                <React.Fragment key={"rok" + p[0].obdobi}>
                  <h5 className="padding-vertical-10">Rok {p[0].obdobi}</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>{lang.prodej}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {p.map((b, index) =>
                        b.prodej > 0 ? (
                          <tr key={index}>
                            <td style={{ width: "50%" }}>{b.vendor}</td>
                            <td style={{ textAlign: "right" }}>
                              <NumberFormat
                                value={b.prodej}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={0}
                                displayType="text"
                              />
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </React.Fragment>
              ))}
            </MobileView>
            <BrowserView>
              <table style={{ tableLayout: "fixed", fontSize: "0.8em" }}>
                <thead>
                  <tr>
                    <th>{lang.vyrobce}</th>
                    {this.state.celkemData.tabledata.map(p => (
                      <th key={p[0].obdobi} style={{ textAlign: "center" }}>
                        {p[0].obdobi}
                      </th>
                    ))}
                  </tr>
                  {this.state.celkemData.tabledata[0].map((p, index) => (
                    <tr key={index}>
                      <td>{p.vendor}</td>
                      {this.state.celkemData.tabledata.map(r => (
                        <td
                          style={{ textAlign: "right" }}
                          key={r[index].obdobi}
                        >
                          <NumberFormat
                            value={r[index].prodej}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={0}
                            displayType="text"
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </thead>
              </table>
            </BrowserView>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  writeRok = rok => {
    return <div>{rok}</div>;
  };

  coverHTML = () => {
    const lang = authService.getLang();
    if (this.state.aktualniFirma) {
      if (this.state.aktualniFirma.partnerweb_obchod) {
        return (
          <React.Fragment>
            <Tabs
              className="awe-nav-responsive"
              onSelect={n => this.ukazRok(this.state.roky[n], true)}
            >
              <TabList className="awe-nav">
                {this.state.roky.map(rok => (
                  <Tab key={rok}>{this.writeRok(rok)}</Tab>
                ))}
              </TabList>
              {this.state.roky.map(rok => (
                <TabPanel key={rok}>{this.ukazRok(rok)}</TabPanel>
              ))}
            </Tabs>
            <hr />
            <h3 className="upper center underline margin-top-30">
              {lang.celkova_sumarizace}
            </h3>
            {this.ukazSumu()}
          </React.Fragment>
        );
      } else {
        return (
          <div className="alert alert-danger">Nemáte přístup do této sekce</div>
        );
      }
    } else {
      return null;
    }
  };
  render() {
    const lang = authService.getLang();
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={lang.statistika_obchodu}
          obrazek="./images/statistika-obchodu.jpg"
        />
        <div className="container">{this.coverHTML()}</div>
      </React.Fragment>
    );
  }
}

export default StatistikaObchodu;
