import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import authService from "../../services/authService";
import NumberFormat from "react-number-format";
import Modal from "../Modal";
import { connect } from "react-redux";
import { vlozitDoKosikuSamoobsluha } from "./../../actions/kosikSamoobsluha";
import { toast } from "react-toastify";

class SamoobsluhaNabidka extends Component {
  state = { dir_id: "0" };

  componentDidMount = () => {
    const { dir_id } = this.props;
    this.nahrajSlozku(dir_id);
  };

  componentDidUpdate = () => {
    const { partner_id } = this.state;
    if (partner_id && partner_id !== this.props.partner_id) {
      this.nahrajSlozku("0");
    }
  };

  slugify = (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  nahrajSlozku = async (dir_idParam) => {
    let dir_id = dir_idParam ? dir_idParam : this.state.dir_id;
    const { baseURL } = this.props;

    const user = authService.getCurrentUser();
    let firma_id = localStorage.getItem("firma_id");
    firma_id = firma_id ? firma_id : user.aktualni_firma;

    const { partner_id } = this.props;
    const slozka = await httpService.post(
      config.apiEndpoint + "produkty/slozka.php",
      { partner_id, dir_id, firma_id }
    );
    let url = baseURL;
    if (slozka && slozka.data && slozka.data.slozka) {
      url += "/" + dir_id + "/" + this.slugify(slozka.data.slozka.nazev);
    }
    window.location = url;
    this.setState({ dir_id, partner_id, slozka: slozka.data });
  };

  addDoplnek = (doplnek) => {
    let { doKosiku } = this.state;
    if (doKosiku.doplnky) {
      doKosiku.doplnky = doKosiku.doplnky.filter(
        (d) =>
          !(
            d.sablona_id === doplnek.sablona_id ||
            d.parent_sablona_id === doplnek.sablona_id
          )
      );
    } else {
      doKosiku.doplnky = [];
    }
    doKosiku.doplnky.push(doplnek);
    console.log("do kosiku", doKosiku);
    this.setState({ doKosiku });
  };

  removeDoplnek = (sablona_id) => {
    let { doKosiku } = this.state;
    if (doKosiku.doplnky) {
      doKosiku.doplnky = doKosiku.doplnky.filter(
        (d) => d.sablona_id !== sablona_id
      );
    }
    this.setState({ doKosiku });
  };

  konfNabidka = (konfigurace, sablona_id, currCena = 0) => {
    const { doKosiku } = this.state;
    return (
      <React.Fragment>
        <h5>+ {konfigurace.skupina_nazev}</h5>
        <div>
          {konfigurace.povinna_polozka === "0" && (
            <div
              className="row"
              style={{
                margin: "10px 2px",
                border:
                  doKosiku &&
                  doKosiku.doplnky &&
                  doKosiku.doplnky.findIndex(
                    (d) => d.sablona_id === konfigurace.sablona_id
                  ) === -1
                    ? "solid 2px blue"
                    : "solid 2px #ddd",
                cursor: "pointer",
              }}
              onClick={() => this.removeDoplnek(konfigurace.sablona_id)}
            >
              <div className="col-md-8" style={{ padding: "10px" }}>
                Nepotřebuji {konfigurace.skupina_nazev}
              </div>
              <div className="col-md-4 right" style={{ padding: "10px" }}>
                {Math.abs(parseFloat(currCena)) > 0 && (
                  <h5>
                    {"- "}
                    <NumberFormat
                      value={Math.abs(parseFloat(currCena))}
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType="text"
                    />
                  </h5>
                )}
              </div>
            </div>
          )}
          {konfigurace.produkty &&
            konfigurace.produkty.map((p) => (
              <div
                className="row"
                key={p.prilezitost_ciselnik_id}
                onClick={() =>
                  this.addDoplnek({
                    sablona_id: konfigurace.sablona_id,
                    doplnek: p,
                    parent_sablona_id: sablona_id,
                  })
                }
                style={{
                  border:
                    doKosiku &&
                    doKosiku.doplnky &&
                    doKosiku.doplnky.findIndex(
                      (d) =>
                        d.sablona_id === konfigurace.sablona_id &&
                        d.doplnek.prilezitost_ciselnik_id ===
                          p.prilezitost_ciselnik_id
                    ) >= 0
                      ? "solid 2px blue"
                      : "solid 2px #ddd",
                  margin: "10px 2px",
                  cursor: "pointer",
                }}
              >
                <div
                  className="col-md-8"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {p.vyrobek}
                </div>
                <div
                  className="col-md-4 right"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {doKosiku &&
                  doKosiku.doplnky &&
                  doKosiku.doplnky.findIndex(
                    (d) =>
                      d.sablona_id === konfigurace.sablona_id &&
                      d.doplnek.prilezitost_ciselnik_id ===
                        p.prilezitost_ciselnik_id
                  ) >= 0 ? (
                    <span className="icon icon-check-sign" />
                  ) : (
                    <h5>
                      {parseFloat(p.cena.prodej_cena_poSleve) -
                        parseFloat(currCena) >
                      0
                        ? "+ "
                        : "- "}
                      <NumberFormat
                        value={Math.abs(
                          parseFloat(p.cena.prodej_cena_poSleve) -
                            parseFloat(currCena)
                        )}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType="text"
                      />
                    </h5>
                  )}
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  };

  konfigurace = () => {
    const { produkt } = this.state;
    let { doKosiku } = this.state;
    console.log("konfigurace", doKosiku && doKosiku.produkt);

    let cena = 0;
    let cenaPoSleve = 0;
    let doplnkyCena = [];

    cena = parseFloat(produkt.cena.prodej_cena);
    cenaPoSleve = parseFloat(produkt.cena.prodej_cena_poSleve);

    if (doKosiku && doKosiku.doplnky) {
      for (let i = 0; i < doKosiku.doplnky.length; i++) {
        cena += parseFloat(doKosiku.doplnky[i].doplnek.cena.prodej_cena);
        cenaPoSleve += parseFloat(
          doKosiku.doplnky[i].doplnek.cena.prodej_cena_poSleve
        );
        doplnkyCena[doKosiku.doplnky[i].sablona_id] =
          doKosiku.doplnky[i].doplnek.cena.prodej_cena_poSleve;
      }
    }

    let i,
      j,
      max = 0;
    let doplnek = null;
    if (!doKosiku || doKosiku === null) {
      doKosiku = {};
      doKosiku.produkt = produkt;
      doKosiku.doplnky = [];
      if (produkt.konfigurace) {
        for (i = 0; i < produkt.konfigurace.length; i++) {
          if (produkt.konfigurace[i].povinna_polozka === "1") {
            max = 0;
            for (j = 0; j < produkt.konfigurace[i].produkty.length; j++) {
              if (max !== 0) {
                if (
                  produkt.konfigurace[i].produkty[j].cena.prodej_cena_poSleve <
                  max
                ) {
                  max =
                    produkt.konfigurace[i].produkty[j].cena.prodej_cena_poSleve;
                  doplnek = produkt.konfigurace[i].produkty[j];
                }
              } else {
                max =
                  produkt.konfigurace[i].produkty[j].cena.prodej_cena_poSleve;
                doplnek = produkt.konfigurace[i].produkty[j];
              }
            }
            doKosiku.doplnky.push({
              sablona_id: produkt.konfigurace[i].sablona_id,
              doplnek,
            });
          }
        }
      }
      this.setState({ doKosiku });
      return true;
    } else {
      return (
        <React.Fragment>
          {produkt.popis}
          {produkt.konfigurace &&
            produkt.konfigurace.length > 0 &&
            produkt.konfigurace.map((k) => (
              <div key={"config" + k.sablona_id}>
                {this.konfNabidka(k, null, doplnkyCena[k.sablona_id])}
              </div>
            ))}
          {doKosiku &&
            doKosiku.doplnky &&
            doKosiku.doplnky.map(
              (d) =>
                produkt.konfigurace.findIndex(
                  (p) => p.sablona_id === d.sablona_id
                ) >= 0 &&
                produkt.konfigurace[
                  produkt.konfigurace.findIndex(
                    (p) => p.sablona_id === d.sablona_id
                  )
                ].produkty.findIndex(
                  (pp) =>
                    pp.prilezitost_ciselnik_id ===
                    d.doplnek.prilezitost_ciselnik_id
                ) >= 0 &&
                produkt.konfigurace[
                  produkt.konfigurace.findIndex(
                    (p) => p.sablona_id === d.sablona_id
                  )
                ].produkty[
                  produkt.konfigurace[
                    produkt.konfigurace.findIndex(
                      (p) => p.sablona_id === d.sablona_id
                    )
                  ].produkty.findIndex(
                    (pp) =>
                      pp.prilezitost_ciselnik_id ===
                      d.doplnek.prilezitost_ciselnik_id
                  )
                ].konfigurace &&
                produkt.konfigurace[
                  produkt.konfigurace.findIndex(
                    (p) => p.sablona_id === d.sablona_id
                  )
                ].produkty[
                  produkt.konfigurace[
                    produkt.konfigurace.findIndex(
                      (p) => p.sablona_id === d.sablona_id
                    )
                  ].produkty.findIndex(
                    (pp) =>
                      pp.prilezitost_ciselnik_id ===
                      d.doplnek.prilezitost_ciselnik_id
                  )
                ].konfigurace.map((kk) =>
                  this.konfNabidka(kk, d.sablona_id, doplnkyCena[kk.sablona_id])
                )
            )}
          <div className="b-t right">
            <h4>
              <small style={{ fontSize: "0.7em" }}>Vaše cena</small>
              <NumberFormat
                value={cenaPoSleve}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
              />{" "}
              {produkt.cena.prodej_mena}
              <div
                style={{ color: "gray", fontSize: "0.8em", paddingTop: "5px" }}
              >
                <small style={{ fontSize: "0.8em" }}>Bežná cena</small>
                <NumberFormat
                  value={cena}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType="text"
                />{" "}
                {produkt.cena.prodej_mena}
              </div>
            </h4>
          </div>
        </React.Fragment>
      );
    }
  };

  onVlozitDoKosiku = (val) => {
    this.props.onVlozitDoKosiku(val);
  };

  doKosiku = () => {
    const { doKosiku } = this.state;
    console.log("kosik", this.props.obsahKosiku, doKosiku);
    let obsahKosiku = [];
    if (this.props.obsahKosiku) {
      obsahKosiku = [...this.props.obsahKosiku];
    }
    obsahKosiku.push({ ...doKosiku, pocet_ks: 1 });
    this.onVlozitDoKosiku(obsahKosiku);
    this.setState({ konfigurace: false, doKosiku: null, produkt: null });
    toast.warn("Zboží bylo přidáno do košíku");
  };

  doKosikuPrimo = async (produkt) => {
    const doKosiku = { produkt, doplnky: [] };
    await this.setState({ doKosiku });
    this.doKosiku();
  };

  render() {
    const { slozka, dir_id, konfigurace, produkt } = this.state;
    return (
      <React.Fragment>
        <Modal
          id="konfigurace"
          title={produkt && produkt.nazev}
          show={konfigurace}
          onClose={() => this.setState({ konfigurace: false })}
          onSubmit={this.doKosiku}
          submitText="Vložit do košíku"
        >
          <section>{produkt && this.konfigurace()}</section>
        </Modal>
        {slozka && slozka.breadcrumbs && (
          <div className="border-bottom padding-bottom-10 margin-bottom-10">
            <a href="javascript:;" onClick={() => this.nahrajSlozku("0")}>
              Úvod
            </a>
            {slozka.breadcrumbs.map((b) => (
              <span key={b.dir_id} className="margin-left-10">
                &gt;{" "}
                <a
                  href="javascript:;"
                  onClick={() => this.nahrajSlozku(b.dir_id)}
                >
                  {b.nazev}
                </a>
              </span>
            ))}
          </div>
        )}
        {slozka && slozka.slozka && (
          <div>
            <h4>{slozka.slozka.nazev}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: slozka && slozka.slozka.popis,
              }}
            />
          </div>
        )}
        <div className="container padding-top-20">
          <div className="row">
            {slozka &&
              slozka.slozky &&
              slozka.slozky.length > 0 &&
              slozka.slozky.map((s) => (
                <div key={s.dir_id} className="col-md-4">
                  <div className="card margin-bottom-30">
                    <div
                      style={{
                        height: "250px",
                        overflow: "hidden",
                        position: "relative",
                        backgroundImage:
                          "url('https://api.exclusive-networks.cz/images/edirfoto.php?dir_id=" +
                          s.dir_id +
                          "')",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        cursor: "pointer",
                      }}
                      onClick={() => this.nahrajSlozku(s.dir_id)}
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        <a
                          href="javascript:;"
                          onClick={() => this.nahrajSlozku(s.dir_id)}
                        >
                          {s.nazev}
                        </a>
                      </h5>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: s.popis,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            {slozka &&
              slozka.produkty &&
              slozka.produkty.map((p) => (
                <div className="col-md-4" key={"prod" + p.produkt_id}>
                  <div className="card">
                    <div
                      style={{
                        height: "250px",
                        overflow: "hidden",
                        position: "relative",
                        backgroundImage:
                          "url('https://api.exclusive-networks.cz/images/eprodfoto.php?dir_id=" +
                          dir_id +
                          "&produkt_id=" +
                          p.prilezitost_ciselnik_id +
                          "')",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    >
                      {/* <span
                        className={
                          "badge " +
                          (p.sklademCZ === "0"
                            ? "badge-warning"
                            : "badge-success")
                        }
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "10px",
                          fontSize: "1em",
                        }}
                      >
                        {p.sklademCZ === "0"
                          ? p.intrastat === "1"
                            ? "K dodání do 14 dnů"
                            : "K dodání do 2 dnů"
                          : "Skladem " + p.sklademCZ + " ks"}
                      </span>*/}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{p.nazev}</h5>
                      <small className="badge badge-info">{p.sku}</small>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: p.popis,
                        }}
                      />
                      <h3 className="center" style={{ marginBottom: "0px" }}>
                        {p.konfigurace && p.konfigurace.length > 0 && (
                          <span style={{ fontSize: "0.8em" }}>od </span>
                        )}
                        <NumberFormat
                          value={
                            parseFloat(p.cena.prodej_cena_poSleve) +
                            parseFloat(p.navyseni)
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType="text"
                        />{" "}
                        {p.cena.prodej_mena}
                      </h3>
                      <div className="center" style={{ marginBottom: "20px" }}>
                        <small>
                          <NumberFormat
                            value={
                              parseFloat(p.cena.prodej_cena) +
                              parseFloat(p.navyseniFull)
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType="text"
                          />{" "}
                          (-{p.cena.prodej_sleva}%)
                        </small>
                      </div>
                      <div className="center">
                        {p.konfigurace && p.konfigurace.length > 0 ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              this.setState({ konfigurace: true, produkt: p })
                            }
                          >
                            Konfigurovat
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.doKosikuPrimo(p)}
                          >
                            Do košíku
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  obsahKosiku: state.kosikSamoobsluha,
});
const mapActionsToProps = {
  onVlozitDoKosiku: vlozitDoKosikuSamoobsluha,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SamoobsluhaNabidka);
