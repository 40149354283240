import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import authService from "../../services/authService";

class ProduktyDokumenty extends Component {
  state = { aktIndex: 0 };

  async componentDidMount() {
    const { partnerId, baseURL } = this.props;
    let { dir_id } = this.props;
    const ak = authService.aktualniFirma();
    const dokumenty = await httpService.post(
      config.apiEndpoint + "produkty/dokumenty.php",
      { partner_id: partnerId, agenda_id: ak.agenda_id, firma_id: ak.firma_id }
    );
    if (!dir_id && dokumenty.data && dokumenty.data.length > 0) {
      dir_id = this.slugify(dokumenty.data[0].nazev);
      window.location = baseURL + "/" + this.slugify(dokumenty.data[0].nazev);
    }
    this.setState({
      dokumentyLoaded: true,
      dokumenty: dokumenty.data,
      aktualniFirma: ak,
      dir_id,
    });
  }

  slugify = (string) => {
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  generateLink = (obj) => {
    if (obj.link) {
      return obj.link;
    } else {
      return (
        "https://api.exclusive-networks.cz/pw/downloads/" +
        this.state.aktualniFirma.firma_id +
        "/" +
        localStorage.getItem("token") +
        "/" +
        obj.soubor_id +
        "/" +
        obj.nazev
      );
    }
  };

  render() {
    const { dokumentyLoaded, dokumenty, dir_id, aktIndex } = this.state;
    const { baseURL } = this.props;
    return (
      <div>
        {dokumentyLoaded ? (
          <div>
            {dokumenty && dokumenty.length ? (
              <div
                className="row cols-border"
                style={{ width: "100%", margin: isMobile ? "0px" : null }}
              >
                <div
                  className="col-md-3 widget widget_categories"
                  style={{
                    padding: isMobile ? "0px" : null,
                  }}
                >
                  <MobileView>
                    <select
                      className="form-control"
                      onChange={(select) =>
                        this.setState({
                          aktIndex: select.target.selectedIndex,
                        })
                      }
                    >
                      {dokumenty.map((p, index) => (
                        <option key={index}>{p.nazev}</option>
                      ))}
                    </select>
                  </MobileView>
                  <BrowserView>
                    <ul>
                      {dokumenty.map((d) => (
                        <li
                          key={d.nazev}
                          className={
                            dir_id === this.slugify(d.nazev) ? "active" : null
                          }
                        >
                          <a
                            href={baseURL + "/" + this.slugify(d.nazev)}
                            onClick={() =>
                              this.setState({ dir_id: this.slugify(d.nazev) })
                            }
                          >
                            {d.nazev}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </BrowserView>
                </div>
                {dokumenty &&
                  dokumenty.findIndex(
                    (d) => this.slugify(d.nazev) === dir_id
                  ) >= 0 && (
                    <div
                      className="col-md-9 obchodniPripad"
                      style={{
                        padding: isMobile ? "0px" : null,
                      }}
                    >
                      <h3 className="light upper center spacing-5">
                        {
                          dokumenty.find(
                            (d) => this.slugify(d.nazev) === dir_id
                          ).nazev
                        }
                      </h3>
                      <ul>
                        {dokumenty.find((d) => this.slugify(d.nazev) === dir_id)
                          .soubory &&
                          dokumenty
                            .find((d) => this.slugify(d.nazev) === dir_id)
                            .soubory.map((s) => (
                              <li key={s.soubor_id}>
                                <div
                                  className="row"
                                  style={{ fontSize: "0.8em", width: "100%" }}
                                >
                                  <div className="col-9">
                                    {s.popis ? s.popis : s.nazev}
                                  </div>
                                  <div className="col-3 right">
                                    <a
                                      href={this.generateLink(s)}
                                      className="btn btn-primary btn-sm"
                                    >
                                      {s.link ? "Link" : "Download"}
                                    </a>
                                  </div>
                                </div>
                              </li>
                            ))}
                      </ul>
                    </div>
                  )}
              </div>
            ) : (
              <div className="alert alert-danger alert-outline">
                Pokud potřebujete aktuální dokumenty, prosím, kontaktujte nás...
              </div>
            )}
          </div>
        ) : (
          <LoadMusic />
        )}
      </div>
    );
  }
}

export default ProduktyDokumenty;
