import React, {Component} from "react";
import NadpisStranky from "./../common/nadpis";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import ProgressBar from "../common/ProgressBar";

class NovaFaktura extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            datum_splatnosti: "",
            soubor: null,
            castka: 0,
            uploading: 0,
            progress: 0,
            error: ""
        };

        this.state = this.defaultState;


        this.nastavitSoubor = this.nastavitSoubor.bind(this);
        this.validate = this.validate.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    validate() {
        let lang = authService.getLang();

        const {soubor} = this.state;

        if (!soubor) {
            this.setState({error: lang.musiBytSoubor});
            return false;
        }

        var reg = /(\.jpg|\.jpeg|\.pdf|\.doc|\.docx|\.png)$/i;
        if (!reg.exec(soubor.name.toLowerCase())) {
            this.setState({error: lang.povoleneSoubory + " *.JPG,*.JPEG, *.PDF,*.DOC,*.DOCX,*.PNG"});
            return false;
        }

        this.setState({error: ""});
        return true;
    }


    uploadFile(event) {
        const {datum_splatnosti, soubor, castka} = this.state;
        const aktualniFirma = authService.aktualniFirma();

        if (this.validate()) {
            this.setState({uploading: 1, progress: 0});

            const fd = new FormData();
            fd.append("soubor", soubor, soubor.name);
            fd.append("datum_splatnosti", datum_splatnosti);
            fd.append("interni_objednavka_id", this.props.match.params.id);
            fd.append("castka", castka);
            fd.append("firma_id", aktualniFirma.firma_id);

            httpService
                .post(config.apiEndpoint + "dodavatel/novaFaktura.php", fd, {
                    onUploadProgress: (progressEvent) => {
                        let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.setState({progress});
                    },
                }).then(() => {
                    window.location.href = "#/dodavatel";
            });
        }

        event.preventDefault();
        return false;
    }

    nastavitSoubor(event) {
        if (event.target && event.target.files) {
            this.setState({soubor: event.target.files[0]});
        }
    }

    render() {
        const {progress, uploading, datum_splatnosti, soubor, castka, error} = this.state;
        const lang = authService.getLang();

        return (
            <React.Fragment>
                <NadpisStranky
                    nadpis={lang.dodavatel + ": " + lang.novaFaktura+ " ("+this.props.match.params.id+")"}
                    obrazek="./images/reference1.jpg"
                />

                <div className="container padding-top-20">

                    <h3 className="light upper underline center spacing-5 padding-bottom-20 padding-top-20">{lang.faktura}</h3>

                    {error != "" && (
                        <p className={"alert alert-danger"}>{error}</p>
                    )}

                    {uploading > 0 ? (
                        <React.Fragment>
                            <ProgressBar popis={soubor.name} progress={progress}/>
                        </React.Fragment>
                    ) : (
                        <form onSubmit={(event) => this.uploadFile(event)}>
                            <table>
                                <tbody>
                                <tr>
                                    <th>{lang.castka}</th>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            required="required"
                                            value={castka}
                                            onChange={(e) => this.setState({castka: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{lang.datum_splatnosti}</th>
                                    <td>
                                        <input
                                            type="date"
                                            required="required"
                                            className="form-control"
                                            value={datum_splatnosti}
                                            onChange={(e) => this.setState({datum_splatnosti: e.target.value})}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{lang.faktura}</th>
                                    <td>
                                        <button
                                            type={"button"}
                                            onClick={() => this.fileInput2.click()}
                                            className={"btn btn-secondary"}
                                        >
                                          <span className="btn-label">
                                            <i className="ti-cloud-up"/>
                                          </span>
                                            {lang.nahratSoubor}
                                            {soubor && (
                                                <React.Fragment>
                                                    &nbsp; ({soubor.name})
                                                </React.Fragment>
                                            )}
                                        </button>
                                        <input
                                            type="file"
                                            name="file"
                                            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                            ref={(fileInput2) => (this.fileInput2 = fileInput2)}
                                            style={{display: "none"}}
                                            onChange={this.nastavitSoubor}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <p className={"text-center padding-top-20"}>
                                <button className={"btn btn-primary"} type={"submit"}>{lang.odeslat}</button>
                            </p>
                        </form>
                    )}

                </div>
            </React.Fragment>
        );
    }
}

export default NovaFaktura;
