import React, { Component } from "react";
import FloatingLabelInput from "floating-label-react";
import "../css/loginForm.css";
import auth from "../services/authService";
import httpService from "../services/httpService";
import config from "../config.json";
import authService from "../services/authService";

class loginForm extends Component {
  state = {
    data: {},
  };

  componentDidMount = () => {
    localStorage.clear();
    const url = window.location.toString();
    if (url.includes("password-recovery")) {
      this.setState({ recoverPass: true });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    auth.login(this.state.data.login, this.state.data.password, true);
    return false;
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data });
  };

  recoverPass = async () => {
    const { login } = this.state.data;
    await httpService.post(config.apiEndpoint + "profil/odkazNoveHeslo.php", {
      login,
    });
    alert("Do e-mailu jsme Vám poslali resetovací odkaz");
    this.setState({ recoverPass: false });
  };

  recoverPassOld = () => {
    const { login } = this.state.data;
    var request = new XMLHttpRequest();
    request.open(
      "POST",
      "https://www.veracomp.cz/cz/user/password-recovery?obnovit_heslo=1&email=" +
        login,
      true
    );
    request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    const data = {};
    request.send(data);
    alert("Do e-mailu jsme Vám poslali resetovací odkaz");
    this.setState({ recoverPass: false });
  };

  render() {
    const { recoverPass } = this.state;
    const lang = authService.getLangByUrl();
    return (
      <div className="loginContainer">
        <div className="form-signin">
          {!recoverPass ? (
            <form action="#" onSubmit={this.handleSubmit}>
              <div style={{ textAlign: "center" }}>
                <img
                  className="mb-4"
                  src="images/logo-exn.png"
                  alt=""
                  style={{ width: "60%" }}
                />
              </div>
              <FloatingLabelInput
                id="login"
                placeholder="E-mail"
                name="login"
                type="email"
                className="inputFullWidth"
                onChange={this.handleChange}
              />
              <FloatingLabelInput
                id="password"
                placeholder="Heslo"
                type="password"
                name="password"
                onChange={this.handleChange}
              />
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
              >
                {lang.prihlasit}
              </button>
              <hr />
              <div className="row">
                <div className="col-6">
                  <a
                    href="#/password-recovery"
                    onClick={() => this.setState({ recoverPass: true })}
                  >
                    {lang.obnova_hesla}
                  </a>
                </div>
                <div className="col-6 right">
                  <a href="#/registrace">{lang.registrace_loginu}</a>
                </div>
              </div>
              <p className="mt-5 mb-3 text-muted">
                &copy; {lang.exlusive_network_czechia}
              </p>
            </form>
          ) : (
            <form action="javascript:void();" onSubmit={this.recoverPass}>
              <div style={{ textAlign: "center" }}>
                <img className="mb-4" src="images/logo-exn.png" alt="" />
              </div>
              <FloatingLabelInput
                id="login"
                placeholder="E-mail"
                name="login"
                type="email"
                className="inputFullWidth"
                onChange={this.handleChange}
              />
              <button
                className="btn btn-lg btn-primary btn-block"
                type="submit"
                disabled={!this.state.data.login}
              >
                {lang.obnovit_heslo}
              </button>
              <p className="mt-5 mb-3 text-muted">
                &copy; {lang.exlusive_network_czechia}
              </p>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default loginForm;
