import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { Link } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Card from "./../common/Card";
import AkceNahled from "../kalendar/AkceNahled";
import ProduktyProduktu from "./ProduktyProduktu";
import ProduktyDokumenty from "./ProduktyDokumenty";
import VyprodejNabidka from "../vyprodej/VyprodejNabidka";
import { BrowserView, MobileView } from "react-device-detect";
import authService from "../../services/authService";
import SamoobsluhaNabidka from "./SamoobsluhaNabidka";
import Faq from "../faq/Faq";

class ProduktDetail extends Component {
  state = { partnerId: 0, partneri: [] };
  async componentDidMount() {
    this.nastavPartnerId();
    const ak = authService.aktualniFirma();
    const partneri = await httpService.post(
      config.apiEndpoint + "produkty/prehled.php",
      { agenda_id: ak.agenda_id }
    );
    this.setState({ partneri: partneri.data });
  }

  componentDidUpdate() {
    this.nastavPartnerId();
  }

  nastavPartnerId = async () => {
    const partnerId = this.props.match.params.id;
    const { nazev, tab, dir_id } = this.props.match.params;
    if (!tab) {
      window.location = "#/produkty/" + partnerId + "/" + nazev + "/uvod";
    }
    if (partnerId != this.state.partnerId) {
      const ak = authService.aktualniFirma();
      const partner = await httpService.post(
        config.apiEndpoint + "produkty/partnerDetail.php",
        { partner_id: partnerId, agenda_id: ak.agenda_id }
      );
      console.log(partner.data);
      this.setState({ partnerId, partner: partner.data, nazev, tab, dir_id });
    }
  };

  changeBrand = (select) => {
    var getSlug = require("speakingurl");
    var index = select.target.selectedIndex;
    const url =
      "#/produkty/" +
      select.target.value +
      "/" +
      getSlug(select.target[index].text, { lang: "cs" });
    window.location = url;
  };

  render() {
    const { partnerId, partneri, partner } = this.state;
    const { nazev, tab, dir_id, nazev2, item_id, item_nazev } = this.props.match.params;
    const lang = authService.getLang();
    var getSlug = require("speakingurl");
    let selectedIndex = 0;
    switch (tab) {
      case "faq":
        selectedIndex = partner && partner.akce && partner.akce.length ? 7 : 6;
        if (partner && !partner.samoobsluha) {
          selectedIndex--;
        }
        break;
      case "vyprodej":
        selectedIndex = partner && partner.akce && partner.akce.length ? 6 : 5;
        if (partner && !partner.samoobsluha) {
          selectedIndex--;
        }
        break;
      case "samoobsluha":
        selectedIndex = partner && partner.akce && partner.akce.length ? 5 : 4;
        break;
      case "kontakt":
        selectedIndex = partner && partner.akce && partner.akce.length ? 4 : 3;
        break;
      case "dokumenty":
        selectedIndex = partner && partner.akce && partner.akce.length ? 3 : 2;
        break;
      case "kalendar-akci":
        selectedIndex = 2;
        break;
      case "produkty":
        selectedIndex = 1;
        break;
      case "uvod":
      default:
        selectedIndex = 0;
        break;
    }
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={partner && partner.nazev}
          obrazek="./images/produkty.jpg"
        />
        <div className="container padding-top-20">
          <div className="row cols-border">
            <div className="col-md-2 padding-bottom-10">
              <BrowserView>
                <h3 className="widget-title">Brandy</h3>
                {partneri && partneri.length ? (
                  <div className="widget widget_categories">
                    <ul>
                      {partneri.map((p) => (
                        <li
                          className={
                            p.partner_id === partnerId ? "active" : null
                          }
                          key={p.partner_id}
                        >
                          <Link
                            to={
                              "/produkty/" +
                              p.partner_id +
                              "/" +
                              getSlug(p.nazev, { lang: "cs" })
                            }
                          >
                            {p.nazev}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </BrowserView>
              <MobileView>
                {partneri && partneri.length ? (
                  <select
                    className="form-control"
                    value={partnerId}
                    onChange={this.changeBrand}
                  >
                    {partneri.map((p) => (
                      <option value={p.partner_id} key={p.partner_id}>
                        {p.nazev}
                      </option>
                    ))}
                  </select>
                ) : null}{" "}
              </MobileView>
            </div>
            <div className="col-md-10">
              <Tabs
                className="awe-nav"
                key={partnerId}
                defaultIndex={selectedIndex}
              >
                <TabList className="awe-nav margin-bottom-10">
                  <Tab
                    onClick={() =>
                      (window.location =
                        "#/produkty/" + partnerId + "/" + nazev + "/uvod")
                    }
                  >
                    Úvod
                  </Tab>
                  <Tab
                    onClick={() =>
                      (window.location =
                        "#/produkty/" + partnerId + "/" + nazev + "/produkty")
                    }
                  >
                    {lang.produkty}
                  </Tab>
                  {partner && partner.akce && partner.akce.length ? (
                    <Tab
                      onClick={() =>
                        (window.location =
                          "#/produkty/" +
                          partnerId +
                          "/" +
                          nazev +
                          "/kalendar-akci")
                      }
                    >
                      {lang.kalendar_akci}
                    </Tab>
                  ) : null}
                  <Tab
                    onClick={() =>
                      (window.location =
                        "#/produkty/" + partnerId + "/" + nazev + "/dokumenty")
                    }
                  >
                    {lang.dokumenty}
                  </Tab>
                  <Tab
                    onClick={() =>
                      (window.location =
                        "#/produkty/" + partnerId + "/" + nazev + "/kontakt")
                    }
                  >
                    {lang.kontakt}
                  </Tab>
                  {partner && partner.samoobsluha && (
                    <Tab
                      onClick={() =>
                        (window.location =
                          "#/produkty/" +
                          partnerId +
                          "/" +
                          nazev +
                          "/samoobsluha")
                      }
                    >
                      Samoobsluha
                    </Tab>
                  )}
                  <Tab
                      onClick={() =>
                          (window.location =
                              "#/produkty/" + partnerId + "/" + nazev + "/vyprodej")
                      }
                  >
                    {lang.vyprodej}
                  </Tab>
                  {partner && partner.faq && (
                    <Tab
                        onClick={() =>
                            (window.location =
                                "#/produkty/" + partnerId + "/" + nazev + "/faq")
                        }
                    >
                      {lang.faq}
                    </Tab>
                  )}
                </TabList>
                <TabPanel>
                  {partner && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: partner.popis,
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {tab === "produkty" && (
                    <ProduktyProduktu
                      partnerId={partnerId}
                      key={partnerId}
                      baseURL={
                        "#/produkty/" + partnerId + "/" + nazev + "/produkty"
                      }
                      produkt_id={dir_id}
                    />
                  )}
                </TabPanel>
                {partner && partner.akce && partner.akce.length ? (
                  <TabPanel>
                    {partner.akce.map((a) => (
                      <AkceNahled akceId={a} key={a} />
                    ))}
                  </TabPanel>
                ) : null}
                <TabPanel>
                  {tab === "dokumenty" && (
                    <ProduktyDokumenty
                      partnerId={partnerId}
                      key={partnerId}
                      baseURL={
                        "#/produkty/" + partnerId + "/" + nazev + "/dokumenty"
                      }
                      dir_id={dir_id}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {partner && partner.garanti ? (
                    <React.Fragment>
                      <h3 className="light upper center spacing-5 padding-vertical-20">
                        {lang.obchodni_kontakt}
                      </h3>
                      <div className="row" style={{ width: "100%" }}>
                        {partner.garanti.map((g) => (
                          <div
                            className="col-md-6 obchodniPripad"
                            key={g.email}
                          >
                            <Card
                              nadpis={g.jmeno + " " + g.prijmeni}
                              image={g.fotka}
                              imageCyrcle={true}
                            >
                              <small style={{ padding: "0px 0px 20px 0px" }}>
                                {g.pozice}
                              </small>
                              <ul>
                                <li>
                                  <span
                                    className="icon icon-send margin-10 margin-right-20"
                                    style={{ color: "#aaa" }}
                                  />
                                  <a href={"mailto:" + g.email}>{g.email}</a>
                                </li>
                                {g.mobil ? (
                                  <li>
                                    <span
                                      className="icon icon-telephone margin-10 margin-right-20"
                                      style={{ color: "#aaa" }}
                                    />
                                    {g.mobil}
                                  </li>
                                ) : null}
                                <li>
                                  <span
                                    className="fa fa-home margin-10 margin-right-20"
                                    style={{ color: "#aaa" }}
                                  />
                                  {g.kancelar}
                                </li>
                              </ul>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ) : null}
                  {partner && partner.presales ? (
                    <React.Fragment>
                      <h3 className="light upper center spacing-5 padding-vertical-20">
                        {lang.presales_kontakt}
                      </h3>
                      <div className="row" style={{ width: "100%" }}>
                        {partner.presales.map((g) => (
                          <div
                            className="col-md-6 obchodniPripad"
                            key={g.email}
                          >
                            <Card
                              nadpis={g.jmeno + " " + g.prijmeni}
                              image={g.fotka}
                              imageCyrcle={true}
                            >
                              <ul>
                                <li>
                                  <span
                                    className="icon icon-send margin-10 margin-right-20"
                                    style={{ color: "#aaa" }}
                                  />
                                  <a href={"mailto:" + g.email}>{g.email}</a>
                                </li>
                                {g.mobil ? (
                                  <li>
                                    <span
                                      className="icon icon-telephone margin-10 margin-right-20"
                                      style={{ color: "#aaa" }}
                                    />
                                    {g.mobil}
                                  </li>
                                ) : null}
                                <li>
                                  <span
                                    className="fa fa-home margin-10 margin-right-20"
                                    style={{ color: "#aaa" }}
                                  />
                                  {g.kancelar}
                                </li>
                              </ul>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ) : null}
                </TabPanel>
                {partner && partner.samoobsluha && (
                  <TabPanel>
                    <SamoobsluhaNabidka
                      partner_id={partnerId}
                      baseURL={
                        "#/produkty/" + partnerId + "/" + nazev + "/samoobsluha"
                      }
                      dir_id={dir_id}
                    />
                  </TabPanel>
                )}
                <TabPanel>
                  <VyprodejNabidka partnerId={partnerId} key={partnerId} />
                </TabPanel>
                {partner && partner.faq && (
                  <TabPanel>
                    <Faq partnerId={partnerId}
                         kategorie_nazev={nazev2} item_id={item_id} item_nazev={item_nazev}
                         baseURL={
                           "#/produkty/" + partnerId + "/" + nazev + "/faq"
                         }
                         kategorie_id={dir_id}/>
                  </TabPanel>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProduktDetail;
