import React, {Component} from "react";
import NadpisStranky from "../common/nadpis";
import {Link, NavLink} from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import {loadVeracoin} from "../../services/veracoinService";
import BonusProduktyItem from "./BonusProduktyItem";
import moment from "moment";

class BonusDetailObjednavka extends Component {
    render() {
        const lang = authService.getLang();
        const {objednavka} = this.props;

        const stav = {
            "-2": {"color": "red", "text": "Storno"},
            "-1": {"color": "red", "text": "Zamítnuto"},
            "0": {"color": "orange", "text": "Přijatá ke zpracování"},
            "1": {"color": "orange", "text": "Zpracovává se"},
            "2": {"color": "green", "text": "Odesláno/Ukončeno"},
        };

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-4 col-md-offset-1 infotab">
                        <ul>
                            <li>
                                <span>Číslo objednávky</span>
                                <span className="value">{objednavka.ext_id}</span>
                            </li>
                            <li>
                                <span>Datum</span>
                                <span className="value">
                                    {moment(objednavka.datum).format("D. M. Y H:mm")}
                                </span>
                            </li>
                            <li>
                                <span>Stav</span>
                                <span className="value"
                                      style={{color: stav[objednavka.stav].color}}>{stav[objednavka.stav].text}</span>
                            </li>
                            <li>
                                <span>Jméno</span>
                                <span className="value">{objednavka.jmeno} {objednavka.prijmeni}</span>
                            </li>
                            <li>
                                <span>Firma</span>
                                <span className="value">{objednavka.firma}</span>
                            </li>
                            {objednavka.budova != "" && (
                                <li>
                                    <span>Budova</span>
                                    <span className="value">{objednavka.budova}</span>
                                </li>
                            )}
                            <li>
                                <span>Ulice</span>
                                <span className="value">{objednavka.ulice}</span>
                            </li>
                            <li>
                                <span>Město</span>
                                <span className="value">{objednavka.mesto}</span>
                            </li>
                            <li>
                                <span>PSČ</span>
                                <span className="value">{objednavka.zeme_trh}-{objednavka.psc}</span>
                            </li>
                            <li>
                                <span>Telefon</span>
                                <span className="value">{objednavka.telefon}</span>
                            </li>
                            <li>
                                <span>E-mail</span>
                                <span className="value">{objednavka.email1}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <div className="payment-detail-wrapper">
                            <ul className="cart-list">
                                {objednavka.polozky && objednavka.polozky.length > 0 && objednavka.polozky.map((produkt, p_key) => (

                                    <li>
                                        <div className="cart-item">
                                            <div className="product-image">
                                                <NavLink to={"/bonus/produkt/" + produkt.bp_produkt_id} title="">
                                                    {produkt.obrazek_url && (JSON.parse(produkt.obrazek_url) && JSON.parse(produkt.obrazek_url).length > 0) ? JSON.parse(produkt.obrazek_url).map((obrazek, obrazek_key) => (
                                                        <img src={obrazek} alt="" key={obrazek_key}
                                                             className={obrazek_key === 0 ? "current" : ""}
                                                             style={{maxWidth: "70px"}}/>
                                                    )) : (
                                                        <img
                                                            src="./img/samples/products/grid/1.jpg"
                                                            alt="" className="current" style={{maxWidth: "70px"}}/>
                                                    )}
                                                </NavLink>
                                            </div>

                                            <div className="product-body">
                                                <div className="product-name">
                                                    <h3>
                                                        <NavLink to={"/bonus/produkt/" + produkt.bp_produkt_id}
                                                                 title="">
                                                            {produkt.nazev}
                                                        </NavLink>
                                                    </h3>
                                                </div>

                                                <div className="">
                                                    Množství: {produkt.pocet}
                                                </div>

                                                <div className="product-price">
                                                    <span>v&cent; {produkt.cena_vc_ks * produkt.pocet} celkem</span>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default BonusDetailObjednavka;
