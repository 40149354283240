import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { Link } from "react-router-dom";
import LoadMusic from "./../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import Pagination from "../common/Pagination";
import { BrowserView, MobileView } from "react-device-detect";
import PotvrditDeal from "./PotvrditDeal";

class Dealy extends Component {
  state = {
    dealy: [],
    aktStranka: 1,
    pocetStranek: 1,
    limitStranka: 10,
    dealyLoaded: false,
  };

  componentDidMount() {
    this.nahratDealy();
  }

  nahratDealy = async (hledat_deal_id) => {
    const aktualniFirma = authService.aktualniFirma();
    if (aktualniFirma.partnerweb_obchod) {
      this.setState({ dealyLoaded: false });
      const dealy = await httpService.post(
        config.apiEndpoint + "dealy/prehledDealu.php",
        { firma_id: aktualniFirma.firma_id, deal_id: hledat_deal_id }
      );
      const pocetStranek = Math.ceil(
        dealy.data ? dealy.data.length / this.state.limitStranka : 0
      );

      this.setState({
        aktualniFirma,
        dealy: dealy.data,
        dealyLoaded: true,
        aktStranka: 1,
        pocetStranek,
      });
    }
  };

  zmenStranku = (aktStranka) => {
    this.setState({ aktStranka });
  };

  potvrdit = (stav) => {
    let { dealy, potvrditDeal } = this.state;
    let stavColor = "warning";
    let stav_popis = "Čeká na potvrzení";
    switch (stav) {
      case -1:
        stavColor = "danger";
        stav_popis = "Zamítnut";
        break;
      case 1:
        stavColor = "success";
        stav_popis = "Potvrzeno";
        break;
    }
    const idx = dealy.findIndex((deal) => deal.deal_id == potvrditDeal);
    if (idx >= 0) {
      let dealyNew = [...dealy];
      dealyNew[idx].stav = stav;
      dealyNew[idx].stavColor = stavColor;
      dealyNew[idx].stav_popis = stav_popis;
      this.setState({ dealy: dealyNew, potvrdit: false });
    }
  };

  hledat = (hledat_deal_id) => {
    this.nahratDealy(hledat_deal_id);
  };

  render() {
    const lang = authService.getLang();
    const { potvrdit, potvrditDeal } = this.state;
    if (
      this.state.aktualniFirma &&
      !this.state.aktualniFirma.partnerweb_obchod
    ) {
      return <div className="alert alert-danger">Nemáte přístup k dealům</div>;
    }
    return (
      <React.Fragment>
        <PotvrditDeal
          show={potvrdit}
          title="Potvrdit deal"
          onClose={() => this.setState({ potvrdit: false })}
          dealId={potvrditDeal}
          onSubmit={this.potvrdit}
        />
        <NadpisStranky
          nadpis={lang.registrace_dealu}
          obrazek="./images/deal.jpg"
        />
        <div className="container">
          <div className="center padding-top-20">
            <Link to="/registrace-dealu/novy-deal" className="btn btn-primary">
              {lang.novy_deal}
            </Link>
          </div>
          <hr />
          <input
            type="text"
            placeholder="Hledat dealy podle ID, části názvu projektu, klienta..."
            className="form-control margin-bottom-20"
            onBlur={(input) => this.hledat(input.target.value)}
          />
          {this.state.dealyLoaded ? (
            <React.Fragment>
              {this.state.dealy ? (
                <React.Fragment>
                  <MobileView>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{lang.nazev_projektu}</th>
                          <th>{lang.zakaznik}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dealy &&
                          this.state.dealy.map((deal, index) =>
                            index >=
                              (this.state.aktStranka - 1) *
                                this.state.limitStranka &&
                            index <
                              this.state.aktStranka *
                                this.state.limitStranka ? (
                              <tr key={deal.deal_id}>
                                <td className="right">
                                  <Link
                                    to={"/registrace-dealu/" + deal.deal_id}
                                  >
                                    <strong>{deal.deal_id}</strong>
                                  </Link>
                                </td>
                                <td>
                                  {deal.nazev_projektu}
                                  <span
                                    className={"badge badge-" + deal.stavColor}
                                  >
                                    {deal.stav_popis}
                                  </span>
                                  {parseInt(deal.stav) === 0 &&
                                  parseInt(deal.smim_potvrdit) === 1 ? (
                                    <div>
                                      <button
                                        style={{ marginTop: "5px" }}
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.setState({
                                            potvrdit: true,
                                            potvrditDeal: deal.deal_id,
                                          })
                                        }
                                      >
                                        Potvrdit
                                      </button>
                                    </div>
                                  ) : null}
                                </td>
                                <td className="center">
                                  {deal.zakaznik_firma_nazev}
                                </td>
                              </tr>
                            ) : null
                          )}
                      </tbody>
                    </table>
                  </MobileView>
                  <BrowserView>
                    <table>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>{lang.nazev_projektu}</th>
                          <th>
                            {lang.partner}/{lang.zakaznik}
                          </th>
                          <th>{lang.registroval}</th>
                          <th>{lang.datum_registrace}</th>
                          <th>{lang.stav}</th>
                          <th>{lang.vyrobce}</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.dealy &&
                          this.state.dealy.map((deal, index) =>
                            index >=
                              (this.state.aktStranka - 1) *
                                this.state.limitStranka &&
                            index <
                              this.state.aktStranka *
                                this.state.limitStranka ? (
                              <tr key={deal.deal_id}>
                                <td className="right">
                                  <Link
                                    to={"/registrace-dealu/" + deal.deal_id}
                                  >
                                    <strong>{deal.deal_id}</strong>
                                  </Link>
                                </td>
                                <td>{deal.nazev_projektu}</td>
                                <td className="center">
                                  {deal.smim_potvrdit ? (
                                    <div style={{ color: "gray" }}>
                                      {deal.partner_firma_nazev}
                                    </div>
                                  ) : null}
                                  {deal.zakaznik_firma_nazev}
                                </td>
                                <td className="center">{deal.registroval}</td>
                                <td className="center">
                                  {deal.registroval_datum}
                                </td>
                                <td className="center">
                                  <span
                                    className={"badge badge-" + deal.stavColor}
                                  >
                                    {deal.stav_popis}
                                  </span>
                                  {parseInt(deal.stav) === 0 &&
                                  parseInt(deal.smim_potvrdit) === 1 ? (
                                    <div>
                                      <button
                                        style={{ marginTop: "5px" }}
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.setState({
                                            potvrdit: true,
                                            potvrditDeal: deal.deal_id,
                                          })
                                        }
                                      >
                                        Potvrdit
                                      </button>
                                    </div>
                                  ) : null}
                                </td>
                                <td className="center">
                                  {deal.brandy &&
                                    deal.brandy.map((b) => (
                                      <img
                                        key={b}
                                        src={
                                          "https://api.exclusive-networks.cz/images/logobrand.php?partner_id=" +
                                          b
                                        }
                                        alt=""
                                        style={{
                                          width: "42px",
                                          borderRadius: "50%",
                                          margin: "5px",
                                        }}
                                      />
                                    ))}
                                </td>
                                <td className="center">
                                  <Link
                                    to={"/registrace-dealu/" + deal.deal_id}
                                    className="btn btn-primary btn-sm"
                                  >
                                    {lang.detail}
                                  </Link>
                                </td>
                              </tr>
                            ) : null
                          )}
                      </tbody>
                    </table>
                  </BrowserView>
                  <Pagination
                    aktStranka={this.state.aktStranka}
                    pocetStranek={this.state.pocetStranek}
                    onSelect={this.zmenStranku}
                  />
                  <div className="text-center padding-10">
                    <a
                      href={
                        "https://api.exclusive-networks.cz/pw/dealy/exportXLSX.php?firma_id=" +
                        this.state.aktualniFirma.firma_id +
                        "&auth_token=" +
                        localStorage.getItem("token")
                      }
                      className="btn btn-primary"
                    >
                      Export všech dealů v XLSX
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <div className="alert alert-danger">{lang.bez_dealu}</div>
              )}
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Dealy;
