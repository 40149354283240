import React, { Component } from "react";
import Card from "../common/Card";
import NumberFormat from "react-number-format";
import { DropdownButton, MenuItem } from "react-bootstrap";
import authService from "../../services/authService";

class VyprodejPolozka extends Component {
  state = {};
  render() {
    const { polozka } = this.props;
    const lang = authService.getLang();
    return (
      <Card
        nadpis={polozka.info.typ + " " + polozka.info.vyrobek}
        style={{ fontSize: "0.8em" }}
        imageHeight="250px"
        image={
          "//api.exclusive-networks.cz/images/logoprodukt.php?prilezitost_ciselnik_id=" +
          polozka.info.prilezitost_ciselnik_id
        }
      >
        {(polozka.uspora && polozka.uspora != "" && polozka.uspora != "0") ? (
            <div
                className="badge badge-success"
                style={{
                  fontSize: "1.2em",
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                }}
            >
              {lang.sleva} {polozka.uspora}%
            </div>
        ):""}
        <p>{lang.skladem} {polozka.polozky.length} ks</p>
        <h3 className="center">
          CZK{" "}
          <NumberFormat
            value={polozka.min_cena}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={0}
            displayType="text"
          />
        </h3>
        <div className="center padding-10">
          <button
            onClick={() => this.props.onDetailShow(polozka)}
            className="btn btn-primary"
          >
            Detaily
          </button>{" "}
          <DropdownButton
            title={<span className="icon icon-shopping-bag" />}
            key={polozka.katalog_id}
            id={polozka.katalog_id}
            className="btn btn-primary btn-outline"
          >
            {polozka.polozky.map((p, index) =>
              !p.rezervace ? (
                <MenuItem
                  key={index}
                  style={{ whiteSpace: "nowrap", fontSize: "0.8em" }}
                  alt="Do košíku toto SN"
                  onClick={() => this.props.doKosiku(polozka, p.sn)}
                >
                  {p.sn} (CZK{" "}
                  <NumberFormat
                    value={p.cena}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={0}
                    displayType="text"
                  />
                  )
                </MenuItem>
              ) : (
                <MenuItem
                  key={index}
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "0.8em",
                  }}
                >
                  {p.sn} - {lang.rezervovano}
                </MenuItem>
              )
            )}
          </DropdownButton>
        </div>
      </Card>
    );
  }
}

export default VyprodejPolozka;
