import React, { Component } from "react";
import config from "../../config.json";
import LoadMusic from "../common/LoadMusic";
import httpService from "../../services/httpService";
import authService from "../../services/authService";
import AkceNahled from "../kalendar/AkceNahled";

class MojePrihlasky extends Component {
  state = {};
  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    const prihlasky = await httpService.post(
      config.apiEndpoint + "profil/mojePrihlasky.php",
      { firma_id: aktualniFirma.firma_id }
    );
    this.setState({ prihlaskyLoaded: true, prihlasky: prihlasky.data });
  }
  render() {
    const { prihlasky, prihlaskyLoaded } = this.state;
    console.log(prihlasky);
    return (
      <React.Fragment>
        {prihlaskyLoaded ? (
          prihlasky ? (
            <div className="padding-top-20 row" style={{ width: "100%" }}>
              {prihlasky.map(p => (
                <div className="col-md-6" key={p}>
                  <AkceNahled akceId={p} />
                </div>
              ))}
            </div>
          ) : (
            <div className="alert alert-danger alert-outline margin-top-20">
              Nevydujeme zatím žádné přihlášky.
            </div>
          )
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default MojePrihlasky;
