import React, { Component } from "react";
import FloatingLabelInput from "floating-label-react";
import "../../css/loginForm.css";
import config from "../../config.json";
import httpService from "../../services/httpService";
import { toast } from "react-toastify";
import authService from "../../services/authService";
import Header from "../header";

class ObnovaHesla extends Component {
  state = { password: "", password2: "" };

  zmenaHesla = async () => {
    const { password, password2 } = this.state;
    const hash = this.props.match.params.id;
    if (password && password !== "" && password === password2) {
      const zmena = await httpService.post(
        config.apiEndpoint + "profil/zmenaHeslaUlozit.php",
        {
          hash,
          password,
        }
      );
      if (zmena && zmena.data && zmena.data.status === "OK") {
        toast.success("Heslo bylo změněno");
        window.location = "#/";
      } else {
        toast.error("Chyba s uložením hesla");
      }
    }
  };

  overHeslo = () => {
    const { password, password2 } = this.state;
    const lang = authService.getLang();
    var passwordValidator = require("password-validator");
    var schema = new passwordValidator();
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .symbols() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]);
    const errs = schema.validate(password, { list: true });
    let chyby = [];
    for (let i = 0; i < errs.length; i++) {
      switch (errs[i]) {
        case "min":
          chyby.push(lang.pass_min);
          break;
        case "max":
          chyby.push(lang.pass_max);
          break;
        case "uppercase":
          chyby.push(lang.pass_uppercase);
          break;
        case "lowercase":
          chyby.push(lang.pass_lowercase);
          break;
        case "digits":
          chyby.push(lang.pass_digits);
          break;
        case "symbols":
          chyby.push(lang.pass_symbols);
          break;
        case "spaces":
          chyby.push(lang.pass_spaces);
          break;
      }
    }
    if (password != password2) {
      chyby.push(lang.pass_match);
    }
    if (chyby.length) {
      return (
        <div className="alert alert-danger alert-outline margin-top-20">
          <ul>
            {chyby.map((p, index) => (
              <li key={index}>{p}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <button
          className="btn btn-primary btn-block"
          onClick={() => this.zmenaHesla()}
        >
          Změnit heslo
        </button>
      );
    }
  };

  render() {
    const { password, password2 } = this.state;
    const lang = authService.getLangByUrl();
    return (
      <div className="loginContainer">
        <div className="form-signin">
          <form action="javascript:void();" onSubmit={this.handleSubmit}>
            <div style={{ textAlign: "center" }}>
              <img className="mb-4" src="images/logo-exn.png" alt=""
                   style={{ width: "60%" }} />
            </div>
            <FloatingLabelInput
              id="login"
              placeholder="Nové heslo"
              name="password"
              type="password"
              className="inputFullWidth"
              value={password}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
            />
            <FloatingLabelInput
              id="password"
              placeholder="Zopakovat heslo"
              type="password"
              name="password2"
              value={password2}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
            />
            {this.overHeslo()}
            <p className="mt-5 mb-3 text-muted">&copy; {lang.exlusive_network_czechia}</p>
          </form>
        </div>
      </div>
    );
  }
}

export default ObnovaHesla;
