import React, { Component } from "react";
import httpService from "../../services/httpService";
import config from "../../config.json";
import authService from "../../services/authService";

class FaqHledat extends Component {
  state = {};

  timer = false;

  hledatLazy = (srch) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => this.hledat(srch), 500);
  };

  hledat = async (srch) => {
    const aktualniFirma = authService.aktualniFirma();
    const { partner_id, baseURL } = this.props;

    if (srch && srch !== "") {
      const results = await httpService.post(config.apiEndpoint + "faq/hledat.php", {
        hledat: srch,
        partner_id,
        firma_id: aktualniFirma.firma_id,
        baseURL
      });
      this.setState({ faqNazev: srch, results: results.data && results.data.faq, hide: false });
    }
  };

  render() {
    const { results, hide } = this.state;
    const lang = authService.getLang();

    return (
      <div>
        <div className="form-material btn-group" style={{ width: "100%" }}>
          <input
            type="text"
            className="form-control"
            placeholder={lang.hledatOtazku}
            onChange={(e) => this.hledatLazy(e.target.value)}
            value={this.state.firmaNazev}
            onBlur={() => setTimeout(() => this.setState({ hide: true }), 500)}
            style={this.props.style}
          />
          {results && !hide && (
            <div className="dropdown-menu scroll1" style={{ display: "block", maxHeight: "300px" }}>
              {results.length > 0 ? (
                results.map((r, index) => (
                  <a
                    href={r.link}
                    className="dropdown-item"
                    key={index + "_" + r.partner_faq_id}
                  >
                    {r.otazka}
                  </a>
                ))
              ) : (
                <div className="dropdown-item">
                  {lang.nicTuNeni}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FaqHledat;
