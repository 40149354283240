import React, { Component } from "react";
import MainApp from "./MainApp";
import LoginForm from "./components/loginForm";
import { ToastContainer } from "react-toastify";
import auth from "./services/authService";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./css/main.css";
import "./css/awe-icon.css";
import "./css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Switch, Route } from "react-router-dom";
import ObnovaHesla from "./components/profil/ObnovaHesla";
import Registrace from "./components/profil/Registrace";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        {!auth.getCurrentUser() && (
          <Switch>
            <Route path="/password-recovery/:id" component={ObnovaHesla} />
            <Route path="/password-recovery/" component={LoginForm} />
            <Route path="/registrace/:id" component={Registrace} />
            <Route path="/registrace" component={Registrace} />
            <Route path="/:cokoliv" component={LoginForm} />
            <Route path="/" exact component={LoginForm} />
          </Switch>
        )}
        {auth.getCurrentUser() && <MainApp />}
      </React.Fragment>
    );
  }
}

export default App;
