import React, { Component } from "react";
import { isMobile } from "react-device-detect";

class Card extends Component {
  state = {};
  render() {
    return (
      <div
        className={
          "card margin-bottom-30" +
          (this.props.imageCyrcle
            ? isMobile
              ? " margin-top-60"
              : " margin-top-75"
            : "")
        }
      >
        {this.props.image ? (
          this.props.imageCyrcle ? (
            <div
              style={{
                width: isMobile ? "100px" : "150px",
                height: isMobile ? "100px" : "150px",
                overflow: "hidden",
                borderRadius: "50%",
                margin: isMobile ? "-50px auto 0 auto" : "-75px auto 0 auto",
                border: "solid 1px #ddd",
                backgroundColor: "#fff"
              }}
            >
              <img src={this.props.image} alt="" />
            </div>
          ) : (
            <div
              style={{
                height: this.props.imageHeight
                  ? this.props.imageHeight
                  : "100px",
                overflow: "hidden",
                position: "relative",
                backgroundImage: "url('" + this.props.image + "')",
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            >
              &nbsp;
            </div>
          )
        ) : null}
        <div className="card-body">
          <h5 className="card-title" style={this.props.style}>
            {this.props.nadpis}
          </h5>
          <div className="card-text" style={this.props.style}>
            {this.props.obsah}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
