import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { connect } from "react-redux";
import { vlozitDoKosiku } from "./../../actions/vyprodejSkladu";
import { vlozitDoKosikuSamoobsluha } from "./../../actions/kosikSamoobsluha";
import authService from "../../services/authService";
import NumberFormat from "react-number-format";
import LoadMusic from "../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import Modal from "../Modal";
import { BrowserView, MobileView } from "react-device-detect";
import Card from "../common/Card";
import FloatingLabelInput from "floating-label-react";
import { toast } from "react-toastify";

class VyprodejKosik extends Component {
  state = { akt_pravdepodobnost: "95" };

  pocetPolozek = () => {
    const user = authService.getCurrentUser();
    const { obsahKosiku, obsahKosiku2 } = this.props;
    let pocet = 0;
    let celkemCena = { CZK: 0, EUR: 0, USD: 0 };
    if (obsahKosiku) {
      for (let i = 0; i < obsahKosiku.length; i++) {
        for (let j = 0; j < obsahKosiku[i].polozky.length; j++) {
          if (
            obsahKosiku[i].polozky[j].rezervace &&
            obsahKosiku[i].polozky[j].login === user.login
          ) {
            pocet++;
            celkemCena.CZK += obsahKosiku[i].polozky[j].cena;
          }
        }
      }
    }
    if (obsahKosiku2) {
      for (let i = 0; i < obsahKosiku2.length; i++) {
        celkemCena[obsahKosiku2[i].produkt.cena.prodej_mena] +=
          obsahKosiku2[i].cenaCelkem * obsahKosiku2[i].pocet_ks;
      }
      pocet += obsahKosiku2 ? obsahKosiku2.length : 0;
    }
    return { pocet, celkemCena };
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    this.setState({
      aktualniFirma,
      /*zakaznik_id: aktualniFirma.firma_id*/
    });
    const vyrobci = await httpService.post(
      config.apiEndpoint + "dealy/vyrobci-1.0.php",
      {
        firma_id: aktualniFirma.firma_id,
        agenda_id: aktualniFirma.agenda_id,
      }
    );
    const firmy = await httpService.post(
      config.apiEndpoint + "zapujcky/firmy.php",
      {
        firma_id: aktualniFirma.firma_id,
      }
    );

    this.setState({
      firmy: firmy.data,
      firmyLoaded: true,
      vyrobci: vyrobci.data,
      vyrobciLoaded: true,
    });
    const index = this.state.firmy.findIndex(
      (f) =>
        this.state.aktualniFirma &&
        f.firma_id === this.state.aktualniFirma.firma_id
    );
    if (index >= 0) {
      const osoba_id =
        this.state.firmy &&
        this.state.firmy.length &&
        this.state.firmy[index].osoby &&
        this.state.firmy[index].osoby.length &&
        this.state.firmy[index].osoby[0].osoba_id;
      this.nastavOsobu(osoba_id);
    }

    this.generujNazev();
    const limity = await httpService.post(
      config.apiEndpoint + "obchodniPripady/brandLimity.php"
    );
    this.setState({ limity: limity.data });
    this.overLimit();
  }

  overLimit = () => {
    const { limity, akt_pravdepodobnost } = this.state;
    const { obsahKosiku2 } = this.props;

    let i = 0;
    let limit = 0;
    let presLimit = false;

    console.log("limit 1");

    if (limity && limity.length > 0) {
      console.log("limit 2", limity);
      for (i = 0; i < limity.length; i++) {
        limit = 0;
        obsahKosiku2 &&
          obsahKosiku2.length > 0 &&
          obsahKosiku2.map((o) => {
            if (o.produkt.partner_id === limity[i].partner_id) {
              console.log("limit 3", o);
              limit += o.cenaCelkem * o.pocet_ks;
            }
          });
        console.log("limit 4", limit);
        if (limit > parseInt(limity[i].samoobsluha_limit)) {
          console.log("limit 5", "over limit");
          presLimit = true;
        }
      }
    }
    this.setState({
      presLimit,
      akt_pravdepodobnost:
        akt_pravdepodobnost === "95" && presLimit ? "25" : akt_pravdepodobnost,
    });
  };

  selectFirmy = () => {
    const lang = authService.getLang();
    if (
      this.state.firmyLoaded &&
      !(this.state.firmy && this.state.firmy.length)
    ) {
      return this.firmaForm();
    }
    return (
      <React.Fragment>
        <select
          name="zakaznik_firma_id"
          className="form-control"
          onChange={this.nastavFirmu}
          defaultValue={this.state.zakaznik_id}
        >
          {!this.state.zakaznik_id && <option value="">{lang.zvolte_firmu}...</option>}
          {this.state.firmy &&
            this.state.firmy.map((f) => (
              <option value={f.firma_id} key={f.firma_id}>
                {f.nazev}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 border-bottom margin-bottom-10">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaFirma: true })}
          >
            {lang.nova_firma}
          </button>
        </div>
      </React.Fragment>
    );
  };

  osobaForm = () => {
    const lang = authService.getLang();
    return (
      <div>
        <h5>Nová kontaktní osoba</h5>
        <FloatingLabelInput
          id="jmeno"
          placeholder={lang.jmeno}
          name="jmeno"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="prijmeni"
          placeholder={lang.prijmeni}
          name="prijmeni"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="pozice"
          placeholder={lang.pozice_ve_firme}
          name="pozice"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="mobil"
          placeholder={lang.mobil}
          name="mobil"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="email1"
          placeholder={lang.email}
          name="email1"
          type="text"
          onChange={this.handleChange}
        />
        <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
          <button
            className="btn btn-primary"
            onClick={() => this.ulozOsobu()}
            type="button"
          >
            {lang.ulozit_novou_osobu}
          </button>
        </div>
      </div>
    );
  };

  firmaForm = () => {
    const lang = authService.getLang();
    return (
      <div>
        <h5>{lang.nova_firma}</h5>
        <FloatingLabelInput
          id="ico"
          placeholder={lang.ic}
          name="ico"
          type="text"
          onChange={this.handleChange}
          onBlur={this.hledejFirmu}
        />
        {this.state.hledamIco ? (
          <LoadMusic />
        ) : (
          <React.Fragment>
            <FloatingLabelInput
              id="dic"
              placeholder={lang.dic}
              name="dic"
              type="text"
              value={this.state.dic}
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="nazev"
              placeholder={lang.nazev_spolecnosti}
              name="nazev"
              value={this.state.nazev}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="ulice"
              placeholder={lang.ulice}
              name="ulice"
              value={this.state.ulice}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="mesto"
              placeholder={lang.mesto}
              name="mesto"
              value={this.state.mesto}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="psc"
              placeholder={lang.psc}
              name="psc"
              value={this.state.psc}
              type="text"
              onChange={this.handleChange}
            />
            <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.ulozFirmu()}
              >
                {lang.ulozit_firmu}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  ulozFirmu = async () => {
    const { ico, dic, ulice, mesto, psc, nazev } = this.state;
    const lang = authService.getLang();

    if (ico && dic && ulice && mesto && psc && nazev) {
      this.setState({ hledamIco: true });
      const firma = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitFirmu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          ico,
          dic,
          ulice,
          mesto,
          psc,
          nazev,
        }
      );
      if (firma.data.firma_id) {
        const firmy = [...this.state.firmy];
        firmy.push(firma.data);
        this.setState({
          firmy,
          novaFirma: false,
          zakaznik_id: firma.data.firma_id,
        });
      }
      this.setState({ hledamIco: false });
    } else {
      toast.error(lang.pro_ulozeni_firma_musite_atd);
    }
  };

  /*handleChange = (currentTarget) => {
    const attr = currentTarget.target.name;
    const val =
      attr === "souhlas"
        ? currentTarget.target.checked
        : currentTarget.target.value;
    const popisLocked = attr === "popis" ? true : this.state.popisLocked;
    console.log("popisLocked", popisLocked);
    this.setState({ [attr]: val, popisLocked });
  };*/

  hledejFirmu = async (currentTarget) => {
    this.setState({ hledamIco: true });
    const ico = currentTarget.target.value;
    const firma = await httpService.post(
      config.apiEndpoint + "zapujcky/hledatFirmu.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        ico,
      }
    );
    if (firma.data.firma_id) {
      const firmy = [...this.state.firmy];
      firmy.push(firma.data);
      this.setState({
        firmy,
        novaFirma: false,
        zakaznik_id: firma.data.firma_id,
      });
    } else {
      console.log("FIRMA", firma);
      if (firma.data.ico && firma.data.ico !== "") {
        const { ico, dic, nazev, ulice, mesto, psc } = firma.data;
        this.setState({ ico, dic, nazev, ulice, mesto, psc });
      }
    }
    this.setState({ hledamIco: false });
  };

  ulozOsobu = async () => {
    const lang = authService.getLang();
    const {
      jmeno,
      prijmeni,
      mobil,
      email1,
      zakaznik_id,
      firmy,
      pozice,
    } = this.state;
    if (jmeno && prijmeni) {
      const osoba = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitOsobu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          jmeno,
          prijmeni,
          mobil,
          email1,
          pozice,
          zakaznik_id,
        }
      );
      if (osoba.data.osoba_id) {
        const index = firmy.findIndex((f) => f.firma_id === zakaznik_id);
        if (!firmy[index].osoby) {
          firmy[index].osoby = [];
        }
        firmy[index].osoby.push(osoba.data);
        this.setState({
          firmy,
          novaOsoba: false,
          osoba_id: osoba.data.osoba_id,
        });
      }
    } else {
      toast.error(lang.jmeno_a_prijmeni_jsou_povinne_udaje);
    }
  };

  nahrajPR = async (zakaznik_firma_id) => {
    const pr = await httpService.post(
      config.apiEndpoint + "obchodniPripady/podobnePR.php",
      { firma_id: this.state.aktualniFirma.firma_id, zakaznik_firma_id }
    );
    this.setState({ pr: pr.data });
  };

  nastavFirmu = async (currentTarget) => {
    if (this.state.zakaznik_id !== currentTarget.target.value) {
      this.nahrajPR(currentTarget.target.value);
      await this.setState({
        zakaznik_id: currentTarget.target.value,
        novaOsoba: false,
      });
    }
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (index >= 0) {
      const osoba_id =
        this.state.firmy[index].osoby &&
        this.state.firmy[index].osoby.length &&
        this.state.firmy[index].osoby[0].osoba_id;
      this.nastavOsobu(osoba_id);
    }
    this.generujNazev();
  };

  nastavOsobu = (osoba_id) => {
    if (osoba_id && osoba_id !== this.state.osoba_id) {
      this.setState({ osoba_id });
    }
  };

  selectOsoby = () => {
    const lang = authService.getLang();
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (index === -1) {
      //return <React.Fragment>osoby none...</React.Fragment>;
      return null;
    }
    if (
      this.state.firmy &&
      this.state.firmy.length &&
      !(this.state.firmy[index].osoby && this.state.firmy[index].osoby.length)
    ) {
      return this.osobaForm();
    }
    return (
      <React.Fragment>
        <select
          name="osoba_id"
          className="form-control"
          onChange={this.nastavOsobu}
          defaultValue={this.state.osoba_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].osoby.map((o) => (
              <option value={o.osoba_id} key={o.osoba_id}>
                {o.jmeno} {o.prijmeni}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 margin-bottom-10">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaOsoba: true })}
          >
            {lang.nova_osoba}
          </button>
        </div>
      </React.Fragment>
    );
  };

  selectOsobyKontakt = () => {
    const lang = authService.getLang();
    const index = this.state.firmy.findIndex(
      (f) =>
        this.state.aktualniFirma &&
        f.firma_id === this.state.aktualniFirma.firma_id
    );
    if (index === -1) {
      return <React.Fragment>osoby2 none...</React.Fragment>;
    }
    return (
      <React.Fragment>
        <select
          name="partner_osoba_id"
          className="form-control"
          onChange={this.nastavOsobu}
          defaultValue={this.state.aktualniFirma.osoba_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].osoby.map((o) => (
              <option value={o.osoba_id} key={o.osoba_id}>
                {o.jmeno} {o.prijmeni}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 margin-bottom-10">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaOsoba: true })}
          >
            {lang.nova_osoba}
          </button>
        </div>
      </React.Fragment>
    );
  };

  onVlozitDoKosiku = (val) => {
    this.props.onVlozitDoKosiku(val);
  };

  onVlozitDoKosiku2 = (val) => {
    this.props.onVlozitDoKosiku2(val);
  };

  zmenaMnozstviSamoobsluha = async (index, pocet_ks) => {
    let obsahKosiku2 = this.props.obsahKosiku2;
    obsahKosiku2[index].pocet_ks = parseInt(pocet_ks);
    await this.onVlozitDoKosiku2(obsahKosiku2);
    this.overLimit();
  };

  vyhoditZKosiku = (katalog_id, sn) => {
    let obsahKosiku = [...this.props.obsahKosiku];
    const index1 = obsahKosiku.findIndex((p) => p.katalog_id === katalog_id);
    const index2 = obsahKosiku[index1].polozky.findIndex((p) => p.sn === sn);
    obsahKosiku[index1].polozky[index2].rezervace = null;
    obsahKosiku[index1].polozky[index2].login = null;
    this.onVlozitDoKosiku(obsahKosiku);
  };

  vyhoditZKosiku2 = async (index) => {
    let obsahKosiku2 = this.props.obsahKosiku2;
    obsahKosiku2 = obsahKosiku2.filter((i, index2) => index !== index2);
    await this.onVlozitDoKosiku2(obsahKosiku2);
    this.overLimit();
  };

  handleChange = (currentTarget) => {
    const attr = currentTarget.target.name;
    let { popisLocked } = this.state;
    popisLocked = attr === "popis" ? true : popisLocked;
    this.setState({ [attr]: currentTarget.target.value, popisLocked });
  };

  odeslatObjednavku = async () => {
    const objednavka = await httpService.post(
      config.apiEndpoint + "obchodniPripady/novaObjednavka.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        kosik: this.props.obsahKosiku,
        kosik2: this.props.obsahKosiku2,
        popis: this.state.popis,
        poznamka: this.state.poznamka,
        zakaznik_firma_id: this.state.zakaznik_id,
        akt_pravdepodobnost: this.state.akt_pravdepodobnost,
        prilezitost_id: this.state.prilezitost_id,
      }
    );
    sessionStorage.removeItem("kosik");
    sessionStorage.removeItem("kosikSamoobsluha");
    this.onVlozitDoKosiku([]);
    this.onVlozitDoKosiku2([]);
    window.location = "#/obchod/" + objednavka.data.prilezitost_id;
  };

  generujNazev = () => {
    const { obsahKosiku, obsahKosiku2 } = this.props;
    const { zakaznik_id, firmy, popisLocked } = this.state;
    if (!popisLocked) {
      let popis = "";
      if (obsahKosiku2 && obsahKosiku2.length > 0) {
        popis += obsahKosiku2[0].produkt.nazev;
      } else if (obsahKosiku && obsahKosiku.length > 0) {
        popis += obsahKosiku[0].info.typ;
      }
      if (
        popis !== "" &&
        zakaznik_id &&
        parseInt(zakaznik_id) !== 0 &&
        firmy &&
        firmy.length > 0
      ) {
        popis += " pro " + firmy.find((f) => f.firma_id === zakaznik_id).nazev;
      }

      this.setState({ popis });
    }
  };

  slugify = (string) => {
    if (!string) {
      return "n-a";
    }
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  render() {
    const lang = authService.getLang();
    const stat = this.pocetPolozek();
    const user = authService.getCurrentUser();
    const { obsahKosiku, obsahKosiku2 } = this.props;
    const {
      akt_pravdepodobnost,
      pr,
      prilezitost_id,
      popis,
      presLimit,
      zakaznik_id,
    } = this.state;

    let nadpis = "";
    let button = "";
    let confirmText = "";
    switch (akt_pravdepodobnost) {
      case "-1":
        nadpis = lang.pridani_varianty_k_existujicimu_obchodu;
        button = lang.pridat_variantu_do_obchodu;
        confirmText = lang.skutecne_pridat_jako_dalsi_variantu;
        break;
      case "10":
        nadpis = lang.nabidka_pro_zakaznika;
        button = lang.ulozit_nabidku;
        confirmText = lang.ulozit_jako_nabidku_pro_zakaznika;
        break;
      case "25":
        nadpis = lang.ulozeni_poptavky_mezi_moje_obchody;
        button = lang.ulozit_poptavku;
        confirmText = lang.skutecne_odeslat_poptavku;
        break;
      case "95":
        nadpis = lang.dokonce_objednavky;
        button = lang.odeslat_objednavku;
        confirmText = lang.skutecne_odeslat_objednavku;
        break;
    }

    return (
      <React.Fragment>
        <Modal
          show={this.state.odeslatShow}
          title="Odeslat?"
          onClose={() => this.setState({ odeslatShow: false })}
          onSubmit={this.odeslatObjednavku}
        >
          {confirmText}
        </Modal>
        <NadpisStranky nadpis={lang.kosik} />
        <div className="container padding-top-20">
          <h2 className="light upper underline center spacing-5">{nadpis}</h2>

          <div className="row cols-border margin-top-20">
            <div className="col-md-3">
              <small>{lang.nazev_obchodu}</small>
              <input
                type="text"
                id="popis"
                placeholder={lang.nazev_obchodu}
                name="popis"
                value={this.state.popis}
                type="text"
                onChange={(e) => this.handleChange(e)}
              />

              <h4 style={{ marginTop: "20px" }}>{lang.zakaznik}</h4>
              {this.state.firmyLoaded ? (
                <React.Fragment>
                  {!this.state.novaFirma
                    ? this.selectFirmy()
                    : this.firmaForm()}
                  {!this.state.novaOsoba
                    ? this.selectOsoby()
                    : this.osobaForm()}
                </React.Fragment>
              ) : (
                <LoadMusic />
              )}
              <h4>{lang.kontaktni_osoba}</h4>
              {this.state.firmyLoaded ? this.selectOsobyKontakt() : null}
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  {stat.pocet > 0 ? (
                    <React.Fragment>
                      <MobileView>
                        {obsahKosiku &&
                          obsahKosiku.length > 0 &&
                          obsahKosiku.map((p, index) =>
                            p.polozky.map((n, index2) =>
                              n.rezervace && n.login === user.login ? (
                                <Card
                                  nadpis={p.info.typ + " " + p.info.vyrobek}
                                  key={index + ":" + index2}
                                >
                                  <div className="obchodniPripad">
                                    <ul>
                                      <li>
                                        <strong>ID:</strong>
                                        <span style={{ float: "right" }}>
                                          {n.sn}
                                        </span>
                                      </li>
                                      <li>
                                        <strong>Cena:</strong>
                                        <span style={{ float: "right" }}>
                                          <small>CZK</small>
                                          <NumberFormat
                                            value={n.cena}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={0}
                                            displayType="text"
                                          />
                                        </span>
                                      </li>
                                    </ul>
                                    <div className="center">
                                      <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          this.vyhoditZKosiku(
                                            p.katalog_id,
                                            n.sn
                                          )
                                        }
                                      >
                                        <i className="icon icon-trash" />{" "}
                                        {lang.odstranit_z_kosiku}
                                      </button>
                                    </div>
                                  </div>
                                </Card>
                              ) : null
                            )
                          )}
                      </MobileView>
                      <BrowserView>
                        <table style={{ fontSize: "0.8em" }}>
                          <thead>
                            <tr>
                              <th>{lang.produkt}</th>
                              <th>ID/SKU</th>
                              <th>{lang.pocet_kusu}</th>
                              <th>{lang.cena}</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {obsahKosiku2 &&
                              obsahKosiku2.length > 0 &&
                              obsahKosiku2.map((i, index) => (
                                <tr key={index}>
                                  <td>
                                    <a
                                      href={
                                        "#/produkty/" +
                                        i.produkt.partner_id +
                                        "/" +
                                        this.slugify(i.produkt.partner) +
                                        "/samoobsluha/" +
                                        i.produkt.dir_id +
                                        "/" +
                                        this.slugify(i.produkt.slozka)
                                      }
                                    >
                                      {i.produkt.nazev}
                                    </a>
                                    {i.doplnky && i.doplnky.length > 0 && (
                                      <div>
                                        {i.doplnky.map((d) => (
                                          <div
                                            style={{ paddingLeft: "20px" }}
                                            key={
                                              d.doplnek.prilezitost_ciselnik_id
                                            }
                                          >
                                            + {d.doplnek.vyrobek}
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {i.produkt.sku}
                                    {i.doplnky && i.doplnky.length > 0 && (
                                      <div>
                                        {i.doplnky.map((d) => (
                                          <div
                                            style={{ paddingLeft: "20px" }}
                                            key={
                                              d.doplnek.prilezitost_ciselnik_id
                                            }
                                          >
                                            + {d.doplnek.sku}
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      value={i.pocet_ks}
                                      className="form-control"
                                      onChange={(e) =>
                                        this.zmenaMnozstviSamoobsluha(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    {i.pocet_ks !== 1 && (
                                      <div>
                                        <small>
                                          {i.produkt.cena.prodej_mena}/ks
                                        </small>
                                        <NumberFormat
                                          value={i.cenaCelkem}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          displayType="text"
                                        />
                                      </div>
                                    )}
                                    <small>{i.produkt.cena.prodej_mena}</small>
                                    <NumberFormat
                                      value={i.cenaCelkem * i.pocet_ks}
                                      thousandSeparator="."
                                      decimalSeparator=","
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      displayType="text"
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        this.vyhoditZKosiku2(index)
                                      }
                                    >
                                      <i className="icon icon-trash" />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            {obsahKosiku &&
                              obsahKosiku.length > 0 &&
                              obsahKosiku.map((p) =>
                                p.polozky.map((n) =>
                                  n.rezervace && n.login === user.login ? (
                                    <tr key={n.sn}>
                                      <td>
                                        {p.info.typ} {p.info.vyrobek}
                                      </td>
                                      <td>{n.sn}</td>
                                      <td>1</td>
                                      <td style={{ textAlign: "right" }}>
                                        <small>CZK</small>
                                        <NumberFormat
                                          value={n.cena}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          displayType="text"
                                        />
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            this.vyhoditZKosiku(
                                              p.katalog_id,
                                              n.sn
                                            )
                                          }
                                        >
                                          <i className="icon icon-trash" />
                                        </button>
                                      </td>
                                    </tr>
                                  ) : null
                                )
                              )}
                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                Celkem:
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {stat &&
                                  stat.celkemCena &&
                                  stat.celkemCena.CZK > 0 && (
                                    <div>
                                      <small>CZK</small>
                                      <NumberFormat
                                        value={stat.celkemCena.CZK}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                      />
                                    </div>
                                  )}
                                {stat &&
                                  stat.celkemCena &&
                                  stat.celkemCena.EUR > 0 && (
                                    <div>
                                      <small>EUR</small>
                                      <NumberFormat
                                        value={stat.celkemCena.EUR}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                      />
                                    </div>
                                  )}
                                {stat &&
                                  stat.celkemCena &&
                                  stat.celkemCena.USD > 0 && (
                                    <div>
                                      <small>USD</small>
                                      <NumberFormat
                                        value={stat.celkemCena.USD}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType="text"
                                      />
                                    </div>
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </BrowserView>
                    </React.Fragment>
                  ) : (
                    <div className="alert alert-danger">{lang.kosik_je_prazdny}</div>
                  )}
                </div>
                <div className="col-md-12 padding-top-20 padding-bottom-20">
                  <textarea
                    onChange={this.handleChange}
                    name="poznamka"
                    className="form-conrol"
                    placeholder={lang.poznamka_k_objednavkce_akdres_pro_doruce}
                    style={{ width: "100%", height: "150px", padding: "10px" }}
                  />
                </div>
                <div className="col-12">
                  <h4>{lang.jak_si_prejete_pokracovat}</h4>
                  <ul
                    style={{ listStyle: "none", padding: "0px", margin: "0px" }}
                  >
                    <li
                      style={{ padding: "10px", borderTop: "solid 1px #ddd" }}
                    >
                      <input
                        type="radio"
                        style={{ marginRight: "10px" }}
                        name="akt_pravdepodobnost"
                        value="10"
                        checked={akt_pravdepodobnost === "10"}
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "10" })
                        }
                      />
                      <strong
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "10" })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {lang.pouze_ulozit_nabidu_pro_zakaznika}
                      </strong>
                      <p style={{ marginLeft: "25px" }}>
                        {lang.pouze_ulozit_nabidu_pro_zakaznika_text}
                      </p>
                    </li>
                    <li
                      style={{ padding: "10px", borderTop: "solid 1px #ddd" }}
                    >
                      <input
                        type="radio"
                        name="akt_pravdepodobnost"
                        value="25"
                        style={{ marginRight: "10px" }}
                        checked={akt_pravdepodobnost === "25"}
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "25" })
                        }
                      />
                      <strong
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "25" })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {lang.vazna_poptavka_obchodni_bude_kontaktovat}
                      </strong>
                      <p style={{ marginLeft: "25px" }}>
                        {lang.vazna_poptavka_obchodni_bude_kontaktovat_text}
                      </p>
                    </li>
                    <li
                      style={{
                        padding: "10px",
                        borderTop: "solid 1px #ddd",
                        color: presLimit ? "#ddd" : null,
                      }}
                    >
                      <input
                        type="radio"
                        name="akt_pravdepodobnost"
                        value="95"
                        style={{ marginRight: "10px" }}
                        checked={akt_pravdepodobnost === "95"}
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "95" })
                        }
                        disabled={presLimit}
                      />
                      <strong
                        onClick={() =>
                          this.setState({ akt_pravdepodobnost: "95" })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {lang.zbozi_rovnou_objedna_dodat_fakturovat}
                      </strong>
                      <p style={{ marginLeft: "25px" }}>
                        {lang.zbozi_rovnou_objedna_dodat_fakturovat_text}
                      </p>
                    </li>
                    {pr && pr.length > 0 && (
                      <li
                        style={{ padding: "10px", borderTop: "solid 1px #ddd" }}
                      >
                        <input
                          type="radio"
                          name="akt_pravdepodobnost"
                          value="-1"
                          style={{ marginRight: "10px" }}
                          checked={akt_pravdepodobnost === "-1"}
                          onClick={() =>
                            this.setState({ akt_pravdepodobnost: "-1" })
                          }
                        />
                        <strong
                          onClick={() =>
                            this.setState({
                              akt_pravdepodobnost: "-1",
                              prilezitost_id: pr && pr[0].prilezitost_id,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {lang.pripojit_jako_variantu_k_jinemu_obchodu}
                        </strong>
                        <p style={{ marginLeft: "25px" }}>
                          {lang.vyberte_prosik_existuji_obchod}
                        </p>
                        <table>
                          <tr>
                            <th />
                            <th>ID</th>
                            <th>{lang.popis}</th>
                          </tr>
                          {pr.map((p) => (
                            <tr key={p.prilezitost_id}>
                              <td className="center">
                                <input
                                  type="radio"
                                  value={p.prilezitost_id}
                                  checked={prilezitost_id === p.prilezitost_id}
                                  onClick={() =>
                                    this.setState({
                                      prilezitost_id: p.prilezitost_id,
                                      akt_pravdepodobnost: "-1",
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <a href={"#/obchod/" + p.prilezitost_id}>
                                  {p.prilezitost_id}
                                </a>
                              </td>
                              <td>{p.popis}</td>
                            </tr>
                          ))}
                        </table>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="divider horizontal">{lang.ceny_bez_dph_string}</div>
          {stat.pocet > 0 ? (
            <div className="center">
              <button
                className="btn btn-primary"
                onClick={() => this.setState({ odeslatShow: true })}
                disabled={!(zakaznik_id && parseInt(zakaznik_id) > 0)}
              >
                {button}
              </button>
              {!(zakaznik_id && parseInt(zakaznik_id)) && (
                <div
                  className="alert alert-danger"
                  style={{ marginTop: "10px" }}
                >
                  {lang.pred_odeslanim_je_treba_zvolit_koncoveho_zakaznika}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  obsahKosiku: state.vyprodejSkladu,
  obsahKosiku2: state.kosikSamoobsluha,
});
const mapActionsToProps = {
  onVlozitDoKosiku: vlozitDoKosiku,
  onVlozitDoKosiku2: vlozitDoKosikuSamoobsluha,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(VyprodejKosik);
