import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import { Link } from "react-router-dom";
import config from "../../config.json";
import httpService from "../../services/httpService";
import authService from "./../../services/authService";
import LoadMusic from "../common/LoadMusic";
import FloatingLabelInput from "floating-label-react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
/*https://reactdatepicker.com/#example-16*/
import "../../../node_modules/react-datepicker/dist/react-datepicker.min.css";
import Modal from "../Modal";

class NovyDeal extends Component {
  state = {
    zakaznik_id: null,
    osoba_id: null,
    dateFormat: "dd.MM.yyyy",
  };

  async componentDidMount() {
    const aktualniFirma = authService.aktualniFirma();
    this.setState({
      aktualniFirma,
      /*zakaznik_id: aktualniFirma.firma_id*/
    });
    const vyrobci = await httpService.post(
      config.apiEndpoint + "dealy/vyrobci-1.0.php",
      {
        firma_id: aktualniFirma.firma_id,
        agenda_id: aktualniFirma.agenda_id,
      }
    );
    const firmy = await httpService.post(
      config.apiEndpoint + "zapujcky/firmy.php",
      {
        firma_id: aktualniFirma.firma_id,
      }
    );

    this.setState({
      firmy: firmy.data,
      firmyLoaded: true,
      vyrobci: vyrobci.data,
      vyrobciLoaded: true,
    });
    const index = this.state.firmy.findIndex(
      (f) =>
        this.state.aktualniFirma &&
        f.firma_id === this.state.aktualniFirma.firma_id
    );
    if (index >= 0) {
      const osoba_id =
        this.state.firmy &&
        this.state.firmy.length &&
        this.state.firmy[index].osoby &&
        this.state.firmy[index].osoby.length &&
        this.state.firmy[index].osoby[0].osoba_id;
      this.nastavOsobu(osoba_id);
    }
  }

  selectFirmy = () => {
    if (
      this.state.firmyLoaded &&
      !(this.state.firmy && this.state.firmy.length)
    ) {
      return this.firmaForm();
    }
    return (
      <React.Fragment>
        <select
          name="zakaznik_firma_id"
          className="form-control"
          onChange={this.nastavFirmu}
          defaultValue={this.state.zakaznik_id}
        >
          {!this.state.zakaznik_id && <option value="">Zvolte firmu...</option>}
          {this.state.firmy &&
            this.state.firmy.map((f) => (
              <option value={f.firma_id} key={f.firma_id}>
                {f.nazev}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 border-bottom margin-bottom-10">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaFirma: true })}
          >
            Nová firma
          </button>
        </div>
      </React.Fragment>
    );
  };

  osobaForm = () => {
    return (
      <div>
        <h5>Nová kontaktní osoba</h5>
        <FloatingLabelInput
          id="jmeno"
          placeholder="Jméno"
          name="jmeno"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="prijmeni"
          placeholder="Příjmení"
          name="prijmeni"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="pozice"
          placeholder="Pozice ve společnosti"
          name="pozice"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="mobil"
          placeholder="Mobil"
          name="mobil"
          type="text"
          onChange={this.handleChange}
        />
        <FloatingLabelInput
          id="email1"
          placeholder="E-mail"
          name="email1"
          type="text"
          onChange={this.handleChange}
        />
        <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
          <button
            className="btn btn-primary"
            onClick={() => this.ulozOsobu()}
            type="button"
          >
            Uložit novou osobu
          </button>
        </div>
      </div>
    );
  };

  firmaForm = () => {
    return (
      <div>
        <h5>Nová firma</h5>
        <FloatingLabelInput
          id="ico"
          placeholder="IČ"
          name="ico"
          type="text"
          onChange={this.handleChange}
          onBlur={this.hledejFirmu}
        />
        {this.state.hledamIco ? (
          <LoadMusic />
        ) : (
          <React.Fragment>
            <FloatingLabelInput
              id="dic"
              placeholder="DIČ"
              name="dic"
              type="text"
              value={this.state.dic}
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="nazev"
              placeholder="Název společnosti"
              name="nazev"
              value={this.state.nazev}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="ulice"
              placeholder="Ulice"
              name="ulice"
              value={this.state.ulice}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="mesto"
              placeholder="Město"
              name="mesto"
              value={this.state.mesto}
              type="text"
              onChange={this.handleChange}
            />
            <FloatingLabelInput
              id="psc"
              placeholder="PSČ"
              name="psc"
              value={this.state.psc}
              type="text"
              onChange={this.handleChange}
            />
            <div className="center padding-bottom-10 margin-bottom-10 border-bottom">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.ulozFirmu()}
              >
                Uložit novou firmu
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  ulozFirmu = async () => {
    const { ico, dic, ulice, mesto, psc, nazev } = this.state;
    console.log(this.state);
    if (ico && dic && ulice && mesto && psc && nazev) {
      this.setState({ hledamIco: true });
      const firma = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitFirmu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          ico,
          dic,
          ulice,
          mesto,
          psc,
          nazev,
        }
      );
      if (firma.data.firma_id) {
        const firmy = [...this.state.firmy];
        firmy.push(firma.data);
        this.setState({
          firmy,
          novaFirma: false,
          zakaznik_id: firma.data.firma_id,
        });
      }
      this.setState({ hledamIco: false });
    } else {
      toast.error("Pro uložení firmy musíte vyplnit všechny údaje");
    }
  };

  handleChange = (currentTarget) => {
    const attr = currentTarget.target.name;
    const val =
      attr === "souhlas"
        ? currentTarget.target.checked
        : currentTarget.target.value;
    this.setState({ [attr]: val });
  };

  hledejFirmu = async (currentTarget) => {
    this.setState({ hledamIco: true });
    const ico = currentTarget.target.value;
    const firma = await httpService.post(
      config.apiEndpoint + "zapujcky/hledatFirmu.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        ico,
      }
    );
    if (firma.data.firma_id) {
      const firmy = [...this.state.firmy];
      firmy.push(firma.data);
      this.setState({
        firmy,
        novaFirma: false,
        zakaznik_id: firma.data.firma_id,
      });
    } else {
      console.log("FIRMA", firma);
      if (firma.data.ico && firma.data.ico !== "") {
        const { ico, dic, nazev, ulice, mesto, psc } = firma.data;
        this.setState({ ico, dic, nazev, ulice, mesto, psc });
      }
    }
    this.setState({ hledamIco: false });
  };

  ulozOsobu = async () => {
    const {
      jmeno,
      prijmeni,
      mobil,
      email1,
      zakaznik_id,
      firmy,
      pozice,
    } = this.state;
    if (jmeno && prijmeni) {
      const osoba = await httpService.post(
        config.apiEndpoint + "zapujcky/ulozitOsobu.php",
        {
          firma_id: this.state.aktualniFirma.firma_id,
          jmeno,
          prijmeni,
          mobil,
          email1,
          pozice,
          zakaznik_id,
        }
      );
      if (osoba.data.osoba_id) {
        const index = firmy.findIndex((f) => f.firma_id === zakaznik_id);
        if (!firmy[index].osoby) {
          firmy[index].osoby = [];
        }
        firmy[index].osoby.push(osoba.data);
        this.setState({
          firmy,
          novaOsoba: false,
          osoba_id: osoba.data.osoba_id,
        });
      }
    } else {
      toast.error("Jméno a příjmení jsou povinné údaje");
    }
  };

  nastavFirmu = (currentTarget) => {
    if (this.state.zakaznik_id !== currentTarget.target.value) {
      this.setState({
        zakaznik_id: currentTarget.target.value,
        novaOsoba: false,
      });
    }
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (index >= 0) {
      const osoba_id =
        this.state.firmy[index].osoby &&
        this.state.firmy[index].osoby.length &&
        this.state.firmy[index].osoby[0].osoba_id;
      this.nastavOsobu(osoba_id);
    }
  };

  nastavOsobu = (osoba_id) => {
    if (osoba_id && osoba_id !== this.state.osoba_id) {
      this.setState({ osoba_id });
    }
  };

  selectOsoby = () => {
    const index = this.state.firmy.findIndex(
      (f) => f.firma_id === this.state.zakaznik_id
    );
    if (index === -1) {
      //return <React.Fragment>osoby none...</React.Fragment>;
      return null;
    }
    if (
      this.state.firmy &&
      this.state.firmy.length &&
      !(this.state.firmy[index].osoby && this.state.firmy[index].osoby.length)
    ) {
      return this.osobaForm();
    }
    return (
      <React.Fragment>
        <select
          name="osoba_id"
          className="form-control"
          onChange={this.nastavOsobu}
          defaultValue={this.state.osoba_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].osoby.map((o) => (
              <option value={o.osoba_id} key={o.osoba_id}>
                {o.jmeno} {o.prijmeni}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 margin-bottom-10">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaOsoba: true })}
          >
            Nová osoba
          </button>
        </div>
      </React.Fragment>
    );
  };

  selectOsobyKontakt = () => {
    const index = this.state.firmy.findIndex(
      (f) =>
        this.state.aktualniFirma &&
        f.firma_id === this.state.aktualniFirma.firma_id
    );
    if (index === -1) {
      return <React.Fragment>osoby2 none...</React.Fragment>;
    }
    return (
      <React.Fragment>
        <select
          name="partner_osoba_id"
          className="form-control"
          onChange={this.nastavOsobu}
          defaultValue={this.state.aktualniFirma.osoba_id}
        >
          {this.state.firmy &&
            this.state.firmy.length &&
            this.state.firmy[index].osoby.map((o) => (
              <option value={o.osoba_id} key={o.osoba_id}>
                {o.jmeno} {o.prijmeni}
              </option>
            ))}
        </select>
        <div className="center padding-vertical-10 margin-bottom-10">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.setState({ novaOsoba: true })}
          >
            Nová osoba
          </button>
        </div>
      </React.Fragment>
    );
  };

  handleChangeDate = (date) => {
    this.setState({ datum_uzavreni: date });
  };
  handleChangeDate2 = (date) => {
    this.setState({ datum_realizace: date });
  };

  odeslat = async (souhlas) => {
    var serialize = require("form-serialize");
    var form = document.querySelector("#dealform");
    var obj = serialize(form, { hash: true });
    if (souhlas) {
      const aktualniFirma = authService.aktualniFirma();
      const data = await httpService.post(
        config.apiEndpoint + "dealy/novyDeal.php",
        {
          firma_id: aktualniFirma.firma_id,
          obj,
        }
      );
      if (data.data.deal_id > 0) {
        window.location = "/#/registrace-dealu/" + data.data.deal_id;
      } else {
        toast.error("Něco se pokazilo...");
      }
    } else {
      this.setState({ odeslatConfirm: true });
    }
  };

  kontrolaDat = () => {
    var serialize = require("form-serialize");
    var form = document.querySelector("#dealform");
    var obj = serialize(form, { hash: true });
    //console.log(obj);
    if (
      obj.nazev_projektu &&
      obj.zakaznik_firma_id &&
      obj.osoba_id &&
      obj.vyrobce &&
      obj.datum_uzavreni &&
      obj.specifikace
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const lang = authService.getLang();
    const { odeslatConfirm } = this.state;
    const vseOK = this.kontrolaDat();
    let idx = -1;
    if (this.state.vyrobce && this.state.vyrobci) {
      idx = this.state.vyrobci.findIndex((v) => v.nazev === this.state.vyrobce);
    }
    return (
      <React.Fragment>
        <Modal
          show={odeslatConfirm}
          title="Odeslání dealu"
          onClose={() => this.setState({ odeslatConfirm: false })}
          hideSubmit={vseOK ? false : true}
          onSubmit={() => this.odeslat(true)}
        >
          {vseOK
            ? "Skutečně odeslat deal?"
            : "Všechna pole s vyjímkou Dalších informací jsou povinná"}
        </Modal>
        <NadpisStranky
          nadpis="Registrace nového dealu"
          obrazek="./images/deal.jpg"
        />
        <div className="container">
          <form id="dealform">
            <div className="row cols-border margin-top-20">
              <div className="col-md-3">
                <h4>{lang.zakaznik}</h4>
                {this.state.firmyLoaded ? (
                  <React.Fragment>
                    {!this.state.novaFirma
                      ? this.selectFirmy()
                      : this.firmaForm()}
                    {!this.state.novaOsoba
                      ? this.selectOsoby()
                      : this.osobaForm()}
                  </React.Fragment>
                ) : (
                  <LoadMusic />
                )}
                <h4>Kontaktní osoba</h4>
                {this.state.firmyLoaded ? this.selectOsobyKontakt() : null}
              </div>
              <div className="col-md-9">
                <h4>{lang.informace_o_projektu}</h4>
                <div className="padding-vertical-20">
                  <FloatingLabelInput
                    id="nazev_projektu"
                    placeholder={lang.nazev_projektu}
                    name="nazev_projektu"
                    value={this.state.nazev_projektu}
                    type="text"
                    onChange={this.handleChange}
                  />

                  <DatePicker
                    placeholderText={lang.datum_uzavreni}
                    selected={this.state.datum_uzavreni}
                    name="datum_uzavreni"
                    onChange={this.handleChangeDate}
                    dateFormat={this.state.dateFormat}
                    minDate={new Date()}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <h5>{lang.ktereho_vyrobce}</h5>
                <select
                  name="vyrobce"
                  className="form-control margin-bottom-10"
                  onChange={(e) => this.setState({ vyrobce: e.target.value })}
                >
                  {this.state.vyrobciLoaded
                    ? this.state.vyrobci.map((v) => (
                        <option value={v.nazev} key={v.partner_id}>
                          {v.nazev}
                        </option>
                      ))
                    : null}
                </select>
                {idx >= 0 &&
                this.state.vyrobci.length &&
                this.state.vyrobci[idx].deal_info ? (
                  <div
                    className="alert alert-danger alert-outline"
                    dangerouslySetInnerHTML={{
                      __html: this.state.vyrobci[idx].deal_info,
                    }}
                  />
                ) : null}
                <h5 className="padding-top-20">{lang.specifikace_zarizeni}</h5>
                <textarea
                  name="specifikace"
                  onChange={this.handleChange}
                  className="form-control"
                />
                <h5 className="padding-top-20">{lang.dalsi_informace}</h5>
                <textarea
                  name="dalsi_info"
                  onChange={this.handleChange}
                  className="form-control"
                />
                <div className="center padding-20" />
              </div>
            </div>
          </form>
          <p className="center">
            <button
              className="btn btn-primary"
              onClick={() => this.odeslat(false)}
            >
              {lang.odeslat_registraci_dealu}
            </button>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default NovyDeal;
