import React, { Component } from "react";
import NadpisStranky from "../common/nadpis";
import LoadMusic from "../common/LoadMusic";
import config from "../../config.json";
import httpService from "../../services/httpService";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Card from "../common/Card";
import Modal from "../Modal";
import PrihlaskaAkceFirma from "./PrihlaskaAkceFirma";
import { BrowserView } from "react-device-detect";
import DalsiAkce from "./DalsiAkce";
import authService from "../../services/authService";

class DetailAkce extends Component {
  state = { ukazPrihlasku: false };

  componentDidMount() {
    this.nactiDetaily();
  }

  componentDidUpdate() {
    const { akce } = this.state;
    if (akce && akce.akce_id != this.props.match.params.id) {
      this.nactiDetaily();
    }
  }

  nactiDetaily = async () => {
    const akceId = this.props.match.params.id;
    const akce = await httpService.post(
      config.apiEndpoint + "akce/detailFull.php",
      { akce_id: akceId }
    );
    this.setState({ akceLoaded: true, akce: akce.data });
  };

  render() {
    const { akce, akceLoaded } = this.state;
    const lang = authService.getLang();
    let prihlasovani = false;
    if (akce) {
      const d1 = new Date();
      const d2 = new Date(akce.uzaverka_prihlasek.replace(" ", "T"));
      prihlasovani = d1.getTime() <= d2.getTime() ? true : false;
    }
    var getSlug = require("speakingurl");
    return (
      <React.Fragment>
        <NadpisStranky
          nadpis={lang.kalendar_akci}
          obrazek="./images/kalendar.jpg"
        />
        <div className="container padding-top-20">
          {akceLoaded ? (
            <React.Fragment>
              <Modal
                key={akce.akce_id}
                title={lang.prihlasit_na_akci + " " + akce.nazev}
                show={this.state.ukazPrihlasku}
                hideSubmit={true}
                onClose={() => this.setState({ ukazPrihlasku: false })}
              >
                <PrihlaskaAkceFirma
                  akceId={akce.akce_id}
                  show={this.state.ukazPrihlasku}
                />
              </Modal>
              <div className="row border-bottom">
                <div className="col-md-4">
                  <img
                    src={
                      "https://api.exclusive-networks.cz/images/logoakce.php?akce_id=" +
                      akce.akce_id +
                      "&typ_akce=internal"
                    }
                  />
                </div>
                <div className="col-md-8 product-details-wrapper">
                  <div className="row border-bottom padding-bottom-20 margin-bottom-20">
                    <div className="col-6">
                      <Link to="/kalendar" className="back-to-pcate">
                        <i className="fa fa-chevron-left" />{" "}
                        {lang.zpet_na_kalendar}
                      </Link>
                    </div>
                    <div className="col-6 right">
                      {akce && akce.akce_prev && akce.akce_prev.akce_id ? (
                        <Link
                          to={
                            "/kalendar/" +
                            akce.akce_prev.akce_id +
                            "/" +
                            getSlug(akce.akce_prev.nazev, { lang: "cs" })
                          }
                          className="btn btn-sm btn-arrow btn-default margin-left-10"
                        >
                          <i className="fa fa-chevron-left" />
                        </Link>
                      ) : null}
                      {akce && akce.akce_next && akce.akce_next.akce_id ? (
                        <Link
                          to={
                            "/kalendar/" +
                            akce.akce_next.akce_id +
                            "/" +
                            getSlug(akce.akce_next.nazev, { lang: "cs" })
                          }
                          className="btn btn-sm btn-arrow btn-default margin-left-10"
                        >
                          <i className="fa fa-chevron-right" />
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <h2>{akce.nazev}</h2>
                  <div className="product-features">
                    <h3>{lang.parametry_akce}</h3>
                    <ul>
                      <li>
                        <strong>{lang.minimalni_pocet}:</strong>{" "}
                        {akce.min_pocet_ucastniku
                          ? akce.min_pocet_ucastniku
                          : lang.nestanoven}
                      </li>
                      <li>
                        <strong>{lang.maximalni_pocet}:</strong>{" "}
                        {akce.max_pocet_ucastniku
                          ? akce.max_pocet_ucastniku
                          : lang.nestanoven}
                      </li>
                      <li>
                        <strong>{lang.uzaverka_prihlasek}:</strong>{" "}
                        {akce.uzaverka_prihlasek}
                      </li>
                      <li>
                        <strong>{lang.cena_kurzu}:</strong>{" "}
                        {akce.cena_prodej ? (
                          <NumberFormat
                            value={akce.cena_prodej}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType="text"
                          />
                        ) : (
                          lang.bezplatna_akce
                        )}
                      </li>
                      <li>
                        <strong>Odměna za absolvování:</strong>{" "}
                        <NumberFormat
                          value={akce.odmena}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType="text"
                        />{" "}
                        v&cent;
                      </li>
                    </ul>
                    {akce.url_registrace ? (
                      <a
                        href={akce.url_registrace}
                        className="btn btn-primary margin-right-10"
                      >
                        {lang.prihlasit}
                      </a>
                    ) : prihlasovani && akce.bez_nasi_registrace !== "1" ? (
                      <div className="padding-top-20 border-top">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.setState({ ukazPrihlasku: true })}
                        >
                          {lang.prihlasit_na_akci}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row cols-border padding-top-20">
                <div className="col-md-4 obchodniPripad">
                  {akce.notebook ? (
                    <div className="alert alert-danger">
                      {lang.vlastni_notebook}
                    </div>
                  ) : null}
                  <ul>
                    <li>
                      <strong>{lang.datum_cas}:</strong>
                      <span style={{ float: "right" }}>{akce.termin}</span>
                    </li>
                    <li style={{ clear: "both" }}>
                      <strong>{lang.misto}:</strong>
                      <span style={{ float: "right" }}>
                        {akce.misto_konani}
                      </span>
                    </li>
                  </ul>

                  {akce.prednasejici ? (
                    <div
                      style={{ clear: "both" }}
                      className="padding-top-40 border-top"
                    >
                      <h5 className="center padding-bottom-20">
                        {lang.prednasejici}
                      </h5>
                      <div className="padding-horizontal-30">
                        {akce.prednasejici.map((p) => (
                          <Card
                            nadpis={p.jmeno + " " + p.prijmeni}
                            key={p.email1}
                            image={p.image}
                            imageCyrcle={true}
                          >
                            <ul>
                              <li>
                                {p.pozice}
                                {p.pozice && p.spolecnost ? ", " : null}
                                {p.spolecnost}
                              </li>
                            </ul>
                          </Card>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: akce.popis,
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <LoadMusic />
          )}
          <DalsiAkce />
        </div>
      </React.Fragment>
    );
  }
}

export default DetailAkce;
