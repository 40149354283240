import React, { Component } from "react";
import LoadMusic from "../common/LoadMusic";
import authService from "../../services/authService";
import httpService from "../../services/httpService";
import config from "../../config.json";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import Card from "../common/Card";
import Modal from "./../Modal";

class LicencePrehledFirma extends Component {
  state = {};

  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate() {
    if (this.props.firma_id != this.state.firma_id) {
      this.loadData();
    }
  }

  ukazDetail = async (
    zakaznik_firma_id,
    zakaznik_firma_nazev,
    vyrobce,
    rozhodne_datum_expirace
  ) => {
    this.setState({
      zakaznik_firma_id,
      zakaznik_firma_nazev,
      vyrobce,
      rozhodne_datum_expirace,
      modalShow: true
    });
    const detaildata = await httpService.post(
      config.apiEndpoint + "licence/detail.php",
      {
        firma_id: this.state.aktualniFirma.firma_id,
        zakaznik_firma_id,
        vyrobce,
        rozhodne_datum_expirace
      }
    );
    let detail = [];
    if (this.state.detail && this.state.detail.length) {
      detail = [...this.state.detail];
    }
    detail[zakaznik_firma_id] = [];
    detail[zakaznik_firma_id][vyrobce] = [];
    detail[zakaznik_firma_id][vyrobce][rozhodne_datum_expirace] =
      detaildata.data;
    this.setState({ detail });
  };

  odeslatPoptavku = async whatToDo => {
    const {
      zakaznik_firma_id,
      vyrobce,
      rozhodne_datum_expirace,
      licence,
      detail,
      aktualniFirma
    } = this.state;

    var serialize = require("form-serialize");
    var form = document.querySelector("#licence_prodlouzeni");
    var obj = serialize(form, { hash: true });

    if (obj.sn && obj.sn.length) {
      let newlicence = [...licence];
      let index1 = -2;
      let pravdepodobnost = -100;
      for (let i = 0; i < newlicence.length; i++) {
        for (let j = 0; j < newlicence[i].length; j++) {
          if (
            newlicence[i][j].zakaznik_firma_id === zakaznik_firma_id &&
            newlicence[i][j].rozhodne_datum_expirace === rozhodne_datum_expirace
          ) {
            newlicence[i][j].pocet_obnov = parseInt(
              newlicence[i][j].pocet_obnov
            );
            newlicence[i][j].pocet_obnov += obj.sn.length;
            switch (whatToDo) {
              case "prodlouzit":
                newlicence[i][j].pocet_obnov_progress = parseInt(
                  newlicence[i][j].pocet_obnov_progress
                );
                newlicence[i][j].pocet_obnov_progress += obj.sn.length;
                pravdepodobnost = 10;
                break;
              case "zahodit":
                newlicence[i][j].pocet_obnov_lost = parseInt(
                  newlicence[i][j].pocet_obnov_lost
                );
                newlicence[i][j].pocet_obnov_lost += obj.sn.length;
                pravdepodobnost = 0;
                break;
            }
          }
        }
      }

      /*-----------odeslani pozadavku------*/
      console.log(
        "odeslat poptavku",
        obj,
        zakaznik_firma_id,
        vyrobce,
        rozhodne_datum_expirace
      );
      const response = await httpService.post(
        config.apiEndpoint + "licence/objednat.php",
        {
          firma_id: aktualniFirma.firma_id,
          zakaznik_firma_id,
          vyrobce,
          rozhodne_datum_expirace,
          obj,
          pravdepodobnost
        }
      );

      let newdetail = [...detail];
      for (let i = 0; i < obj.sn.length; i++) {
        const index1 = newdetail[zakaznik_firma_id][vyrobce][
          rozhodne_datum_expirace
        ].findIndex(p => p.sn + "_" + p.prilezitost_ciselnik_id == obj.sn[i]);
        if (index1 >= 0) {
          newdetail[zakaznik_firma_id][vyrobce][rozhodne_datum_expirace][
            index1
          ].navazujici_akt_pravdepodobnost = pravdepodobnost;
          newdetail[zakaznik_firma_id][vyrobce][rozhodne_datum_expirace][
            index1
          ].navazujici_prilezitost_id = response.data.prilezitost_id;
        }
      }
      this.setState({ licence: newlicence, detail: newdetail });
    }
  };

  detail = () => {
    const {
      detail,
      zakaznik_firma_id,
      vyrobce,
      rozhodne_datum_expirace
    } = this.state;
    return detail &&
      detail[zakaznik_firma_id] &&
      detail[zakaznik_firma_id][vyrobce] &&
      detail[zakaznik_firma_id][vyrobce][rozhodne_datum_expirace] ? (
      <React.Fragment>
        <form id="licence_prodlouzeni">
          <MobileView>
            {detail[zakaznik_firma_id][vyrobce][rozhodne_datum_expirace].map(
              (l, index) => (
                <div
                  className="row border-bottom padding-vertical-10"
                  key={index}
                >
                  <div className="col-2 center">
                    <input
                      name={"sn[]"}
                      type="checkbox"
                      value={l.sn + "_" + l.prilezitost_ciselnik_id}
                      disabled={l.navazujici_prilezitost_id > 0 ? true : false}
                    />
                  </div>
                  <div className="col-10">
                    <h5 style={{ fontSize: "1em" }}>
                      {l.typ} {l.vyrobek}
                    </h5>
                    <div
                      className="badge badge-primary margin-vertical-10"
                      style={{ display: "block" }}
                    >
                      {l.sn}
                    </div>
                    {l.navazujici_prilezitost_id > 0 &&
                    l.navazujici_akt_pravdepodobnost > 0 ? (
                      <React.Fragment>
                        Již prodlužujeme obchodem{" "}
                        <Link
                          to={"/obchod/" + l.navazujici_prilezitost_id}
                          style={{ fontWeight: "bold" }}
                        >
                          {l.navazujici_prilezitost_id}
                        </Link>
                      </React.Fragment>
                    ) : l.navazujici_prilezitost_id > 0 &&
                      l.navazujici_akt_pravdepodobnost <= 0 ? (
                      <span
                        className="badge badge-secondary"
                        style={{ fontSize: "1em" }}
                      >
                        Licence vyřazena
                      </span>
                    ) : l.prodlouzeni ? (
                      <select
                        className="form-control"
                        name={
                          "licence_" + l.sn + "_" + l.prilezitost_ciselnik_id
                        }
                      >
                        {l.prodlouzeni.map((pr, index) => (
                          <option
                            value={pr.prilezitost_ciselnik_id}
                            key={index}
                          >
                            {pr.vyrobek} ({pr.prodej_mena}{" "}
                            {pr.prodej_cena_sleva} / EU: {pr.prodej_cena})
                          </option>
                        ))}
                      </select>
                    ) : (
                      "Licence bude upřesněna nabídkou později"
                    )}
                  </div>
                </div>
              )
            )}
          </MobileView>
          <BrowserView>
            <table style={{ fontSize: "0.8em" }}>
              <thead>
                <tr>
                  <th />
                  <th>Licence</th>
                  <th>Prodloužit čím/jak</th>
                </tr>
              </thead>
              <tbody>
                {detail[zakaznik_firma_id][vyrobce][
                  rozhodne_datum_expirace
                ].map((l, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      <input
                        name={"sn[]"}
                        type="checkbox"
                        value={l.sn + "_" + l.prilezitost_ciselnik_id}
                        disabled={
                          l.navazujici_prilezitost_id > 0 ? true : false
                        }
                      />
                    </td>
                    <td>
                      {l.typ} {l.vyrobek}
                      <div>
                        <span className="badge badge-primary">{l.sn}</span>
                      </div>
                    </td>
                    <td>
                      {l.navazujici_prilezitost_id > 0 &&
                      l.navazujici_akt_pravdepodobnost > 0 ? (
                        <React.Fragment>
                          Již prodlužujeme obchodem{" "}
                          <Link
                            to={"/obchod/" + l.navazujici_prilezitost_id}
                            style={{ fontWeight: "bold" }}
                          >
                            {l.navazujici_prilezitost_id}
                          </Link>
                        </React.Fragment>
                      ) : l.navazujici_prilezitost_id > 0 &&
                        l.navazujici_akt_pravdepodobnost <= 0 ? (
                        <span
                          className="badge badge-secondary"
                          style={{ fontSize: "1em" }}
                        >
                          Licence vyřazena
                        </span>
                      ) : l.prodlouzeni ? (
                        <select
                          className="form-control"
                          name={
                            "licence_" + l.sn + "_" + l.prilezitost_ciselnik_id
                          }
                        >
                          {l.prodlouzeni.map((pr, index) => (
                            <option
                              value={pr.prilezitost_ciselnik_id}
                              key={index}
                            >
                              {pr.vyrobek} ({pr.prodej_mena}{" "}
                              {pr.prodej_cena_sleva} / EU: {pr.prodej_cena})
                            </option>
                          ))}
                        </select>
                      ) : (
                        "Licence bude upřesněna nabídkou později"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </BrowserView>
          <textarea
            className="form-control margin-top-10"
            name="poznamka"
            placeholder="Poznámka k obnově"
          />
        </form>
      </React.Fragment>
    ) : (
      <LoadMusic />
    );
  };

  async loadData() {
    const aktualniFirma = authService.aktualniFirma();
    const prehled = await httpService.post(
      config.apiEndpoint + "licence/prehledFirma.php",
      {
        firma_id: aktualniFirma.firma_id,
        zakaznik_firma_id: this.props.firma_id
      }
    );
    this.setState({
      prehledLoaded: true,
      prehled: prehled.data,
      firma_id: this.props.firma_id,
      aktualniFirma
    });
  }

  render() {
    const {
      prehledLoaded,
      prehled,
      modalShow,
      zakaznik_firma_nazev,
      vyrobce
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          show={modalShow}
          title={zakaznik_firma_nazev + " / " + vyrobce}
          onClose={() => this.setState({ modalShow: false })}
          onSubmit={() => this.odeslatPoptavku("prodlouzit")}
          width="700px"
          submitText="Poptat označené"
          extraButtonText="Označené vyřadit z obnov"
          extraButtonClass="btn btn-warning"
          extraButtonOnClick={() => this.odeslatPoptavku("zahodit")}
        >
          {this.detail()}
        </Modal>
        {prehledLoaded ? (
          <React.Fragment>
            <h3 className="light upper center spacing-5">
              {this.props.firma_nazev}
            </h3>
            <MobileView>
              {prehled.map((r, index) => (
                <Card key={index} nadpis={r.vyrobce}>
                  <div className="obchodniPripad">
                    <ul>
                      <li>
                        <strong>Datum expirace</strong>
                        <span style={{ float: "right" }}>
                          {r.rozhodne_datum_expirace}
                        </span>
                      </li>
                      <li>
                        <strong>Celkový počet licencí</strong>
                        <span style={{ float: "right" }}>{r.pocet}</span>
                      </li>
                      <li>
                        <strong>Objednané licence</strong>
                        <span style={{ float: "right" }}>
                          {r.pocet_obnov == 0 ? (
                            <span className="badge badge-primary">
                              {r.pocet_obnov}
                            </span>
                          ) : (
                            r.pocet_obnov
                          )}
                        </span>
                      </li>
                      <li>
                        <strong>Licence v obnově</strong>
                        <span style={{ float: "right" }}>
                          {r.pocet_obnov_progress > 0 ? (
                            <span className="badge badge-warning">
                              {r.pocet_obnov_progress}
                            </span>
                          ) : (
                            r.pocet_obnov_progress
                          )}
                        </span>
                      </li>
                      <li>
                        <strong>Obnoveno</strong>
                        <span style={{ float: "right" }}>
                          {r.pocet_obnov_done > 0 ? (
                            <span className="badge badge-success">
                              {r.pocet_obnov_done}
                            </span>
                          ) : (
                            r.pocet_obnov_done
                          )}
                        </span>
                      </li>
                      <li>
                        <strong>Bez další obnovy</strong>
                        <span style={{ float: "right" }}>
                          {r.pocet_obnov_lost > 0 ? (
                            <span className="badge badge-alert">
                              {r.pocet_obnov_lost}
                            </span>
                          ) : (
                            r.pocet_obnov_lost
                          )}
                        </span>
                      </li>
                    </ul>
                    <div className="center">
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          this.ukazDetail(
                            r.zakaznik_firma_id,
                            r.zakaznik_firma_nazev,
                            r.vyrobce,
                            r.rozhodne_datum_expirace
                          )
                        }
                      >
                        Detail
                      </button>
                    </div>
                  </div>
                </Card>
              ))}
            </MobileView>
            <BrowserView>
              <table className="margin-top-20">
                <thead>
                  <tr>
                    <th rowSpan="2">Výrobce</th>
                    <th rowSpan="2">Datum expirace</th>
                    <th colSpan="5">Počet licencí</th>
                    <th rowSpan="2" />
                  </tr>
                  <tr>
                    <th>Celkem</th>
                    <th>V řešení</th>
                    <th>Obnovujeme</th>
                    <th>Obnoveno</th>
                    <th>
                      Nebudeme
                      <br />
                      obnovovat
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {prehled.map((r, index) => (
                    <tr key={index}>
                      <td>{r.vyrobce}</td>
                      <td style={{ textAlign: "center" }}>
                        {r.rozhodne_datum_expirace}
                      </td>
                      <td style={{ textAlign: "right" }}>{r.pocet}</td>
                      <td style={{ textAlign: "right" }}>
                        {r.pocet_obnov == 0 ? (
                          <span className="badge badge-primary">
                            {r.pocet_obnov}
                          </span>
                        ) : (
                          r.pocet_obnov
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {r.pocet_obnov_progress > 0 ? (
                          <span className="badge badge-warning">
                            {r.pocet_obnov_progress}
                          </span>
                        ) : (
                          r.pocet_obnov_progress
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {r.pocet_obnov_done > 0 ? (
                          <span className="badge badge-success">
                            {r.pocet_obnov_done}
                          </span>
                        ) : (
                          r.pocet_obnov_done
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {r.pocet_obnov_lost > 0 ? (
                          <span className="badge badge-secondary">
                            {r.pocet_obnov_lost}
                          </span>
                        ) : (
                          r.pocet_obnov_lost
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            this.ukazDetail(
                              r.zakaznik_firma_id,
                              r.zakaznik_firma_nazev,
                              r.vyrobce,
                              r.rozhodne_datum_expirace
                            )
                          }
                        >
                          Detail
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </BrowserView>
          </React.Fragment>
        ) : (
          <LoadMusic />
        )}
      </React.Fragment>
    );
  }
}

export default LicencePrehledFirma;
