import React, { Component } from "react";
import Loader from "react-loader-spinner";

class LoadMusic extends Component {
  render() {
    const color = this.props.color ? this.props.color : "#ddd";
    const size = this.props.size ? this.props.size : 80;
    if (this.props.inline) {
      return <Loader type="Bars" color={color} height={size} width={size} />;
    } else {
      /*return (
        <div className="center padding-vertical-25">
          <img
            src="images/loader-pw-anim.svg"
            style={{ height: size + "px" }}
          />
        </div>
      );*/
      return (
        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <Loader type="Bars" color={color} height={size} width={size} />
        </div>
      );
    }
  }
}

export default LoadMusic;
