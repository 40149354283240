import React, {Component} from "react";
import {connect} from "react-redux";
import authService from "../../services/authService";
import Modal from "../Modal";
import Tracking from "../obchod/Tracking";

class FaqKategorie extends Component {
    constructor(props) {
        super(props);
    }

    slugify = (string) => {
        const a =
            "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
        const b =
            "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
        const p = new RegExp(a.split("").join("|"), "g");

        return string
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with -
            .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, "-and-") // Replace & with 'and'
            .replace(/[^\w\-]+/g, "") // Remove all non-word characters
            .replace(/\-\-+/g, "-") // Replace multiple - with single -
            .replace(/^-+/, "") // Trim - from start of text
            .replace(/-+$/, ""); // Trim - from end of text
    };

    render() {
        const {baseURL, partnerId, faqKategorie, aktualniFaqKategorie, parent_id, onSelect} = this.props;

        if (!partnerId) {
            return "Načítání...";
        }

        return (
            <React.Fragment>
                <ul>
                {faqKategorie.map((k, k_index) => (
                    ((!parent_id && !k.rodic_partner_faq_kategorie_id) || (k.rodic_partner_faq_kategorie_id === parent_id)) && (
                        <li
                            className={
                                aktualniFaqKategorie === k.partner_faq_kategorie_id ? "active" : null
                            }
                            key={k_index}
                        >
                            <a
                                href={
                                    baseURL +
                                    "/" +
                                    k.partner_faq_kategorie_id +
                                    "/" +
                                    this.slugify(k.nazev)
                                }
                                onClick={() =>
                                    onSelect(k.partner_faq_kategorie_id)
                                }
                            >
                                {k.nazev}
                            </a>

                            <FaqKategorie onSelect={onSelect} parent_id={k.partner_faq_kategorie_id} baseURL={baseURL} partnerId={partnerId} faqKategorie={faqKategorie} aktualniFaqKategorie={aktualniFaqKategorie} />
                        </li>
                    )
                ))}
                </ul>
            </React.Fragment>
        );
    }
}

export default connect(null)(FaqKategorie);
