import React, { Component } from "react";
import Card from "../common/Card";
import authService from "../../services/authService";
import { MobileView, BrowserView } from "react-device-detect";
import Pagination from "../common/Pagination";

class SkupinaObchodnichPripadu extends Component {
  state = {
    obchodniPripady: [],
    aktStranka: 1,
    pocetStranek: 0,
    limitStranka: 10
  };

  componentDidMount() {
    if (this.props.obchodniPripady) {
      const pocetStranek = Math.ceil(
        this.props.obchodniPripady
          ? this.props.obchodniPripady.length / this.state.limitStranka
          : 0
      );
      this.setState({
        obchodniPripady: this.props.obchodniPripady,
        pocetStranek
      });
    }
  }

  zmenStranku = aktStranka => {
    this.setState({ aktStranka });
  };

  render() {
    let x = 1;
    const lang = authService.getLang();
    return (
      <React.Fragment>
        {this.state.obchodniPripady.length ? (
          <div
            className="row padding-top-20"
            style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}
          >
            <BrowserView style={{ width: "100%" }}>
              <p>
                Zobrazit:{" "}
                <select
                  onChange={e =>
                    this.setState({
                      aktStranka: 1,
                      limitStranka: e.target.value
                    })
                  }
                >
                  <option value="10">10</option>
                  <option value="100">100</option>
                  <option value="1000">1000</option>
                </select>{" "}
                záznamů na stánku
              </p>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{lang.zakaznik}</th>
                    <th>{lang.popis}</th>
                    <th>{lang.datum}</th>
                    <th>Obchodní kontakt</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.obchodniPripady.map((e, index) =>
                    index >=
                      (this.state.aktStranka - 1) * this.state.limitStranka &&
                    index < this.state.aktStranka * this.state.limitStranka ? (
                      <tr key={index}>
                        <td>
                          <a href={"#/obchod/" + e.prilezitost_id}>
                            {e.prilezitost_id}
                          </a>
                        </td>
                        <td>{e.zakaznik_firma_nazev}</td>
                        <td>{e.popis}</td>
                        <td>{e.datum_zadani}</td>
                        <td>
                          {e.jmeno} {e.prijmeni}
                        </td>
                        <td>
                          <a
                            href={"#/obchod/" + e.prilezitost_id}
                            className="btn btn-primary"
                          >
                            {lang.vice_o_prilezitosti}
                          </a>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
              <Pagination
                aktStranka={this.state.aktStranka}
                pocetStranek={this.state.pocetStranek}
                onSelect={this.zmenStranku}
              />
            </BrowserView>
            <MobileView>
              {this.state.obchodniPripady.map((e, index) => (
                <div className="col-md-4 obchodniPripad" key={index}>
                  <Card
                    nadpis={e.popis ? e.popis : "Bez popisu"}
                    style={{ fontSize: "0.8em" }}
                  >
                    <ul>
                      <li>
                        <strong>ID:</strong>
                        <span style={{ float: "right" }}>
                          {e.prilezitost_id}
                        </span>
                      </li>
                      <li>
                        <strong>{lang.zakaznik}:</strong>
                        <span style={{ float: "right" }}>
                          {e.zakaznik_firma_nazev}
                        </span>
                      </li>
                      <li>
                        <strong>{lang.datum}:</strong>
                        <span style={{ float: "right" }}>{e.datum_zadani}</span>
                      </li>
                      <li>
                        <strong>{lang.registrovany_deal}:</strong>
                        <span style={{ float: "right" }}>{e.deal_id}</span>
                      </li>
                    </ul>
                    <hr />
                    <div className="row">
                      <div className="col-4">
                        <img
                          src={e.foto}
                          alt={e.jmeno + " " + e.prijmeni}
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                      <div className="col-8">
                        <ul>
                          <li>
                            <strong>
                              {e.jmeno} {e.prijmeni}
                            </strong>
                          </li>
                          <li>
                            <a
                              href={"mailto:" + e.email}
                              style={{ fontSize: "0.85em" }}
                            >
                              {e.email}
                            </a>
                          </li>
                          <li>
                            <a
                              href={"callto:" + e.mobil}
                              style={{ fontSize: "0.85em" }}
                            >
                              {e.mobil}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="center padding-10">
                      <a
                        href={"#/obchod/" + e.prilezitost_id}
                        className="btn btn-primary"
                      >
                        {lang.vice_o_prilezitosti}
                      </a>
                    </div>
                  </Card>
                </div>
              ))}
            </MobileView>
          </div>
        ) : (
          <div className="alert alert-danger alert-outline margin-20">
            {lang.zadny_obchod}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SkupinaObchodnichPripadu;
